import React, { useState } from "react"
import { Button, Card, CardBody, CardHeader, Col, Container, Form, Input, Label, OffcanvasBody, Row } from "reactstrap"
const BookingInfo = () => {
  const [extraHours, setExtraHours] = useState(0);
  const [waitingCharge, setWaitingCharge] = useState(0)
  const [waitingMinutes, setWaitingMinutes] = useState(0)
  function countUP(id, prev_data_attr) {
    id(prev_data_attr + 1);
  }

  function countDown(id, prev_data_attr) {
    id(prev_data_attr - 1);
  }
  return (
    <React.Fragment>
      <Form className="d-flex flex-column justify-content-end h-100 canvas-right-sidebar">
       
          <OffcanvasBody>

            <div className="mb-3">
              <Label for="datepicker-range" className="form-label text-muted mb-1 fs-14">Booking Status (<span
                className="text-danger">*</span>)</Label>
              <Row className="g-2">
                <Col lg={6}>
                  <div className="form-check">
                    <Input className="form-check-input" type="radio" name="bookingStatus"  id="inlineCheckbox1" defaultChecked />
                    <Label className="form-check-label" for="inlineCheckbox1">Accept Booking</Label>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-check">
                    <Input className="form-check-input" type="radio" name="bookingStatus"  id="inlineCheckbox2" />
                    <Label className="form-check-label" for="inlineCheckbox2">Cancel Booking</Label>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="mb-4">
              <Label for="datepicker-range" className="form-label text-muted mb-0">Assign Driver Name (<span
                className="text-danger">*</span>)</Label>
              <select className="form-select mb-3" aria-label="Default select example">
                <option selected="">Select Drivers </option>
                <option value="1">John Selvaraj</option>
                <option value="2">Chandra Mohan</option>
                <option value="3">Rajamanickam Rajesh</option>
                <option value="3">Jegadeesh Selvaraj</option>
                <option value="3">John Risher</option>
              </select>
            </div>
            <hr />

            <div className="mb-3">
              <Label for="datepicker-range" className="form-label text-muted mb-2 fs-14">Ride Status <span
                className="text-dark fs-13">(Airport Departure)</span></Label>
              <Row className="g-2">
                <Col lg={6}>
                  <div className="form-check">
                    <Input className="form-check-input" type="radio" name="rideStatus" id="inlineCheckbox3" />
                    <Label className="form-check-label" for="inlineCheckbox3">Ride Completed</Label>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-check">
                    <Input className="form-check-input" type="radio" name="rideStatus" id="inlineCheckbox4" defaultChecked />
                    <Label className="form-check-label" for="inlineCheckbox4">Not Yet Start Ride</Label>
                  </div>
                </Col>
              </Row>
            </div>


            <div className="mb-3">
              <Row className="g-2">
                <Col lg={6}>
                  <Label for="datepicker-range" className="form-label text-dark mb-0">Extra Stop </Label>
                  <div className="input-step">
                    <button
                      type="button"
                      className="minus"
                      onClick={() => {
                        countDown(setExtraHours, extraHours);
                      }}
                    >
                      –
                    </button>
                    <Input
                      type="number"
                      className="input-number-sidebar"
                      value={extraHours}
                      min="0"
                      max="100"
                      readOnly

                    />
                    <button
                      type="button"
                      className="plus"
                      onClick={() => {
                        countUP(setExtraHours, extraHours);
                      }}
                    >
                      +
                    </button>
                  </div>


                  <div id="passwordHelpBlock" className="form-text">Waiting Charge $30</div>
                </Col>
                <Col lg={6}>
                  <Label for="datepicker-range" className="form-label text-dark mb-0">Disposal Extra Hours </Label>
                  <div className="input-step">
                    <button
                      type="button"
                      className="minus"
                      onClick={() => {
                        countDown(setWaitingCharge, waitingCharge);
                      }}
                    >
                      –
                    </button>
                    <Input
                      type="number"
                      className="input-number-sidebar"
                      value={waitingCharge}
                      min="0"
                      max="100"
                      readOnly

                    />
                    <button
                      type="button"
                      className="plus"
                      onClick={() => {
                        countUP(setWaitingCharge, waitingCharge);
                      }}
                    >
                      +
                    </button>
                  </div>
                  <div id="passwordHelpBlock" className="form-text">Waiting Charge $30</div>
                </Col>
                <Col lg={6}>
                  <Label for="datepicker-range" className="form-label text-dark mb-0">Waiting Time</Label>
                  <div className="input-step">
                    <button
                      type="button"
                      className="minus"
                      onClick={() => {
                        countDown(setWaitingMinutes, waitingMinutes);
                      }}
                    >
                      –
                    </button>
                    <Input
                      type="number"
                      className="input-number-sidebar"
                      value={waitingMinutes}
                      min="0"
                      max="100"
                      readOnly

                    />
                    <button
                      type="button"
                      className="plus"
                      onClick={() => {
                        countUP(setWaitingMinutes, waitingMinutes);
                      }}
                    >
                      +
                    </button>
                  </div>


                  <div id="passwordHelpBlock" className="form-text">Waiting Charge $30</div>
                </Col>
              </Row>
            </div>
            <hr />

            <div className="mb-4">
              <Label for="datepicker-range" className="form-label text-muted mb-1 fs-14">Hotel Payment</Label>
              <Row className="g-2">
                <Col lg={6}>
                  <div className="form-check">
                    <Input className="form-check-input" type="radio" name="hotelPayment" id="inlineCheckbox5" />
                    <Label className="form-check-label" for="inlineCheckbox5">Paid</Label>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-check">
                    <Input className="form-check-input" type="radio" name="hotelPayment" id="inlineCheckbox6" defaultChecked />
                    <Label className="form-check-label" for="inlineCheckbox6">Not Paid</Label>
                  </div>
                </Col>
                <Col lg={12}>
                  <Label for="formtextInput" className="form-label">Payment Remarks</Label>
                  <textarea className="form-control" id="exampleFormControlTextarea5" rows="3"></textarea>
                  <div id="passwordHelpBlock" className="form-text">
                    Must be 8-20 characters long.
                  </div>
                </Col>
              </Row>
            </div>
            <hr />

            <div className="mb-0">
              <Label for="datepicker-range" className="form-label text-muted mb-1 fs-14">Driver Payment</Label>
              <Row className="g-2">
                <Col lg={6}>
                  <div className="form-check">
                    <Input className="form-check-input" type="radio" name="driverPayment" id="inlineCheckbox7" />
                    <Label className="form-check-label" for="inlineCheckbox7">Paid</Label>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-check">
                    <Input className="form-check-input" type="radio" name="driverPayment" id="inlineCheckbox8" defaultChecked />
                    <Label className="form-check-label" for="inlineCheckbox8">Not Paid</Label>
                  </div>
                </Col>
                <Col lg={12}>
                  <Label for="formtextInput" className="form-label">Payment Remarks</Label>
                  <textarea className="form-control" id="exampleFormControlTextarea5" rows="3"></textarea>
                  <div id="passwordHelpBlock" className="form-text">
                    Must be 8-20 characters long.
                  </div>
                </Col>
              </Row>
            </div>
          </OffcanvasBody>

          <div className="offcanvas-footer border-top p-3 text-center hstack gap-2 bg-light position-sticky bottom-0">
            <button
              className="btn btn-success w-100"
            >
              Save
            </button>
            <button
              type="submit"
              className="btn btn-outline-light w-100 border-color-b9b9b9"
            >
              Cancel
            </button>
          </div>
        </Form>

    </React.Fragment>
  )
}

export default BookingInfo