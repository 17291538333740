import React from "react";
import { Col, Input, Row } from "reactstrap";
import Flatpickr from "react-flatpickr";
import { useFormik } from "formik";
import config from "../../common/config";
import { useState,useEffect } from "react"
const productname = [
  {
    options: [
      { label: "Naveen Rajanderan", value: 0 },
      { label: "Vetrivel Rajesh", value: 1 },
      { label: "Ajith Kumar Mohan", value: 2 },
      { label: "Jeyasri Selvaraj", value: 3 },
    ],
  },
];
const Section = ({props,setBookingCount1}) => {
  const userType=localStorage.getItem("userType");
  //const USERNAME= localStorage.getItem("username");first_name
  let username1;
 
  const [startDate,setStartDate]=useState("")
  const [endDate,setEndDate]=useState("")

  const [startDate1,setStartDate1]=useState("")
  const [endDate1,setEndDate1]=useState("")
  const [bookingCount,setBookingCount]=useState("")
  //const [bookingCount1,setBookingCount1]=useState("")

  if (userType === "1") {
    username1 =  localStorage.getItem("username");
  } else {
    username1 = localStorage.getItem("firstname");
  }



  const currentDate = new Date();
  const currentMonthStart = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
  const currentMonthEnd = new Date(currentDate.getFullYear(), currentDate.getMonth(), lastDay.getDate());

  const formatDate = (date) => {
    return `${date.getDate()} ${date.toLocaleString('default', { month: 'short' })}, ${date.getFullYear()}`;
  };

  function addLeadingZero(number) {
    return number < 10 ? `0${number}` : number.toString();
  }

  const handleDateTimeChange = (selectedDates) => {
        
    if (selectedDates.length > 0) {
      const startDate = selectedDates[0];
      const endDate = selectedDates[1];
      setStartDate(startDate)
      setEndDate(endDate)
    } else {
      setStartDate(null)
      setEndDate(null)
    }   
   // console.log(selectedDates,"FROM DATE RANGER")
    const date1=getDate(startDate,endDate);
  };

  const getDate = (startDate,endDate) => {
    if(startDate && endDate){

        const day1 = startDate.getDate();
        const monthIndex1 = startDate.getMonth();
        const year1 = startDate.getFullYear();
        const day2 = endDate.getDate();
        const monthIndex2 = endDate.getMonth();
        const year2 = endDate.getFullYear();
        const months = [
          "Jan", "Feb", "Mar", "Apr", "May", "Jun",
          "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
       
        const month1 = addLeadingZero(monthIndex1 + 1);
        const date1=addLeadingZero(day1); 
        const formattedDate1 = `${year1}-${month1}-${date1}`;

        const month2 = addLeadingZero(monthIndex2 + 1);
        const date2 = addLeadingZero(day2); 
        const formattedDate2 = `${year2}-${month2}-${date2}`;
        setStartDate1(formattedDate1)
        setEndDate1(formattedDate2)

        return [formattedDate1,formattedDate2];
      }
    
}
useEffect(() => {
  getBookingCount()

}, [startDate1,endDate1]);


useEffect(() => {
  setBookingCount1(bookingCount) 
}, [bookingCount]);



    const getBookingCount = async ()=>{

              const today = new Date();
              const day = today.getDate();
              const monthIndex = today.getMonth();
              const year = today.getFullYear();
              const month = addLeadingZero(monthIndex + 1);
              const date1=addLeadingZero(day); 
              const date2 = `${year}-${month}-${date1}`;
              const currentYear = today.getFullYear();
              const currentMonth = today.getMonth();
              // Get the first day of the current month
              const firstDayOfMonth = new Date(currentYear, currentMonth, 1);
            
              // Get the last day of the next month and subtract one day to get the last day of the current month
              const lastDayOfNextMonth = new Date(currentYear, currentMonth + 1, 0);
              const lastDayOfMonth = new Date(currentYear, currentMonth, lastDayOfNextMonth.getDate());

              const fday = firstDayOfMonth.getDate();
              const fmonthIndex = firstDayOfMonth.getMonth();
              const fyear = firstDayOfMonth.getFullYear();
              const fmonth = addLeadingZero(fmonthIndex + 1);
              const fdate1=addLeadingZero(fday); 
              const fdate2 = `${fyear}-${fmonth}-${fdate1}`;

              const lday = lastDayOfMonth.getDate();
              const lmonthIndex = lastDayOfMonth.getMonth();
              const lyear = lastDayOfMonth.getFullYear();
              const lmonth = addLeadingZero(lmonthIndex + 1);
              const ldate1=addLeadingZero(lday); 
              const ldate2 = `${lyear}-${lmonth}-${ldate1}`;
              console.log(fdate2,ldate2,startDate1,endDate1,"CURRENT MONTH DATE")
        let res=  await fetch(config.baseUrl + "api/dashboard", {
          method: "POST",

          headers: {
              "Content-Type": "application/json",
          },
          body: JSON.stringify({
              from: startDate1 ? startDate1 : fdate2,
              to: endDate1 ? endDate1 : ldate2,
              booking_overview: "all",
              upcoming_booking_from: date2,
              upcoming_booking_to: date2
          }),
      });
      let resJson = await res.json();
              if (res.status === 200) {
                //console.log(resJson.data,"Retrieved Data");
                setBookingCount(resJson.data.booking_count)
                
              } else {
                
                  console.log("Data Not retrieved")
              }

    }



  return (
    <React.Fragment>
      <Row className="mb-3 pb-1">
        <Col xs={12}>
          <div className="d-flex align-items-lg-center flex-lg-row flex-column">
            <div className="flex-grow-1">
              <h4 className="fs-16 mb-1">Good Morning, {username1}!</h4>
              <p className="text-muted mb-0">
              {/* Here's what's happening with your store today. */}
              </p>
            </div>
            <div className="mt-3 mt-lg-0">
              <form action="#">
                <Row className="g-3 mb-0 align-items-center">
                 
                  <div className="col-sm-auto">
                    <div className="input-group">
                      <Flatpickr
                        className="form-control border-0 shadow dash-filter-picker"
                        options={{
                          mode: "range",
                          dateFormat: "d M, Y",
                          defaultDate: [formatDate(currentMonthStart), formatDate(currentMonthEnd)],
                          onValueUpdate: handleDateTimeChange,
                        }}
                      />
                      <div className="input-group-text bg-primary text-white dark-theme-calendar">
                        <i className="ri-calendar-2-line"></i>
                      </div>
                    </div>
                  </div>
                 
                </Row>
              </form>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};
export default Section;
