import React from 'react';

import CountUp from "react-countup";
import  { useEffect, useState } from 'react';
//Import Icons
import FeatherIcon from "feather-icons-react";
import { Card, CardBody, Col, Row } from 'reactstrap';
import { AllBookingWidget } from './data';



const BookingWidgets = ({ bookingDetails,totalcount,setSelectedWidget, widgetId }) => {
  const modifiedWidgetData = AllBookingWidget.map((item) => {
    let counter = "";
    let suffix = "";
    //console.log(bookingDetails,"IN WIDGETS PAGE")
    // Get the corresponding counter value from the bookingDetails object based on the label
    switch (item.label) {
      case "Total Booking":
        counter = bookingDetails?.total_booking;
        break;
      case "Requested Booking":
        counter = bookingDetails?.requested_booking;
        break;
      case "Accepted Booking":
        counter = bookingDetails?.accepted_booking;
        break;
      case "Cancelled Booking":
        counter = bookingDetails?.cancelled_booking;
        break;
      case "Completed Rides":
        counter = bookingDetails?.completed_ride;
        break;
      default:
        counter = 0;
    }

    // Return the modified item with updated counter value
    return {
      ...item,
      subCounter: [{ id: 1, counter, suffix }],
    };
  });

  // const handleWidgetClick = (widgetId) => {
  //   // You can now use the widgetId to identify which widget was clicked
  //  // console.log(`Widget with id ${widgetId} clicked`);
  //  setSelectedWidget(widgetId);
  //   // You can perform any other actions based on the clicked widget
  // };

 // console.log(`Widget with id ${selectedId} clicked`);
//   useEffect(() => {

//   }, [selectedId]);
// setSelectedWidget(selectedId)


  return (
    <React.Fragment>
      <Row className='gap-4 mx-0 mb-4'>
        {modifiedWidgetData.map((item, key) => (
          <Card className={`bg-soft-${item.cardBg} card-animate border_0 w-62 mb-0 ${item.id === widgetId ? 'active-widget': ''}`} key={key} style={{cursor: 'pointer'}} onClick={() => setSelectedWidget(item.id)}>
            <CardBody>
              <div className="d-flex align-items-start">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="fw-medium text-truncate mb-0">{item.label}</p>
                  <div className="d-flex align-items-center mb-0">
                    <h2 className="mt-4 ff-secondary">
                      {item.subCounter.map((subItem, subKey) => (
                        <span
                          className="counter-value me-1"
                          data-target="825"
                          key={subKey}
                        >
                          <CountUp
                            start={0}
                            suffix={subItem.suffix}
                            separator={subItem.separator}
                            end={subItem.counter}
                            duration={4}
                          />
                        </span>
                      ))}
                    </h2>
                  </div>
                </div>
                <div className="avatar-sm flex-shrink-0">
                  <span
                    className={`avatar-title bg-${item.feaIconClass} text-info rounded-circle fs-4"`}
                  >
                    <i className={`${item.icon} text-white fs-4`}></i>
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>
        ))}
      </Row>
    </React.Fragment>
  );
};

export default BookingWidgets;