import PropTypes from "prop-types";
import React from "react";
import { Modal, ModalBody } from "reactstrap";

const DeleteModal = ({ show, onDeleteClick, onCloseClick , entityName,entityId}) => {
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true} modalClassName="flip">
      <ModalBody className="p-5 text-center">
        
        <lord-icon
            src="https://cdn.lordicon.com/gsqxdxog.json"
            trigger="loop"
            colors="primary:#405189,secondary:#f06548"

            style={{ width: "100px", height: "100px" }}
          ></lord-icon>
          
          <div className="mt-4 text-center">
            <h4>You are about to delete a {entityName}?</h4>
            <p className="text-muted mb-4 fs-15">
            Deleting the {entityName} {entityId} will remove all of its information from our database.
            </p>
         
        </div>
        <div className="hstack gap-2 justify-content-center remove">
        
          <button
            type="button"
            className="btn w-sm btn-danger "
            id="delete-record"
            onClick={onDeleteClick}
          >
            Yes, Delete It!
          </button>
          <button
            type="button"
            className="btn btn-link text-dark link-success fw-medium text-decoration-none"
            data-bs-dismiss="modal"
            onClick={onCloseClick}
          >
           <i className="ri-close-line me-1 align-middle"></i> Close
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

DeleteModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
  entityName: PropTypes.string, // Add entityName prop
  entityId: PropTypes.string,
};

export default DeleteModal;