
export const data = [
    {
        id: 1,
        driverID: '#Ht001',
        carbrandAndModel: {
            details: [
                {
                    brand: 'Mercedes Benz',
                    model: 'S400',
                    carNumber: 'FZ-G2005'
                }
            ]
        },
        driverName: {
            details: [
                {
                    name: 'John David',
                    contactNumber: '+65 6878 7876 98',
                }
            ]
        },

        seatCapacity: {
            details: [
                {
                    totalSeat: 4,
                    babySeat: 'Yes'
                }
            ]
        },
        servicePrice: {
            details: [
                {
                    price: '$180.00',
                    fromLocation: 'Singapore Disposal',
                    toLocation: 'Malaysia Disposal',
                    fromDisposal: '$180.00',
                    toDisposal: '$215.00'
                }
            ]
        },
        extraPrice: {
            details: [
                {
                    extraStopSurcharge: '$ 15.00',
                    midnightSurcharge: '$ 15.00',
                    waitingTime: '$15.00'
                }
            ]
        },
        emailAddress: 'raj198672@gmail.com',
        userName: 'Johhnmay@may',
        appRoleName: 'primary',
        appRollNameColor: 'primary',
        userAccount: 25,
        status: 'Active',
        statusColor: 'success',
        address: [
            '1 North Bridge Road,',
            '#01-02 , High Street Centre,',
            'Singapore - 179094'
        
        ]
    },
    {
        id: 2,
        driverID: '#Ht002',
        carbrandAndModel: {
            details: [
                {
                    brand: 'Mercedes Benz',
                    model: 'S400',
                    carNumber: 'FZ-G2005'
                }
            ]
        },
        driverName: {
            details: [
                {
                    name: 'John David',
                    contactNumber: '+65 6878 7876 98',
                }
            ]
        },


        seatCapacity: {
            details: [
                {
                    totalSeat: 4,
                    babySeat: 'Yes'
                }
            ]
        },
        servicePrice: {
            details: [
                {
                    price: '$180.00',
                    fromLocation: 'Singapore Disposal',
                    toLocation: 'Malaysia Disposal',
                    fromDisposal: '$180.00',
                    toDisposal: '$215.00'
                }
            ]
        },
        extraPrice: {
            details: [
                {
                    extraStopSurcharge: '$ 15.00',
                    midnightSurcharge: '$ 15.00',
                    waitingTime: '$15.00'
                }
            ]
        },
        emailAddress: 'raj198672@gmail.com',
        userName: 'Johhnmay@may',
        appRoleName: 'primary',
        appRollNameColor: 'primary',
        userAccount: 25,
        status: 'Active',
        statusColor: 'success',
        address: [
            '1 North Bridge Road,',
            '#01-02 , High Street Centre,',
            'Singapore - 179094'
        
        ]
    },
    {
        id: 3,
        driverID: '#Ht003',
        carbrandAndModel: {
            details: [
                {
                    brand: 'Mercedes Benz',
                    model: 'S400',
                    carNumber: 'FZ-G2005'
                }
            ]
        },
        driverName: {
            details: [
                {
                    name: 'John David',
                    contactNumber: '+65 6878 7876 98',
                }
            ]
        },


        seatCapacity: {
            details: [
                {
                    totalSeat: 4,
                    babySeat: 'Yes'
                }
            ]
        },
        servicePrice: {
            details: [
                {
                    price: '$180.00',
                    fromLocation: 'Singapore Disposal',
                    toLocation: 'Malaysia Disposal',
                    fromDisposal: '$180.00',
                    toDisposal: '$215.00'
                }
            ]
        },
        extraPrice: {
            details: [
                {
                    extraStopSurcharge: '$ 15.00',
                    midnightSurcharge: '$ 15.00',
                    waitingTime: '$15.00'
                }
            ]
        },
        emailAddress: 'raj198672@gmail.com',
        userName: 'Johhnmay@may',
        appRoleName: 'primary',
        appRollNameColor: 'primary',
        userAccount: 25,
        status: 'Active',
        statusColor: 'success',
        address: [
            '1 North Bridge Road,',
            '#01-02 , High Street Centre,',
            'Singapore - 179094'
        
        ]
    },
    {
        id: 4,
        driverID: '#Ht004',
        carbrandAndModel: {
            details: [
                {
                    brand: 'Mercedes Benz',
                    model: 'S400',
                    carNumber: 'FZ-G2005'
                }
            ]
        },
        driverName: {
            details: [
                {
                    name: 'John David',
                    contactNumber: '+65 6878 7876 98',
                }
            ]
        },


        seatCapacity: {
            details: [
                {
                    totalSeat: 4,
                    babySeat: 'Yes'
                }
            ]
        },
        servicePrice: {
            details: [
                {
                    price: '$180.00',
                    fromLocation: 'Singapore Disposal',
                    toLocation: 'Malaysia Disposal',
                    fromDisposal: '$180.00',
                    toDisposal: '$215.00'
                }
            ]
        },
        extraPrice: {
            details: [
                {
                    extraStopSurcharge: '$ 15.00',
                    midnightSurcharge: '$ 15.00',
                    waitingTime: '$15.00'
                }
            ]
        },
        emailAddress: 'raj198672@gmail.com',
        userName: 'Johhnmay@may',
        appRoleName: 'primary',
        appRollNameColor: 'primary',
        userAccount: 25,
        status: 'Active',
        statusColor: 'success',
        address: [
            '1 North Bridge Road,',
            '#01-02 , High Street Centre,',
            'Singapore - 179094'
        
        ]
    },
    {
        id: 5,
        driverID: '#Ht005',
        carbrandAndModel: {
            details: [
                {
                    brand: 'Mercedes Benz',
                    model: 'S400',
                    carNumber: 'FZ-G2005'
                }
            ]
        },
        driverName: {
            details: [
                {
                    name: 'John David',
                    contactNumber: '+65 6878 7876 98',
                }
            ]
        },


        seatCapacity: {
            details: [
                {
                    totalSeat: 4,
                    babySeat: 'Yes'
                }
            ]
        },
        servicePrice: {
            details: [
                {
                    price: '$180.00',
                    fromLocation: 'Singapore Disposal',
                    toLocation: 'Malaysia Disposal',
                    fromDisposal: '$180.00',
                    toDisposal: '$215.00'
                }
            ]
        },
        extraPrice: {
            details: [
                {
                    extraStopSurcharge: '$ 15.00',
                    midnightSurcharge: '$ 15.00',
                    waitingTime: '$15.00'
                }
            ]
        },
        emailAddress: 'raj198672@gmail.com',
        userName: 'Johhnmay@may',
        appRoleName: 'primary',
        appRollNameColor: 'primary',
        userAccount: 25,
        status: 'Active',
        statusColor: 'success',
        address: [
            '1 North Bridge Road,',
            '#01-02 , High Street Centre,',
            'Singapore - 179094'
        
        ]
    },
    {
        id: 6,
        driverID: '#Ht006',
        carbrandAndModel: {
            details: [
                {
                    brand: 'Mercedes Benz',
                    model: 'S400',
                    carNumber: 'FZ-G2005'
                }
            ]
        },
        driverName: {
            details: [
                {
                    name: 'John David',
                    contactNumber: '+65 6878 7876 98',
                }
            ]
        },


        seatCapacity: {
            details: [
                {
                    totalSeat: 4,
                    babySeat: 'Yes'
                }
            ]
        },
        servicePrice: {
            details: [
                {
                    price: '$180.00',
                    fromLocation: 'Singapore Disposal',
                    toLocation: 'Malaysia Disposal',
                    fromDisposal: '$180.00',
                    toDisposal: '$215.00'
                }
            ]
        },
        extraPrice: {
            details: [
                {
                    extraStopSurcharge: '$ 15.00',
                    midnightSurcharge: '$ 15.00',
                    waitingTime: '$15.00'
                }
            ]
        },
        emailAddress: 'raj198672@gmail.com',
        userName: 'Johhnmay@may',
        appRoleName: 'primary',
        appRollNameColor: 'primary',
        userAccount: 25,
        status: 'Active',
        statusColor: 'success',
        address: [
            '1 North Bridge Road,',
            '#01-02 , High Street Centre,',
            'Singapore - 179094'
        
        ]
    }

]
export const executiveNamesList = [
    {
        label: 'Booking Status',
        options: [
            
            { label: "Accepted", value: "Accepted" },
            { label: "Cancelled", value: "Cancelled" },
            { label: "Requested", value: "Requested" },
        ]
    },
    {
        label: 'Ride Status',
        options: [
            
            { label: "Completed", value: "Completed" },
            { label: "Not Yet", value: "Not Yet" },
           
        ]
    }
]
