const NoResultData = () => {
    return (
        <div className="noresult no-record-message ">
            <div className="text-center">
                 <lord-icon
            src="https://cdn.lordicon.com/msoeawqm.json"
            trigger="loop"
            colors="primary:#121331,secondary:#08a88a"

            style={{ width: "100px", height: "100px" }}
          ></lord-icon>
                <h5 className="mt-2">Sorry! No Result Found</h5>
                <p className="text-muted mb-0">We did not find any Data for your search.</p>
            </div>
        </div>
    )
}

export default NoResultData