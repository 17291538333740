import React from 'react';
import Flatpickr from "react-flatpickr";
import { Link } from 'react-router-dom';
import config from "../../common/config";
import { useState,useEffect,useRef  } from "react"

import './shedule.css'


const UpcomingSchedules = () => {
    const [selectedDateTime, setSelectedDateTime] = useState(null)
    const [date,setDate]=useState()
    const [datePart,setDatePart]=useState("")
    const [booking,setBooking]=useState("")
    const initialDisplayCount = 3;
    const [displayAll, setDisplayAll] = useState(false);

    const booking1 = Object.values(booking);
    const displayedData = displayAll ? booking1 : booking1.slice(0, initialDisplayCount);


    const handleDateTimeChange = (selectedDates) => {
        
        if (selectedDates.length > 0) {
          setSelectedDateTime(selectedDates[0]);
        } else {
          setSelectedDateTime(null);
        } 
        
        const date1=getDate();
        setDate(date1);
      };



      function addLeadingZero(number) {
        return number < 10 ? `0${number}` : number.toString();
      }




      const getDate = () => {
        if (selectedDateTime) {
            const day = selectedDateTime.getDate();
            const monthIndex = selectedDateTime.getMonth();
            const year = selectedDateTime.getFullYear();
            const months = [
              "Jan", "Feb", "Mar", "Apr", "May", "Jun",
              "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
            ];
           
            const month = addLeadingZero(monthIndex + 1);
            const date=addLeadingZero(day); 
            const formattedDate1 = `${year}-${month}-${date}`;
            setDatePart(date)
           // setDateFormat(formattedDate1)
            return formattedDate1;
        }
    }

    //console.log(date,"THE DATE SELECTED")
    useEffect(() => {
        getBookingOverview()
       
    }, [date]);

    const getBookingOverview = async()=> {
        const today = new Date();
        const day = today.getDate();
        const monthIndex = today.getMonth();
        const year = today.getFullYear();
        const month = addLeadingZero(monthIndex + 1);
        const date1=addLeadingZero(day); 
        const date2 = `${year}-${month}-${date1}`;
        setDatePart(date1)
        let res=  await fetch(config.baseUrl + "api/dashboard", {
            method: "POST",
    
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                from: date ? date : date2,
                to: date ? date :date2,
                booking_overview: "1m",
                upcoming_booking_from: date ? date : date2,
                upcoming_booking_to: date ? date : date2
            }),
        });
        let resJson = await res.json();
                if (res.status === 200) {
                   
                   setBooking(resJson.data.upcoming_booking)
                  
                } else {
                   
                    console.log("Data Not retrieved")
                }
    }
   // console.log(booking,"Retrieved Data For Upcoming Booking");

    const getType=(type)=>{
        if(type==="1"|| type==="Arrival"){
            return "Airport Arrival";
        }else if(type==="2"){
            return "Airport Departure";
        }else if(type==="3"){
            return "Point To Point(Transfer)";
        }else if(type==="4"){
            return "Singapore Disposal";
        }else if(type==="5"){
            return "Malaysia Disposal";
        }
      }

    return (
        <React.Fragment>
            <div className="col-xxl-4">
                <div className="card">
                    <div className="card-header border-0">
                        <h4 className="card-title mb-0">Upcoming Booking</h4>
                    </div>
                    <div className="card-body pt-0">
                        <div className="upcoming-scheduled">
                            <Flatpickr
                                className="form-control flatpickr-container"
                               
                                options={{
                                    dateFormat: "d M, Y",
                                    inline: true,
                                    mode: "single",
                                    value:selectedDateTime,
                                    onValueUpdate: handleDateTimeChange, 
                                                                       
                                }}
                            />

                        </div>

                        <h6 className="text-uppercase fw-semibold mt-4 mb-3 text-muted">Booking:</h6>
                        {displayedData.length > 0 ?  
                        (displayedData.map((item, index) => (
                            <div className="mini-stats-wid d-flex align-items-center mt-3" key={index}>
                            <div className="flex-shrink-0 avatar-sm">
                                <span className="mini-stat-icon avatar-title rounded-circle text-success bg-soft-success fs-4">
                                {datePart}
                                </span>
                            </div>
                            <div className="flex-grow-1 ms-3">
                                <h6 className="mb-1">{getType(item.pickup_type)}</h6>
                                <p className="text-muted mb-0">
                                <span className="text-secondary">{item.hotel_name}</span>
                                </p>
                            </div>
                            <div className="flex-shrink-0 text-end">
                                <p className="text-muted mb-0">Booking Time</p>
                                <p className="text-muted mb-0">{item.time}</p>
                            </div>
                            </div>
                        )))
                       : <div className="flex-grow-1 ms-3">No Bookings On this Date</div> 
                        }
                        <div className="mt-3 text-center">
                          {displayedData.length > 4 ? (<Link to="#" className="text-muted text-decoration-underline">
                            <button className="btn btn-link" onClick={() => setDisplayAll(!displayAll)}>
                                    View all Booking
                            </button>
                            </Link>) :''}  
                        </div>

                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default UpcomingSchedules;