import GooglePlacesAutocomplete from "react-google-places-autocomplete"

const LocationAutoComplete = ({ onChange, value, placeholder }) => { 

  console.log("value", value)

    return (<GooglePlacesAutocomplete 
      apiKey="AIzaSyDCuB4llgsLQU77YhUXVjyhsE55ORMPFsU"
      autocompletionRequest={{componentRestrictions: {country: ['SG', 'MY']}}}
      minLengthAutocomplete={3}
      selectProps={{
          placeholder: placeholder || 'Search Location',
          styles: { container: () => ({}) },
          value: value ? {  "label": value, "value": { structured_formatting: { main_text: value }}} : null,
          onChange: (selectedOpt) => { onChange(selectedOpt.value.structured_formatting.main_text)},
      }}
    />)
  }

  export default LocationAutoComplete;