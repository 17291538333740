import { Button, Card, CardBody, CardHeader, Col, Container, Input, Label, Row } from "reactstrap"
import { useState, useEffect } from "react";
import config from "../../../common/config"
import DataTable from "react-data-table-component"
import React from "react"
import { executiveNamesList, executiveNamesList1 } from "./data"
import { useMemo } from "react"
import FilterComponent from '../../../Components/datatable/filter'
import { Link } from "react-router-dom"
import BreadCrumb from "../../../Components/Common/BreadCrumb"
import Select from "react-select";
import Flatpickr from "react-flatpickr";
//import { utils, writeFileXLSX } from 'xlsx';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import NoResultData from "../../../Components/datatable/no-result"
import CustomPagination from "../../../Components/custom-pagination/pagination"
// eslint-disable-next-line react/display-name
const TableCheckBox = React.forwardRef(({ onClick, ...rest }, ref) => (
  <div className="form-check form-check-size-16" >
    <input
      htmlFor="booty-check"
      type="checkbox"
      className="form-check-input"
      ref={ref}
      onClick={onClick}
      {...rest}
    />
    <label className="form-check-label" id="booty-check" />
  </div>
))
const BookingReport = () => {
  const [reportdetails, setreportdetails] = useState([])
  const [ridestatus, setridestatus] = useState()
  const [totalpages, setTotalpages] = useState('')
  const [perpage, setPerPage] = useState(10)
  const [totalcount, setTotalcount] = useState()
  const [pageNumber, setPageNumber] = useState(1)
  const [reportdaterange, setReportDateRange] = useState([])

  const [query, setquery] = useState("");
  const userType = localStorage.getItem("userType");

  useEffect(() => {
    fetchReport();
    fetchDrivers();
    callAPI();
    //console.log("pagenumber",pageNumber)
  }, [pageNumber]);

  const [drivernumber, setdrivernumber] = useState()
  const [drivers, setDrivers] = useState("")

  const getType = (type) => {
    if (type === "1" || type === "Arrival") {
      return "Airport Arrival";
    } else if (type === "2") {
      return "Airport Departure";
    } else if (type === "3") {
      return "Point To Point(Transfer)";
    } else if (type === "4") {
      return "Singapore Disposal";
    } else {
      return "Malaysia Disposal";
    }
  }

  const getDate = (date) => {
    //console.log("date in api:",date)
    const [year, month, day] = date.split('-');
    const dateObject = new Date(year, month - 1, day);
    const monthName = dateObject.toLocaleString('default', { month: 'short' });
    const formattedDate = `${parseInt(day, 10)} ${monthName}, ${year}`;
    // console.log("formatted Date:",formattedDate)
    return formattedDate;

  }

  function getHotelPayment(value) {
    if (value === 1) {
      return 'PAID'
    } else {
      return "UNPAID"
    }
  }

  function getDriverPayment(value) {
    if (value === 1) {
      return 'PAID'
    } else {
      return "UNPAID"
    }
  }

  const getBookingPerson = (bookingHistory) => {
    let bookperson;
    if (bookingHistory && bookingHistory.length > 0) {
      bookperson = bookingHistory && bookingHistory.find((history) => history && "Booking Requested" in history);
    } else {
      // Handle the case when bookingHistory is undefined or empty
      bookperson = null; // Or set it to a default value based on your needs
    }

    if (bookperson) {
      const bookingperson = bookperson["Booking Requested"];
      const value = bookingperson["Booking Person"];
      // console.log("bookingperson:",bookingperson["Booking Person"]);
      return value;

    }

    return "";
  };


  const getDriverDetails = (id) => {
    // console.log(id,"DRIVER ID")
    if (id !== undefined || id !== null) {
      if (drivers) {
        const driverArrray = Object.values(drivers);
        const driver = driverArrray.find((obj) => obj._id === id);
        if (driver) {
          const phoneNumber = driver && driver.phone_number;

          return phoneNumber;
        } else {
          return ""
        }

      } else {
        return ""
      }
    } else {
      return "";
    }
  }


  const [carNumber, setCarNumber] = useState("")
  const getDriverDetails1 = (id) => {
    // console.log(id,"DRIVER ID")
    if (id !== undefined || id !== null) {
      if (drivers) {
        const driverArrray = Object.values(drivers);
        const driver = driverArrray.find((obj) => obj._id === id);
        if (driver) {
          const carNumber = driver && driver.car_number;
          //setCarNumber(carNumber);
          return carNumber;
        } else {
          return ""
        }

      } else {
        return ""
      }
    } else {
      return "";
    }


  }


  const fetchDrivers = async () => {
    await fetch(config.baseUrl + "api/admin/driverlist", {
      method: "POST",

      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        page: pageNumber,
        limit: "all",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("Drivers List",data);
        setDrivers(data.data);

      });
  }

  const handleSubmit = () => {
    let fromDate = "";
    let toDate = "";

    if (reportdaterange.length === 2) {
      fromDate = reportdaterange[0];
      toDate = reportdaterange[1];
    } else if (reportdaterange.length === 1) {
      fromDate = reportdaterange[0];
      toDate = reportdaterange[0];
    }

    let requestBody = {
      query: query,
      confirmation_status: bookstatus5,
      ride_status: statusRide5,
      from: fromDate,
      to: toDate,
      page: pageNumber,
      limit: perpage,
      hotel_name: userType === "1" ? (selectedHotel ? selectedHotel.label : "") : hotelname,

    };


    fetch(config.baseUrl + "api/report/booking", {
      method: "POST",

      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody)
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data.data);
        setreportdetails(data.data)
        setTotalpages(data.totalPages)
        setTotalcount(data.totalCount)
      })
  }
  const [hotelList, setHotelList] = useState([])
  const [selectedHotel, setSelectedHotel] = useState("");
  const callAPI = async () => {
    console.log("API CALL");
    //setIsLoading(true)
    await fetch(config.baseUrl + "api/admin/hotellist", {
      method: "POST",

      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        page: pageNumber,
        limit: "all",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data.data);
        setHotelList(data.data);
        //setIsLoading(false)
      });
    //setIsLoading(false)
  }

  const activeHotels = hotelList.filter((hotel) => hotel.status === 1);
  console.log("Active hotels", activeHotels)
  const hotelOptions = hotelList && activeHotels.map((hotel) => ({
    value: hotel._id,
    label: hotel.hotel_name,

  }));
  const hotelname = localStorage.getItem("hotelname");
  const userType1 = localStorage.getItem("userType");

  const handleHotelChange = (selectedOption) => {

    setSelectedHotel(selectedOption);
    console.log(selectedOption.label,)

  };



  const fetchReport = async () => {
    console.log(selectedHotel.label, "rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr")
    try {
      const response = await fetch(config.baseUrl + "api/report/booking", {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: "",
          confirmation_status: "",
          ride_status: "",
          // hotel_id: selectedHotel ? selectedHotel.value : null,
          hotel_name: userType === "1" ? (selectedHotel ? selectedHotel.label : "") : hotelname,
          from: "",
          to: "",
          page: pageNumber,
          limit: perpage,
        })
      });
      const data = await response.json();
      console.log(data.data);

      setreportdetails(data.data);
      setTotalpages(data.totalPages)
      setTotalcount(data.totalCount)
    }
    catch (error) {
      console.error(error);
    }
  };


  const formatMobileNumber = (number) => {
    return typeof number === 'number' ? number.toString() : number;
  };

  const exceldata = reportdetails.map((obj, index) => {

    let travelInfo = "";

    if (obj.pickup_type === "1") {
      travelInfo = `Pickup Airport: ${obj.airport_name}\nDrop Location: ${obj.destination}\nFlight Number: ${obj.flight_number}`;
    } else if (obj.pickup_type === "2") {
      travelInfo = `Pickup Location: ${obj.destination}\nDrop Airport: ${obj.airport_name}\nFlight Number: ${obj.flight_number}`;
    } else if (obj.pickup_type === "3" || obj.pickup_type === "4" || obj.pickup_type === "5") {
      travelInfo = `Pickup Location: ${obj.pickup_location}\nDrop Location: ${obj.dropoff_location}`;
    }
    return {
      "Sr. No": index + 1,
      "Booking ID": obj.booking_id,
      "Hotel Name": obj.hotel_name,
      "Booking Person Name": getBookingPerson(obj.booking_history),
      "Booking Date & Time": obj.booking_date,
      "Booking Info": `Passenger Name:\n${obj.passenger_name}\n${obj.country_code} ${formatMobileNumber(obj.passenger_contact_number)}\nPassenger Seat: ${obj.number_of_passengers.adult}\nBaby Seat: ${obj.number_of_passengers.child}\nLuggage: ${obj.number_of_luggage}`,
      "Travel Date & Time": ` ${getDate(obj.date)} - ${obj.time}`,
      "Travel Type": getType(obj.pickup_type),
      "Travel Info": travelInfo,
      "Car": ` ${obj.car_info.car_brand} - ${obj.car_info.car_model}`,
      "Assign Driver": `${obj.confirmation_status === 2 ? obj.driver_name : ''}\n${obj.confirmation_status === 2 ? "+65 " + getDriverDetails(obj.driver_id) : ''}${obj.confirmation_status === 2 ? "\nCar Plate No:" + getDriverDetails1(obj.driver_id) : ''}`,
      "Confirmation Date & Time": obj.confirmation_date,
      "Booking Status": getbookstatus(obj.confirmation_status),
      "Ride status": getridestatus(obj.ride_status),
      "Payment Info": `${obj.payment_info.disposal_charge > 0 && typeof obj.payment_info.disposal_charge !== "undefined" ? "\nDisposalCharge: $" + obj.payment_info.disposal_charge : ""}${obj.payment_info.extra_disposal_charge > 0 && typeof obj.payment_info.extra_disposal_charge !== "undefined" ? "\nExtraDisposal Charge: $" + obj.payment_info.extra_disposal_charge : ""}${obj.payment_info.midnight_charge > 0 && typeof obj.payment_info.midnight_charge !== "undefined" ? "\nMidnightCharge: $" + obj.payment_info.midnight_charge : ""}${obj.payment_info.waiting_minutes_charge > 0 && typeof obj.payment_info.waiting_minutes_charge !== "undefined" ? "\nWaitingCharge: $" + obj.payment_info.waiting_minutes_charge : ""}${obj.payment_info.arrival_charge > 0 && typeof obj.payment_info.arrival_charge !== "undefined" ? "\nArrivalCharge: $" + obj.payment_info.arrival_charge : ""}${obj.payment_info.extra_stop_charge > 0 && typeof obj.payment_info.extra_stop_charge !== "undefined" ? "\nExtra Stop Charge: $" + obj.payment_info.extra_stop_charge : ""}${obj.payment_info.point_point_charge > 0 && typeof obj.payment_info.point_point_charge !== "undefined" ? "\nPointtopoint Charge: $" + obj.payment_info.point_point_charge : ""}${obj.payment_info.departure_charge > 0 && typeof obj.payment_info.departure_charge !== "undefined" ? "\nAirportDeparture: $" + obj.payment_info.departure_charge : ""}${obj.surcharge_percentage ? "\nSurcharge Percentage: " + obj.surcharge_percentage * 25 + "%" : ""}`,
      "Payment Total": obj.payment_info.total,
      "Booking Remarks": obj.remark || '',
      "Additional Remarks": obj.hotel_payment_remark || '',
    }

  });



  const pdfdata = reportdetails.map(obj => {
    return {
      "Booking ID": obj.booking_id,
      "Booking Info": `Passenger Name: ${obj.passenger_name}\nPassenger Number: ${obj.country_code} ${obj.passenger_contact_number}\nAdult Seat: ${obj.number_of_passengers.adult}\nBaby Seat: ${obj.number_of_passengers.child}`,
      "Travel Info": `${getType(obj.pickup_type)}\n${obj.pickup_type !== "4" && obj.pickup_type !== "5" ? "Destination: " + obj.destination : "Drop Location: " + obj.dropoff_location}\nDate: ${getDate(obj.date)}\nTime: ${obj.time}`,
      "Booking Confirmation": obj.confirmation_date,
      "Car Info": `Car Brand: ${obj.car_info.car_brand}\nCar Model: ${obj.car_info.car_model}${obj.confirmation_status === 2 ? "\nAssign Driver: " + obj.driver_name : ''} ${obj.confirmation_status === 2 ? "+65 " + getDriverDetails(obj.driver_id) : ''}`,
      "Booking Status": getbookstatus(obj.confirmation_status),
      "Ride Status": getridestatus(obj.ride_status),
      "Payment Info": `Total: $ ${obj.payment_info.total}\n${obj.payment_info.disposal_charge > 0 && typeof obj.payment_info.disposal_charge !== "undefined" ? "\nDisposal Charge: $" + obj.payment_info.disposal_charge : ""} ${obj.payment_info.extra_disposal_charge > 0 && typeof obj.payment_info.extra_disposal_charge !== "undefined" ? "\nExtra Disposal Charge: $" + obj.payment_info.extra_disposal_charge : ""}${obj.payment_info.midnight_charge > 0 && typeof obj.payment_info.midnight_charge !== "undefined" ? "\nMidnight Charge: $" + obj.payment_info.midnight_charge : ""}${obj.payment_info.waiting_minutes_charge > 0 && typeof obj.payment_info.waiting_minutes_charge !== "undefined" ? "\nWaiting Charge: $" + obj.payment_info.waiting_minutes_charge : ""}${obj.payment_info.arrival_charge > 0 && typeof obj.payment_info.arrival_charge !== "undefined" ? "\nArrival Charge: $" + obj.payment_info.arrival_charge : ""}${obj.payment_info.extra_stop_charge > 0 && typeof obj.payment_info.extra_stop_charge !== "undefined" ? "\nExtra Stop Charge: $" + obj.payment_info.extra_stop_charge : ""}${obj.payment_info.point_point_charge > 0 && typeof obj.payment_info.point_point_charge !== "undefined" ? "\nPointtopoint Charge: $" + obj.payment_info.point_point_charge : ""}${obj.payment_info.departure_charge > 0 && typeof obj.payment_info.departure_charge !== "undefined" ? "\nAirport Departure: $" + obj.payment_info.departure_charge : ""}`,
    }

  });

  const userType2 = localStorage.getItem("userType");
  const loginhotelname = localStorage.getItem("hotelname");
  const loginhotelid = localStorage.getItem("hotelid")
  const handlePageChange = async (pageNumber) => {
    //callAPI(pageNumber)
    //     console.log("pagenumber",pageNumber)
    //     console.log(JSON.stringify({
    //      page: pageNumber,
    //      limit: perpage,
    //  }));
    await fetch(config.baseUrl + "api/report/booking", {
      method: "POST",

      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: "",
        confirmation_status: "",
        ride_status: "",
        from: "",
        to: "",
        page: pageNumber,
        limit: perpage,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        setreportdetails(data.data)
        setTotalpages(data.totalPages)
        setTotalcount(data.totalCount)

      });


    // console.log("pagenumber",pageNumber)

  };

  const handlePerRowsChange = async (newPerPage, page) => {
    // if(newPerPage === totalcount){
    //     console.log("Need to show all data in list");
    //     return
    // }else{
    //     console.log("Selected data in list");
    //     return
    // }
    await fetch(config.baseUrl + "api/report/booking", {
      method: "POST",

      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: "",
        confirmation_status: "",
        ride_status: "",
        from: "",
        to: "",
        page: page,
        limit: newPerPage,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setreportdetails(data.data)
        //  setTotalpages(data.totalPages)
        setTotalcount(data.totalCount)

      });

    setPerPage(newPerPage);
    //setLoading(false);
  };
  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: 'ALL',
  };


  const [allExecutiveName, setAllExecutiveName] = React.useState(null)
  const [bookstatus, setbookstatus] = useState();
  const [statusRide, setstatusRide] = useState();
  const [bookstatus5, setbookstatus5] = useState("");
  const [statusRide5, setstatusRide5] = useState("");
  var testride = "";
  var testbook = "";
  const [status, setStatus] = useState()
  const [status1, setStatus1] = useState()

  function changeStatus(e) {
    setStatus(e.value);
    const value = e.value;

    if (value === "Requested") { setbookstatus5("1"); }
    if (value === "Accepted") { setbookstatus5("2"); }
    if (value === "Cancelled") { setbookstatus5("3"); }

    console.log("bookstatus:", testbook)
  }


  function changeStatus1(e) {
    setStatus1(e.value);
    const value = e.value;


    if (value === "Not Yet") { setstatusRide5("1"); }
    if (value === "Completed") { setstatusRide5("2"); }
    console.log("ridestatus:", testride)
    // console.log("bookstatus:",testbook)
  }



  const getTime = (time1) => {
    if (time1) {

      const [time, period] = time1.split(' ');
      let [hours, minutes] = time.split(':');
      hours = parseInt(hours, 10);
      if (period === 'PM' && hours !== 12) {
        hours += 12;
      } else if (period === 'AM' && hours === 12) {
        hours = 0;
      }
      hours = hours.toString().padStart(2, '0');
      minutes = minutes.padStart(2, '0');

      const time24hr = ` ${hours}.${minutes}`;
      return time24hr;
    }
    return "";
  };



  const firstname23 = localStorage.getItem("firstname")

  const handlequeryChange = (event) => {
    const value = event.target.value;
    setquery(value);
  }
  const options = {
    mode: "range",
    onChange: (selectedDates, dateStr, instance) => {
      const myArray = dateStr.split(" to ");
      setReportDateRange(myArray);
      console.log(myArray)


    }
  }

  const handleCancel = (event) => {
    window.location.reload();
  };



  const columns = [
    {
      name: <span className='font-weight-bold fs-13 text-nowrap'>Booking ID</span>,
      selector: row => row.booking_id,
      cell: (row =>

        <div className="fw-medium" >{row.booking_id}
        </div>

      ),
      sortable: true,
    },
    {
      name: <span className='font-weight-bold fs-13 text-nowrap'>Booking Info</span>,
      selector: row => row.booking_info,
      cell: (row =>
        <div>
          <h5 className="fs-14 mb-1">{row.passenger_name}</h5>
          <p className="text-secondary fw-medium  mb-0"> {row.country_code} {row.passenger_contact_number}</p>
          {row.number_of_passengers && (
            <div>
              <p className="text-muted mb-0 mt-3">
                <span className="table-label">Passenger Seat : </span> <span className="fw-medium text-dark">{row.number_of_passengers.adult}</span>
              </p>
              {row.number_of_passengers.child ? <p className="text-muted mb-0">
                <span className="table-label">Baby Seat : </span> <span className="fw-medium text-dark">{row.number_of_passengers.child}</span>
              </p> : ''}
              <p className="text-muted mb-0">
                <span className="table-label">Luggage  : </span> <span className="fw-medium text-dark">{row.number_of_luggage}</span>
              </p>
            </div>
          )}
          {userType !== "2" && userType !== "3" && (<p className="text-muted mb-0 mt-3">
            <span className="table-label">Hotel Name : </span>
            <span className="fw-medium text-dark d-block">{row.hotel_name}</span></p>)}
          <p className="text-muted mb-0">
            <span className="table-label">Booking Person: </span>
            <span className="fw-medium text-dark d-block">{getBookingPerson(row.booking_history)}</span>
          </p>
        </div>
      ),
      sortable: true,
    },
    {
      name: <span className='font-weight-bold fs-13 text-nowrap'>Travel Info</span>,
      selector: row => row.data,
      width: '300px',
      cell: (row) => (
        <div >
          {row.pickup_type && (
            <div >
              <h5 className="fs-14 mb-1">{getType(row.pickup_type)}</h5>
              {/*<p className="text-secondary fw-medium  mb-0">{item.mobileNumber}</p>*/}

              {row.pickup_type !== "3" && row.pickup_type !== "4" && row.pickup_type !== "5" && (<p className="text-muted mb-0 mt-2">
                <span className="table-label d-block">Destination </span>
              </p>)}
              {row.pickup_type !== "3" && row.pickup_type !== "4" && row.pickup_type !== "5" && (<h5 className="fs-14 mb-1 text-dark"> <span className="">{row.destination}</span></h5>)}
              {row.flight_number ? <p className="text-muted mb-0 mt-2">
                <span className="table-label">Flight Number : </span>
                <span className="fw-medium text-dark">{row.flight_number}</span>
              </p> : ''}


              {getType(row.pickup_type) !== "Airport Arrival" && getType(row.pickup_type) !== "Airport Departure" && row.pickup_location && (
                <div className="d-flex align-items-center gap-1 mt-2">
                  <span className="text-muted  table-label">Pickup Location: </span>
                  <span className="fw-medium text-dark ellipsis" title={row.pickup_location}>{row.pickup_location}</span>
                </div>
              )}


              {row.dropoff_location ? <div className="d-flex align-items-center gap-1 mt-2">
                <span className=" text-muted table-label">Drop Location : </span>
                <span className="fw-medium text-dark ellipsis" title={row.dropoff_location}>{row.dropoff_location}</span>
              </div> : ''}
              {row.extra_stop ? <p className="text-muted mb-0 mt-0 mb-2">
                <span className="table-label">Extra Stop : </span>
                <span className="fw-medium text-dark">{row.extra_stop}</span>
              </p> : ''}
              {row.pickup_type === "4" && <p className="text-muted mb-0 mt-0">
                <span className="table-label">Disposal Hours:</span>
                <span className="fw-medium text-dark">{row.disposal_hours}</span></p>}
              {row.pickup_type === "5" && <p className="text-muted mb-0 mt-0">
                <span className="table-label">Disposal Hours:</span>
                <span className="fw-medium text-dark">{row.disposal_hours}</span></p>
              }
              {row?.remark ? <p className="text-muted mb-0 mt-1">Remarks: <span className="fw-medium text-dark d-block ellipsis" title={row?.remark}>{row?.remark}</span></p> : ''}
            </div>
          )}
        </div>
      ),
      sortable: true,
    },
    {
      name: <span className='font-weight-bold fs-13 text-nowrap'>Travel Date & Time</span>,
      selector: row => row.bookingID,
      cell: (row =>
        <div>
          <h5 className="fs-14 mb-1">{getDate(row.date)}</h5>
          <p className="text-muted mb-0 mt-0">
            <span className="d-inline">Time:</span>
            <span className="fs-14 text-dark d-inline">{row.time}</span>
          </p>
        </div>
      ),
      sortable: true,
    },
    {
      name: <span className='font-weight-bold fs-13 text-nowrap'>Car Info</span>,
      selector: row => row.carInfo,
      cell: (row => (
        <div>
          <h5 className="fs-14 mb-1 text-nowrap">{row.car_info.car_brand} {"-"} {row.car_info.car_model}</h5>
          {row.confirmation_status === 2 && row.driver_name && (
            <div>
              <p className="text-muted mb-0 mt-3">
                Assign Driver:
                <span className="fw-medium d-block text-dark text-uppercase">{row.driver_name}</span>
              </p>
              <p className="text-secondary fw-medium  mb-0">+65 {row.confirmation_status === 2 && getDriverDetails(row.driver_id)}</p>
            </div>

          )}
          {
            row.confirmation_status === 2 && row.driver_name && (
              <div>
                <p className="text-muted mb-0 mt-3">
                  Car Plate No:
                </p>
                <p className="fw-medium d-block text-dark text-uppercase">{row.confirmation_status === 2 && getDriverDetails1(row.driver_id)}</p>
              </div>
            )
          }


        </div>
      )),
      sortable: true,
    },
    {
      name: <span className='font-weight-bold fs-13 text-nowrap'>Booking Status</span>,
      selector: row => row.confirmation_status,
      cell: (row => <div>{row.confirmation_status1}</div>),
      sortable: true,
    },
    {
      name: <span className='font-weight-bold fs-13 text-nowrap'>Date & Time Info</span>,
      selector: row => row.travelDateAndTime,
      cell: (row => <div className="text-muted mb-0 mt-0">
        <span className="d-block">Booking Date: </span>
        <span className="fw-medium text-dark d-block text-nowrap">{row.booking_date}</span>

        {
          row.confirmation_status === 2 && (
            <div>
              <p className="text-muted mb-0 mt-3">
                Confirmation Date:

              </p>
              <p className="text-dark mb-0">  {row.confirmation_date} </p>
            </div>
          )
        }
      </div>),
      sortable: true,
    },
    {
      name: <span className='font-weight-bold fs-13 text-nowrap'>Ride Status</span>,
      selector: row => row.ride_status,
      cell: (row => <div to="#">{row.ride_status1}</div>),
      sortable: true,
    },
    {
      name: <span className='font-weight-bold fs-13 text-nowrap'>Payment Info</span>,
      selector: row => row.paymentInfo,
      cell: (row =>
        <div>


          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1"><span className="text-muted fs-12 fw-medium">Total:</span> <span className="text-dark">${row.payment_info.total}</span></h5>
              {row.payment_info.pickup_type === "1" && row.payment_info.arrival_charge ? (
                <p className="mb-0 mt-3">
                  <span className="text-muted">{getType(row.payment_info.pickup_type)}:</span>{" "}
                  <span className="fw-medium text-dark">
                    ${row.payment_info.arrival_charge}
                  </span>
                </p>
              ) : null}

              {row.payment_info.pickup_type === "2" && row.payment_info.departure_charge ? (
                <p className="mb-0 mt-3">
                  <span className="text-muted">{getType(row.payment_info.pickup_type)}:</span>{" "}
                  <span className="fw-medium text-dark">
                    ${row.payment_info.departure_charge}
                  </span>
                </p>
              ) : null}

              {row.payment_info.pickup_type === "3" && row.payment_info.point_point_charge ? (
                <p className="mb-0 mt-3">
                  <span className="text-muted">{getType(row.payment_info.pickup_type)}:</span>{" "}
                  <span className="fw-medium text-dark">
                    ${row.payment_info.point_point_charge}
                  </span>
                </p>
              ) : null}


              {["4", "5"].includes(row.payment_info.pickup_type) ? (
                <>
                  {row.payment_info.midnight_charge !== undefined && row.payment_info.midnight_charge !== null && row.payment_info.midnight_charge > 0 && (
                    <p className="mb-0 mt-3">
                      <span className="text-muted">Midnight Surcharge:</span>{" "}
                      <span className="fw-medium text-dark">
                        ${row.payment_info.midnight_charge}
                      </span>
                    </p>
                  )}

                  {/* {row.payment_info.midnight_charge !== undefined && row.payment_info.midnight_charge !== null &&  row.payment_info.midnight_charge > 0  && (
        <p className="mb-0 mt-3">
          <span className="text-muted">Midnight Surcharge:</span>{" "}
          <span className="fw-medium text-dark">
            ${row.payment_info.midnight_charge}
          </span>
        </p>
      )} */}
                  {row.payment_info.disposal_charge !== undefined && row.payment_info.disposal_charge !== null && (<p className="mb-0 mt-3"><span className="text-muted">{getType(row.payment_info.pickup_type)}:</span>{" "}<span className="fw-medium text-dark">
                    ${row.payment_info.disposal_charge}</span></p>)}
                  {row.payment_info.extra_disposal_charge !== undefined && row.payment_info.extra_disposal_charge !== null && row.payment_info.extra_disposal_charge > 0 && (
                    <p className="mb-0 mt-0">
                      <span className="text-muted">Extra Hours For Disposal (2 Hrs) :</span>{" "}
                      <span className="fw-medium text-dark">
                        ${row.payment_info.extra_disposal_charge}
                      </span>
                    </p>
                  )}
                </>
              ) : null}
              {["1", "2", "3"].includes(row.payment_info.pickup_type) ? (
                <>

                  {row.payment_info.midnight_charge !== undefined && row.payment_info.midnight_charge !== null && row.payment_info.midnight_charge > 0 && (
                    <p className="mb-0 mt-0">
                      <span className="text-muted">Midnight Surcharge:</span>{" "}
                      <span className="fw-medium text-dark">
                        ${row.payment_info.midnight_charge}
                      </span>
                    </p>
                  )}

                  {row.payment_info.waiting_minutes_charge !== undefined && row.payment_info.waiting_minutes_charge !== null && row.payment_info.waiting_minutes_charge > 0 && (
                    <p className="mb-0 mt-0">
                      <span className="text-muted">Waiting Time Price (30 Mins):</span>{" "}
                      <span className="fw-medium text-dark">
                        ${row.payment_info.waiting_minutes_charge}
                      </span>
                    </p>
                  )}
                  {row.payment_info.extra_stop_charge !== undefined && row.payment_info.extra_stop_charge !== null && (
                    <p className="mb-0 mt-3">

                      <span className="text-muted">Extra Stop Surcharge:</span>{" "}
                      <span className="fw-medium text-dark">
                        ${row.payment_info.extra_stop_charge}
                      </span>
                    </p>
                  )}

                  {row.surcharge_percentage ?  <div>
                    <span className="table-label text-muted">Surcharge Percentage: </span>
                    <span className="fw-medium text-dark">{row.surcharge_percentage * 25}%</span>
                  </div> : ''}

                </>
              ) : null}


              {/* <p className="mb-0 mt-3"><span className="text-muted">Hotel Payment Status : </span> <span className={`fw-medium text-${row.paymentNameColor}`}>{row.hotel_payment1}</span></p> */}
              {/* {userType !== "2" && userType !== "3" && (<p className="mb-0"><span className="text-muted">Driver Payment Status : </span> <span className={`fw-medium text-${row.driverpaymentNameColor}`}>{row.driver_payment1}</span></p>)} */}
            </div>
          </div>


        </div>

      ),
      sortable: true,
    },
  ];

  /********Data table Search****** */
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
    false
  );
  const filteredItems = reportdetails.filter(
    item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
  );
  statusIdentification(filteredItems);
  RidestatusIdentification(filteredItems);
  PaymentstatusIdentification(filteredItems);
  DriverpaymentstatusIdentification(filteredItems);
  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  function getbookstatus(value) {
    if (value === 2) {
      return 'ACCEPTED';
    } else if (value === 1) {
      return 'REQUESTED';

    } else if (value === 3) {
      return 'CANCELLED';

    }
  }





  function statusIdentification1(array) {
    for (var i = 0; i < array.length; i++) {
      if (array[i].confirmation_status === 2) {
        array[i].status1 = 'Accepted';
        array[i].statusColor = 'success';
      } else if (array[i].confirmation_status === 1) {
        array[i].status1 = 'Requested';
        array[i].statusColor = 'warning';
      } else if (array[i].confirmation_status === 3) {
        array[i].status1 = 'Cancelled';
        array[i].statusColor = 'danger';
      }
    }
  }
  function statusIdentification(array) {
    //  console.log("inside app")
    for (var j = 0; j < array.length; j++) {
      // console.log("inside for loop,value of confirmation_status is ",array[j].confirmation_status)
      if (array[j].confirmation_status === 1) {
        array[j].confirmation_status1 = 'Requested';
        array[j].confirmationNameColor = 'warning';
      }
      else if (array[j].confirmation_status === 2) {
        array[j].confirmation_status1 = 'Accepted';
        array[j].confirmationNameColor = 'success';
      }

      else {
        array[j].confirmation_status1 = 'Cancelled';
        array[j].confirmationNameColor = 'danger';


      }
    }
    //console.log(filteredItems);
  }

  function getridestatus(value) {

    if (value === "2") {
      return 'COMPLETED';
    } else if (value === "1") {
      return 'NOT YET';

    }
  }

  function RidestatusIdentification1(array) {
    for (var i = 0; i < array.length; i++) {
      if (array[i].ride_status === 2) {
        array[i].status2 = 'Completed';
        array[i].statusColor = 'success';
      } else if (array[i].ride_status === 1) {
        array[i].status2 = 'Not Yet';
        array[i].statusColor = 'danger';
      }
    }
  }
  function RidestatusIdentification(array) {
    // console.log("inside app")
    for (var k = 0; k < array.length; k++) {
      //  console.log("inside for loop,value of ride_status is ",array[k].ride_status)
      if (array[k].ride_status === '1') {
        array[k].ride_status1 = 'Not yet';
        array[k].rideNameColor = 'danger';
      }
      else {
        array[k].ride_status1 = 'Completed';
        array[k].rideNameColor = 'success';


      }
    }
    // console.log(filteredItems);
  }

  function PaymentstatusIdentification(array) {
    // console.log("inside app")
    for (var l = 0; l < array.length; l++) {
      // console.log("inside for loop,value of ride_status is ",array[l].hotel_payment)
      if (array[l].hotel_payment === 0) {
        array[l].hotel_payment1 = 'Unpaid';
        array[l].paymentNameColor = 'danger';
      }
      else if (array[l].hotel_payment === 1) {
        array[l].hotel_payment1 = 'Paid';
        array[l].paymentNameColor = 'success';
      }
      else {
        array[l].hotel_payment1 = 'Unpaid';
        array[l].paymentNameColor = 'danger';


      }
    }
    // console.log(filteredItems);
  }
  function DriverpaymentstatusIdentification(array) {
    //  console.log("inside app")
    for (var s = 0; s < array.length; s++) {
      //  console.log("inside for loop,value of ride_status is ",array[s].driver_payment)
      if (array[s].driver_payment === 0) {
        array[s].driver_payment1 = 'Unpaid';
        array[s].driverpaymentNameColor = 'danger';
      }
      else if (array[s].driver_payment === 1) {
        array[s].driver_payment1 = 'Paid';
        array[s].driverpaymentNameColor = 'success';
      }
      else {
        array[s].driver_payment1 = 'Unpaid';
        array[s].driverpaymentNameColor = 'danger';


      }
    }
    // console.log(filteredItems);
  }
  const getExtraStop = (stop) => {
    if (stop > 0) {
      return "Yes";
    } else {
      return "No";
    }
  }

  /********Data table Export PDF and Excel****** */
  // const handleDownloadExcel = (dataSource, sheetName) => {
  //     const ws = utils.json_to_sheet(dataSource);
  //     const wb = utils.book_new();
  //     const wrapTextCellStyle = {
  //       alignment: {
  //         wrapText: true,
  //       },
  //     };
  //     const cellRange = utils.decode_range(ws['!ref']);
  //     for (let R = cellRange.s.r; R <= cellRange.e.r; R++) {
  //       for (let C = cellRange.s.c; C <= cellRange.e.c; C++) {
  //         const cellAddress = utils.encode_cell({ r: R, c: C });
  //         ws[cellAddress].s = wrapTextCellStyle;
  //       }
  //     }
  //     utils.book_append_sheet(wb, ws, sheetName);
  //     writeFileXLSX(wb, `Booking-Report.xlsx`);
  // };

  const handleDownloadExcel = (dataSource) => {
    try {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Booking Report');

      const headerRow = worksheet.addRow(Object.keys(dataSource[0]));
      headerRow.height = 50; // Set the desired row height for the header row

      headerRow.eachCell((cell) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'C6E2FF' }
        };
        cell.font = {
          bold: true
        };
        cell.alignment = {
          vertical: 'middle', // Align text vertically to the middle
          horizontal: 'center' // Align text horizontally to the center
        };
      });


      // Add data rows
      // dataSource.forEach((row) => {
      //   worksheet.addRow(Object.values(row));
      // });
      dataSource.forEach((row) => {
        const newRow = worksheet.addRow(Object.values(row));

        // Loop through cells in the new row and apply multiline formatting
        newRow.eachCell((cell) => {
          cell.alignment = {
            wrapText: true,
            vertical: 'top',
            horizontal: 'left'
          };
        });
      });

      // Set column widths
      worksheet.getColumn(1).width = 10;

      // Set width of other columns to 30
      for (let i = 2; i <= headerRow.cellCount; i++) {
        worksheet.getColumn(i).width = 30;
      }

      // Save the workbook
      const blobPromise = workbook.xlsx.writeBuffer();
      blobPromise.then((buffer) => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'Booking-Report.xlsx');
      });
    } catch (error) {
      console.error('Error generating Excel:', error);
    }
  };



  const handleDownloadPDF = (data) => {
    const doc = new jsPDF();

    const columns = Object.keys(data[0]);
    const rows = data.map((row) => Object.values(row));
    const options = {
      headStyles: {
        valign: 'middle',
        halign: 'center',
        fontSize: 9,
      },
      columnStyles: {
        columnWidth: 'auto',
        fontSize: 9,
      },
      bodyStyles: {
        fontSize: 9,
      },

    };

    doc.autoTable({
      head: [columns],
      body: rows,
      ...options,
    });

    doc.save('Data-Report.pdf');
  };







  // const exportColumns = columns.map((col) => ({ title: col.header, dataKey: col.field }));
  //  const handleDownloadPDF = (exceldata) => {
  //      import('jspdf').then((jsPDF) => {
  //          import('jspdf-autotable').then(() => {
  //              const doc = new jsPDF.default(0, 0);

  //              doc.autoTable(exportColumns, exceldata);
  //              doc.autoTable({
  //                  headerStyles: {
  //                      fillColor: 'red',
  //                      lineColor: 'red',
  //                      lineWidth: '10'
  //                  }
  //              })

  //              doc.save('Booking-Report.pdf');
  //          });
  //      });

  //  };
  document.title = "DB LIMO - Booking Report";
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Booking Reports" pageTitle="Home"></BreadCrumb>
        <Card>
          <CardHeader>
            <Row className="g-3">
              <Col xxl={2} md={6}>
                <div>
                  <Label className="mb-1">Advance Search</Label>
                  <div className="search-box ms-0">
                    <Input type="text" className="form-control" id="searchProductList" value={query} onChange={handlequeryChange} placeholder="Search..." />
                    <i className="ri-search-line search-icon"></i>
                  </div>
                </div>


                            </Col>
                            <Col xxl={2} md={6}>
                                <div>
                                    <Label className="mb-1">Hotel Name</Label>
                                    {/* <Select
                  className="mb-0"
                  classNamePrefix="form-control-select"
                  //  value={status}
                  defaultValue={executiveNamesList[0]}
                  placeholder="Booking Status"
                  isSearchable={false}
                  onChange={changeStatus}
                  options={executiveNamesList}
                  id="country-select"
                ></Select> */}
                  <div className="mb-0">


                    {userType2 === "2" ? (
  <Input
    className="mb-0"
    classNamePrefix="form-control-select"
    value={loginhotelname}
    inputId="hotelName"
    readOnly
    required
    style={{ userSelect: "none", cursor: "not-allowed", background: " rgba(var(--vz-light-rgb), .75)" }}
  />
) : (
    <Select
                      className="mb-0"
                      classNamePrefix="form-control-select"
                      value={selectedHotel}
                      onChange={handleHotelChange}
                      options={hotelOptions}
                      placeholder="Select Hotel Name"
                      inputId="hotelName"
                      required

                    />
)}

                  </div>

                                </div>


              </Col>
              <Col xxl={2} md={6}>
                <Label className="mb-1">Booking Status</Label>
                <Select
                  className="mb-0"
                  classNamePrefix="form-control-select"
                  //  value={status}
                  defaultValue={executiveNamesList[0]}
                  placeholder="Booking Status"
                  isSearchable={false}
                  onChange={changeStatus}
                  options={executiveNamesList}
                  id="country-select"
                ></Select>

              </Col>
              <Col xxl={2} md={6}>
                <Label className="mb-1">Ride Status</Label>
                <Select
                  className="mb-0"
                  classNamePrefix="form-control-select"
                  //  value={status}
                  defaultValue={executiveNamesList1[0]}
                  placeholder="Ride Status"
                  isSearchable={false}
                  onChange={changeStatus1}
                  options={executiveNamesList1}
                  id="country-select"
                ></Select>

              </Col>
              <Col xxl={2} md={6} className="date-range-fixed-size">
                <Label className="mb-1">Booking Date Range</Label>
                <div className="input-group">
                  <Flatpickr
                    className="form-control dash-filter-picker"
                    placeholder="Enter Booking Date Range"
                    options={options}
                  />

                </div>

              </Col>
              <Col >
                <div className="mt-3 pt-2">
                  <Button className="btn btn-info add-btn me-1" onClick={() => handleSubmit()}> Search </Button>
                  <Button href="#" className="btn btn-soft-info btn-infor-border add-btn border-1 border_1" onClick={() => handleCancel()}> Cancel </Button>
                </div>
              </Col>
            </Row>
          </CardHeader>
        </Card>
        <Card>
          <CardBody className="p-0">
            <Row>
              <Col>
                <DataTable
                  title={<h5 className="mb-0">Booking Reports</h5>}
                  className="custom-datatable"
                  columns={columns}
                  data={filteredItems}
                  sortIcon={<span className="custom-sort-img"><i className='bx bxs-sort-alt custom-sort-icon'></i></span>}
                  pagination
                  paginationServer={true}
                  paginationTotalRows={totalcount}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  paginationComponentOptions={paginationComponentOptions}
                  paginationRowsPerPageOptions={["10", "20", "40"]}
                  // pagination
                  // paginationComponent={CustomPagination}
                  // paginationComponentOptions={0}
                  // paginationRowsPerPageOptions={[2, 4, 8, 12, 15]}
                  // selectableRows={false}
                  // selectableRowsComponent={TableCheckBox}
                  noDataComponent={<NoResultData />}
                  // selectableRowsHighlight={true}
                  selectableRowsVisibleOnly={false}
                  subHeaderAlign="left"
                  contextMenu={true}
                  persistTableHead
                  actions={
                    <Row className="g-4 align-items-start align-items-lg-center flex-column flex-row-lg row">

                      <Col md={12} lg="auto" className="ms-lg-auto no-padding-mobile">
                        <div className="hstack gap-2 flex-lg-row flex-wrap">
                          {subHeaderComponent}
                          <button type="button" className="btn btn-success w-100-mobile" onClick={() => handleDownloadExcel(exceldata)}>
                            <i className="ri-file-excel-2-fill align-bottom me-1"></i>{" "}
                            Export Excel
                          </button>
                          {/* <button type="button" className="btn btn-success w-100-mobile" onClick={()=>handleDownloadPDF(pdfdata)} >
                                                        <i className="ri-file-pdf-fill align-bottom me-1"></i>{" "}
                                                        Export Pdf
                                                    </button> */}


                        </div>
                      </Col>
                    </Row>

                  }
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default BookingReport