import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert, Spinner } from 'reactstrap';
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { loginAPI } from './api';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
//redux
import { useSelector, useDispatch } from "react-redux";
import { UncontrolledAlert } from "reactstrap"
import { Link } from "react-router-dom";


// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Social Media Imports
import { GoogleLogin } from "react-google-login";
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
// actions
import { loginUser, socialLogin, resetLoginFlag } from "../../store/actions";
import { useNavigate } from 'react-router-dom';
import logoLight from "../../assets/images/db_limo_1.svg";
//Import config
import { facebook, google } from "../../config";
import withRouter from "../../Components/Common/withRouter";
import authImgFooter from "../../assets/images/footer-sec-one-car.png"
//import images

const Login = (props) => {
    const dispatch = useDispatch();
   // const history = useHistory();
    const { user, errorMsg, loading, error } = useSelector(state => ({
        user: state.Account.user,
        errorMsg: state.Login.errorMsg,
        loading: state.Login.loading,
        error: state.Login.error,
    }));
    const [updatestatus, setupdatestatus] = useState(false);
    const [message, setMessage] = useState("");
    const [errors, setErrors] = useState(null)
    const userTypelogin = localStorage.getItem("userType")
    const [authorization, setAuthorization] = useState(null);
    const [userLogin, setUserLogin] = useState([]);
    const [passwordShow, setPasswordShow] = useState(false);
    const navigate = useNavigate()
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [loggedIn,setLoggedIn]=useState(false);
    const [userType,setUserType]=useState("")
    const [loginMail,setLoginMail]=useState("")
    const [loginPass,setLoginPass]=useState("")

    useEffect(() => {
        const rememberedEmail = localStorage.getItem('rememberedEmail');
        const rememberedpassword = localStorage.getItem('rememberedpassword');
        const email=localStorage.getItem('loginEmail');
        const pass=localStorage.getItem('loginpassword');
        const loggedIn = localStorage.getItem("keepLoggedIn")
        const usertype=localStorage.getItem("userType")
        setLoginMail(email);
        setLoginPass(pass);
        setLoggedIn(loggedIn);
        setUserType(usertype);
        if (rememberedEmail) {
          validation.setFieldValue('email', rememberedEmail);
          validation.setFieldValue('password', rememberedpassword);
          setRememberMe(true); // Set this state to true to show the "Remember Me" checkbox as checked
        }

      }, []);




    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            email: userLogin.email,
            password: userLogin.password,
        },
        validationSchema: Yup.object({
            email: Yup.string()
              .email("Please enter a valid email address")
              .required("Please enter your email"),
            password: Yup.string()
              .required("Please enter your password"),
          }),

        onSubmit: async (values) => {
            const { email, password } = values;
            try {
                const response = await loginAPI(values);
                localStorage.setItem('loginEmail', validation.values.email);
                localStorage.setItem('loginpassword',validation.values.password)
                if (rememberMe) {
                    localStorage.setItem('rememberedEmail', validation.values.email);
                    localStorage.setItem('rememberedpassword',validation.values.password)
                  } else {
                    localStorage.removeItem('rememberedEmail');
                    localStorage.removeItem('rememberedpassword');
                  }
                console.log(response,"helloooo",response.data.hotel_name);
                sessionStorage.setItem("authUser", JSON.stringify(response));
                const token = response.data.user_type;
                setAuthorization(token);

                //console.log(response.data.user_type, "mounika")
                sessionStorage.setItem("userType", response.data.user_type);
                sessionStorage.setItem("hotelid", response.data.hotel_id);
                sessionStorage.setItem("hotelname", response.data.hotel_name);
                sessionStorage.setItem("username", response.data.user_name);
                sessionStorage.setItem("firstname", response.data.first_name +" "+ response.data.last_name);
                sessionStorage.setItem("jobrole", response.data.job_role);
                sessionStorage.setItem("userid", response.data._id);
                localStorage.setItem("keepLoggedIn",true)
                localStorage.setItem("authUser", JSON.stringify(response));
                localStorage.setItem("userType", response.data.user_type);
                localStorage.setItem("hotelid", response.data.hotel_id);
                localStorage.setItem("hotelname", response.data.hotel_name);
                localStorage.setItem("username", response.data.user_name);
                localStorage.setItem("firstname", response.data.first_name +" "+ response.data.last_name);
                localStorage.setItem("jobrole", response.data.job_role);
                localStorage.setItem("userid", response.data._id);

                if (response.data.user_type == "1") {

                    navigate('/dashboard');

                    console.log(response.data.user_type,"loginresponsedashboard")
                } else if(response.data.user_type == "2")  {
                    navigate('/booking');
                    console.log(response.data.user_type,"loginresponsebooking")
                    //return
                }else{
                    navigate('/booking');
                }
            } catch (error) {
                console.log(error);
                setErrors(error);

                setTimeout(() => {
                    setErrors(null);
                }, 3000);
            }
        }

    });

    useEffect(() => {
    //     if (loggedIn !== null) {

    //       console.log(loginMail,loginPass,loggedIn,"VALUES LOGGED IN")

    //       validation.setFieldValue('email', loginMail);
    //       validation.setFieldValue('password', loginPass);

    //       handleSubmit();
    //   }
  }, []);

  const { handleSubmit } = validation;

    const getNavigation = () =>{
            navigate('/dashboard');


    };


    const signIn = (res, type) => {
        if (type === "google" && res) {
            const postData = {
                name: res.profileObj.name,
                email: res.profileObj.email,
                token: res.tokenObj.access_token,
                idToken: res.tokenId,
            };
            dispatch(socialLogin(postData, props.router.navigate, type));
        } else if (type === "facebook" && res) {
            const postData = {
                name: res.name,
                email: res.email,
                token: res.accessToken,
                idToken: res.tokenId,
            };
            dispatch(socialLogin(postData, props.router.navigate, type));
        }
    };

    //handleGoogleLoginResponse
    const googleResponse = response => {
        signIn(response, "google");
    };

    //handleTwitterLoginResponse
    // const twitterResponse = e => {}

    //handleFacebookLoginResponse
    const facebookResponse = response => {
        signIn(response, "facebook");
    };


    document.title = "Sign In | DB LIMO - Car Booking Portal";

    return (
        <React.Fragment>
            <ParticlesAuth>
                <div className="auth-page-content overflow-hidden pt-lg-5">
                    <Container>

                       <Row className="justify-content-center">
                            <Col md={12} lg={10} xl={10}>
                                <Card className="mt-0 overflow-hidden">

                                    <Row className='g-0'>
                                        <Col md={6}>
                                            <div className="p-lg-5 p-4">
                                                <div>
                                                    <img src={logoLight} alt="" height="40" className=' mb-4' />
                                                    <h5 className="text-dark mb-1">Welcome Back !</h5>
                                                    <p className="text-muted">Sign in to continue to DB LIMO</p>

                                                </div>
                                                {errors && errors ? (<Alert color="danger"> {errors} </Alert>) : null}
                                                <div className="mt-4">
                                                    <Form
                                                        onSubmit={(e) => {
                                                            e.preventDefault();
                                                            validation.handleSubmit();
                                                            return false;
                                                        }}
                                                        action="#">

                                                        <div className="mb-3">
                                                            <Label htmlFor="email" className="form-label">Username</Label>
                                                            <Input
                                                                name="email"
                                                                className="form-control"
                                                                placeholder="Enter email"
                                                                type="email"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.email || ""}
                                                                invalid={
                                                                    validation.touched.email && validation.errors.email ? true : false
                                                                }
                                                            />
                                                            {validation.touched.email && validation.errors.email ? (
                                                                <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                                            ) : null}
                                                        </div>

                                                        <div className="mb-3">

                                                            <Label className="form-label" htmlFor="password-input">Password</Label>
                                                            <div className="position-relative auth-pass-inputgroup mb-3">
                                                                <Input
                                                                    name="password"
                                                                    value={validation.values.password || ""}
                                                                    type={passwordShow ? "text" : "password"}
                                                                    className="form-control pe-5"
                                                                    placeholder="Enter Password"
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    invalid={
                                                                        validation.touched.password && validation.errors.password ? true : false
                                                                    }
                                                                />
                                                                {validation.touched.password && validation.errors.password ? (
                                                                    <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                                                ) : null}
                                                                <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon" onClick={() => setPasswordShow(!passwordShow)}><i className="ri-eye-fill align-middle"></i></button>
                                                            </div>
                                                        </div>
                                                        {/* <div className="float-end">
                                                            <Link to="/forgot-password" className="text-muted">Forgot password?</Link>
                                                        </div> */}

                                                        {/* <div className="form-check">
                                                            <Input className="form-check-input" type="checkbox" checked={rememberMe} onChange={() => setRememberMe(!rememberMe)}
                                                              value="" id="auth-remember-check" />
                                                            <Label className="form-check-label" htmlFor="auth-remember-check" checked={rememberMe}> Remember me</Label>
                                                        </div> */}

                                                        <div className="mt-4">
                                                            <Button color="success" disabled={error ? null : loading ? true : false} className="btn btn-success w-100" type="submit">
                                                                {error ? null : loading ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null}
                                                                Sign In
                                                            </Button>
                                                        </div>
                                                        {/* <div className="mt-5 text-center">
                                                            <p className="mb-0">Do you have more enquiry? <Link to="http://limogenie.sg/contact-us.html" class="fw-semibold text-primary text-decoration-underline"> Contact Us</Link> </p>
                                                        </div> */}

                                                    </Form>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={6} className='d-none d-md-block'>
                                            <img src={authImgFooter} alt={authImgFooter} className='imgCarStyle' />
                                            <div className='p-lg-5 p-4 auth-one-bg_new h-100'>
                                                <div className='position-relative h-100 d-flex flex-column'></div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>



                            </Col>
                        </Row>



                    </Container>
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default withRouter(Login);