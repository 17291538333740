import { Button, Card, CardBody, CardHeader, Col, Container, Input, Label, Pagination, PaginationItem, Row } from "reactstrap"
import BreadCrumb from "../../../Components/Common/BreadCrumb"

import DataTable, { Direction } from "react-data-table-component"
import React from "react"
import { data } from "./data"
import { useMemo } from "react"
import FilterComponent from '../../../Components/datatable/filter'
import { Link } from "react-router-dom"
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import { executiveNamesList } from "../payment-report/data"
import { utils, writeFileXLSX } from 'xlsx';
import CustomDataTablePagination from "../../../Components/datatable/custom-pagination"
import NoResultData from "../../../Components/datatable/no-result"
// eslint-disable-next-line react/display-name
const TableCheckBox = React.forwardRef(({ onClick, ...rest }, ref) => (
    <div className="form-check form-check-size-16" >
        <input
            htmlFor="booty-check"
            type="checkbox"
            className="form-check-input"
            ref={ref}
            onClick={onClick}
            {...rest}
        />
        <label className="form-check-label" id="booty-check" />
    </div>
))
const DriverReport = () => {
    const [allExecutiveName, setAllExecutiveName] = React.useState(null)
    const handleExecutiveName = (allExecutiveName) => {
        setAllExecutiveName(allExecutiveName)
    }
    const columns = [
        {
            name: <span className='font-weight-bold fs-13 text-nowrap'>Driver ID</span>,
            selector: row => row.driverID,
            cell: (row => <Link to="#" className="fw-medium link-primary">{row.driverID}</Link>),
            sortable: true,
            header: 'Driver ID',
            field: 'driverID',
        },
        {
            name: <span className='font-weight-bold fs-13 text-nowrap'>Driver Name</span>,
            selector: row => row.driverName,
            cell: (row => row.driverName.details.map((item, i) => (
                <div key={i}>
                    <h5 className="fs-14 mb-1">{item.name}</h5>
                    <p className="fw-medium text-secondary">{item.contactNumber}</p>
                </div>
            ))),

            sortable: true,
            header: 'Driver Name',
            field: 'contactNumber',
        },
        {
            name: <span className='font-weight-bold fs-13 text-nowrap'>Email Address</span>,
            selector: row => row.emailAddress,
            cell: (row => row.emailAddress),
            sortable: true,
            header: 'Email Address',
            field: 'emailAddress',
        },
        {
            name: <span className='font-weight-bold fs-13 text-nowrap'>Car Info</span>,

            selector: row => row.carbrandAndModel,
            cell: (row => row.carbrandAndModel.details.map((item, i) => (
                <div key={i}>
                    <h5 className="fs-14 mb-1 text-dark">{item.brand}</h5>
                    <p className="fw-medium text-dark">{item.model}</p>
                    <p className="text-muted mt-2 mb-0">Car Number</p>
                    <h5 className="fs-14 m-0">{item.carNumber}</h5>
                </div>
            ))),
            header: 'Car Info',
            field: 'carbrandAndModel',
            sortable: true,
        },
        {
            name: <span className='font-weight-bold fs-13 text-nowrap'>Address</span>,
            selector: row => row.address,
            cell: (row => row.address.map((item, i) =>
           {
            return <p className="text-muted mb-0" key={i}>{item}</p>
           }
            )),
            header: 'Address',
            field: 'address',
            sortable: true,
        },


        {
            name: <span className='font-weight-bold fs-13 text-nowrap'>Status</span>,
            selector: row => row.status,
            cell: (row => <div className={`badge text-uppercase pt-1 bg-${row.statusColor}`}>{row.status}</div>),
            sortable: true,
            header: 'Payment Info',
            field: 'paymentInfo',
        },


    ];

    /********Data table Search****** */
    const [filterText, setFilterText] = React.useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
        false
    );
    const filteredItems = data.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <FilterComponent
                onFilter={e => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [filterText, resetPaginationToggle]);


    /********Data table Export PDF and Excel****** */
    const handleDownloadExcel = (dataSource, sheetName) => {
        const ws = utils.json_to_sheet(dataSource);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, sheetName);
        writeFileXLSX(wb, `Driver-Report.xlsx`);
    };
    const exportColumns = columns.map((col) => ({ title: col.header, dataKey: col.field }));
    const handleDownloadPDF = () => {
        import('jspdf').then((jsPDF) => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default(0, 0);

                doc.autoTable(exportColumns, data);
                doc.autoTable({
                    headerStyles: {
                        fillColor: 'red',
                        lineColor: 'red',
                        lineWidth: '10'
                    }
                })

                doc.save('Driver-Report.pdf');
            });
        });

    };
    document.title="DB LIMO - Driver Report";
    return (
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title="Reports" pageTitle="Home"></BreadCrumb>
                <Card>
                <CardHeader>
                        <Row className="g-3">
                            <Col xxl={2} md={6}>
                                <div>
                                    <Label className="mb-1">Advance Search</Label>
                                    <div className="search-box ms-0">
                                        <Input type="text" className="form-control" id="searchProductList" placeholder="Search..." />
                                        <i className="ri-search-line search-icon"></i>
                                    </div>
                                </div>


                            </Col>
                            <Col xxl={2} md={6}>
                                <Label className="mb-1">Booking Status</Label>
                                <Select
                                    className="mb-0"
                                    classNamePrefix="form-control-select"
                                    //value={allExecutiveName}
                                    defaultValue={executiveNamesList[0]}
                                    placeholder="Booking Status"
                                    isSearchable={false}
                                    onChange={() => {
                                        handleExecutiveName();
                                    }}
                                    options={executiveNamesList}
                                    id="country-select"
                                ></Select>

                            </Col>
                            <Col xxl={2} md={6} className="date-range-fixed-size">
                                <Label className="mb-1">Booking Date Range</Label>
                                <div className="input-group">
                                    <Flatpickr
                                        className="form-control dash-filter-picker"
                                        placeholder="Enter Booking Date Range"
                                        options={{
                                            mode: "range",
                                            dateFormat: "d M, Y",
                                            //  defaultDate: ["01 Jan 2022", "31 Jan 2022"],
                                        }}
                                    />

                                </div>

                            </Col>
                            <Col >
                                <div className="mt-3 pt-2">
                                    <Button className="btn btn-info add-btn me-2"> Search </Button>
                                    <Button href="#" className="btn btn-soft-info add-btn border-1 border_1"> Cancel </Button>
                                </div>
                            </Col>
                        </Row>
                    </CardHeader>
                </Card>

                <Card>
                    <CardBody className="p-0">
                        <Row>
                            <Col>
                                <DataTable
                                    title={<h5 className="mb-0">Driver Report</h5>}
                                    className="custom-datatable"
                                   // paginationComponent={CustomDataTablePagination}
                                    //paginationRowsPerPage
                                    columns={columns}
                                    data={filteredItems}
                                    pagination
                                    paginationComponentOptions={0}
                                    paginationRowsPerPageOptions={[2, 4, 8, 12, 15]}

                                    selectableRows={false}
                                    selectableRowsComponent={TableCheckBox}
                                    noDataComponent={<NoResultData />}
                                    selectableRowsHighlight={true}
                                    selectableRowsVisibleOnly={false}
                                    subHeaderAlign="left"
                                    contextMenu={true}
                                    persistTableHead
                                    actions={
                                        <Row className="g-4 align-items-start align-items-lg-center flex-column flex-row-lg row">

                                        <Col md={12} lg="auto" className="ms-lg-auto no-padding-mobile">
                                            <div className="hstack gap-2 flex-lg-row flex-wrap">
                                                {subHeaderComponent}
                                                <button type="button" className="btn btn-success w-100-mobile" onClick={() => handleDownloadExcel(data)}>
                                                        <i className="ri-file-excel-2-fill align-bottom me-1"></i>{" "}
                                                        Export Excel
                                                    </button>
                                                    <button type="button" className="btn btn-success w-100-mobile" onClick={handleDownloadPDF} >
                                                        <i className="ri-file-pdf-fill align-bottom me-1"></i>{" "}
                                                        Export Pdf
                                                    </button>


                                            </div>
                                        </Col>
                                    </Row>

                                    }
                                />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>
        </div>
    )
}

export default DriverReport