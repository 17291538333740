import PropTypes from "prop-types";
import React from "react";
import { Modal, ModalBody } from "reactstrap";

const LogoutModal = ({ show, onDeleteClick, onCloseClick }) => {
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true} modalClassName="flip">
      <ModalBody className="p-5 text-center">
        
        
          
          <div className="mt-4 text-center">
            <h4>Are you sure you need to Logout?</h4>
           
        </div>
        <div className="hstack gap-2 justify-content-center remove">
        
          <button
            type="button"
            className="btn w-sm btn-danger "
            id="delete-record"
            onClick={onDeleteClick}
          >
            Yes, Logout!
          </button>
          <button
            type="button"
            className="btn btn-link text-dark link-success fw-medium text-decoration-none"
            data-bs-dismiss="modal"
            onClick={onCloseClick}
          >
           <i className="ri-close-line me-1 align-middle"></i> Close
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

LogoutModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
 };

export default LogoutModal;