import React from "react";
import { Navigate } from "react-router-dom";

import Basic404 from "../pages/AuthenticationInner/Errors/Basic404";
import Cover404 from "../pages/AuthenticationInner/Errors/Cover404";
import Alt404 from "../pages/AuthenticationInner/Errors/Alt404";
import Error500 from "../pages/AuthenticationInner/Errors/Error500";

import BasicPasswCreate from "../pages/AuthenticationInner/PasswordCreate/BasicPasswCreate";
import CoverPasswCreate from "../pages/AuthenticationInner/PasswordCreate/CoverPasswCreate";
import Offlinepage from "../pages/AuthenticationInner/Errors/Offlinepage";

//login
import Login from "../pages/Authentication/Login";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";


import DashboardProject from "../pages/Dashboard";
import AllBooking from "../pages/Booking/AllBooking/index";
import HotelOnBoarding from "../pages/HotelOnBoarding";
import PrimaryUserOnBoarding from "../pages/PrimaryUserOnboard";
import CarOnBoarding from "../pages/CarOnBoard";
import DriverOnBoarding from "../pages/DriverOnBoard";
import BookingReport from "../pages/reports/booking-report";
import DriverReport from "../pages/reports/driver-report";
import PaymentReport from "../pages/reports/payment-report";
import CreateBooking from "../pages/Booking/CreateBooking";
import ChangePasswordPage from "../pages/Authentication/change-password";
import CarModel from "../pages/CarOnBoard/CarModel";
import CarBrand from "../pages/CarOnBoard/CarBrand";
const authProtectedRoutes = [

  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  { path: "*", component: <Navigate to="/dashboard" /> },
  { path: "/dashboard", component: <DashboardProject /> },
  { path: "/index", component: <DashboardProject /> },

  /**********Pages************* */
  { path: "booking", component: <AllBooking /> },
  {path: "add-booking", component: <CreateBooking />},
  { path: "hotel-onboarding", component: <HotelOnBoarding /> },
  { path: "Primary-user-onboard", component: <PrimaryUserOnBoarding /> },
  { path: "car-onboard", component: <CarOnBoarding /> },
  { path: "car-model", component: <CarModel /> },
  { path: "car-brand", component: <CarBrand /> },
  { path: "driver-onboard", component: <DriverOnBoarding /> },

   /**********Reports************* */
   { path: "booking-report", component: <BookingReport /> },
   { path: "driver-report", component: <DriverReport /> },
   { path: "payment-Report", component: <PaymentReport /> }
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/change-password", component: <ChangePasswordPage /> },
  { path: "/register", component: <Register /> },

  { path: "/auth-404-basic", component: <Basic404 /> },
  { path: "/auth-404-cover", component: <Cover404 /> },
  { path: "/auth-404-alt", component: <Alt404 /> },
  { path: "/auth-500", component: <Error500 /> },



  { path: "/auth-pass-change-basic", component: <BasicPasswCreate /> },
  { path: "/auth-pass-change-cover", component: <CoverPasswCreate /> },
  { path: "/auth-offline", component: <Offlinepage /> },
];

export { authProtectedRoutes, publicRoutes };
