/* eslint-disable no-debugger */
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form, Button, UncontrolledAlert } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
import config from "../../common/config";

import { Link, useNavigate } from "react-router-dom";

// Formik Validation
import * as Yup from "yup";
import { ErrorMessage, useFormik } from "formik";

// action
import { userForgetPassword } from "../../store/actions";

// import images
// import profile from "../../assets/images/bg.png";
import logoLight from "../../assets/images/logo-light.svg";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import withRouter from "../../Components/Common/withRouter";

import authImgFooter from "../../assets/images/footer-sec-one-car.png"
import axios from "axios";
const ChangePasswordPage = props => {

  const [currentpasswordShow, setCurrentPasswordShow] = useState(false);
  const [NewpasswordShow, setNewPasswordShow] = useState(false);
  const [confrimPasswordShow, setConfrimPasswordShow] = useState(false);

  const [errorAlert, setErrorAlert] = useState({status: false, message: ''});

  useEffect(() => {
    if(errorAlert) {
      setTimeout(() => {
        setErrorAlert(false);
      }, 2000)
    }
  }, [errorAlert])

  const navigate = useNavigate();

  const onSubmit = (values) => {
    const data = {
      _id: localStorage.getItem('loginEmail'),
      old_password: values.old_password,
      new_password: values.new_password,
    }
    axios.post(`${config.baseUrl}api/change_password`, data).then(res => {
      navigate('/login');
    }).catch(error => {
      if(error.response) {
        setErrorAlert({ status: true, message: error.response?.data?.message });
      } else {
        console.log(error);
      }
    })
  }

  const { handleChange, handleSubmit, errors, touched } = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
      confirm_password: "",
    },
    onSubmit: onSubmit,
    validationSchema: Yup.object().shape({
      old_password: Yup.string().required('Required'),
      new_password: Yup.string().required('Required'),
      confirm_password: Yup.string().required('Required').oneOf([Yup.ref('new_password'), null], 'Passwords must match'),
    })
  })



  document.title = "Reset Password | Velzon - React Admin & Dashboard Template";

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content overflow-hidden pt-lg-5">
          <Container>

            <Row className="justify-content-center">
              <Col md={12} lg={10} xl={10}>
                <Card className="mt-0 overflow-hidden">

                  <Row className='g-0'>
                    <Col md={6}>
                      <div className="p-lg-5 p-4">
                        <div>
                          <img src={logoLight} alt="" height="50" className=' mb-4' />
                          <h5 className="text-dark mb-1">Update Your Password !</h5>
                          {/* <p className="text-muted">Sign in to continue to DB LIMO.</p> */}

                        </div>

                        {errorAlert.status && <UncontrolledAlert color="danger" >{errorAlert.message}</UncontrolledAlert>}

                        <div className="mt-4">
                          <Form
                            onSubmit={handleSubmit}
                            action="#">
                            <div className="mb-3">
                              <Label className="form-label" htmlFor="password-input">Currrent Password</Label>
                              <div className="position-relative auth-pass-inputgroup mb-3">

                                <Input
                                  name="old_password"

                                  type={currentpasswordShow ? "text" : "password"}
                                  className="form-control pe-5"
                                  placeholder="Enter Password"
                                  onChange={handleChange}
                                />

                                <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon" onClick={() => setCurrentPasswordShow(!currentpasswordShow)}><i className="ri-eye-fill align-middle"></i></button>
                                {touched && touched.old_password && errors.old_password && (
                                    <div className="invalid m-1">{errors.old_password}</div>
                                  )}
                              </div>
                            </div>


                            <div className="mb-3">

                              <Label className="form-label" htmlFor="password-input">New Password</Label>
                              <div className="position-relative auth-pass-inputgroup mb-3">
                                <Input
                                  name="new_password"

                                  type={NewpasswordShow ? "text" : "password"}
                                  className="form-control pe-5"
                                  placeholder="Enter Password"
                                  onChange={handleChange}
                                />

                                <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon" onClick={() => setNewPasswordShow(!NewpasswordShow)}><i className="ri-eye-fill align-middle"></i></button>
                                {touched && touched.new_password && errors.new_password && (
                                    <div className="invalid m-1">{errors.new_password}</div>
                                  )}
                              </div>
                            </div>
                            <div className="mb-3">

                              <Label className="form-label" htmlFor="password-input">Confrim Password</Label>
                              <div className="position-relative auth-pass-inputgroup mb-3">
                                <Input
                                  name="confirm_password"

                                  type={confrimPasswordShow ? "text" : "password"}
                                  className="form-control pe-5"
                                  placeholder="Enter Password"
                                  onChange={handleChange}
                                />

                                <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon" onClick={() => setConfrimPasswordShow(!confrimPasswordShow)}><i className="ri-eye-fill align-middle"></i></button>
                                {touched && touched.confirm_password && errors.confirm_password && (
                                    <div className="invalid m-1">{errors.confirm_password}</div>
                                  )}
                              </div>
                            </div>




                            <div className="mt-4">
                              <Button color="success" className="btn btn-success w-100" type="submit">

                                Update Password
                              </Button>
                            </div>
                            <div className="mt-5 text-center">
                              <p className="mb-0">Do you have more enquiry? <Link to="http://limogenie.sg/contact-us.html" class="fw-semibold text-primary text-decoration-underline"> Contact Us</Link> </p>
                            </div>

                          </Form>
                        </div>
                      </div>
                    </Col>
                    <Col md={6} className='d-none d-md-block'>
                      <img src={authImgFooter} alt={authImgFooter} className='imgCarStyle' />
                      <div className='p-lg-5 p-4 auth-one-bg_new h-100'>
                        <div className='position-relative h-100 d-flex flex-column'></div>
                      </div>
                    </Col>
                  </Row>
                </Card>



              </Col>
            </Row>



          </Container>
        </div >
      </ParticlesAuth >
    </React.Fragment >
  );
};

ChangePasswordPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ChangePasswordPage);
