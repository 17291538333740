import { Button, Card, CardBody, Col, Container, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row ,UncontrolledAlert} from "reactstrap"
import BreadCrumb from "../../../Components/Common/BreadCrumb"
import DataTable from "react-data-table-component"
import React, { useState,useEffect,useMemo } from "react";
import config from "../../../common/config"
import FilterComponent from "../../../Components/datatable/filter";
import DeleteModal from "../../../Components/Common/DeleteModal";

const CarBrand = () => {
    const [isAdd, setIsAdd] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [perpage, setPerPage] = useState(10)
    const [totalcount, setTotalcount] = useState()
    const [totalpages, setTotalpages] = useState('')
    const [modellist, setModellist] = useState([])
    const [pageNumber, setPageNumber] = useState(1)
    const [carbrandname, setCarbrandname] = useState("");
    const [carbranddescription, setCarbranddescription] = useState("")
    const [message, setMessage] = useState("");

    const [error, setError] = useState({
        status: false,
        msg: "",
        type: ""
    })

    const [addStatus, setAddStatus] = useState(false);
    const [addAlert, setAddAlert] = useState(false)
    const [updatestatus, setupdatestatus] = useState(false);
    const [updateAlert, setUpdateAlert] = useState(false)
    const handleCloseDialog = () => {
        setIsAdd(false)
        setIsEdit(false)
        setCarbranddescription("")
        setCarbrandname("");

    }
    // const handleEditDialog = () => {
    //     setIsLoading(true)
    //     setIsAdd(true)
    // }

    const handleBookingConfirmation = () => {
        alert()
    }
    const carModellist = async () => {
        console.log("API CALL");
        setIsLoading(true)
        await fetch(config.baseUrl + "api/admin/getallcarbrand", {
            method: "POST",

            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                page: pageNumber,
                limit: perpage,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                setModellist(data.data);
                setTotalpages(data.totalPages)
                setTotalcount(data.totalCount)

            });
        setIsLoading(false)
    }
    useEffect(() => {
        carModellist();

    }, [pageNumber]);
    const columns = [
        {
            name: 'Car Brand ID',
            selector: row => row.car_id,
            cell: (row =>
                    <div className="fw-medium ">{row.car_id}</div>
            ),
        },
        {
            name: 'Car Brand',
            selector: row => row.car_brand,
        },
        {
            name: 'Description',
            selector: row => row.car_description,
        },
        {
            name: <span className='font-weight-bold fs-13 text-nowrap'>Action</span>,
            id: "action",
            style: { position: 'sticky', right: 0, backgroundColor: 'white', zIndex: 9 },
            selector: row => row.bookingID,
            cell: (row =>
                <div>


                      <Button className="btn btn-soft-info btn-icon fs-14 me-1" onClick={setIsEdit ? () => handleEditDialog(row._id) : null} >
    <i className="ri-edit-box-line"></i>
  </Button>

                    <Button className="btn btn-soft-info btn-icon fs-14"  onClick={()=>handleDeleteData(row._id,row.car_id)} >
                        <i className="ri-delete-bin-line"></i>
                    </Button>
                </div>
            ),
            sortable: true,
        },
    ];

    const data = [
        {
            id: 1,
            carID: '#LG0001',
            carName: 'Beetlejuice',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
        },
        {
            id: 2,
            carID: '#LG0002',
            carName: 'Ghostbusters',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
        },
    ]
    const[cmid,setCmid]=useState([])
    let handleCreatecarmodel = async (e) => {
        e.preventDefault();


        if (!carbrandname) {
            setError({ status: true, message: "Please Enter Car Brand Name", type: 'error' });
            setTimeout(() => {
                setError({ status: false, message: '', type: '' });
            }, 3000);
            return;
        }

        setIsEdit(false);
        try {
            setIsLoading(true)

            let res = await fetch(config.baseUrl + "api/admin/createcarbrand", {
                method: "POST",

                headers: {
                    "Content-Type": "application/json",


                },
                body: JSON.stringify({
                    "car_brand": carbrandname,
                    "car_description": carbranddescription


                }),
            });
            let resJson = await res.json();
            if (res.status === 200) {
                //setDriver(resJson.data.driver_id);
                setAddStatus(true)
                setAddAlert(true)
                //setMessage("User created successfully");
setCmid(resJson.data.car_id)
console.log(resJson.data.car_id,"carid",resJson,res)
                setCarbrandname("")
                setCarbranddescription("")
                carModellist()
                setIsLoading(false)
                setTimeout(() => {
                    //setIsAdd(false)
                    setAddAlert(false)
                    handleCloseDialog();
                    //setError({ status: false, message: '', type: '' });
                }, 2000);

            } else {
                setMessage("Some error occured");
                setError({ status: true, message: resJson.message, type: 'error' });
                setTimeout(() => {
                    setError(prevError => ({ ...prevError, status: false }));
                    setAddAlert(false)
                }, 3000);

                setIsLoading(false)
                setAddStatus(false)
                setAddAlert(true)
            }
        } catch (err) {
            console.log(err);
            setIsLoading(false)

        }
        setIsLoading(false)
    };
    const [CId, setCId] = useState();
    const [editId, setEditId] = useState("")
    const handleEditDialog = (rowId) => {
        console.log(rowId, "poooooconsole");
        setEditId(rowId);
        setIsLoading(true);
        console.log(config.baseUrl + 'api/admin/getcarbrand/' + rowId);

        fetch(config.baseUrl + 'api/admin/getcarbrand/' + rowId)
            .then((response) => response.json())
            .then((data) => {
                // Check if data and data.data exist before accessing properties
                if (data?.data?.car_id) {
                    setCId(data.data.car_id)
                }

                if (data?.data?.car_brand) {
                    setCarbrandname(data.data.car_brand);
                }
                if (data?.data?.car_description) {
                    setCarbranddescription(data.data.car_description);
                }
                console.log(data.data.car_brand, data.data.car_description, "mounik", data.data.car_brand);
                setIsLoading(false);
            })
            .catch((error) => {
                // Handle any errors here
                console.error(error);
            });

        setIsAdd(true);
        setIsEdit(true);
    }

    let handleUpdatemodel = async (e) => {

        e.preventDefault();

        if (!carbrandname) {
            setError({ status: true, message: "Please Enter Car Brand Name", type: 'error' });
            setTimeout(() => {
                setError({ status: false, message: '', type: '' });
            }, 3000);
            return;
        }





        try {
            setIsLoading(true)

            let res = await fetch(config.baseUrl + "api/admin/updatecarbrand/" + editId, {
                method: "PUT",

                headers: {
                    "Content-Type": "application/json",

                },
                body: JSON.stringify({
                    car_brand: carbrandname,

                    car_description: carbranddescription,

                }),
            });
            let resJson = await res.json();

            if (res.status === 200) {

                carModellist();
                setUpdateAlert(true)
                setupdatestatus(true);

                setTimeout(() => {
                    setUpdateAlert(false)
                    setupdatestatus(false)
                    handleCloseDialog();
                    //setIsAdd(false)
                }, 2000);
                setIsLoading(false)
            } else {

                console.log("Error", resJson.message);
                setError(resJson.message);
                setUpdateAlert(true)
                setupdatestatus(false)
                setIsLoading(false)
            }
        } catch (err) {
            console.log(err);
            setUpdateAlert(true)
            setupdatestatus(false)
            setIsLoading(false)
        }
        setError("")
        setIsLoading(false)

    }
    const handleAddDialog = () => {
        setIsAdd(true)
        setIsEdit(false)
    }
    const [selectedId, setSelectedId] = useState("");
    const [Id,setId]=useState("");
    const [deleteModal, setDeleteModal] = useState(false);
     // Delete APi
     const handleDeleteData = (_id,car_id) => {
        console.log("Selected car brand Id is", _id);
        setSelectedId(_id);
        setId(car_id)
        setDeleteModal(!deleteModal);
    }
    const handleDeleteData1 = () => {
        if (selectedId) {
            console.log(config.baseUrl + 'api/admin/deletecarbrand/' + selectedId);
            fetch(config.baseUrl + 'api/admin/deletecarbrand/' + selectedId, {
                method: 'DELETE'
            }).then((response) => response.json())
                .then((data) => {
                    console.log(data);
                  carModellist();
                });
        }
        setDeleteModal(!deleteModal);
    }
    // const data = [
    //     {
    //         id: 1,
    //         carID: '#LG0001',
    //         carName: 'Beetlejuice',
    //         description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
    //     },
    //     {
    //         id: 2,
    //         carID: '#LG0002',
    //         carName: 'Ghostbusters',
    //         description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
    //     },
    // ]

    /********Data table Search****** */
    const [filterText, setFilterText] = React.useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
        false
    );
    const filteredItems = modellist.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );
    const hotelListArray = Object.values(modellist);

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <FilterComponent
                onFilter={e => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [filterText, resetPaginationToggle]);
    document.title = "DB LIMO - Car Brand";

    return (
        <React.Fragment>

            <div className="page-content">

                <Container fluid>
                    <BreadCrumb title="Car Brand" pageTitle="Home"></BreadCrumb>
                    <Card>
                        <CardBody className="p-0">
                            <Row>
                                <Col>
                                    <DataTable
                                        columns={columns}
                                        data={filteredItems}
                                        selectableRows={false}
                                        title={<h5 className="mb-0">Car Brand</h5>}
                                        className="custom-datatable "
                                        actions={
                                            <Row className="g-4 align-items-start align-items-lg-center flex-column flex-row-lg">

                                                <Col md={12} lg="auto" className="ms-lg-auto no-paading-mobile">
                                                    <div className="hstack gap-2 flex-lg-row flex-wrap">

                                                        <button   onClick={handleAddDialog}  type="button" className="btn btn-success w-100-mobile">
                                                            <i className="ri-add-line align-bottom me-1"></i> Create Car Brand
                                                        </button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        }
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Container>
            </div>
            <Modal
                isOpen={isAdd}
                toggle={handleCloseDialog}
                centered
                size="lg"
                className="border-0"
                modalClassName='modal fade zoomIn'
            >
                <ModalHeader className="p-3 bg-light" toggle={handleCloseDialog}>

                    {!!isEdit ? 'Update Car Brand' : 'Create Car Brand'}
                </ModalHeader>
                <Form onSubmit={isEdit ? handleUpdatemodel : handleCreatecarmodel}>
                    <ModalBody>
                    {isEdit && updateAlert && updatestatus && (
                            <UncontrolledAlert color="success" onClose={() => setupdatestatus(false)}>Car Brand has been updated successfully - {CId}</UncontrolledAlert>)}
                        {isEdit && updateAlert && !updatestatus && error.status && (
                            <UncontrolledAlert color="danger" onClose={() => setupdatestatus(false)}>  <div >
                                <div
                                    id="error"
                                    style={{
                                        // color: 'red',
                                        //  fontWeight: 'bold',
                                        textAlign: 'start'
                                    }}
                                >
                                    <i className="bi bi-exclamation-circle" ></i>&nbsp;&nbsp;&nbsp;&nbsp;{error.message}
                                </div>

                            </div></UncontrolledAlert>)}
                        {isAdd && addAlert && addStatus && (<UncontrolledAlert color="success" onClose={() => setAddStatus(false)}>Car Brand has been added successfully - {cmid} </UncontrolledAlert>)}
                        {isAdd && addAlert && !addStatus && error.status && (<UncontrolledAlert color="danger" onClose={() => setAddStatus(false)}><div >  <div id='error'
                            style={{ textAlign: "start" }} ><i className="bi bi-exclamation-circle"
                            ></i>&nbsp;&nbsp;&nbsp;&nbsp;{error.message}</div>
                        </div></UncontrolledAlert>)}
                        <Row className="g-3">
                            <Col sm={12} xl={12} xxl={12} md={12}>
                                <div className="mb-0">
                                    <Label
                                        htmlFor="firstName"
                                        className="form-label"
                                    >
                                        Car Brand Name (<span className="text-danger">*</span>)
                                    </Label>
                                    <Input
                                        id="firstName"
                                        name="firstName"
                                        type="text"
                                        placeholder="Enter Your Car Brand Name"
                                        value={carbrandname}
                                        onChange={(e) => setCarbrandname(e.target.value)} required

                                    />

                                </div>

                            </Col>
                            <Col xxl={12} sm={12} lg={12} md={12}>
                                <div className="mb-0">
                                    <Label
                                        htmlFor="lastName"
                                        className="form-label"
                                    >
                                        Description
                                    </Label>
                                    <textarea
                                        id="lastName"
                                        name="lastName"

                                        placeholder="Comments"
                                        rows={4}
                                        className="form-control"
                                        value={carbranddescription}
                                        onChange={(e) => setCarbranddescription(e.target.value)}
                                    />


                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter className="justify-content-start">
                        <div className="hstack gap-2">
                        {isEdit ? (
                                <button type="submit" className="btn btn-success" id="add-btn">Update Car Brand</button>
                            ) : (
                                <button type="submit" className="btn btn-success" id="add-btn" >Add Car Brand</button>
                            )}

                            <button type="button" className="btn btn-light border_gry" data-bs-dismiss="modal" onClick={handleCloseDialog}>Cancel</button>
                        </div>
                    </ModalFooter>
                </Form>
            </Modal>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteData1}
                onCloseClick={() => {
                    setSelectedId(null);
                    setDeleteModal(false);
                }}
                entityName="Car Brand"
                entityId={Id}
            />
        </React.Fragment>
    )
}

export default CarBrand