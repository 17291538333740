import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import CountUp from "react-countup";
import { useSelector, useDispatch } from "react-redux";
import { ProjectsOverviewCharts } from './DashboardProjectCharts';
import { getProjectChartsData } from '../../store/dashboardProject/action';
import config from "../../common/config";

const BookingOverView = () => {
    const dispatch = useDispatch();

    const [chartData, setchartData] = useState([]);
    const [type,setType]=useState("year")
    const [bookingData,setBookingData]=useState([])
    const [extractedData, setExtractedData] = useState([]);
    const [totalBookingValues, setTotalBookingValues] = useState([]);
    const [acceptedValues, setAcceptedValues] = useState([]);
    const [cancelledValues, setCancelledValues] = useState([]);


    const { projectData } = useSelector(state => ({
        projectData: state.DashboardProject.projectData
    }));
    const [chartData1, setChartData1] = useState([
        {
          "data": [],
          "name": "Confirm Booking",
          "type": "bar"
        },
        {
          "data": [],
          "name": "Total Booking",
          "type": "area"
        },
        {
          "data": [],
          "name": "Cancel Booking",
          "type": "bar"
        }
      ]);


   // console.log(chartData,"THE DEFAULT DATA")


    const onChangeChartPeriod = pType => {
        setType(pType)
       // dispatch(getProjectChartsData(pType));

    };
    

    useEffect(() => {
       // dispatch(getProjectChartsData("all"));
        getBookingOverview()
       
    }, [type]);

    useEffect(() => {
        const booking1 = Object.values(bookingData);
        const statusByMonth = booking1.map(item => ({
            status: item.status,
            month: new Date(item.month).getMonth() + 1, // Get month value (1-based)
          }));
      
          statusByMonth.sort((a, b) => a.month - b.month);
          const uniqueData = statusByMonth.reduce((acc, curr) => {
            const existingIndex = acc.findIndex(item => item.month === curr.month);
            if (existingIndex === -1) {
              acc.push(curr);
            } else {
              if (curr.status.total_booking > acc[existingIndex].status.total_booking) {
                acc[existingIndex] = curr;
              }
            }
            return acc;
          }, []);
      
          setExtractedData(uniqueData);
      }, [bookingData]);

      useEffect(() => {

      // console.log(extractedData,"WITH INDEX-------------")
        // Extract total_booking values
        const bookingValues = Array.from({ length: 12 }, (_, i) =>
             extractedData.find(item => item.month === i + 1)?.status.total_booking || 0
        );
        setTotalBookingValues(bookingValues);

        const acceptValues = Array.from({ length: 12 }, (_, i) =>
            extractedData.find(item => item.month === i + 1)?.status.accepted_booking || 0
         );
        setAcceptedValues(acceptValues);

        const cancelValues = Array.from({ length: 12 }, (_, i) =>
            extractedData.find(item => item.month === i + 1)?.status.cancelled_booking || 0
        );
        setCancelledValues(cancelValues);


      }, [extractedData]);



      useEffect(() => {
        const updatedChartData = chartData1.map((item, index) => {
          switch (item.name) {
            case "Confirm Booking":
              return { ...item, data: acceptedValues };
            case "Total Booking":
              return { ...item, data: totalBookingValues };
            case "Cancel Booking":
              return { ...item, data: cancelledValues };
            default:
              return item;
          }
        });
        setChartData1(updatedChartData);
      }, [totalBookingValues, acceptedValues, cancelledValues]);

      useEffect(() => {
        setchartData(chartData1);
       
    }, [chartData1]);


    //console.log("BOOKING OVERVIEW DATA",bookingData)
    const getPeriod = () =>{
        if(type === "3m"){
            return "3m"
        }else if(type === "month"){
            return "1m"
        }else if(type === "halfyear"){
            return "6m"
        }else{
            return "1y"
        }
    }

    const getBookingOverview = async()=> {
        let res=  await fetch(config.baseUrl + "api/dashboard", {
            method: "POST",
    
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                from: "2023-08-03",
                to: "2023-08-14",
                booking_overview: getPeriod(),
                upcoming_booking_from: "2023-07-03",
                upcoming_booking_to: "2023-08-30"
            }),
        });
        let resJson = await res.json();
                if (res.status === 200) {
                  // console.log(resJson.data,"Retrieved Data");
                   setBookingData(resJson.data.booking_overview.data)
                   
                  
                } else {
                   
                    console.log("Data Not retrieved")
                }

    }

      //console.log("BOOKING OVERVIEW DATA",chartData1)
    return (
        <React.Fragment>
            <Row>
                <Col xl={12}>
                    <Card>
                        <CardHeader className="border-0 align-items-center d-flex">
                            <h4 className="card-title mb-0 flex-grow-1">Booking Overview</h4>
                            <div className="d-flex gap-1">
                                
                                <button type="button" className={`${type === 'month' ? 'btn btn-soft-primary btn-sm' : 'btn btn-soft-secondary btn-sm' }`} onClick={() => { onChangeChartPeriod("month"); }}>
                                    1M
                                </button>
                                <button type="button" className={`${type === '3m' ? 'btn btn-soft-primary btn-sm' : 'btn btn-soft-secondary btn-sm' }`} onClick={() => { onChangeChartPeriod("3m"); }}>
                                    3M
                                </button>
                                <button type="button" className={`${type === 'halfyear' ? 'btn btn-soft-primary btn-sm' : 'btn btn-soft-secondary btn-sm' }`} onClick={() => { onChangeChartPeriod("halfyear"); }}>
                                    6M
                                </button>
                                <button type="button" className={`${type === 'year' ? 'btn btn-soft-primary btn-sm' : 'btn btn-soft-secondary btn-sm' }`} onClick={() => { onChangeChartPeriod("year"); }}>
                                    1Y
                                </button>
                            </div>
                        </CardHeader>

                     
                        <CardBody className="p-0 pb-2">
                            <div>
                                <div dir="ltr" className="apex-charts">
                                    <ProjectsOverviewCharts series={chartData} dataColors='["--vz-primary", "--vz-warning", "--vz-success"]' />
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default BookingOverView;