import React, { useState,useEffect } from "react"
import { Input, Label, OffcanvasBody,UncontrolledAlert } from "reactstrap"
import Select from "react-select";
import config from "../../../common/config"
import ConfirmModal from "../../../Components/Common/ConfirmModal"

const BookingConfirmation = ({array,SuccesStatus,setShowSideBar,fetchBooking}) => {
  const [driverId,setDriverId]=useState("")
  const [driverName,setDriverName]=useState("")
  const [bookingId,setBookingId]=useState("")
  const [hotelRemarks,setHotelRemarks]=useState("")
  const [time,setTime]=useState("")
  const [isMidnight,setIsMidnight]=useState(false)
  const [driverRemarks,setDriverRemarks]=useState("")
  const [confirmtationStatus,setConfiramtionStatus]=useState("")
  const [count,setCount]=useState(0)
  const [disposalCharge,setDisposalCharge]=useState(0)
  const [selectedDriver, setSelectedDriver] = useState(""); 
  const [midnightCharge,setMidnightCharge]=useState(0)
  const [cancelStatus,setCancelStatus]=useState("")
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [newSelectedDriver, setNewSelectedDriver] = useState(null);
  const [percentage, setPercentage] = useState(0);
  const handleOnChage = (event) => { 
    const selectedOption = event.target.value;
    setSelectedDriver(event.target.value);
    const name=event.target.options[event.target.selectedIndex].text;
   
    setDriverName(name);
  const selectedId = selectedOption;
    setDriverId(selectedId)
  }
  console.log("Driver Id is",driverId);
  console.log("Name is",driverName);
  const [extraHours, setExtraHours] = React.useState(0);
  const [extraStops, setExtraStops] = React.useState(0);
  const [waitingCharge, setWaitingCharge] = useState(0)
  const [disposalHours,setDisposalhours]=useState(0);
  const [extraDisposal,setExtraDisposal]=useState(0)
  const [extraStopCharge,setExtraStopCharge]=useState(0)
  const [addExtraStop,setAddExtraStop]=useState(0)
  const [addExtraStopCharge,setAddExtraStopCharge]=useState(0)
  const [midCharge,setMidCharge]=useState(0)
  const [waitCharge,setWaitCharge]=useState(0)
  const [carExtraCharge,setCarExtraCharge]=useState(0)
  const [carWaitingCharge,setCarWaitingCharge]=useState(0)
  const [carSingCharge,setCarSingCharge]=useState(0)
  const [carMalCharge,setCarMalCharge]=useState(0)
  const [carMidCharge,setCarMidCharge]=useState(0)
  const [driverlist, setDriverlist] = useState([])
  const [pageNumber, setPageNumber] = useState(1)
  const [isHotelPaid, setIsHotelPaid] = useState(false)
  const [isRideStarted, setIsRaidStarted] = useState(false)
  const [isDriverpaid,setIsDriverPaid]=useState(false)
  const [confirmStatus,setConfirmationStatus]=useState(true)
  const [success,setSucess]=useState(false)
  const [isVisible,setIsvisible]=useState(true);
  const [isBookingSuccess,setIsBookingSuccess]=useState(false)
  const [disabled, setDisabled] = useState(false);
  const [arrivalCharge,setArrivalCharge]=useState(0)
  const [total,setTotal]=useState(0)
  const [dName,setDName]=useState()
  const [exDisCharge,setExDisCharge]=useState(0)
  const [error, setError] = useState({
    status: false,
    message: "",
    type: ""
})
  function countUP(setMidnightCharge, midnightCharge) {
    //setMidnightCharge(midnightCharge+1);
    setMidnightCharge((prevHours) => {
      const newExtraHours = prevHours + 1;
      const charge = newExtraHours * carMidCharge;
      setMidCharge(charge);
      return newExtraHours; 
    });

  }

  function countDown(setMidnightCharge, midnightCharge) {
    if(midnightCharge!==0){
      setMidnightCharge((prevHours) => {
        const newExtraHours = prevHours - 1;
        const charge = newExtraHours * carMidCharge;
        setMidCharge(charge);
        return newExtraHours; 
      });
    }
   
  }
  function countUPDisposal(setExtraDisposal, extraDisposal) {
    setExtraDisposal(extraDisposal+1)
    setExDisCharge((extraDisposal + 1) * extraHours);
  }

  function countDownDisposal(setExtraDisposal, extraDisposal) {
    if(extraDisposal > 0) {
      setExtraDisposal(extraDisposal - 1)
      setExDisCharge((extraDisposal - 1) * extraHours);
    }
}
   
  




  function countUP1(setWaitingCharge, waitingCharge, setWaitCharge,carWaitingCharge) {
    setWaitingCharge(waitingCharge+1);
    setWaitCharge((waitingCharge + 1) * carWaitingCharge);

  }

  function countDown1(setWaitingCharge, waitingCharge, setWaitCharge, carWaitingCharge) {
    if (waitingCharge !== 0) {
      //const newWaitingCharge = waitingCharge - 1;
       setWaitingCharge(waitingCharge - 1);
       setWaitCharge((waitingCharge -1) * carWaitingCharge);
    }
  }



  function countUPStop(setAddExtraStop, addExtraStop,setAddExtraStopCharge){
    if(addExtraStop + extraStops !== 2) {
      setAddExtraStop((prevExtraHours) => {
        const newExtraHours = prevExtraHours + 1;
        const charge = newExtraHours * carExtraCharge;
        setAddExtraStopCharge(charge);
        return newExtraHours; 
      });
    }
    
  }

  function countDownStop(setAddExtraStop, addExtraStop,setAddExtraStopCharge){
    if(addExtraStop!==0){
      setAddExtraStop((prevExtraHours) => {
        const newExtraHours = prevExtraHours - 1;
        const charge = newExtraHours * carExtraCharge;
        setAddExtraStopCharge(charge);
        return newExtraHours; 
      });
    }
  }


  useEffect(() => {
    driverlistAPI()
    if(array){
     console.log("ARRAY----",array)

     // const carname = array.
      const extrastop=array.extra_stop;
      setExtraStops(extrastop);

      setExtraHours(array.car_info.extra_hours);

      const additionalStop=array.additional_extra_stop;
      setAddExtraStop(additionalStop);

      const additionalCharge=additionalStop * array.car_info.extra_stop_surcharge;
      setAddExtraStopCharge(additionalCharge);

      const extraCharge=array.payment_info.extra_stop_charge;
      if(extraCharge > 0){
        setExtraStopCharge(extraCharge)
      }else{
        setExtraStopCharge(0)
      }
     

      const midNCharge=array.payment_info.midnight_charge;
      if(midNCharge > 0){
        setMidCharge(midNCharge)
      }else{
        setMidCharge(0)
      }
     
      const exDisHours = array.payment_info.extra_disposal_charge;
      setExDisCharge(exDisHours)

      const waitingCharge=array.payment_info.waiting_minutes_charge;
      if(waitingCharge > 0){
        setWaitCharge(waitingCharge)
      }else{
        setWaitCharge(0)
      }
      
      const disposCharge=array.payment_info.disposal_charge;
      if(disposCharge > 0){
        setDisposalCharge(disposCharge)
      }else{
        setDisposalCharge(0)
      }
      
      const id=array._id;
      setBookingId(id);
      //setCStatus(array.confirmation_status)
      const name=array.driver_name;
      setDName(name)

      const midHours=array.midnight_hours;
      if(midHours>0){
        setMidnightCharge(midHours)
      }else{
        setMidnightCharge(0)
      }

      if(array.disposal_hours){
        setDisposalhours(array.disposal_hours)
      }
      if(array.waiting_minutes){
        setWaitingCharge(array.waiting_minutes)
        setWaitCharge(array.waiting_minutes * array.car_info.waiting_time_per_minutes);
      }

      const dremarks = array.driver_payment_remark;
      setDriverRemarks(dremarks)

      const hremarks = array.hotel_payment_remark;
      setHotelRemarks(hremarks)

      const extraDisposeHours = array.extra_disposal_hours;
        setExtraDisposal(extraDisposeHours)
      
     
      if(array.confirmation_status===3){
        setConfirmationStatus(false);
      }else{
        setConfirmationStatus(true);
      }


      if(array.driver_payment===0){
        setIsDriverPaid(false)
      }else{
        setIsDriverPaid(true)
      }


      if(array.hotel_payment===0){
        setIsHotelPaid(false)
      }else{
        setIsHotelPaid(true)
      }


      if(array.ride_status==="2"){
        setIsRaidStarted(true)
      }else {
        setIsRaidStarted(false)
      }


      const arrivalCharge=array.car_info.airport_arrival;
      setArrivalCharge(arrivalCharge);

      const EXTRA_STOP_CHARGE =array.car_info.extra_stop_surcharge;
      setCarExtraCharge(EXTRA_STOP_CHARGE)

      const carSingDispCharge=array.car_info.singapore_disposal_per_hours;
      setCarSingCharge(carSingDispCharge)

      const carMidNightCharge=array.car_info.midnight_surcharge_per_hours;
      setCarMidCharge(carMidNightCharge)

      const carMalaDispCharge=array.car_info.malaysia_disposal_per_hours;
      setCarMalCharge(carMalaDispCharge)

      const carWaitCharge = array.car_info.waiting_time_per_minutes;
      setCarWaitingCharge(carWaitCharge)


      if(array.driver_id){
        setSelectedDriver({
          value: array.driver_id,
          label: array.driver_name,
        });
      }
      setDriverId(array.driver_id);
      setDriverName(array.driver_name);
     }

     const travelTime = array.time;
     setTime(travelTime);
     checkIsMidnight(travelTime);

  }, [array]);


  const driverlistAPI = async ()=> {
    await fetch(config.baseUrl + "api/admin/driverlist", {
        method: "POST",

        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            page: pageNumber,
            limit: "all",
        }),
    })
    .then((response) => response.json())
    .then((data) => {
            console.log("Drivers List Array",data);
            setDriverlist(data.data);
           
        });
    }
    const filteredDriverListArray = driverlist.filter((cobj) => cobj.status === 1);

    var namesArray=[];

    namesArray = filteredDriverListArray.map(({ _id, first_name, last_name }) => ({
      id: _id,
      name: `${first_name} ${last_name}`,
    }));

    const newcar = array.car_info.car_brand+" - "+array.car_info.car_model;
    console.log(newcar);
    const namesArray1 = filteredDriverListArray.filter((newlist)=> newlist.car_name === newcar)
    var newdriver = [];
    newdriver =  namesArray1.map(({ _id, first_name, last_name }) => ({
      value: _id,
      label: `${first_name} ${last_name}`,
    }));
    console.log(namesArray,"NAMES OF THE DRIVER")


    // const dropdown = document.getElementById('nameDropdown');
    // if (dropdown) {
    //   dropdown.innerHTML = ''; // Clear the dropdown
    //   namesArray.forEach((name) => {
    //     const option = document.createElement('option');
    //     option.value = name.id;
    //     option.text = name.name;
    //     dropdown.appendChild(option);
    
    //   });
    //   dropdown.value = selectedDriver;
    // }

    const checkIsMidnight = (travelTime) => {
      let time=travelTime;
      //console.log("THE TIME BOOKED IS",time)
      // Regular expression to validate the time format (HH:mm)
      const timeRegex = /^(0?[0-9]|1[0-9]|2[0-3]):(0?[0-9]|[1-5][0-9])$/;
      let check=timeRegex.test(time);
     // console.log(check,"CHECKING WITH EXPRESSION")
    if (timeRegex.test(time)) {
      const [hours, minutes] = time.split(':').map(Number);

      // Convert the time to a numerical value in minutes for easier comparison
      const totalMinutes = hours * 60 + minutes;
    //  console.log("THE TIME AFTER SPLIT",totalMinutes)
      // if(totalMinutes <= 23 * 60 + 59)
      // {
      //   setIsMidnight(true)
      // } else if(totalMinutes >= 6 * 60 + 59){
      //   setIsMidnight(true)
      // }else{
      //   setIsMidnight(false);
      // }
      setIsMidnight(totalMinutes >= 23 * 60 + 59 || totalMinutes <= 6 * 60 + 59 || totalMinutes === 0);
      
    }  else {
        // Reset the state if the time format is invalid
        setIsMidnight(false);
      }
     
    };
  //  console.log("THE TIME IS MIDNIGHT",isMidnight);

    const getTotal = () =>{

      if(array.pickup_type !== "4" && array.pickup_type !== "5"){
        let total= arrivalCharge + extraStopCharge + waitCharge + midCharge + exDisCharge;
        setTotal(total)
        console.log(total,"THE PRICE CALCULATED")

      }else{
        let total= waitCharge + midCharge + disposalCharge + exDisCharge;
        setTotal(total)
        console.log(total,"THE PRICE CALCULATED")
      }
      
      
    }

    const handleSave = async ()=>{
      if(confirmStatus){
        if(!driverId || driverId===null){
          setError({ status: true, message: "Please Select Driver", type: 'error' });
          setIsBookingSuccess(false)
          setTimeout(() => {
            setError({ status: false, message: '', type: '' });
          }, 3000);
          return;
           }
      }

     
      let res = await fetch(config.baseUrl + "api/confirm_booking/"+bookingId , {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            confirmation_status: getConfirmationStatus(),
            driver_id: driverId,
            driver_name:driverName,
            ride_status: getRideStatus(),
            extra_stop: extraStops,
            additional_extra_stop: addExtraStop,
            disposal_hours: disposalHours? disposalHours:0,
            extra_disposal_hours:extraDisposal ? extraDisposal :0,
            waiting_minutes: waitingCharge,
            surcharge_percentage: percentage,
            hotel_payment: getHotelPayment(),
            hotel_payment_remark:hotelRemarks,
            driver_payment: getDriverPayment(),
            driver_payment_remark: driverRemarks,
            midnight_hours:midnightCharge ? midnightCharge : 0,
            midnight_charge:midCharge ? midCharge : 0,
            total:getTotal(),
            user_id:localStorage.getItem("userid")
        }),
    }); 
    let resJson = await res.json();
    if (res.status === 200) {
      console.log("Successs")
      setIsvisible(false)
      namesArray=[];
      setIsBookingSuccess(true)
     setTimeout(() => {
      setShowSideBar(false)
      setIsBookingSuccess(false)
    }, 3000);
      setSucess(true)
      fetchBooking()
    }else{
      setError({ status: true, message: resJson.message, type: 'error' });
      setIsBookingSuccess(false)
      console.log("Error IS",resJson.message);
      setSucess(false)
      namesArray=[];
    }
    }

    function handleOnChange(event) {
      const hremarks = event.target.value;
      setHotelRemarks(hremarks)
      
    }
    function handleDriverRemarks(event){
      const dremarks=event.target.value;
      setDriverRemarks(dremarks);
    }




const handleConfirmation = (e) => {
  const isChecked = e.target.checked;
  const checkboxId = e.target.id;

  if (isChecked) {
    if (checkboxId === "acceptbooking") {
      setDisabled(false); 
      setConfirmationStatus(true); // Select "Paid" option
    } else if (checkboxId === "cancelbooking") {
      setDisabled(true); 
      setConfirmationStatus(false); // Select "Not Paid" option
    }
  }
};


    const getConfirmationStatus=()=>{
      return confirmStatus ? "2" :"3";
    }
    const handleCheckboxChange = (e) => {
      const isChecked = e.target.checked;
      const checkboxId = e.target.id;
    
      if (isChecked) {
        if (checkboxId === "inlineCheckbox5") {        
          setIsHotelPaid(true); // Select "Paid" option
        } else if (checkboxId === "inlineCheckbox6") {
         
          setIsHotelPaid(false); // Select "Not Paid" option
        }
      }
    };
   
    const getHotelPayment =()=>{
      return isHotelPaid ? "1":"0";
    }
    const handleRideChange =(e)=>{
      
      const isChecked = e.target.checked;
      const checkboxId = e.target.id;
      if (isChecked) {
        if (checkboxId === "inlineCheckbox3") {        
          setIsRaidStarted(true); // Select "Paid" option
        } else if (checkboxId === "inlineCheckbox4") {
         
          setIsRaidStarted(false); // Select "Not Paid" option
        }
      }
    }
   
    const getRideStatus =()=>{
      return isRideStarted ? "2":"1";
    }


    const handleDriverPayment =(e)=>{
     
      const isChecked = e.target.checked;
      const checkboxId = e.target.id;
      if (isChecked) {
        if (checkboxId === "inlineCheckbox7") {        
          setIsDriverPaid(true); // Select "Paid" option
        } else if (checkboxId === "inlineCheckbox8") {
         
          setIsDriverPaid(false); // Select "Not Paid" option
        }
      }
    }
   
    const getDriverPayment=()=>{
      return isDriverpaid ? "1":"0";
    }


    const handleCancel=()=>{
      setShowSideBar(false)
      namesArray=[];
    }

    const handleWaitCharge=()=>{

          if(waitingCharge > 0){
            return waitCharge+1 
          }else{
            return waitCharge
          }
     
    }


    const handleDriverChange = (selectedOption) => {
     // setSelectedDriver(selectedOption);
      if (selectedDriver && selectedDriver !== selectedOption) {
        setSelectedDriver(selectedOption);
        setNewSelectedDriver(selectedOption);
        setShowConfirmationModal(true);

      } else {
        setSelectedDriver(selectedOption);
        setDriverId(selectedOption.value);
        setDriverName(selectedOption.label);
      }

    };
   // console.log(selectedDriver,driverId,driverName,"AFTER SELECTING FROM SECOND SELECT")

    const confirmDriverChange = () => {
      setSelectedDriver(newSelectedDriver);
      setDriverId(selectedDriver.value);
      setDriverName(selectedDriver.label);
      setShowConfirmationModal(false);
    };


    const getType=(type)=>{
      if(type==="1"|| type==="Arrival"){
          return "Airport Arrival";
      }else if(type==="2"){
          return "Airport Departure";
      }else if(type==="3"){
          return "Point To Point(Transfer)";
      }else if(type==="4"){
          return "Singapore Disposal";
      }else {
          return "Malaysia Disposal";
      }
    }

    const isRideAlreadyCompleted = array.ride_status === "2";
  return (

    <React.Fragment>

      <OffcanvasBody>
        <div className="mb-3">
        {isBookingSuccess && (
                                <UncontrolledAlert color="success" onClose={() => setIsBookingSuccess(false)}>Your Booking has been Updated successfully</UncontrolledAlert>)}
                            {!isBookingSuccess  && error.status && (
                                <UncontrolledAlert color="danger" onClose={() => setIsBookingSuccess(false)}>   {error.status && (
                                    <div
                                        id="error"
                                        style={{
                                            
                                            textAlign: 'start'
                                        }}
                                    >
                                        <i className="bi bi-exclamation-circle" >
                                            </i>&nbsp;&nbsp;&nbsp;&nbsp;{error.message}
                                    </div>
                                )}</UncontrolledAlert>)}
          <Label htmlFor="datepicker-range" className="form-label text-muted mb-1 fs-14">Booking Status (<span className="text-danger">*</span>)</Label>
          <div className="row g-2">
            <div className="col-lg-6">
              <div className="form-check">
                <Input className="form-check-input" type="checkbox" name="bookingStatus" id="acceptbooking" 
                checked={confirmStatus}
                onChange={handleConfirmation}
                defaultChecked
                disabled={isRideAlreadyCompleted}
                 />
                <Label className="form-check-label" htmlFor="acceptbooking">Accept Booking</Label>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-check">
                <Input className="form-check-input" type="checkbox" name="bookingStatus" id="cancelbooking"
                checked={!confirmStatus}
                onChange={handleConfirmation} 
                disabled={isRideAlreadyCompleted}
                />
                <Label className="form-check-label" htmlFor="cancelbooking">Cancel Booking</Label>
              </div>
            </div>
          </div>
        </div>


        {confirmStatus ?  <div className="mb-4">
       <Label htmlFor="datepicker-range" className="form-label text-muted mb-0">Assign Driver Name (<span className="text-danger">*</span>)</Label>
                          <Select
                      className="mb-0"
                      classNamePrefix="form-control-select"
                      value={selectedDriver}
                      onChange={handleDriverChange}
                      options={newdriver}
                      placeholder="Select Driver"
                      inputId="driverSelect"
                      isDisabled={isRideAlreadyCompleted}
                    />
        </div>
        :''}                                
       
        
        {confirmStatus && driverId && <hr />}
        { confirmStatus && driverId && (    <div className="mb-3">
          <Label htmlFor="datepicker-range" className="form-label text-muted mb-2 fs-14">Ride Status <span className="text-dark fs-13">({getType(array.pickup_type)})</span></Label>
          <div className="row g-2">
            <div className="col-lg-6">
              <div className="form-check">
                <Input className="form-check-input" type="checkbox" name="rideStatus" id="inlineCheckbox3"
                  checked={isRideStarted}
                  disabled={isRideAlreadyCompleted}
                  onChange={handleRideChange}/>
                <Label className="form-check-label" htmlFor="inlineCheckbox3">Ride Completed</Label>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-check">
                <Input className="form-check-input" type="checkbox" name="rideStatus" id="inlineCheckbox4" 
                checked={!isRideStarted}
                onChange={handleRideChange}
                disabled={isRideAlreadyCompleted}
                defaultChecked />
                <Label className="form-check-label" htmlFor="inlineCheckbox4">Not Yet Start Ride</Label>
              </div>
            </div>
          </div>
        </div>)}
        <div className="mb-3">
         
        <div className="row g-2">
        {confirmStatus && isRideStarted && ( array.pickup_type!=="4" && array.pickup_type!=="5" &&(<div className="col-lg-6">
              <Label htmlFor="datepicker-range" className="form-label text-dark mb-0">Extra Stop </Label>
              <div className="input-step">
                <button
                  type="button"
                  className="minus"
                  onClick={() => {
                    countDownStop(setAddExtraStop, addExtraStop,setAddExtraStopCharge);
                  }}
                >
                  –
                </button>
                <Input
                  type="number"
                  className="input-number-sidebar"
                  value={addExtraStop}
                  min="0"
                  max="100"
                  readOnly

                />
                <button
                  type="button"
                  className="plus"
                  onClick={() => {
                    countUPStop(setAddExtraStop, addExtraStop,setAddExtraStopCharge);
                  }}
                >
                  +
                </button>
              </div>
              <div className="form-text">Extra stop Charge ${addExtraStopCharge}</div>
            </div>))}
           

        {confirmStatus && isRideStarted && (array.pickup_type !== "1" && array.pickup_type !== "2" && array.pickup_type !== "3" &&(<div className="col-lg-6">
              <Label htmlFor="datepicker-range" className="form-label text-dark mb-0">Disposal Extra Hours</Label>
              <div className="input-step">
                <button
                  type="button"
                  className="minus"
                  onClick={() => {
                    countDownDisposal(setExtraDisposal, extraDisposal);
                  }}
                >
                  –
                </button>
                <Input
                  type="number"
                  className="input-number-sidebar"
                  value={extraDisposal}
                  min="0"
                  max="100"
                  readOnly

                />
                <button
                  type="button"
                  className="plus"
                  onClick={() => {
                    countUPDisposal(setExtraDisposal, extraDisposal);
                  }}
                >
                  +
                </button>
              </div>


          <div className="form-text">Extra Disposal Charge ${exDisCharge}</div>
        </div>)) }
           
      {confirmStatus && isRideStarted && ( array.pickup_type!=="4" && array.pickup_type!=="5" && (  <div className="col-lg-6">
          
          <Label htmlFor="datepicker-range" className="form-label text-dark mb-0">Waiting Time</Label>
          <div className="input-step">
            <button
              type="button"
              className="minus"
              onClick={() => {
                countDown1(setWaitingCharge, waitingCharge,setWaitCharge,carWaitingCharge);
              }}
            >
              –
            </button>
            <Input
              type="number"
              className="input-number-sidebar"
              value={waitingCharge}
              min="0"
              max="100"
              readOnly

            />
            <button
              type="button"
              className="plus"
              onClick={() => {
                countUP1(setWaitingCharge, waitingCharge, setWaitCharge,carWaitingCharge);
              }}
            >
              +
            </button>
          </div>

          <div className="form-text">Waiting Charge ${waitCharge}</div>
        </div>))}  
      
      </div>
        </div>
      <div className="row g-2">
        {confirmStatus && isRideStarted && (   <div className="col-lg-6">
          <Label htmlFor="datepicker-range" className="form-label text-dark mb-0">Midnight Charge</Label>
          <div className="input-step">
            <button
              type="button"
              className="minus"
              onClick={() => {
                countDown(setMidnightCharge, midnightCharge);
              }}
            >
              –
            </button>
            <Input
              type="number"
              className="input-number-sidebar"
              value={midnightCharge}
              min="0"
              max="100"
              readOnly

            />
            <button
              type="button"
              className="plus"
              onClick={() => {
                countUP(setMidnightCharge, midnightCharge);
              }}
            >
              +
            </button>
          </div>

          <div className="form-text">Midnight Charge ${midCharge}</div>
        </div>)}

        {confirmStatus && isRideStarted && ( <div className="col-lg-6">
          <Label htmlFor="datepicker-range" className="form-label text-dark mb-0">Surcharge Percentage</Label>
          <div className="input-step">
            <button
              type="button"
              className="minus"
              onClick={() => {
                if(percentage!==0){
                  setPercentage(percentage-1)
                }
              }}
            >
              –
            </button>
            <Input
              type="number"
              className="input-number-sidebar"
              value={percentage}
              min="0"
              max="100"
              readOnly

            />
            <button
              type="button"
              className="plus"
              onClick={() => {
                  setPercentage(percentage+1)
              }}
            >
              +
            </button>
          </div>

          <div className="form-text">Surcharge Percentage {percentage ? `${percentage * 25}%`: '0%' }</div>
        </div>)}
      </div>
     
       
  
        {confirmStatus && isRideStarted && <hr />}
        {confirmStatus && isRideStarted && (   <div>
          {/* <Label htmlFor="datepicker-range" className="form-label text-muted mb-1 fs-14">Hotel Payment</Label> */}
          <div className="row g-2">
            {/* <div className="col-lg-6">
              <div className="form-check">
                <Input className="form-check-input" type="checkbox" name="hotelPayment" id="inlineCheckbox5"
                checked={isHotelPaid}
                onChange={handleCheckboxChange} />
                <Label className="form-check-label" htmlFor="inlineCheckbox5">Paid</Label>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-check">
                <Input className="form-check-input" type="checkbox" name="hotelPayment" id="inlineCheckbox6" 
                checked={!isHotelPaid}
                onChange={handleCheckboxChange}
                defaultChecked />
                <Label className="form-check-label" htmlFor="inlineCheckbox6">Not Paid</Label>
              </div>
            </div> */}
            <div className="col-lg-12">
              <Label htmlFor="formtextInput" className="form-label">Additional Remarks</Label> {/* Hotelremarks changed to  Additional Remarks*/}
              <textarea className="form-control" id="exampleFormControlTextarea5" rows="3" value={hotelRemarks} onChange={handleOnChange}></textarea>
              <div id="passwordHelpBlock" className="form-text">
                Must be 8-20 characters long.
              </div>
            </div>
          </div>
        </div>)}
        {confirmStatus ? <hr />:''}
        
        {confirmStatus && isRideStarted && (    <div className="mb-4">
          <Label htmlFor="datepicker-range" className="form-label text-muted mb-1 fs-14">Driver Payment</Label>
          <div className="row g-2">
            <div className="col-lg-6">
              <div className="form-check">
                <Input className="form-check-input" type="checkbox" name="driverPayment" id="inlineCheckbox7" 
                checked={isDriverpaid}
                onChange={handleDriverPayment}/>
                <Label className="form-check-label" htmlFor="inlineCheckbox7">Paid</Label>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-check">
                <Input className="form-check-input" type="checkbox" name="driverPayment" id="inlineCheckbox8" 
                checked={!isDriverpaid}
                onChange={handleDriverPayment}
                defaultChecked />
                <Label className="form-check-label" htmlFor="inlineCheckbox8">Not Paid</Label>
              </div>
            </div>
            <div className="col-lg-12">
              <Label htmlFor="formtextInput" className="form-label">Payment Remarks</Label>
              <textarea className="form-control" id="exampleFormControlTextarea5" rows="3"value={driverRemarks} onChange={handleDriverRemarks}></textarea>
              <div id="passwordHelpBlock" className="form-text">
                Must be 8-20 characters long.
              </div>
            </div>
          </div>
        </div>)}
    
      </OffcanvasBody>
      <div className="offcanvas-footer border-top p-3 text-center hstack gap-2 bg-light position-sticky bottom-0">
        {isVisible ? <button
          className="btn btn-success w-100"
          onClick={handleSave}
        >
          Save
        </button>:""}
       
        <button
          onClick={handleCancel}
          className="btn  btn-outline-light w-100 border-color-b9b9b9"
        >
          Cancel
        </button>
      </div>
      <ConfirmModal
        show={showConfirmationModal}
        onDeleteClick={confirmDriverChange}
        onCloseClick={() => {
          setSelectedDriver({
            value: driverId,
            label: driverName,
          });
        //  setDriverId(dr);
         // setDriverName(selectedDriver.label);
          setShowConfirmationModal(false)}}
        
      />
    </React.Fragment>
  )
}

export default BookingConfirmation