import React from 'react';

//import Scss
import './assets/scss/themes.scss';

//imoprt Route
import Route from './Routes';

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper";

// Fake Backend 
import fakeBackend from "./helpers/AuthType/fakeBackend";

// Activating fake backend
fakeBackend();

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_API_KEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// };

// init firebase backend
// initFirebaseBackend(firebaseConfig);

function App() {
  window.onscroll = function () {
    scrollFunction();
  }
  const scrollFunction = () => {
    const element = document.getElementById("back-to-top");
    if (element) {
        if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
            element.style.display = "block";
        } else {
            element.style.display = "none";
        }
    }
};
const toTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
};
  return (
    <React.Fragment>
      <Route />
      <button onClick={() => toTop()} className="btn btn-danger btn-icon landing-back-top" id="back-to-top">
                    <i className="ri-arrow-up-line"></i>
                </button>
    </React.Fragment>
  );
}

export default App;
