/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";
import "swiper/css/effect-flip";
import { Pagination, Navigation, Scrollbar, EffectFade, EffectCreative, Mousewheel, EffectFlip, EffectCoverflow, Autoplay } from "swiper";
import { useState,useEffect } from "react"
import img7 from '../../../assets/images/cars-info/Mercedes Benz - S400 - 5.jpg'
import img8 from '../../../assets/images/cars-info/Mercedes Benz - S400 - 6.jpg'
import img9 from '../../../assets/images/cars-info/Mercedes Benz - S400 -1.jpg'
import { Card, CardBody, CardFooter, CardHeader, Col, Input, Row } from 'reactstrap';
import { CarsInfo } from './data';
import config from "../../../common/config"
const CarsInfoList = ({selectedCar,extraHours,isEdit,carId, setCarId, activeTab,babySeats,isBookingSuccess}) => {
    const [isAcitve, setIsActive] = React.useState(false)
    const [id,setId]=useState(false);
    const [carDetails,setCarDetails]=useState("");
    const[pageNumber,setPageNumber]=useState(1)
    const [car,setCar]=useState("")
    const [selectedSeats,setSelectedSeats]=useState("")
    const [selectCar,setSelectCar]=useState([])
    const selectedCars = [];
    const [currentActiveTab, setCurrentActiveTab] = useState(activeTab);
    const [malaysiaCars,setMalaysiaCars] = useState("")

    useEffect(() => {
        fetchCars()
    }, [selectedSeats])

    useEffect(() => {
        if(carDetails) {
            setIsActive(carId);
        }
     }, [carId, carDetails])

     useEffect(() => {
        const carBrandAndModel = getCarById(isAcitve);
        setCar(carBrandAndModel)
     }, [isAcitve])

    useEffect(() => {
        setSelectedSeats(extraHours + babySeats)
        setCurrentActiveTab(activeTab)   
    }, [extraHours,babySeats, isAcitve]);


    useEffect(() => {
        const carListArray = Object.values(carDetails);
        const filteredCarListArray = carListArray.filter((car) => car.status === 1);

        const carsNotavailable = filteredCarListArray.filter((car)=> car.malaysia_disposal_per_hours !== 0);
        const carsWithSeats = carsNotavailable.filter((car) => car.seat_capacity >= selectedSeats);

        const carsNotavailableForArrival = filteredCarListArray.filter((car)=> car.airport_arrival !== 0);
        const carsWithSeatsArrival = carsNotavailableForArrival.filter((car) => car.seat_capacity >= selectedSeats);


        const carsNotavailableForSingapore = filteredCarListArray.filter((car)=> car.singapore_disposal_per_hours !== 0);
        const carsWithSeatsSingapore = carsNotavailableForSingapore.filter((car) => car.seat_capacity >= selectedSeats);


        if(activeTab === "4" && carsWithSeatsSingapore.length === 0){
            setCarId('');
        } else if(activeTab === "5" && carsWithSeats.length === 0){
            setCarId('');
        } else if(activeTab !== "4" && activeTab !== "5" && carsWithSeatsArrival.length === 0){
            setCarId('');
        }

    }, [carDetails])

      const getCarById = (carId) => {
        const carListArray = Object.values(carDetails);
        const car = carListArray.find((carObj) => carObj._id === carId);
        return car ? car : null;
      };


      const handleCarSelection = (carId) => {
       // setIsActive(carId);
       setIsActive((prevCarId) => (prevCarId === carId ? false : carId));
       };


      const fetchCars = async () => {
        await fetch(config.baseUrl + "api/admin/carlist", {
            method: "POST",

            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                page: pageNumber,
                limit: "all",
            }),
        })
        .then((response)=>response.json())
            .then((data)=>{
               // console.log(data.data)
               setCarDetails(data.data);
            });
      };



      const carListArray = Object.values(carDetails);
      const filteredCarListArray = carListArray.filter((car) => car.status === 1);
     // console.log(filteredCarListArray);
      const findCarBrandAndModel = (id) => {
        const car = carListArray.find((car) => car._id === id);
        if (car) {
          const { _id,car_brand, car_model,airport_arrival } = car;
          return { _id,car_brand, car_model,airport_arrival };
        }
        return null;
      };
      

  
      selectedCar(car);
      const SelectedSeatCar = filteredCarListArray.filter((car) => car.seat_capacity >= selectedSeats);

      const carsNotavailable = filteredCarListArray.filter((car)=> car.malaysia_disposal_per_hours !== 0);
      const carsWithSeats = carsNotavailable.filter((car) => car.seat_capacity >= selectedSeats);

      const carsNotavailableForArrival = filteredCarListArray.filter((car)=> car.airport_arrival !== 0);
      const carsWithSeatsArrival = carsNotavailableForArrival.filter((car) => car.seat_capacity >= selectedSeats);


      const carsNotavailableForSingapore = filteredCarListArray.filter((car)=> car.singapore_disposal_per_hours !== 0);
      const carsWithSeatsSingapore = carsNotavailableForSingapore.filter((car) => car.seat_capacity >= selectedSeats);

      const calculatePrice = (car) => {
       
        if (currentActiveTab === "4") {
          return car.singapore_disposal_per_hours;
        } else if (currentActiveTab === "5") {
          return car.malaysia_disposal_per_hours;
        } else {
          
          return car.airport_arrival;
        }
      };



    return (
        <React.Fragment>
            <div>
                <h5 className="mb-0">Cars Info</h5>
                <p className="text-muted mt-0">Fill all information below</p>
            </div>
            {activeTab === "4" && (( carsWithSeatsSingapore ? (<Row>
                
                {
                    carsWithSeatsSingapore.map((item, i) => (
                        <Col md={6} lg={4} xl={3} key={i}>
                            <Card className={`card-car-info ${isAcitve === item._id ? 'active-card' : ''}`}>
                                <CardHeader className='bg-light border-1 align-items-center d-flex justify-content-between py-2'>
                                    <h4 className="card-title mb-0 fs-13">{item.car_brand} - {item.car_model}</h4>
                                    <div className="flex-shrink-0 align-items-center">
                                        <div className="form-check form-switch form-switch-right mt-2 me-2">
                                            <Input className="form-check-input code-switcher" type="checkbox" role="switch" id={item._id} name='carInfo'  checked={isAcitve === item._id} defaultChecked={carId ? carId === item._id : false} onChange={() => handleCarSelection(item._id)}/>
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody className='p-0'>
                                    <Swiper pagination={{ clickable: true, dynamicBullets: true, }} modules={[Pagination, Autoplay]} loop={true} autoplay={{ delay: 2500, disableOnInteraction: false }} className="swiper pagination-dynamic-swiper dynamic-pagination rounded" >
                                        <div className="swiper-wrapper">
                                            {
                                                item.car_photos.map((sliderItem, i) => (
                                                    <SwiperSlide key={i}><img src={config.baseUrl + sliderItem} alt="" className="img-fluid" /></SwiperSlide>


                                                ))
                                            }

                                        
                                        </div>
                                    </Swiper>
                                    <div className="p-2 row">
                                        <div className="col-6">
                                            <p className="text-muted mb-0 mt-0">Price</p>
                                        
                                                <h3 className="card-title mb-0 fs-17">${calculatePrice(item)}</h3>
                                            
                                            
                                            
                                        </div>
                                        <div className="col-6 text-end">
                                            <p className="text-muted mb-0 mt-0">Seat Capacity</p>
                                            <h3 className="card-title mb-0 fs-17">{item.seat_capacity}</h3>
                                        </div>
                                    </div>
                                </CardBody>
                                <CardFooter className='bg-light border-top-dashed py-2'>
                                    <div className="flex-grow-1">
                                        <h5 className="mb-1 fs-14">Car Details</h5>
                                        { item.car_details
                                           /* item.carDetail.map((cardItems, i) => (
                                                <p key={i} className="text-muted text-truncate-two-lines mb-1 fs-12 fw-normal ">{cardItems.id}. {cardItems.label}</p>
                                            ))*/
                                        }


                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>
                    ))
                }


            </Row>) : (<Row>
                
                {
                    carsNotavailableForSingapore.map((item, i) => (
                        <Col md={6} lg={4} xl={3} key={i}>
                            <Card className={`card-car-info ${isAcitve === item._id ? 'active-card' : ''}`}>
                                <CardHeader className='bg-light border-1 align-items-center d-flex justify-content-between py-2'>
                                    <h4 className="card-title mb-0 fs-13">{item.car_brand} - {item.car_model}</h4>
                                    <div className="flex-shrink-0 align-items-center">
                                        <div className="form-check form-switch form-switch-right mt-2 me-2">
                                            <Input className="form-check-input code-switcher" type="checkbox" role="switch" id={item._id} name='carInfo' checked={isAcitve === item._id} defaultChecked={carId ? carId === item._id : false} onChange={() => handleCarSelection(item._id)}/>
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody className='p-0'>
                                    <Swiper pagination={{ clickable: true, dynamicBullets: true, }} modules={[Pagination, Autoplay]} loop={true} autoplay={{ delay: 2500, disableOnInteraction: false }} className="swiper pagination-dynamic-swiper dynamic-pagination rounded" >
                                        <div className="swiper-wrapper">
                                            {
                                                item.car_photos.map((sliderItem, i) => (
                                                    <SwiperSlide key={i}><img src={config.baseUrl + sliderItem} alt="" className="img-fluid" /></SwiperSlide>


                                                ))
                                            }

                                        
                                        </div>
                                    </Swiper>
                                    <div className="p-2 row">
                                        <div className="col-6">
                                            <p className="text-muted mb-0 mt-0">Price</p>
                                        
                                                <h3 className="card-title mb-0 fs-17">${calculatePrice(item)}</h3>
                                            
                                            
                                            
                                        </div>
                                        <div className="col-6 text-end">
                                            <p className="text-muted mb-0 mt-0">Seat Capacity</p>
                                            <h3 className="card-title mb-0 fs-17">{item.seat_capacity}</h3>
                                        </div>
                                    </div>
                                </CardBody>
                                <CardFooter className='bg-light border-top-dashed py-2'>
                                    <div className="flex-grow-1">
                                        <h5 className="mb-1 fs-14">Car Details</h5>
                                        { item.car_details
                                           /* item.carDetail.map((cardItems, i) => (
                                                <p key={i} className="text-muted text-truncate-two-lines mb-1 fs-12 fw-normal ">{cardItems.id}. {cardItems.label}</p>
                                            ))*/
                                        }


                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>
                    ))
                }


            </Row> ))) }

            {activeTab === "5" &&  ( carsWithSeats ? (<Row>
                
                {
                    carsWithSeats.map((item, i) => (
                        <Col md={6} lg={4} xl={3} key={i}>
                            <Card className={`card-car-info ${isAcitve === item._id ? 'active-card' : ''}`}>
                                <CardHeader className='bg-light border-1 align-items-center d-flex justify-content-between py-2'>
                                    <h4 className="card-title mb-0 fs-13">{item.car_brand} - {item.car_model}</h4>
                                    <div className="flex-shrink-0 align-items-center">
                                        <div className="form-check form-switch form-switch-right mt-2 me-2">
                                            <Input className="form-check-input code-switcher" type="checkbox" role="switch" id={item._id} name='carInfo' checked={isAcitve === item._id} defaultChecked={carId ? carId === item._id : false} onChange={() => handleCarSelection(item._id)}/>
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody className='p-0'>
                                    <Swiper pagination={{ clickable: true, dynamicBullets: true, }} modules={[Pagination, Autoplay]} loop={true} autoplay={{ delay: 2500, disableOnInteraction: false }} className="swiper pagination-dynamic-swiper dynamic-pagination rounded" >
                                        <div className="swiper-wrapper">
                                            {
                                                item.car_photos.map((sliderItem, i) => (
                                                    <SwiperSlide key={i}><img src={config.baseUrl + sliderItem} alt="" className="img-fluid" /></SwiperSlide>


                                                ))
                                            }

                                        
                                        </div>
                                    </Swiper>
                                    <div className="p-2 row">
                                        <div className="col-6">
                                            <p className="text-muted mb-0 mt-0">Price</p>
                                        
                                                <h3 className="card-title mb-0 fs-17">${calculatePrice(item)}</h3>
                                            
                                            
                                            
                                        </div>
                                        <div className="col-6 text-end">
                                            <p className="text-muted mb-0 mt-0">Seat Capacity</p>
                                            <h3 className="card-title mb-0 fs-17">{item.seat_capacity}</h3>
                                        </div>
                                    </div>
                                </CardBody>
                                <CardFooter className='bg-light border-top-dashed py-2'>
                                    <div className="flex-grow-1">
                                        <h5 className="mb-1 fs-14">Car Details</h5>
                                        { item.car_details
                                           /* item.carDetail.map((cardItems, i) => (
                                                <p key={i} className="text-muted text-truncate-two-lines mb-1 fs-12 fw-normal ">{cardItems.id}. {cardItems.label}</p>
                                            ))*/
                                        }


                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>
                    ))
                }


            </Row>) : (<Row>
                
                {
                    carsNotavailable.map((item, i) => (
                        <Col md={6} lg={4} xl={3} key={i}>
                            <Card className={`card-car-info ${isAcitve === item._id ? 'active-card' : ''}`}>
                                <CardHeader className='bg-light border-1 align-items-center d-flex justify-content-between py-2'>
                                    <h4 className="card-title mb-0 fs-13">{item.car_brand} - {item.car_model}</h4>
                                    <div className="flex-shrink-0 align-items-center">
                                        <div className="form-check form-switch form-switch-right mt-2 me-2">
                                            <Input className="form-check-input code-switcher" type="checkbox" role="switch" id={item._id} name='carInfo'  checked={isAcitve === item._id} defaultChecked={carId ? carId === item._id : false} onChange={() => handleCarSelection(item._id)}/>
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody className='p-0'>
                                    <Swiper pagination={{ clickable: true, dynamicBullets: true, }} modules={[Pagination, Autoplay]} loop={true} autoplay={{ delay: 2500, disableOnInteraction: false }} className="swiper pagination-dynamic-swiper dynamic-pagination rounded" >
                                        <div className="swiper-wrapper">
                                            {
                                                item.car_photos.map((sliderItem, i) => (
                                                    <SwiperSlide key={i}><img src={config.baseUrl + sliderItem} alt="" className="img-fluid" /></SwiperSlide>


                                                ))
                                            }

                                        
                                        </div>
                                    </Swiper>
                                    <div className="p-2 row">
                                        <div className="col-6">
                                            <p className="text-muted mb-0 mt-0">Price</p>
                                        
                                                <h3 className="card-title mb-0 fs-17">${calculatePrice(item)}</h3>
                                            
                                            
                                            
                                        </div>
                                        <div className="col-6 text-end">
                                            <p className="text-muted mb-0 mt-0">Seat Capacity</p>
                                            <h3 className="card-title mb-0 fs-17">{item.seat_capacity}</h3>
                                        </div>
                                    </div>
                                </CardBody>
                                <CardFooter className='bg-light border-top-dashed py-2'>
                                    <div className="flex-grow-1">
                                        <h5 className="mb-1 fs-14">Car Details</h5>
                                        { item.car_details
                                           /* item.carDetail.map((cardItems, i) => (
                                                <p key={i} className="text-muted text-truncate-two-lines mb-1 fs-12 fw-normal ">{cardItems.id}. {cardItems.label}</p>
                                            ))*/
                                        }


                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>
                    ))
                }


            </Row> ))}
            { activeTab !== "4" && activeTab !== "5" && (  selectedSeats > 0 ? ( <Row>
                
                {
                    carsWithSeatsArrival.map((item, i) => (
                        <Col md={6} lg={4} xl={3} key={i}>
                            <Card className={`card-car-info ${isAcitve === item._id ? 'active-card' : ''}`}>
                                <CardHeader className='bg-light border-1 align-items-center d-flex justify-content-between py-2'>
                                    <h4 className="card-title mb-0 fs-13">{item.car_brand} - {item.car_model}</h4>
                                    <div className="flex-shrink-0 align-items-center">
                                        <div className="form-check form-switch form-switch-right mt-2 me-2">
                                            <Input className="form-check-input code-switcher" type="checkbox" role="switch" id={item._id} name='carInfo' checked={isAcitve === item._id} defaultChecked={carId ? carId === item._id : false} onChange={() => handleCarSelection(item._id)}/>
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody className='p-0'>
                                    <Swiper pagination={{ clickable: true, dynamicBullets: true, }} modules={[Pagination, Autoplay]} loop={true} autoplay={{ delay: 2500, disableOnInteraction: false }} className="swiper pagination-dynamic-swiper dynamic-pagination rounded" >
                                        <div className="swiper-wrapper">
                                            {
                                                item.car_photos.map((sliderItem, i) => (
                                                    <SwiperSlide key={i}><img src={config.baseUrl + sliderItem} alt="" className="img-fluid" /></SwiperSlide>


                                                ))
                                            }

                                        
                                        </div>
                                    </Swiper>
                                    <div className="p-2 row">
                                        <div className="col-6">
                                            <p className="text-muted mb-0 mt-0">Price</p>
                                        
                                                <h3 className="card-title mb-0 fs-17">${calculatePrice(item)}</h3>
                                            
                                            
                                            
                                        </div>
                                        <div className="col-6 text-end">
                                            <p className="text-muted mb-0 mt-0">Seat Capacity</p>
                                            <h3 className="card-title mb-0 fs-17">{item.seat_capacity}</h3>
                                        </div>
                                    </div>
                                </CardBody>
                                <CardFooter className='bg-light border-top-dashed py-2'>
                                    <div className="flex-grow-1">
                                        <h5 className="mb-1 fs-14">Car Details</h5>
                                        { item.car_details
                                           /* item.carDetail.map((cardItems, i) => (
                                                <p key={i} className="text-muted text-truncate-two-lines mb-1 fs-12 fw-normal ">{cardItems.id}. {cardItems.label}</p>
                                            ))*/
                                        }


                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>
                    ))
                }


            </Row>):( <Row>
                
                {
                    carsNotavailableForArrival.map((item, i) => (
                        <Col md={6} lg={4} xl={3} key={i}>
                            <Card className={`card-car-info ${isAcitve === item._id ? 'active-card' : ''}`}>
                                <CardHeader className='bg-light border-1 align-items-center d-flex justify-content-between py-2'>
                                    <h4 className="card-title mb-0 fs-13">{item.car_brand} - {item.car_model}</h4>
                                    <div className="flex-shrink-0 align-items-center">
                                        <div className="form-check form-switch form-switch-right mt-2 me-2">
                                            <Input className="form-check-input code-switcher" type="checkbox" role="switch" id={item._id} name='carInfo'  checked={isAcitve === item._id} defaultChecked={carId ? carId === item._id : false} onChange={() => handleCarSelection(item._id)} />
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody className='p-0'>
                                    <Swiper pagination={{ clickable: true, dynamicBullets: true, }} modules={[Pagination, Autoplay]} loop={true} autoplay={{ delay: 2500, disableOnInteraction: false }} className="swiper pagination-dynamic-swiper dynamic-pagination rounded" >
                                        <div className="swiper-wrapper">
                                            {
                                                item.car_photos.map((sliderItem, i) => (
                                                    <SwiperSlide key={i}><img src={config.baseUrl + sliderItem} alt="" className="img-fluid" /></SwiperSlide>


                                                ))
                                            }

                                        </div>
                                    </Swiper>
                                    <div className="p-2 row">
                                        <div className="col-6">
                                            <p className="text-muted mb-0 mt-0">Price</p>
                                            <h3 className="card-title mb-0 fs-17">${calculatePrice(item)}</h3>
                                        </div>
                                        <div className="col-6 text-end">
                                            <p className="text-muted mb-0 mt-0">Seat Capacity</p>
                                            <h3 className="card-title mb-0 fs-17">{item.seat_capacity}</h3>
                                        </div>
                                    </div>
                                </CardBody>
                                <CardFooter className='bg-light border-top-dashed py-2'>
                                    <div className="flex-grow-1">
                                        <h5 className="mb-1 fs-14">Car Details</h5>
                                        { item.car_details
                                           /* item.carDetail.map((cardItems, i) => (
                                                <p key={i} className="text-muted text-truncate-two-lines mb-1 fs-12 fw-normal ">{cardItems.id}. {cardItems.label}</p>
                                            ))*/
                                        }


                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>
                    ))
                }


            </Row>))}

           


        </React.Fragment>
    )
}

export default CarsInfoList;