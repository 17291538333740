import { Button, Card, CardBody, Col, Container, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, UncontrolledAlert } from "reactstrap"
import BreadCrumb from "../../Components/Common/BreadCrumb"

import DataTable from "react-data-table-component"
import React from "react"
import { cityList, countryList, approledata } from "./data"
import { useMemo, useEffect } from "react"
import FilterComponent from '../../Components/datatable/filter'
import { Link } from "react-router-dom"
import DeleteModal from "../../Components/Common/DeleteModal"
import { useState } from "react"
import Select from "react-select";
import LoadingSpinner from "./loader"
import NoResultData from "../../Components/datatable/no-result"
import CustomPagination from "../../Components/custom-pagination/pagination"
import config from "../../common/config"
// eslint-disable-next-line react/display-name
const TableCheckBox = React.forwardRef(({ onClick, ...rest }, ref) => (
  <div className="form-check form-check-size-16" >
    <input
      htmlFor="booty-check"
      type="checkbox"
      className="form-check-input"
      ref={ref}
      onClick={onClick}
      {...rest}
    />
    <label className="form-check-label" id="booty-check" />
  </div>
))
const PrimaryUserOnBoarding = () => {
  const [isAdd, setIsAdd] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false);
  const [isActiveChecked, setIsActiveChecked] = useState(true);
  const [firstname, setFirstname] = useState("")
  const [lastname, setLastname] = useState("")
  const [contactNumber, setContactnumber] = useState("")
  const [jobrole, setJobrole] = useState("")
  const [username, setUsername] = useState("")
  const [approle, setAppRole] = useState(null);
  const [email, setEmail] = useState("")
  const [selectedId, setselectedId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [uid, setuid] = useState("")
  const [validMail,setValidMail]=useState("")
  const handleDeleteData = (user_id,_id) => {
   // console.log("inside delete, value of id:", user_id)
    setselectedId(user_id);
    setuid(_id)
    setDeleteModal(!deleteModal);
    console.log();
  }
  const handleJobRoleChange = (e) => {

    const inputJob = e.target.value;
    const newJob = inputJob.replace(/[^a-zA-Z\s]/g, '');
    setJobrole(newJob);
      }
  const handleDeleteData1 = () => {
    if (selectedId) {
      console.log(config.baseUrl + 'api/admin/deleteuser/' + selectedId);
      fetch(config.baseUrl + 'api/admin/deleteuser/' + selectedId, {
        method: 'DELETE'
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          fetchUserData();
        });
    }
    setDeleteModal(!deleteModal);
  }
  const handleActiveStatusChange = () => {
    setIsActiveChecked((state) => !state);
  };
  const getStatusFlag = () => {
    return isActiveChecked ? 1 : 0;
  };
  const [error, setError] = useState({
    status: false,

    msg: "",
    type: ""
  })
  const [message, setMessage] = useState("");
  const handleNumberChange = (e) => {
    const inputNumber = e.target.value;
    const regex = /^[0-9]{0,8}$/;
    if (regex.test(inputNumber)) {
      setContactnumber(inputNumber);
      //setNumberError("");
    } else {
      //  setNumberError("Please enter a valid 8-digit number.");
      //  setTimeout(() => {
      //     setNumberError("");
      //   }, 3000);
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === '+') {
      e.preventDefault();
    }
  };
  const [userId, setUserId] = useState("")
  const [nApprole, setNapprole] = useState()
  let handleCreateuser = async (e) => {
    e.preventDefault();
    let formIsValid = true;

    if (!firstname && !jobrole && !email && !selectedHotel) {
      setError({ status: true, message: "Please Enter Required Fields", type: 'error' });
      setTimeout(() => {
        setError({ status: false, message: '', type: '' });
      }, 3000);
      return;
    }
    if (!firstname) {
      setError({ status: true, message: "Please Enter Your First Name", type: 'error' });
      setTimeout(() => {
        setError({ status: false, message: '', type: '' });
      }, 3000);
      return;
    }
    if (!jobrole) {
      setError({ status: true, message: "Please Enter Your Job Role", type: 'error' });
      setTimeout(() => {
        setError({ status: false, message: '', type: '' });
      }, 3000);
      return;
    }
    if (!selectedHotel) {
      setError({ status: true, message: "Please Select Hotel", type: 'error' });
      setTimeout(() => {
        setError({ status: false, message: '', type: '' });
      }, 3000);
      return;
    }
    if (!email) {
      setError({ status: true, message: "Please Enter Your Email", type: 'error' });
      setTimeout(() => {
        setError({ status: false, message: '', type: '' });
      }, 3000);
      return;
    }
    // const isValid = email.includes("@")&& email.includes(".com");
    const isValid = /\S+@\S+\.\S+/.test(email);
    if (!isValid) {
      setError({
        status: true,
        message: 'Please Enter a valid Email',
        type: 'error'
      });
      setAddAlert(true)
      setAddStatus(false)
      setTimeout(() => {
        setError({ status: false, message: '', type: '' });
      }, 3000);
      return;
    }
    if (contactNumber.length < 8) {
      setError({
        status: true,
        message: 'Contact Number Should be 8-digits.',
        type: 'error'
      });
      setTimeout(() => {
        setError({ status: false, message: '', type: '' });
      }, 3000);
      return;
    }
    let newErrors = {};
    if (!selectedHotel) {
      newErrors.selectedHotel = "*Please enter Hotel Name";
      formIsValid = false;
    }
    if (!firstname) {
      newErrors.firstname = "*Please enter First Name";
      formIsValid = false;
    }
    if (!lastname) {
      newErrors.lastname = "*Please enter Last Name";
      formIsValid = false;
    }
    if (!email) {
      newErrors.email = "*Please enter Email";
      formIsValid = false;
    }
    if (!jobrole) {
      newErrors.jobrole = "*Please enter Job Role";
      formIsValid = false;
    }
    if (!approle) {
      newErrors.approle = "*Please Select App role";
      formIsValid = false;
    }
    if (!username) {
      newErrors.username = "*Please enter Username";
      formIsValid = false;
    }
    //const nApprole= 0;

    const userType1 = localStorage.getItem("userType");
    const loginhotelname=localStorage.getItem("hotelname");
    const loginhotelid=localStorage.getItem("hotelid")
let inputapprole = 0;
 if (userType1 === "1") {
      inputapprole = "1"
    } else if (userType1 === "2") {
      inputapprole = "2"
 }

    try {

      setIsLoading(true)
      let res = await fetch(config.baseUrl + "api/admin/createuser", {
        method: "POST",

        headers: {
          "Content-Type": "application/json",


        },

        body: JSON.stringify({
          ...(
            userType2 === "2"
              ? {
                  hotel_id: loginhotelid,
                  hotel_name: loginhotelname,
                }
              : {
                  hotel_id: selectedHotel ? selectedHotel.value : null,
                  hotel_name: selectedHotel ? selectedHotel.label : null,
                }
          ),
          first_name: firstname,
          last_name: lastname,
          job_role: jobrole,
          email_id: validMail,
          country_code: "+65",
          mobile_number: contactNumber,
          app_role: inputapprole,
          status: getStatusFlag(),
        }),
      });
      let resJson = await res.json();
      if (res.status === 200) {
        setUserId(resJson.data.userId);
        console.log(resJson.data);
        console.log("Success");
        setAddStatus(true)
        setAddAlert(true)
        // setFirstname("")
        // setLastname("")
        // setEmail("")
        // setJobrole("")
        // setContactnumber("")
        // setUsername("")
        // setAppRole("")
        // setSelectedHotel("")
        setIsLoading(false)
        //setIsAdd(false)
        //setMessage("User created successfully");
        fetchUserData()
        setTimeout(() => {
          //setIsAdd(false)
          setAddAlert(false)
          handleCloseDialog();
          //setError({ status: false, message: '', type: '' });
        }, 2000);

      } else {
        //setMessage("Some error occured");
        setError({ status: true, message: resJson.message, type: 'error' });
        setTimeout(() => {
          setError(prevError => ({ ...prevError, status: false }));
          setAddAlert(false)
        }, 3000);
        setAddStatus(false)
        setAddAlert(true)
        setIsLoading(false)
      }
    } catch (err) {
      console.log(err);
      setAddStatus(false)
      setIsLoading(false)
    }
    setIsLoading(false)
  };
  const [selecteduser, setSelecteduser] = useState("")
  const [userStatus, setUserStatus] = useState();
  const handleEditDialog = (rowId) => {
    setIsLoading(true)
    console.log(rowId);
    setEditId(rowId);
    const newStatus = isActiveChecked ? 1 : 0;
    setUserStatus(newStatus);
    fetch(config.baseUrl + 'api/admin/getuser/' + rowId).then((response) => response.json())
      .then((data) => {
        setSelectedHotel({ value: data.data.hotel_id, label: data.data.hotel_name });
        //setHotelname(data.data.hotel_name);
        setuid(data.data.user_id)
        setFirstname(data.data.first_name)
        setLastname(data.data.last_name)
        setEmail(data.data.email_id)
        setValidMail(data.data.email_id)
        setJobrole(data.data.job_role)
        setContactnumber(data.data.mobile_number);
        setUsername(data.data.user_name)

        setIsActiveChecked(data.data.status)
        // if(data.data.status===1){
        //     setIsActiveChecked(true);
        // }else if(data.data.status===0){
        //     setIsActiveChecked(false);
        // }
        setIsLoading(false)
      });
    setIsAdd(true)
    setIsEdit(true)
    setIsLoading(false)
  }
  const handleUpdateUser = async (e) => {
    e.preventDefault();
    if (!firstname && !jobrole && !email && !selectedHotel) {
      setError({ status: true, message: "Please Enter Required Fields", type: 'error' });
      setTimeout(() => {
        setError({ status: false, message: '', type: '' });
      }, 3000);
      return;
    }
    if (!firstname) {
      setError({ status: true, message: "Please Enter Your First Name", type: 'error' });
      setTimeout(() => {
        setError({ status: false, message: '', type: '' });
      }, 3000);
      return;
    }
    if (!jobrole) {
      setError({ status: true, message: "Please Enter Your Job Role", type: 'error' });
      setTimeout(() => {
        setError({ status: false, message: '', type: '' });
      }, 3000);
      return;
    }
    if (!selectedHotel) {
      setError({ status: true, message: "Please Select Hotel", type: 'error' });
      setTimeout(() => {
        setError({ status: false, message: '', type: '' });
      }, 3000);
      return;
    }
    if (!email) {
      setError({ status: true, message: "Please Enter Your Email", type: 'error' });
      setTimeout(() => {
        setError({ status: false, message: '', type: '' });
      }, 3000);
      return;
    }
    // const isValid = email.includes("@")&& email.includes(".com");
    const isValid=/^[A-Za-z][A-Za-z0-9_.-]*@[A-Za-z][A-Za-z0-9_.-]*\.[A-Za-z]{2,}$/.test(email);
    if (!isValid) {
      setError({
        status: true,
        message: 'Please Enter a valid Email',
        type: 'error'
      });
      setTimeout(() => {
        setError({ status: false, message: '', type: '' });
      }, 3000);
      return;
    }
    if (contactNumber.length < 8) {
      setError({
        status: true,
        message: 'Contact Number Should be 8-digits.',
        type: 'error'
      });
      setTimeout(() => {
        setError({ status: false, message: '', type: '' });
      }, 3000);
      return;
    }
    //const formattedNumber = String(number).padStart(10, "0");
    const userType1 = localStorage.getItem("userType");
    const loginhotelname=localStorage.getItem("hotelname");
    const loginhotelid=localStorage.getItem("hotelid")
    let inputapprole = 0;
     if (userType1 === "1") {
          inputapprole = "1"
        } else if (userType1 === "2") {
          inputapprole = "2"
     }
    try {
      setIsLoading(true)
      let res = await fetch(config.baseUrl + "api/admin/updateuser/" + editId, {
        method: "PUT",

        headers: {
          "Content-Type": "application/json",

        },
        body: JSON.stringify({
          hotel_id: selectedHotel ? selectedHotel.value : null,
          hotel_name: selectedHotel ? selectedHotel.label : null,
          first_name: firstname,
          last_name: lastname,
          job_role: jobrole,
          email_id: validMail,
          country_code: "+65",
          mobile_number: contactNumber,
          //user_name: username,
          app_role: inputapprole,

          status: getStatusFlag()
        }),
      });
      let resJson = await res.json();
      if (res.status === 200) {
        console.log("SUCCESS");
        setUpdateAlert(true)
        setupdatestatus(true);
        fetchUserData();
        setIsLoading(false)
        setTimeout(() => {
          setUpdateAlert(false)
          handleCloseDialog();
          // setIsAdd(false)
        }, 2000);
        //setIsAdd(false)
      } else {
        //setMessage("Some error occured");
        // setError({ status: true, message: resJson.message, type: 'error' });
        console.log("Error", resJson.message);
        setUpdateAlert(true)
        setupdatestatus(false)
        setError(resJson.message);
        setIsLoading(false)
        setTimeout(() => {
          setError({ status: false, message: '', type: '' });
          setUpdateAlert(false)
        }, 3000);

      }
    } catch (err) {
      console.log(err);
      setIsLoading(false)
      setUpdateAlert(true)
      setupdatestatus("failed")

    }
    setError("")
    setIsLoading(false)
  }
  const [editId, setEditId] = useState("")

  const [selectCity, setSelectCity] = React.useState(null);
  const handleSelectCity = (selectCity) => {
    setSelectCity(selectCity)
  }
  const handleSelectrole = (approle) => {
    setAppRole(approle)
  }
  const [selectCountry, setSelectCountry] = React.useState(null)
  const handleSelectCountry = (selectCountry) => {
    setSelectCountry(selectCountry)
  }
  const handleCloseDialog = () => {
    setIsAdd(false)
    setIsEdit(false)
    setFirstname("")
    setLastname("")
    setEmail("")
    setValidMail("")
    setJobrole("")
    setUsername("")
    setContactnumber("")
    setSelectedHotel("")
    setShowError(false)
    setShowcontactError(false)
  }
  const handleAddDialog = () => {
    setIsAdd(true)
    setIsEdit(false)
  }

  const [UserList, setUserList] = useState([])
  const [hotelList, setHotelList] = useState([])
  const [selectedHotel, setSelectedHotel] = useState(null);
  const callAPI = async () => {
    console.log("API CALL");
    setIsLoading(true)
    await fetch(config.baseUrl + "api/admin/hotellist", {
      method: "POST",

      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        page: pageNumber,
        limit: "all",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data.data);
        setHotelList(data.data);
        setIsLoading(false)
      });
    setIsLoading(false)
  }


  const activeHotels = hotelList.filter((hotel) => hotel.status === 1);
  console.log("Active hotels", activeHotels)
  const hotelOptions = hotelList && activeHotels.map((hotel) => ({
    value: hotel._id,
    label: hotel.hotel_name,

  }));
  const hotelname = localStorage.getItem("hotelname");
  const userType1 = localStorage.getItem("userType");

  const handleHotelChange = (selectedOption) => {

    setSelectedHotel(selectedOption);

  };

  const handleAppRoleChange = (selectedOption) => {
    let flagValue;
    if (selectedOption.value === '1') {
      flagValue = "1";
    } else if (selectedOption.value === '2') {
      flagValue = '2';
    }

    setAppRole(flagValue);
  };


  const options = [
    { value: '1', label: 'Primary' },
    { value: '2', label: 'Secondary' },
  ];

  const [pageNumber, setPageNumber] = useState(1)
  const [totalpages, setTotalpages] = useState('')
  const [perpage, setPerPage] = useState(10)
  const [totalcount, setTotalcount] = useState()
  const fetchUserData = async () => {
    console.log("Fetch USER", JSON.stringify({
      page: pageNumber,
      limit: 10,
    }));
    /* const formData = new FormData();
     formData.append('user_type', '1');
     formData.append('hotel_id', '');

     fetch(`${config.baseUrl}api/admin/userlist/${pageNumber}`, {
       method: 'GET',
       headers: formData
     })
       .then((response) => response.json())
       .then((data) => {
         console.log(data.data);
         setUserList(data.data);
         setTotalpages(data.totalPages)
         setTotalcount(data.totalCount)
       });*/
    const hotelid = localStorage.getItem("hotelid");
    console.log("usertype&hotelid", userType, hotelid)
    await fetch(config.baseUrl + "api/admin/userlist", {
      method: "POST",

      headers: {
        "user_type": userType,
        "hotel_id": hotelid ? hotelid :'',
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        page: pageNumber,
        limit: 10,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("USER LIST", data.data);
        setUserList(data.data);
        setTotalpages(data.totalPages)
        setTotalcount(data.totalCount)
        setIsLoading(false)
      });
  }
  const userType2 = localStorage.getItem("userType");
  const loginhotelname=localStorage.getItem("hotelname");
  const loginhotelid=localStorage.getItem("hotelid")
  useEffect(() => {
    const loginhotelid = localStorage.getItem("hotelid")
 //  console.log(loginhotelid,"lo66666666666666666666")
    fetchUserData();
    callAPI()
    const userType2 = localStorage.getItem("userType");

     if (userType2 === "2") {
     setSelectedHotel( localStorage.getItem("hotelname"))
    }
  }, [pageNumber]);
  const handlePageChange = async (selectedPage) => {
    setPageNumber(selectedPage);
    console.log("pagenumber888", pageNumber)
    await fetch(config.baseUrl + "api/admin/userlist", {
      method: "POST",

      headers: {
        "user_type": userType2,
        "hotel_id": loginhotelid ? loginhotelid :"",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        page: pageNumber,
        limit: perpage,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("USER LIST", data.data);
        setUserList(data.data);
        setTotalpages(data.totalPages)
        setTotalcount(data.totalCount)
        setIsLoading(false)
      });

  };

  const handlePerRowsChange = async (newPerPage, page) => {
    if (newPerPage === totalcount) {
      console.log("Need to show all data in list");
      await fetch(config.baseUrl + "api/admin/userlist", {
        method: "POST",

        headers: {
          "user_type": userType2,
          "hotel_id": loginhotelid ? loginhotelid :"",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          page: page,
          limit: newPerPage,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data.data);
          setUserList(data.data);
          setTotalpages(data.totalPages)
          setTotalcount(data.totalCount)
          setIsLoading(false)
        });

      setPerPage(newPerPage);
      return
    } else {
      await fetch(config.baseUrl + "api/admin/userlist", {
        method: "POST",

        headers: {
          "user_type": userType2,
          "hotel_id": loginhotelid ? loginhotelid :"",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          page: page,
          limit: newPerPage,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          setHotelList(data.data);
          //  setTotalpages(data.totalPages)
          setTotalcount(data.totalCount)
          setIsLoading(false)
        });

      setPerPage(newPerPage);
      console.log("Selected data in list");
      return
    }
  };
  const [updatestatus, setupdatestatus] = useState(false);
  const [addStatus, setAddStatus] = useState(false);
  const [addAlert, setAddAlert] = useState(false)
  const [updateAlert, setUpdateAlert] = useState(false)
  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: 'ALL',
  };

  function statusIdentification(array) {
    console.log("inside status")
    for (var i = 0; i < array.length; i++) {
      if (array[i].status === 1) {
        array[i].status1 = 'Active';
        array[i].statusColor = 'success';

      } else if (array[i].status === 0) {
        array[i].status1 = 'In-Active';
        array[i].statusColor = 'danger';

      }
    }
    console.log(filteredItems);
  }
  function appRoleName(array) {
    console.log("inside app")
    for (var j = 0; j < array.length; j++) {
      console.log("inside for loop,value of app_role is ", array[j].app_role)
      if (array[j].app_role === '1') {
        array[j].app_role1 = 'primary';
        array[j].appRollNameColor = 'primary';
      }
      else {
        array[j].app_role1 = 'secondary';
        array[j].appRollNameColor = 'secondary';


      }
    }
    console.log(filteredItems);
  }
  const handleFirstNameChange = (e) => {
    const inputValue = e.target.value;
    const newFirstName = inputValue.replace(/[^a-zA-Z\s]/g, '');
       setFirstname(newFirstName);

if (lastname) {
  setUsername(newFirstName + '@' + lastname);
} else {
  const emailUsername = getEmailUsername(email);
  setUsername(newFirstName + emailUsername);
}
  };
 const [showcontacterror, setShowcontactError] = useState(false)
  const [isBlurred, setIsBlurred] = useState(false);

  const validatePhoneNumber = (input) => {
    return /^\d{8}$/.test(input);
  };

  const handlenumberBlur = () => {
    setIsBlurred(true);
    setShowcontactError(!validatePhoneNumber(contactNumber));
  };

  const handleLastNameChange = (e) => {

    const inputValue = e.target.value;
    const newLastName = inputValue.replace(/[^a-zA-Z\s]/g, '');
    setLastname(newLastName);

if (firstname) {
  setUsername(firstname + ' @' + newLastName);
} else {
  const emailUsername = getEmailUsername(email);
  setUsername(emailUsername + '@' + newLastName);
}
  };
  const [showError, setShowError] = useState(false);
  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setShowError(false);
    validateEmail(newEmail);
    if(validateEmail(newEmail)){
      setValidMail(newEmail);
    }else{
      setAddStatus(false)
      setAddAlert(true)
      setError({
          status: true,
          message: 'Please Enter a valid Email',
          type: 'error'
      });
      setTimeout(() => {
          setError({ status: false, message: '', type: '' });
          setAddAlert(true)
      }, 3000);
    }

    if (!lastname) {
      const emailUsername = getEmailUsername(newEmail);
      setUsername(firstname + '@' + emailUsername);
    }
  };
  const handlenumberfocus=()=>{
    setShowcontactError(false)
  }
  const handleFocus = () => {
    setShowError(false); // Hide the error when input is focused again
  };
  const validateEmail = (input) => {
    const regex = /^[A-Za-z][A-Za-z0-9_.-]*@[A-Za-z][A-Za-z0-9_.-]*\.[A-Za-z]{2,}$/;
   // const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return regex.test(input);
  };
  const handleBlur = () => {
    setShowError(!validateEmail(email));
  };
  const getEmailUsername = (email) => {
    const atIndex = email.indexOf('@');
    if (atIndex !== -1) {
      return email.substring(0, atIndex);
    }
    return '';
  };


  const columnsProducts = [
    {
      name: <span className='font-weight-bold fs-13 text-nowrap'>User ID</span>,
      selector: row => row.user_id,
      cell: (row => <p className="fw-medium link-primary">{row.user_id}</p>),
      sortable: true,
    },
    {
      name: <span className='font-weight-bold fs-13 text-nowrap'>Employee Name</span>,
      selector: row => row.first_name + row.last_name,
      cell: (row => row.first_name + " " + row.last_name),
      sortable: true,
    },
    {
      name: <span className='font-weight-bold fs-13 text-nowrap'>Job Role Name</span>,
      selector: row => row.job_role,
      cell: (row => row.job_role),
      sortable: true,
    },
    {
      name: <span className='font-weight-bold fs-13 text-nowrap'>Hotel Name</span>,
      selector: row => row.hotel_name,
      cell: (row => <div className="fw-medium">{row.hotel_name}</div>),
      sortable: true,
    },
    {
      name: <span className='font-weight-bold fs-13 text-nowrap'>Contact Number</span>,
      selector: row => row.mobile_number,

      cell: (row => <div >{row.country_code} {row.mobile_number}</div>),
      sortable: true,
    },
    {
      name: <span className='font-weight-bold fs-13 text-nowrap'>Email Address</span>,
      selector: row => row.email_id,
      cell: (row => <div className="text-secondary">{row.email_id}</div>),
      sortable: true,
    },
    /*  {
          name: <span className='font-weight-bold fs-13 text-nowrap'>User Name</span>,
          selector: row => row.user_name,
          cell: (row => <div className="text-uppercase">{row.user_name}</div>),
          sortable: true,
      },*/
    {
      name: <span className='font-weight-bold fs-13 text-nowrap'>App Role Name</span>,
      selector: row => row.app_role,
      cell: (row => <div className={`badge badge-outline-${row.appRollNameColor} text-uppercase pt-1 bg`}>{row.app_role1}</div>),
      sortable: true,
    },

    {
      name: <span className='font-weight-bold fs-13 text-nowrap'>Status</span>,
      selector: row => row.status,
      cell: (row => <div className={`badge text-uppercase pt-1 bg-${row.statusColor}`}>{row.status1}</div>),
      sortable: true,
    },
    {
      name: <span className='font-weight-bold fs-13 text-nowrap'>Action</span>,
      id: "action",
      style: { position: 'sticky', right: 0, backgroundColor: 'white', zIndex: 9 },
      selector: row => row.userAccount,
      cell: (row =>
        <div>
          <Button className="btn btn-soft-info btn-icon fs-14 me-1" onClick={setIsEdit ? () => handleEditDialog(row._id) : null}>
            <i className="ri-edit-box-line"></i>
          </Button>
          <Button className="btn btn-soft-info btn-icon fs-14" onClick={() => handleDeleteData(row._id,row.user_id)}>
            <i className="ri-delete-bin-line"></i>
          </Button>
        </div>
      ),
      sortable: true,
    },

  ];

  /********Data table Search****** */
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
    false
  );
  const UserListArray = Object.values(UserList);
  const filteredItems = UserListArray.filter(
    item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
  );
  console.log("FILTERED ITEMS", filteredItems)
  statusIdentification(filteredItems);
  appRoleName(filteredItems);
  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  document.title = "DB LIMO - Primary User Onboarding";
  const userType = localStorage.getItem("userType");

  let inputValue = "";

  if (userType === "1") {
    inputValue = "Primary";
  } else if (userType === "2") {
    inputValue = "Secondary";
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="User Onboard" pageTitle="Home"></BreadCrumb>
          {//isLoading ? <LoadingSpinner /> :
            <Card>
              <CardBody className="p-0">
                <Row>
                  <Col>
                    <DataTable
                      title={<h5 className="mb-0">User Details</h5>}
                      className="custom-datatable"
                      columns={columnsProducts}
                      data={filteredItems}
                      sortIcon={<span className="custom-sort-img"><i className='bx bxs-sort-alt custom-sort-icon'></i></span>}
                      pagination
                      paginationServer={true}
                      paginationTotalRows={totalcount}
                      onChangeRowsPerPage={handlePerRowsChange}
                      onChangePage={handlePageChange}
                      paginationComponentOptions={paginationComponentOptions}
                      paginationRowsPerPageOptions={["10", "20", "40"]}
                      // pagination
                      // paginationComponent={CustomPagination}
                      // paginationComponentOptions={0}
                      // paginationRowsPerPageOptions={[2, 4, 8, 12, 15]}
                      // selectableRows={false}
                      // selectableRowsComponent={TableCheckBox}
                       noDataComponent={<NoResultData />}
                      // selectableRowsHighlight={true}
                      // selectableRowsVisibleOnly={false}
                       subHeaderAlign="left"
                       contextMenu={true}
                       persistTableHead
                      actions={
                        <Row className="g-4 align-items-start align-items-lg-center flex-column flex-row-lg row">

                          <Col md={12} lg="auto" className="ms-lg-auto no-padding-mobile">
                            <div className="hstack gap-2 flex-lg-row flex-wrap">
                              {subHeaderComponent}
                              <button type="button" className="btn btn-success w-100-mobile" onClick={handleAddDialog} >
                                <i className="ri-add-line align-bottom me-1"></i>{" "}
                                Add New User
                              </button>


                            </div>
                          </Col>
                        </Row>

                      }
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>}
        </Container>
      </div>
      { //isLoading ? <LoadingSpinner /> :
        <Modal
          isOpen={isAdd}
          toggle={handleCloseDialog}
          centered
          size="lg"
          className="border-0"
          modalClassName='modal fade zoomIn'
        >
          <ModalHeader className="p-3 bg-light" toggle={handleCloseDialog}>
            {!!isEdit ? 'User Onboarding' : 'User Onboarding'}
          </ModalHeader>
          <Form onSubmit={isEdit ? handleUpdateUser : handleCreateuser}>
            <ModalBody>
              {isEdit && updateAlert && updatestatus && (
                <UncontrolledAlert color="success" onClose={() => setupdatestatus(false)}>User has been updated successfully - {uid}</UncontrolledAlert>)}
              {isEdit && updateAlert && !updatestatus && error.status && (
                <UncontrolledAlert color="danger" onClose={() => setupdatestatus(false)}><div >  <div id='error'
                  style={{ textAlign: "start" }} ><i className="bi bi-exclamation-circle"
                  ></i>&nbsp;&nbsp;&nbsp;&nbsp;{error.message}</div>
                </div></UncontrolledAlert>)}

              {isAdd && addAlert && addStatus && (
                <UncontrolledAlert color="success" onClose={() => setAddStatus(false)}>User has been added successfully - {userId}</UncontrolledAlert>)}
              {isAdd && addAlert && !addStatus && error.status && (
                <UncontrolledAlert color="danger" onClose={() => setAddStatus(false)}><div >  <div id='error'
                  style={{ textAlign: "start" }} ><i className="bi bi-exclamation-circle"></i>&nbsp;&nbsp;&nbsp;&nbsp;{error.message}</div>
                </div></UncontrolledAlert>)}

              <Row className="g-3">
                <Col sm={12} xl={6} xxl={6} md={12}>
                  <div className="mb-0">
                    <Label
                      htmlFor="firstName"
                      className="form-label"
                    >
                      First Name (<span className="text-danger">*</span>)
                    </Label>
                    <Input
                      id="firstName"
                      name="firstName"
                      type="text"
                      placeholder="Enter Your First Name"
                      value={firstname} onChange={handleFirstNameChange} required

                    />

                  </div>

                </Col>
                <Col xxl={6} sm={12} lg={6} md={12}>
                  <div className="mb-0">
                    <Label
                      htmlFor="lastName"
                      className="form-label"
                    >
                      Last Name
                    </Label>
                    <Input
                      id="lastName"
                      name="lastName"
                      type="text"
                      placeholder="Enter Your Last Name"
                      value={lastname} onChange={handleLastNameChange}
                    />


                  </div>
                </Col>
                <Col xxl={6} sm={12} lg={6} md={12}>
                  <div className="mb-0">
                    <Label
                      htmlFor="jobRoleName"
                      className="form-label"
                    >
                      Job Role Name (<span className="text-danger">*</span>)
                    </Label>
                    <Input
                      id="jobRoleName"
                      name="jobRoleName"
                      type="text"
                      placeholder="Enter Your Job Role Name"
                      value={jobrole} onChange={handleJobRoleChange} required
                    />
                  </div>
                </Col>
                <Col xxl={6} sm={12} lg={6} md={12}>
                  <div className="mb-0">
                    <Label
                      htmlFor="hotelName"
                      className="form-label"
                    >
                      Hotel Name (<span className="text-danger">*</span>)
                    </Label>

                    {userType2 === "2" ? (
  <Input
    className="mb-0"
    classNamePrefix="form-control-select"
    value={loginhotelname}
    inputId="hotelName"
    readOnly
    required
    style={{ userSelect: "none", cursor: "not-allowed", background: " rgba(var(--vz-light-rgb), .75)" }}
  />
) : (
    <Select
                      className="mb-0"
                      classNamePrefix="form-control-select"
                      value={selectedHotel}
                      onChange={handleHotelChange}
                      options={hotelOptions}
                      placeholder="Select Hotel Name"
                      inputId="hotelName"
                      required

                    />
)}

                  </div>
                </Col>
                <Col xxl={6} sm={12} lg={6} md={12}>
                  <div className="mb-0">
                    <Label
                      htmlFor="contactNumber"
                      className="form-label"
                    >
                      Contact Number (<span className="text-danger">*</span>)
                    </Label>
                    <div className="input-group">
                      <div className="input-group-text">
                        +65
                      </div>
                      <Input
                        id="contactNumber"
                        name="contactNumber"
                        type="number"
                        placeholder="Enter Contact Number"
                        //onKeyPress={handleKeyPress}
                        value={contactNumber} //onChange={handleNumberChange}
                        onChange={(e) => setContactnumber(e.target.value)}
                        onBlur={handlenumberBlur}
                        onFocus={handlenumberfocus}
                        className={showcontacterror && isBlurred ? 'invalid' : ''}
                        onKeyPress={(e) => {
                          const allowedKeys = /[0-9]/;
                          const key = e.key;
                          if (!allowedKeys.test(key)) {
                            e.preventDefault();
                          } else if (contactNumber.length >= 8) {
                            e.preventDefault();
                          }
                        }}
                        required
                      />
                      {showcontacterror && contactNumber && <span style={{ color: 'red' }}>Please enter a valid 8-digit phone number</span>}
                    </div>
                  </div>
                </Col>
                <Col xxl={6} sm={12} lg={6} md={12}>
                  <div className="mb-0">
                    <Label
                      htmlFor="emailAddress"
                      className="form-label"
                    >
                      Email Address (<span className="text-danger">*</span>)
                    </Label>
                    <Input
                      id="emailAddress"
                      name="emailAddress"
                      type="email"
                      placeholder="Enter Email Address"
                      value={email} onChange={handleEmailChange} required
                      onBlur={handleBlur}
                      onFocus={handleFocus}
                      className={showError ? 'invalid' : ''}
      />
      {showError && email && <span style={{ color: 'red' }}>Please enter a valid email address</span>}


                  </div>
                </Col>
                <Col xxl={6} sm={12} lg={6} md={12}>
                  <div className="mb-0">
                    <Label
                      htmlFor="appRole"
                      className="form-label"
                    >
                      App Role
                    </Label>
                    <Input
                      className="mb-0"
                      classNamePrefix="form-control-select"
                      placeholder="Select App Role"
                      value={inputValue}
                      style={{ userSelect: "none", cursor: "not-allowed", background: " rgba(var(--vz-light-rgb), .75)" }}
                      //value={approle ? options.find((option) => option.value === approle.toString()) : ""}
                      readOnly
                      //onChange={handleAppRoleChange}
                      //options={options}
                      inputId="appRole"
                      required
                    />


                  </div>
                </Col>
                <Col xxl={6} sm={12} lg={6} md={12}>
                  {/*   <div className="mb-0">
                                    <Label
                                        htmlFor="username"
                                        className="form-label"
                                    >
                                        User Name
                                    </Label>
                                    <Input
                                            id="username"
                                            name="username"
                                            type="text"
                                            //placeholder="Enter Your User Name"
                                            placeholder="JOHNMAY@MAY"
                                            value={username} //onChange={(e)=>setUsername(e.target.value)}
                                            disabled
                                            style={{userSelect:"none",cursor:"not-allowed",background:" rgba(var(--vz-light-rgb), .75)"}}

                                        />
                                </div>*/}
                </Col>

                <Col xxl={12} sm={12} lg={6} md={12}>
                  <div className="form-check form-switch form-check-primary mt-0">
                    <Input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="ActiveStatus"
                      checked={isActiveChecked}
                      onChange={handleActiveStatusChange}
                    />
                    <Label className="form-check-label" for="ActiveStatus">
                      User Status Active?
                    </Label>
                  </div>
                </Col>

              </Row>
            </ModalBody>
            <ModalFooter className="justify-content-start">
              <div className="hstack gap-2">

                {isEdit ? (<button type="submit" className="btn btn-success" id="add-btn1" >Update User</button>) :
                  (<button type="submit" className="btn btn-success" id="add-btn" >Add User</button>)}
                <button type="button" className="btn btn-light border_gry" data-bs-dismiss="modal" onClick={handleCloseDialog}>Cancel</button>
              </div>
            </ModalFooter>
          </Form>
        </Modal>}
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteData1}
        onCloseClick={() =>

          setDeleteModal(false)}
        entityName="User"
        //entityId={selectedId}
        entityId={uid}
      />
    </React.Fragment>
  )
}

export default PrimaryUserOnBoarding