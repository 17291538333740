import React from "react";
import { Input } from "reactstrap";

const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <React.Fragment>
        <div className="search-box w-100-mobile searchbox-mobile">
            <Input
                type="text"
                className="form-control search"
                placeholder="Search..."
                value={filterText}
                onChange={onFilter}
            />
            <i className="ri-search-line search-icon"></i>
        </div>
       

    </React.Fragment>
);

export default FilterComponent;
