import config from "../../common/config";

export const loginAPI = ({ email, password }) => {
  return new Promise((resolve, reject) => {
    fetch(config.baseUrl + "api/login", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    })
  
  .then((response) => {
    if (response.ok) {
      resolve(response.json());
    } else {
      response.json().then((data) => {
        reject(data.message);
      
      }).catch((error) => {
        reject('An error occurred.');
      });
    }
  })
      .catch((error) => {
        reject(error);
      });
  });
};
