export const AllBookingWidget = [
    {
        id: 1,
        icon: "ri-police-car-fill",
        feaIconClass: "primary",
        cardBg: 'warning',
        label: "Total Booking",
        badgeClass: "danger",       
        subCounter: [{ id: 1, counter: "547", suffix: " K" }]
    },
    {
        id: 2,
        icon: "ri-bus-wifi-fill",
        feaIconClass: "primary",
        cardBg: 'success',
        label: "Requested Booking",
        badgeClass: "danger",       
        subCounter: [{ id: 1, counter: "124", suffix: " K" }]
    },
    {
        id: 3,
        icon: "ri-taxi-wifi-fill",
        feaIconClass: "primary",
        cardBg: 'danger',
        label: "Accepted Booking",
        badgeClass: "danger",       
        subCounter: [{ id: 1, counter: "107", suffix: " K" }]
    },
    {
        id: 4,
        icon: "ri-taxi-wifi-fill",
        feaIconClass: "primary",
        cardBg: 'primary',
        label: "Cancelled Booking",
        badgeClass: "danger",       
        subCounter: [{ id: 1, counter: "74", suffix: " K" }]
    },
]

export const data = [
{
    id: '1',
    bookingID: '#LG0001',
    bookingInfo: {
        details: [
        {
            name: 'John Selvaraj',
            mobileNumber: '+65 987 654 321',
            passengerSeat: 4,
            babySeat: 1,
            luggage : 4,
            hotelAddress: 'Radisson Blu, West Singapore',
            bookingPerson: 'John David'
        }
       ]
    },
    travelInfo: {
        details: [
            {
                airport: 'Airport Departure',
                destination: 'Singapore Museum',
                flightNumber : 'JADR024GH78',
                travelType: 'One Way Trip',
                oneStop: 'Yes',
                pickupLocation: 'Singapore Museum',
                dropLocation: 'Singapore Museum'
 
            }
        ]
    },
    travelDateAndTime: '25 Jan, 2022',
    time: '10.30 AM',
    carInfo: 'Mercedes Benz S400',
    bookingStatus: 'Requested',
    bookingStatusColor: 'warning',    
    rideStatus: 'Not Yet',
    rideStatusColor: 'danger',
    paymentInfo: {
        details: [
            {
                total: '$578',
                airportArrival: '$180',
                airportDepature: '$180',
                pointtopoint: '$180',
                fromDisposal: '$180',
                fromDisposalLocation: 'Singapore Disposal',
                toDisposalLocation: 'Malaysia Disposal',
                toDisposal: '$180',
                extraHoursDisposal: '$60',
                extraStopSurCharge: '$15',
                minNightSurcharge: '$30',
                waintingTimePrice: '$30',
                hotelPaymentStatus: 'Unpaid',
                hotelPaymentStatusColor: 'danger',
                driverPayment: 'UnPaid',
                driverPaymentStatusColor: 'danger',

            }
        ]
    }
},
{
    id: '2',
    bookingID: '#LG0002',
    bookingInfo: {
        details: [
        {
            name: 'John',
            mobileNumber: '+65 987 654 321',
            passengerSeat: 4,
            babySeat: 1,
            luggage : 4,
            hotelAddress: 'Radisson Blu, West Singapore',
            bookingPerson: 'John David'
        }
       ]
    },
    travelInfo: {
        details: [
            {
                airport: 'Airport Departure',
                destination: 'Singapore Museum',
                flightNumber : 'JADR024GH78',
                travelType: 'One Way Trip',
                oneStop: 'Yes',
                pickupLocation: 'Singapore Museum',
                dropLocation: 'Singapore Museum'
 
            }
        ]
    },
    travelDateAndTime: '25 Jan, 2022',
    time: '10.30 AM',
    carInfo: 'Mercedes Benz S400',
    bookingStatus: 'Requested',
    bookingStatusColor: 'warning',    
    rideStatus: 'Not Yet',
    rideStatusColor: 'danger',
    paymentInfo: {
        details: [
            {
                total: '$578',
                airportArrival: '$180',
                airportDepature: '$180',
                pointtopoint: '$180',
                fromDisposal: '$180',
                fromDisposalLocation: 'Singapore Disposal',
                toDisposalLocation: 'Malaysia Disposal',
                toDisposal: '$180',
                extraHoursDisposal: '$60',
                extraStopSurCharge: '$15',
                minNightSurcharge: '$30',
                waintingTimePrice: '$30',
                hotelPaymentStatus: 'Unpaid',
                hotelPaymentStatusColor: 'danger',
                driverPayment: 'UnPaid',
                driverPaymentStatusColor: 'danger',

            }
        ]
    }
},
{
    id: '3',
    bookingID: '#LG0003',
    bookingInfo: {
        details: [
        {
            name: 'John Selvaraj',
            mobileNumber: '+65 987 654 321',
            passengerSeat: 4,
            babySeat: 1,
            luggage : 4,
            hotelAddress: 'Radisson Blu, West Singapore',
            bookingPerson: 'John David'
        }
       ]
    },
    travelInfo: {
        details: [
            {
                airport: 'Airport Departure',
                destination: 'Singapore Museum',
                flightNumber : 'JADR024GH78',
                travelType: 'One Way Trip',
                oneStop: 'Yes',
                pickupLocation: 'Singapore Museum',
                dropLocation: 'Singapore Museum'
 
            }
        ]
    },
    travelDateAndTime: '25 Jan, 2022',
    time: '10.30 AM',
    carInfo: 'Mercedes Benz S400',
    bookingStatus: 'Requested',
    bookingStatusColor: 'warning',    
    rideStatus: 'Not Yet',
    rideStatusColor: 'danger',
    paymentInfo: {
        details: [
            {
                total: '$578',
                airportArrival: '$180',
                airportDepature: '$180',
                pointtopoint: '$180',
                fromDisposal: '$180',
                fromDisposalLocation: 'Singapore Disposal',
                toDisposalLocation: 'Malaysia Disposal',
                toDisposal: '$180',
                extraHoursDisposal: '$60',
                extraStopSurCharge: '$15',
                minNightSurcharge: '$30',
                waintingTimePrice: '$30',
                hotelPaymentStatus: 'Unpaid',
                hotelPaymentStatusColor: 'danger',
                driverPayment: 'UnPaid',
                driverPaymentStatusColor: 'danger',

            }
        ]
    }
},
{
    id: '4',
    bookingID: '#LG0004',
    bookingInfo: {
        details: [
        {
            name: 'John Selvaraj',
            mobileNumber: '+65 987 654 321',
            passengerSeat: 4,
            babySeat: 1,
            luggage : 4,
            hotelAddress: 'Radisson Blu, West Singapore',
            bookingPerson: 'John David'
        }
       ]
    },
    travelInfo: {
        details: [
            {
                airport: 'Airport Departure',
                destination: 'Singapore Museum',
                flightNumber : 'JADR024GH78',
                travelType: 'One Way Trip',
                oneStop: 'Yes',
                pickupLocation: 'Singapore Museum',
                dropLocation: 'Singapore Museum'
 
            }
        ]
    },
    travelDateAndTime: '25 Jan, 2022',
    time: '10.30 AM',
    carInfo: 'Mercedes Benz S400',
    bookingStatus: 'Requested',
    bookingStatusColor: 'warning',    
    rideStatus: 'Not Yet',
    rideStatusColor: 'danger',
    paymentInfo: {
        details: [
            {
                total: '$578',
                airportArrival: '$180',
                airportDepature: '$180',
                pointtopoint: '$180',
                fromDisposal: '$180',
                fromDisposalLocation: 'Singapore Disposal',
                toDisposalLocation: 'Malaysia Disposal',
                toDisposal: '$180',
                extraHoursDisposal: '$60',
                extraStopSurCharge: '$15',
                minNightSurcharge: '$30',
                waintingTimePrice: '$30',
                hotelPaymentStatus: 'Unpaid',
                hotelPaymentStatusColor: 'danger',
                driverPayment: 'UnPaid',
                driverPaymentStatusColor: 'danger',

            }
        ]
    }
},
{
    id: '5',
    bookingID: '#LG0005',
    bookingInfo: {
        details: [
        {
            name: 'John Selvaraj',
            mobileNumber: '+65 987 654 321',
            passengerSeat: 4,
            babySeat: 1,
            luggage : 4,
            hotelAddress: 'Radisson Blu, West Singapore',
            bookingPerson: 'John David'
        }
       ]
    },
    travelInfo: {
        details: [
            {
                airport: 'Airport Departure',
                destination: 'Singapore Museum',
                flightNumber : 'JADR024GH78',
                travelType: 'One Way Trip',
                oneStop: 'Yes',
                pickupLocation: 'Singapore Museum',
                dropLocation: 'Singapore Museum'
 
            }
        ]
    },
    travelDateAndTime: '25 Jan, 2022',
    time: '10.30 AM',
    carInfo: 'Mercedes Benz S400',
    bookingStatus: 'Requested',
    bookingStatusColor: 'warning',    
    rideStatus: 'Not Yet',
    rideStatusColor: 'danger',
    paymentInfo: {
        details: [
            {
                total: '$578',
                airportArrival: '$180',
                airportDepature: '$180',
                pointtopoint: '$180',
                fromDisposal: '$180',
                fromDisposalLocation: 'Singapore Disposal',
                toDisposalLocation: 'Malaysia Disposal',
                toDisposal: '$180',
                extraHoursDisposal: '$60',
                extraStopSurCharge: '$15',
                minNightSurcharge: '$30',
                waintingTimePrice: '$30',
                hotelPaymentStatus: 'Unpaid',
                hotelPaymentStatusColor: 'danger',
                driverPayment: 'UnPaid',
                driverPaymentStatusColor: 'danger',

            }
        ]
    }
}
]
export const executiveNamesList = [
    {
        label: 'Ride Status',
        options: [
            
            { label: "All Status", value: "All Status" },
            { label: "Completed", value: "Completed" },
            { label: "Not Yet", value: "Not Yet" },
        ]
    },

]

export const executiveNamesList1 = [

    {
        label: 'Payment Status',
        options: [
            
            { label: "All Status", value: "All Status" },
            { label: "Paid", value: "Paid" },
            { label: "UnPaid", value: "UnPaid" },
           
        ]
    }
]

export const executiveNamesList2 = [

    {
        label: 'Payment Type',
        options: [
            
            { label: "All Status", value: "All Status" },
            { label: "Hotel", value: "Hotel" },
            { label: "Driver", value: "Driver" },
           
        ]
    }
]