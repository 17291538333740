import { Button, Card, CardBody, Col, Container, Offcanvas, OffcanvasBody, OffcanvasHeader, Row, UncontrolledAlert } from "reactstrap"
import BreadCrumb from "../../../Components/Common/BreadCrumb"
import BookingWidgets from "./widget"
import DataTable from "react-data-table-component"
import React from "react"
//import { data } from "./data"
import { useMemo, useEffect } from "react"
import FilterComponent from '../../../Components/datatable/filter'
import { Link, useNavigate } from "react-router-dom"
import { useState } from "react"
import BookingInfo from "./booking-info"
import ShareBooking from "./share-booking"
import PaymentConfirmation from "./payment-confirmation"
import ShareViewBooking from "./viewBooking"
import BookingConfirmation from "./booking-confirmation"
import NoResultData from "../../../Components/datatable/no-result"
import styled from "styled-components"
import CustomPagination from "../../../Components/custom-pagination/pagination"
import config from "../../../common/config"
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Section from '../../../Components/Common/Section'
import CancelModal from "../../../Components/Common/CancelModal"
import moment from "moment"
// eslint-disable-next-line react/display-name
const TableCheckBox = React.forwardRef(({ row, onClick, ...rest }, ref) => {


  return (
    <div className="form-check form-check-size-16">
      <input
        type="checkbox"
        className="form-check-input"
        ref={ref}
        onClick={onClick}
        {...rest}
      />
      <label className="form-check-label" htmlFor="booty-check" />
    </div>
  );
});




const AllBooking = () => {
  const [bookingdetails, setBookingdetails] = useState([])
  const [data, setData] = useState(null);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [selectedBooking, setSelectedbooking] = useState(null)
  const [bookingcount, setBookingcount] = useState({})
  const [passengerSeat, setpassengerSeat] = useState();
  const [passengername, setpassengername] = useState();
  const [passengernumber, setpassengernumber] = useState();
  const [carmodel, setcarmodel] = useState()
  const [babyseat, setbabyseat] = useState();
  const [luggage, setluggage] = useState();
  const [bookstatus1, setbookstatus1] = useState();
  const [bId, setBId] = useState("")
  const [confirmed_time, setconfirmed_time] = useState();
  const [destination, setdestination] = useState()
  const [fnumber, setfnumber] = useState()
  const [traveltype, settraveltype] = useState()
  const [onestop, setonestop] = useState()
  const [pickup, setpickup] = useState()
  const [drop, setdrop] = useState()
  const [traveldate, settraveldate] = useState()
  const [traveltime, settraveltime] = useState()
  const [hotel, sethotel] = useState()
  const [number, setnumber] = useState()
  const [ridestatus1, setridestatus1] = useState()
  const [carbrand, setcarbrand] = useState()
  const [drivername, setdrivername] = useState()
  const [drivernumber, setdrivernumber] = useState()
  const [driverId, setdriverId] = useState()
  const [book_info, setbook_info] = useState()
  const [airportarrival, setairportarrival] = useState()
  const [airportdeparture, setairportdeparture] = useState()
  const [ptp, setptp] = useState()
  const [singaporedspsl, setsingaporedspsl] = useState()
  const [malaysiadspsl, setmalaysiadspsl] = useState()
  const [dspslhrs, setdspslhrs] = useState()
  const [extraDisposalHours, setExtraDisposalHours] = useState(0)
  const [extra, setextra] = useState()
  const [midnight, setmidnight] = useState()
  const [waiting, setwaiting] = useState()
  const [hpayment, sethpayment] = useState()
  const [remarks, setremarks] = useState()
  const [dpayment, setdpayment] = useState()
  const [dremarks, setdremarks] = useState()
  const [extrastop, setextrastop] = useState();
  const [extraStops, setExtraStops] = useState();
  const [total, settotal] = useState()
  const [widgetId, setWidgetId] = useState(1)
  const [carNumber, setCarNumber] = useState("")
  const [filteredBooking, setFilteredBooking] = useState("")
  const userType = localStorage.getItem("userType");
  const userId = localStorage.getItem("userid")
  const [totalpages, setTotalpages] = useState('')
  const [perpage, setPerPage] = useState(10)
  const [totalcount, setTotalcount] = useState()
  const [pageNumber, setPageNumber] = useState(1)
  const [pickupType, setPickupType] = useState(0);
  const [rideFilter, setRideFilter] = useState(1); // Number(userType) === 1 ? 1 : 0 for admin(userType = 1) default is upcoming rides, for hotelUsers default is All rides
  const navigate = useNavigate()


  const getConfirmationStatus = (widgetId) => {
    switch (widgetId) {
      case 1:
        return undefined
      case 2:
        return 1
      case 3:
        return 2
      case 4:
        return 3;

      default:
        break;
    }
  }

  const fetchBooking = async () => {
    // console.log("checkinglist", userType === "1" ? "all" : userId,userType)
    let userID = 1;
    if (userType !== "1") {
      userID = localStorage.getItem("userid")
    }

    let date;
    let sort;
    if (rideFilter === 0) { // All rides - default for hotel users
      sort = { createdAt: 'desc' }
    } else if (rideFilter === 1) { // Upcoming rides -  default for admin
      date = { from: moment().format('YYYY-MM-DD') };
      sort = { date: 'asc', time: 'asc' }
    } else if (rideFilter === 2) { // existing rides
      date = {to: moment().subtract(1, 'days').format('YYYY-MM-DD')};
      sort = { date: 'desc', time: 'desc' }
    }

    try {
      const response = await fetch(config.baseUrl + 'api/get_booking', {
        method: "POST",
        headers: {
          "user_id": userID,
          is_primary: userType === "2" ? 1 : 0, // for temp work sending 1 for primary key
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          page: pageNumber,
          limit: perpage,
          pickup_type: Number(pickupType) || undefined,
          confirmation_status: getConfirmationStatus(widgetId),
          ride_status: widgetId === 5 ? '2' : undefined,
          date: date,
          sort: sort
        })
      });

      const data = await response.json();

      if (data.data?.length > 0) {
        setBookingcount(data.booking_count);
        setBookingdetails(data.data);
        setTotalcount(data.totalCount);
      } else {
        setBookingcount(data.booking_count || {});
        setBookingdetails([]);
        setTotalcount(0);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchBooking()
    fetchDrivers()

    /*if (widgetId) {
      getAllBooking();
    }*/

  }, [pageNumber, perpage, pickupType, rideFilter, widgetId]);
  /*
  useEffect(() => {
    const bookingListArray = Object.values(bookingdetails);
    const filteredItems = bookingListArray.filter(
      item =>
        JSON.stringify(item)
          .toLowerCase()
          .indexOf(filterText.toLowerCase()) !== -1
    );
    statusIdentification(filteredItems);
    RidestatusIdentification(filteredItems);
    PaymentstatusIdentification(filteredItems);
    DriverpaymentstatusIdentification(filteredItems);
    if (widgetId === 2) {
      const itemsWithConfirmation1 = filteredItems.filter(item => item.confirmation_status === 1);
      setFilteredBooking(itemsWithConfirmation1)
    } else if (widgetId === 3) {
      const itemsWithConfirmation1 = filteredItems.filter(item => item.confirmation_status === 2);
      setFilteredBooking(itemsWithConfirmation1)
    } else if (widgetId === 4) {
      const itemsWithConfirmation1 = filteredItems.filter(item => item.confirmation_status === 3);
      setFilteredBooking(itemsWithConfirmation1)
    } else {
      setFilteredBooking(filteredItems)
    }
  }, [bookingdetails]);


  const getAllBooking = async () => {
    let userID = 1;
    if (userType !== "1") {
      userID = localStorage.getItem("userid")
    }
    const response = await fetch(config.baseUrl + 'api/get_booking', {
      method: "POST",
      headers: {
        "user_id": userID,
        is_primary: userType === "2" ? 1 : 0,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        page: pageNumber,
        limit: totalcount,
      })
    });
    const data = await response.json();
    // console.log(data.data, "kjkj----------");

    if (data.data.length > 0) {
      setBookingcount(data.booking_count);
      setBookingdetails(data.data);
      setTotalcount(data.totalCount);
    }

  }
  // console.log(filteredBooking,'AFTER SELECTING THE ID')

  const handlePageChange = async (pageNumber) => {

    //callAPI(pageNumber)
    // console.log("pagenumber",pageNumber)
    //     console.log(JSON.stringify({
    //      page: pageNumber,
    //      limit: perpage,
    //  }));
    await fetch(config.baseUrl + "api/get_booking", {
      method: "POST",

      headers: {
        "Content-Type": "application/json",
        user_id: userType === "1" ? "all" : userId,
        is_primary: userType === "2" ? 1 : 0
      },
      body: JSON.stringify({
        page: pageNumber,
        limit: perpage,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        //console.log(data);
        setBookingdetails(data.data);
        //  setTotalpages(data.totalPages)
        setTotalcount(data.totalCount)

      });


    // console.log("pagenumber",pageNumber)

  };
  */
  const fetchDrivers = async () => {
    await fetch(config.baseUrl + "api/admin/driverlist", {
      method: "POST",

      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        page: pageNumber,
        limit: "all",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Drivers List", data);
        setDrivers(data.data);

      });
  }
  const handlePerRowsChange = async (newPerPage, page) => {
    // if(newPerPage === totalcount){
    //     console.log("Need to show all data in list");
    //     return
    // }else{
    //     console.log("Selected data in list");
    //     return
    // }
    // await fetch(config.baseUrl + "api/get_booking", {
    //   method: "POST",

    //   headers: {
    //     "Content-Type": "application/json",
    //     user_id: userType === "1" ? "all" : userId,
    //     is_primary: userType === "2" ? 1 : 0
    //   },
    //   body: JSON.stringify({
    //     page: page,
    //     limit: newPerPage,
    //   }),
    // })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     //console.log(data);
    //     setBookingdetails(data.data);
    //     //  setTotalpages(data.totalPages)
    //     setTotalcount(data.totalCount)

    //   });

    setPerPage(newPerPage);
    //setLoading(false);
  };
  const [drivers, setDrivers] = useState("")

  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: 'ALL',
  };
  function statusIdentification1(array) {
    for (var i = 0; i < array.length; i++) {
      if (array[i].confirmation_status === 2) {
        array[i].status1 = 'Accepted';
        array[i].statusColor = 'success';
      } else if (array[i].confirmation_status === 1) {
        array[i].status1 = 'Requested';
        array[i].statusColor = 'warning';
      } else if (array[i].confirmation_status === 3) {
        array[i].status1 = 'Cancelled';
        array[i].statusColor = 'danger';
      }
    }
  }
  function statusIdentification(array) {
    // console.log("inside app")
    for (var j = 0; j < array.length; j++) {
      //  console.log("inside for loop,value of confirmation_status is ",array[j].confirmation_status)
      if (array[j].confirmation_status === 1) {
        array[j].confirmation_status1 = 'Requested';
        array[j].confirmationNameColor = 'warning';
      }
      else if (array[j].confirmation_status === 2) {
        array[j].confirmation_status1 = 'Accepted';
        array[j].confirmationNameColor = 'success';
      }

      else {
        array[j].confirmation_status1 = 'Cancelled';
        array[j].confirmationNameColor = 'danger';


      }
    }
    //console.log(filteredItems);
  }
  function RidestatusIdentification1(array) {
    for (var i = 0; i < array.length; i++) {
      if (array[i].ride_status === 2) {
        array[i].status2 = 'Completed';
        array[i].statusColor = 'success';
      } else if (array[i].ride_status === 1) {
        array[i].status2 = 'Not Yet';
        array[i].statusColor = 'danger';
      }
    }
  }
  function RidestatusIdentification(array) {
    // console.log("inside app")
    for (var k = 0; k < array.length; k++) {
      //console.log("inside for loop,value of ride_status is ",array[k].ride_status)
      if (array[k].ride_status === '1') {
        array[k].ride_status1 = 'NOTYET';
        array[k].rideNameColor = 'danger';
      }
      else {
        array[k].ride_status1 = 'COMPLETED';
        array[k].rideNameColor = 'success';


      }
    }
    //console.log(filteredItems);
  }
  function PaymentstatusIdentification(array) {
    // console.log("inside app")
    for (var l = 0; l < array.length; l++) {
      // console.log("inside for loop,value of ride_status is ",array[l].hotel_payment)
      if (array[l].hotel_payment === 0) {
        array[l].hotel_payment1 = 'Unpaid';
        array[l].paymentNameColor = 'danger';
      }
      else if (array[l].hotel_payment === 1) {
        array[l].hotel_payment1 = 'Paid';
        array[l].paymentNameColor = 'success';
      }
      else {
        array[l].hotel_payment1 = 'Unpaid';
        array[l].paymentNameColor = 'danger';


      }
    }
    //console.log(filteredItems);
  }
  function DriverpaymentstatusIdentification(array) {
    // console.log("inside app")
    for (var s = 0; s < array.length; s++) {
      // console.log("inside for loop,value of ride_status is ",array[s].driver_payment)
      if (array[s].driver_payment === 0) {
        array[s].driver_payment1 = 'Unpaid';
        array[s].driverpaymentNameColor = 'danger';
      }
      else if (array[s].driver_payment === 1) {
        array[s].driver_payment1 = 'Paid';
        array[s].driverpaymentNameColor = 'success';
      }
      else {
        array[s].driver_payment1 = 'Unpaid';
        array[s].driverpaymentNameColor = 'danger';


      }
    }
    //console.log(filteredItems);
  }
  const getExtraStop = (stop) => {
    if (stop > 0) {
      return "Yes";
    } else {
      return "No";
    }
  }
  const firstname23 = localStorage.getItem("firstname")

  const getGlobalDate = (date) => {
    const date1 = new Date(date); // Your date object
    // const date1=new Date("11 Aug, 2023 : 13 : 31")
    const locale = navigator.language; // User's preferred language
    const day = date1.getDate().toString().padStart(2, '0');
    const month = new Intl.DateTimeFormat(locale, { month: 'short' }).format(date1);
    const year = date1.getFullYear();
    const hours = date1.getHours().toString().padStart(2, '0');
    const minutes = date1.getMinutes().toString().padStart(2, '0');

    const formattedDate = `${day} ${month}, ${year}`;
    const formattedTime = `${hours} : ${minutes}`;

    return `${formattedDate} : ${formattedTime}`;


  }

  // const handleChange = (event, row) => {
  //   const isChecked = event.target.checked;
  //   setChecked(isChecked);

  //   if (isChecked) {
  //     setSelectedRowId(filteredItems.map(row => row._id));
  //   } else {
  //     setSelectedRowId([]);
  //   }
  // };

  const getType = (type) => {
    if (type === "1" || type === "Arrival") {
      return "Airport Arrival";
    } else if (type === "2") {
      return "Airport Departure";
    } else if (type === "3") {
      return "Point To Point";
    } else if (type === "4") {
      return "Singapore Disposal";
    } else if (type === "5") {
      return "Malaysia Disposal";
    }
  }


  const getText = (type) => {
    if (type === "4") {
      return "(Min 3Hrs)";
    } else if (type === "5") {
      return "(Min 4Hrs)";
    }

  }
  const [checked1, setChecked1] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [selectedRowId, setSelectedRowId] = useState([]);

  const handleChange11 = (event, row) => {
    const isChecked1 = event.target.checked;
    setChecked1(isChecked1);

    if (row === 'header') {
      if (isChecked1) {
        setSelectedRowId(filteredItems.map(row => row._id));
        setChecked(true); // Update the state of the header checkbox to checked
      } else {
        setSelectedRowId([]);
        setChecked(false); // Update the state of the header checkbox to unchecked
      }
    } else {
      const selectedId = row._id;
      setSelectedRowId(prevSelectedRowIds => {
        if (prevSelectedRowIds.includes(selectedId)) {
          return prevSelectedRowIds.filter(id => id !== selectedId);
        } else {
          return [...prevSelectedRowIds, selectedId];
        }
      });

      // Update the state of the header checkbox based on the state of individual checkboxes
      if (isChecked1) {
        setChecked(selectedRowId.length + 1 === filteredItems.length);
      } else {
        setChecked(false);
      }
    }
  };

  const handleChange = (event, row) => {
    const isChecked = event.target.checked;
    setChecked(isChecked);

    if (isChecked) {
      setSelectedRowId(prevSelectedRowIds => [...prevSelectedRowIds, row._id]);
    } else {
      setSelectedRowId(prevSelectedRowIds =>
        prevSelectedRowIds.filter(id => id !== row._id)
      );
    }

    // Update the state of the header checkbox based on the state of individual checkboxes
    setChecked1(selectedRowId.length + 1 === filteredItems.length);
  };
  const [booktype, setbooktype] = useState();
  const [editId, setEditId] = useState("")
  const handleEditBooking = async (id) => {
    setEditId(id)
    localStorage.setItem("CreateFlow", "Update");
    // console.log("Inside Edit")
    let res = await fetch(config.baseUrl + 'api/bookingid/' + id, {
      method: "GET",
    });
    let resJson = await res.json();
    if (res.status === 200) {
      //console.log(resJson.data,"INSIDE EDIT API SUCCESS")
      const editDetails = resJson.data;
      // console.log(editDetails,"AFTER ASSIGNING TO EDITDETAILS")
      navigate('/add-booking', { state: { editDetails } });

    } else {

      console.log("Data Not retrieved")
    }

  }
  const getDriverDetails = (id) => {
    // console.log(id,"DRIVER ID")
    if (id !== undefined || id !== null) {
      if (drivers) {
        const driverArrray = Object.values(drivers);
        const driver = driverArrray.find((obj) => obj._id === id);
        if (driver) {
          const phoneNumber = driver && driver.phone_number;
          setdrivernumber(phoneNumber);
          return phoneNumber;
        } else {
          return ""
        }

      } else {
        return ""
      }
    } else {
      return "";
    }


  }

  const getDriverDetails1 = (id) => {
    // console.log(id,"DRIVER ID")
    if (id !== undefined || id !== null) {
      if (drivers) {
        const driverArrray = Object.values(drivers);
        const driver = driverArrray.find((obj) => obj._id === id);
        if (driver) {
          const carNumber = driver && driver.car_number;
          setCarNumber(carNumber);
          return carNumber;
        } else {
          return ""
        }

      } else {
        return ""
      }
    } else {
      return "";
    }


  }

  const getDate = (date) => {
    //console.log("date in api:",date)
    const [year, month, day] = date.split('-');
    const dateObject = new Date(year, month - 1, day);
    const monthName = dateObject.toLocaleString('default', { month: 'short' });
    const formattedDate = `${parseInt(day, 10)} ${monthName}, ${year}`;
    // console.log("formatted Date:",formattedDate)
    return formattedDate;

  }
  const getBookingConfirmedTime = (bookingHistory) => {
    // const confirmedHistory = bookingHistory.find(
    //   (history) => "Booking Confirmed" in history
    // );
    // const confirmedHistory = bookingHistory.find((history) => history.hasOwnProperty("Booking Confirmed"));
    let confirmedHistory;
    if (bookingHistory && bookingHistory.length > 0) {
      confirmedHistory = bookingHistory && bookingHistory.find((history) => history && "Booking Confirmed" in history);
    } else {
      // Handle the case when bookingHistory is undefined or empty
      confirmedHistory = null; // Or set it to a default value based on your needs
    }

    if (confirmedHistory) {
      const bookingConfirmed = confirmedHistory["Booking Confirmed"];

      return bookingConfirmed.TIme;


    }

    return "";
  };

  const getBookingPerson = (bookingHistory) => {
    let reqBooking;
    if (bookingHistory && bookingHistory.length > 0) {
      reqBooking = bookingHistory && bookingHistory.find((history) => history && "Booking Requested" in history);
    } else {
      // Handle the case when bookingHistory is undefined or empty
      reqBooking = null; // Or set it to a default value based on your needs
    }
    if (reqBooking) {
      const bookingPerson = reqBooking["Booking Requested"];

      return bookingPerson["Booking Person"];

    }

    return "";
  }

  const getTime = (time1) => {
    if (time1) {

      const [time, period] = time1.split(' ');
      let [hours, minutes] = time.split('.');
      hours = parseInt(hours, 10);
      if (period === 'PM' && hours !== 12) {
        hours += 12;
      } else if (period === 'AM' && hours === 12) {
        hours = 0;
      }
      hours = hours.toString().padStart(2, '0');
      minutes = minutes.padStart(2, '0');

      const time24hr = ` ${hours}.${minutes}`;
      return time24hr;
    }
    return "";
  };

  const getSorting = (columnId) => (cellA, cellB) => {
    // console.log(cellA.date,"Inside Sorting")
    if (columnId === 'date & time') {
      const dateA = new Date(cellA.date);
      const dateB = new Date(cellB.date);
      return dateA - dateB;

    }

    return cellA.value - cellB.value;
  };

  const columnsProducts = [
    // {
    //   name: <span style={{ height: 20, width: 20 }} >
    //     {userType !== "2" && userType !== "3" && (<div >
    //       <FormGroup>
    //         <FormControlLabel
    //           style={{ height: 20, width: 30 }}
    //           control={
    //             <Checkbox
    //               size="small"
    //               onChange={event => handleChange11(event, 'header')}
    //               checked={checked1}
    //               inputProps={{ 'aria-label': 'controlled', }}
    //             />
    //           }
    //           labelPlacement="end"
    //         />
    //       </FormGroup>
    //     </div>)}
    //   </span>,
    //   id: "checkbox-selection",
    //   width: '50px',
    //   style: { position: 'sticky', left: 0, backgroundColor: 'white', zIndex: 9 },
    //   selector: row => row.booking_id,
    //   cell: (row => {
    //     // Check if both hotel_payment and driver_payment are "Paid"
    //     if (row.hotel_payment1 === 'Paid' && row.driver_payment1 === 'Paid') {
    //       return null; // Return null to hide the cell
    //     }
    //     if (userType === "2" || userType === "3") {
    //       return (
    //         <div style={{ visibility: 'hidden' }}></div>
    //       );
    //     }
    //     if (row.confirmation_status === 2 && row.ride_status === "2") {
    //       return (
    //         <div >

    //           <div >
    //             <FormGroup>
    //               <FormControlLabel
    //                 control={
    //                   <Checkbox
    //                     size="small"
    //                     onChange={(event) => handleChange(event, row)}
    //                     checked={selectedRowId.includes(row._id)}
    //                     inputProps={{ 'aria-label': 'controlled' }}
    //                   />
    //                 }
    //                 labelPlacement="end"
    //               />
    //             </FormGroup>
    //           </div>

    //         </div>
    //       );
    //     }

    //   }),
    //   sortable: false,

    // },

    // {
    //   name: <span className='font-weight-bold fs-13 text-nowrap'>Booking ID</span>,
    //   selector: row => row.booking_id,
    //   cell: (row =>
    //     <div>
    //       <div className="fw-medium ">{row.booking_id}
    //       </div>
    //       <div>
    //         {row.confirmation_status === 2 && row.driver_name && (<Link className="badge bg-soft-primary text-dark mt-1 pt-1 pb-1 fs-10 px-3 bg-success text-uppercase" onClick={() => handleShareBooking(row._id)}>Share</Link>)}
    //       </div>
    //     </div>
    //   ),
    //   sortable: false,
    // },
    {
      name: <span className='font-weight-bold fs-13 text-nowrap'>Booking Info</span>,
      id: "booking-info-2",
      style: { position: 'sticky', left: 0, backgroundColor: 'white', zIndex: 9 },
      selector: "booking info",
      cell: (row) => (
        <div >
          <h5 className="text-info fs-14">{row.booking_id}</h5>
          <h5 className="fs-14 mb-1">{row.passenger_name}</h5>
          <p className="text-secondary fw-medium  mb-0">{row.country_code} {row.passenger_contact_number}</p>
          {row.number_of_passengers && (
            <div>
              <p className="text-muted mb-0 mt-3">
                <span className="table-label">Passenger Seat : </span> <span className="fw-medium text-dark">{row.number_of_passengers.adult}</span>
              </p>
              {row.number_of_passengers.child ? <p className="text-muted mb-0">
                <span className="table-label">Baby Seat : </span> <span className="fw-medium text-dark">{row.number_of_passengers.child}</span>
              </p> : ''}
              <p className="text-muted mb-0">
                <span className="table-label">Luggage  : </span> <span className="fw-medium text-dark">{row.number_of_luggage}</span>
              </p>
            </div>
          )}
          {userType !== "2" && userType !== "3" && (<p className="text-muted mb-0 mt-3">
            <span className="table-label">Hotel Name : </span>
            <span className="fw-medium text-dark d-block">{row.hotel_name}</span>
          </p>)}
          {userType !== "1" && (<p className="text-muted mb-0 mt-3">
            <span className="table-label">Booking Person: </span>
            <span className="fw-medium text-dark d-block">{getBookingPerson(row.booking_history)}</span>
          </p>)}

          {<button className="btn share-btn mt-3" onClick={() => { handleShareBooking(row._id)} }>Share</button>}
          {/* <p className="text-muted mb-0 mt-1">
                      <span className="table-label">Booking Person : </span>
                      <span className="fw-medium text-dark d-block">{item.bookingPerson}</span>
                  </p> */}
        </div>
      ),
      sortable: false,
    },
    {
      name: <span className='font-weight-bold fs-13 text-nowrap'>Travel Info</span>,
      selector: 'travel info',
      width: '300px',
      cell: (row) => (
        <div >
          {row.pickup_type && (
            <div >
              <h5 className="fs-14 mb-0">{getType(row.pickup_type)}</h5>
              {(row.pickup_type === "1" || row.pickup_type === '2') &&
                (<>
                  <div className="mt-2">
                    <span className="table-label text-muted">Pickup Location : </span>
                    <span className="fw-medium text-dark">
                      {row.pickup_type === '1' ?  row.airport_name : row.destination}
                    </span>
                  </div>
                  <div>
                    <span className="table-label text-muted">Drop Location : </span>
                    <span className="fw-medium text-dark">
                      {row.pickup_type === '1' ?  row.destination : row.airport_name}
                    </span>
                  </div>
                </>)
              }
              {(row.pickup_type === "1" || row.pickup_type === "2") && row.flight_number ? <div className="mt-2">
                <span className="table-label text-muted">Flight Number : </span>
                <span className="fw-medium text-dark">{row.flight_number}</span>
              </div> : ''}

              {(row.pickup_type === "3" || row.pickup_type === '4' || row.pickup_type === '5') && row.pickup_location && (
                <div className="d-flex align-items-center gap-1 mt-2">
                  <span className="table-label text-muted">Pickup Location : </span>
                  <span className="fw-medium text-dark ellipsis" title={row.pickup_location}>{row.pickup_location}</span>
                </div>
              )}

              {row.dropoff_location ? <div className="d-flex align-items-center gap-1">
                  <span className="table-label text-muted">Drop Location : </span>
                  <span className="fw-medium text-dark ellipsis" title={row.dropoff_location}>{row.dropoff_location}</span>
                </div> : ''}

               {(row.pickup_type === '4' || row.pickup_type === '5') && (
                <div>
                  <span className="table-label text-muted">Disposal Hours: </span>
                  <span className="fw-medium text-dark">{row.disposal_hours}</span>
                </div>
              )}

              {row.extra_stop ? <div>
                <span className="table-label text-muted">Extra Stop : </span>
                <span className="fw-medium text-dark">{row.extra_stop}</span>
              </div> : ''}

              {row.pickup_type !== "4" && row.pickup_type !== "5" && row.extra_stop >= 1 ? <div className="mt-1">
                <div className="table-label text-muted">Stop 1</div>
                <div className="fs-14 fw-semibold">
                  {row.extra_stop_1}
                </div>
              </div> : ''}

              {row.pickup_type !== "4" && row.pickup_type !== "5" && row.extra_stop >= 2 ? <div className="mt-1">
                <div className="table-label text-muted">Stop 2</div>
                <div className="fs-14 fw-semibold">
                  {row.extra_stop_2}
                </div>
              </div> : ''}
              {row?.remark ? <p className="text-muted mb-0 mt-1">Remarks: <span className="fw-medium text-dark d-block ellipsis" title={row?.remark}>{row?.remark}</span></p> : ''}

              {row.first_call ? <div className="mt-2">
                <span className="table-label text-muted">First Call : </span>
                <span className="fw-medium text-dark">+65 {row.first_call}</span>
              </div> : ''}

              {row.second_call ? <div>
                <span className="table-label text-muted">Second Call : </span>
                <span className="fw-medium text-dark">+65 {row.second_call}</span>
              </div> : ''}
            </div>
          )}
        </div>
      ),
      sortable: false,
    },
    {
      name: <span className='font-weight-bold fs-13 text-nowrap'>Date & Time</span>,
      selector: 'date & time',
      cell: (row =>
        <div>
          <>
            <p className="text-muted mb-0 mt-2">
              <span className="table-label">Travel Date & Time</span><br/>
              <span className="fw-medium text-dark fw-semibold">{getDate(row.date)}</span>
            </p>
            <p className="text-muted mb-0 mt-0">
              <span className="d-inline">Time:</span>
              <span className="fs-14 text-dark d-inline">{row.time}</span>
            </p>
          </>

          <>
            <p className="text-muted mb-0 mt-2">
              <span className="table-label">Booking Date & Time</span><br/>
              <span className="fw-medium text-dark fw-semibold">{row.booking_date.split(' : ')[0]}</span>
            </p>
            <p className="text-muted mb-0 mt-0">
              <span className="d-inline">Time:</span>
              <span className="fs-14 text-dark d-inline">{row.booking_date.split(' : ')[1]}</span>
            </p>
          </>

          {row.confirmation_date && <>
            <p className="text-muted mb-0 mt-2">
              <span className="table-label">Confirmation Date & Time</span><br/>
              <span className="fw-medium text-dark fw-semibold">{row.confirmation_date.split(' : ')[0]}</span>
            </p>
            <p className="text-muted mb-0 mt-0">
              <span className="d-inline">Time:</span>
              <span className="fs-14 text-dark d-inline">{row.confirmation_date.split(' : ')[1]}</span>
            </p>
          </>}
        </div>
      ),
      sortable: false,
      // sortFunction: getSorting('date & time')
    },

    {
      name: <span className='font-weight-bold fs-13 text-nowrap'>Car Info</span>,
      selector: 'car-info',
      cell: (row) => (
        <div>

          {row.car_info && <h5 className="fs-14 mb-1 text-nowrap">{row.car_info.car_brand}-{row.car_info.car_model}</h5>}
          {
            row.confirmation_status === 2 && row.driver_name && (
              <div>
                <p className="text-muted mb-0 mt-3">
                  Assign Driver:
                  <span className="fw-medium d-block text-dark text-uppercase">{row.driver_name}</span>
                </p>
                <p className="text-secondary fw-medium  mb-0">+65 {row.confirmation_status === 2 && getDriverDetails(row.driver_id)}</p>
              </div>
            )
          }
          {
            row.confirmation_status === 2 && row.driver_name && (
              <div>
                <p className="text-muted mb-0 mt-3">
                  Car Plate No:
                </p>
                <p className="fw-medium d-block text-dark text-uppercase">{row.confirmation_status === 2 && getDriverDetails1(row.driver_id)}</p>
              </div>
            )
          }

        </div>
      ),
      sortable: false,
    },
    {
      name: <span className='font-weight-bold fs-13 text-nowrap'>Status</span>,
      selector: row => row.confirmation_status,
      cell: (row => <div>
        <p className="text-muted mb-0 mt-2">
          <span className="table-label">Booking Status</span><br/>
          <span className={`badge text-uppercase bg-${row.confirmationNameColor}`}>{row.confirmation_status1}</span>
        </p>

        <p className="text-muted mb-0 mt-2">
          <span className="table-label">Ride Status</span><br/>
          <span className={`badge text-uppercase bg-${row.rideNameColor} `}>{row.ride_status1}</span>
        </p>
      </div>),
      sortable: false,
    },
    {
      name: <span className='font-weight-bold fs-13 text-nowrap'>Payment Info</span>,
      selector: row => row.paymentInfo,
      cell: (row =>
        <div>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-0"><span className="text-muted fs-12 fw-medium">Total:</span> <span className="text-dark">${row.payment_info.total}</span></h5>
              <div className="mt-2">
                {row.payment_info.pickup_type === "1" && row.payment_info.arrival_charge ?  <div>
                  <span className="table-label text-muted">Airport Arrival : </span>
                  <span className="fw-medium text-dark">${row.payment_info.arrival_charge}</span>
                </div> : ''}

                {row.payment_info.pickup_type === "2" && row.payment_info.departure_charge ?  <div>
                  <span className="table-label text-muted">Airport Departure : </span>
                  <span className="fw-medium text-dark">${row.payment_info.departure_charge}</span>
                </div> : ''}

                {row.payment_info.pickup_type === "3" && row.payment_info.point_point_charge ?  <div>
                  <span className="table-label text-muted">Point to Point : </span>
                  <span className="fw-medium text-dark">${row.payment_info.point_point_charge}</span>
                </div> : ''}

                {(row.payment_info.pickup_type === "4" || row.payment_info.pickup_type === "5") && row.payment_info.disposal_charge ?  <div>
                  <span className="table-label text-muted">{getType(row.payment_info.pickup_type)} : </span>
                  <span className="fw-medium text-dark">${row.payment_info.disposal_charge}</span>
                </div> : ''}
              </div>
              <div className="mt-2">
                {(row.payment_info.pickup_type === "4" || row.payment_info.pickup_type === "5") && row.payment_info.extra_disposal_charge ?  <div>
                  <span className="table-label text-muted">Extra Hours For Disposal ({row.extra_disposal_hours} Hrs): </span>
                  <span className="fw-medium text-dark">${row.payment_info.extra_disposal_charge}</span>
                </div> : ''}

                {row.payment_info.midnight_charge ?  <div>
                  <span className="table-label text-muted">Midnight Surcharge ({row.midnight_hours} Hrs): </span>
                  <span className="fw-medium text-dark">${row.payment_info.midnight_charge}</span>
                </div> : ''}

                {row.payment_info.extra_stop_charge ?  <div>
                  <span className="table-label text-muted">Extra Stop Surcharge ({row.extra_stop + row.additional_extra_stop} Stop): </span>
                  <span className="fw-medium text-dark">${row.payment_info.extra_stop_charge}</span>
                </div> : ''}

                {row.payment_info.waiting_minutes_charge || row.waiting_minutes ?  <div>
                  <span className="table-label text-muted">Waiting Time Surcharge ({row.waiting_minutes * 15} Mins): </span>
                  <span className="fw-medium text-dark">${row.payment_info.waiting_minutes_charge}</span>
                </div> : ''}
                {row.surcharge_percentage ?  <div>
                  <span className="table-label text-muted">Surcharge Percentage: </span>
                  <span className="fw-medium text-dark">{row.surcharge_percentage * 25}%</span>
                </div> : ''}
              </div>
              {/* <p className="mb-0 mt-3"><span className="text-muted">Hotel Payment Status : </span> <span className={`fw-medium text-${row.paymentNameColor}`}>{row.hotel_payment1}</span></p> */}
              {/* {userType !== "2" && userType !== "3" && (<p className="mb-0"><span className="text-muted">Driver Payment Status : </span> <span className={`fw-medium text-${row.driverpaymentNameColor}`}>{row.driver_payment1}</span></p>)} */}
            </div>
          </div>
        </div>

      ),
      sortable: false,
    },

    {
      name: <span className='font-weight-bold fs-13 text-nowrap'>Action</span>,
      id: "action",
      style: { position: 'sticky', right: 0, backgroundColor: 'white', zIndex: 9 },
      selector: row => row.bookingID,
      cell: (row =>
        <div>
          <Button className="btn btn-soft-info btn-icon fs-14 me-1" onClick={() => handleViewBooking(row._id, row.booking_id)}>
            <i className="ri-eye-line"></i>
          </Button>
          {row.ride_status !== "2" && row.confirmation_status !== 3 && row.confirmation_status !== 2 && userType !== "1" && (
            <Button className="btn btn-soft-info btn-icon fs-14 me-1" onClick={() => handleEditBooking(row._id)} >
              <i className="ri-edit-box-line"></i>
            </Button>
          )}

          {userType === "1" && row.confirmation_status !== 3 && (row.ride_status !== "2" || row.driver_payment !== 1 || row.hotel_payment !== 1) && (<Button className="btn btn-soft-info btn-icon fs-14" onClick={() => handleBookingConfirmation(row._id)}>
            <i className="ri-settings-4-line"></i>
          </Button>)}
        </div>
      ),
      sortable: false,
    },
  ];
  //userType 2 & 3
  const columnsProducts1 = [


    // {
    //   name: <span className='font-weight-bold fs-13 text-nowrap'>Booking ID</span>,
    //   selector: row => row.booking_id,
    //   cell: (row =>
    //     <div>
    //       <div className="fw-medium " >{row.booking_id}
    //       </div>
    //       <div>
    //         {row.confirmation_status === 2 && row.driver_name && (<Link className="badge bg-soft-primary text-dark mt-1 pt-1 pb-1 fs-10 px-3 bg-success text-uppercase" onClick={() => handleShareBooking(row._id)}>Share</Link>)}
    //       </div>
    //     </div>
    //   ),
    //   sortable: false,
    // },
    {
      name: <span className='font-weight-bold fs-13 text-nowrap'>Booking Info</span>,
      selector: "booking info",
      id: "booking-info",
      style: { position: 'sticky', left: 0, backgroundColor: 'white', zIndex: 9 },
      cell: (row) => (
        <div >
          <h5 className="text-info fs-14">{row.booking_id}</h5>
          <h5 className="fs-14 mb-1">{row.passenger_name}</h5>
          <p className="text-secondary fw-medium  mb-0">{row.country_code} {row.passenger_contact_number}</p>
          {row.number_of_passengers && (
            <div>
              <p className="text-muted mb-0 mt-3">
                <span className="table-label">Passenger Seat : </span> <span className="fw-medium text-dark">{row.number_of_passengers.adult}</span>
              </p>
              {row.number_of_passengers.child ? <p className="text-muted mb-0">
                <span className="table-label">Baby Seat : </span> <span className="fw-medium text-dark">{row.number_of_passengers.child}</span>
              </p> : ''}
              <p className="text-muted mb-0">
                <span className="table-label">Luggage  : </span> <span className="fw-medium text-dark">{row.number_of_luggage}</span>
              </p>
            </div>
          )}
          {userType !== "2" && userType !== "3" && (<p className="text-muted mb-0 mt-3">
            <span className="table-label">Hotel Name : </span>
            <span className="fw-medium text-dark d-block">{row.hotel_name}</span>
          </p>)}
          {userType !== "1" && (<p className="text-muted mb-0 mt-3">
            <span className="table-label">Booking Person: </span>
            <span className="fw-medium text-dark d-block">{getBookingPerson(row.booking_history)}</span>
          </p>)}
          {<button className="btn share-btn mt-3" onClick={() => { handleShareBooking(row._id)} }>Share</button>}
          {/* <p className="text-muted mb-0 mt-1">
                    <span className="table-label">Booking Person : </span>
                    <span className="fw-medium text-dark d-block">{item.bookingPerson}</span>
                </p> */}
        </div>
      ),
      sortable: false,
    },
    {
      name: <span className='font-weight-bold fs-13 text-nowrap'>Travel Info</span>,
      selector: 'travel info',
      width: '300px',
      cell: (row) => (
        <div >
          {row.pickup_type && (
             <div >
             <h5 className="fs-14 mb-0">{getType(row.pickup_type)}</h5>
             {(row.pickup_type === "1" || row.pickup_type === '2') &&
                (<>
                  <div className="mt-2">
                    <span className="table-label text-muted">Pickup Location : </span>
                    <span className="fw-medium text-dark">
                      {row.pickup_type === '1' ?  row.airport_name : row.destination}
                    </span>
                  </div>
                  <div>
                    <span className="table-label text-muted">Drop Location : </span>
                    <span className="fw-medium text-dark">
                      {row.pickup_type === '1' ?  row.destination : row.airport_name}
                    </span>
                  </div>
                </>)
              }
             {(row.pickup_type === "1" || row.pickup_type === "2") && row.flight_number ? <div className="mt-2">
               <span className="table-label text-muted">Flight Number : </span>
               <span className="fw-medium text-dark">{row.flight_number}</span>
             </div> : ''}

             {(row.pickup_type === "3" || row.pickup_type === '4' || row.pickup_type === '5') && row.pickup_location && (
                <div className="d-flex align-items-center gap-1 mt-2">
                  <span className="table-label text-muted">Pickup Location : </span>
                  <span className="fw-medium text-dark ellipsis" title={row.pickup_location}>{row.pickup_location}</span>
                </div>
             )}

             {row.dropoff_location ? <div className="d-flex align-items-center gap-1">
                 <span className="table-label text-muted">Drop Location : </span>
                 <span className="fw-medium text-dark ellipsis" title={row.dropoff_location}>{row.dropoff_location}</span>
               </div> : ''}

              {(row.pickup_type === '4' || row.pickup_type === '5') && (
               <div>
                 <span className="table-label text-muted">Disposal Hours: </span>
                 <span className="fw-medium text-dark">{row.disposal_hours}</span>
               </div>
             )}

             {row.extra_stop ? <div>
               <span className="table-label text-muted">Extra Stop : </span>
               <span className="fw-medium text-dark">{row.extra_stop}</span>
             </div> : ''}

             {row.pickup_type !== "4" && row.pickup_type !== "5" && row.extra_stop >= 1 ? <div className="mt-1">
               <div className="table-label text-muted">Stop 1</div>
               <div className="fs-14 fw-semibold">
                 {row.extra_stop_1}
               </div>
             </div> : ''}

             {row.pickup_type !== "4" && row.pickup_type !== "5" && row.extra_stop >= 2 ? <div className="mt-1">
               <div className="table-label text-muted">Stop 2</div>
               <div className="fs-14 fw-semibold">
                 {row.extra_stop_2}
               </div>
             </div> : ''}
             {row?.remark ? <p className="text-muted mb-0 mt-1">Remarks: <span title={row?.remark} className="fw-medium text-dark d-block ellipsis">{row?.remark}</span></p> : ''}

             {row.first_call ? <div className="mt-2">
                <span className="table-label text-muted">First Call : </span>
                <span className="fw-medium text-dark">+65 {row.first_call}</span>
              </div> : ''}

              {row.second_call ? <div>
                <span className="table-label text-muted">Second Call : </span>
                <span className="fw-medium text-dark">+65 {row.second_call}</span>
              </div> : ''}
           </div>
          )}
        </div>
      ),
      sortable: false,
    },
    {
      name: <span className='font-weight-bold fs-13 text-nowrap'>Date & Time</span>,
      selector: 'date & time',
      cell: (row =>
        <div>
          <>
            <p className="text-muted mb-0 mt-2">
              <span className="table-label">Travel Date & Time</span><br/>
              <span className="fw-medium text-dark fw-semibold">{getDate(row.date)}</span>
            </p>
            <p className="text-muted mb-0 mt-0">
              <span className="d-inline">Time:</span>
              <span className="fs-14 text-dark d-inline">{row.time}</span>
            </p>
          </>

          <>
            <p className="text-muted mb-0 mt-2">
              <span className="table-label">Booking Date & Time</span><br/>
              <span className="fw-medium text-dark fw-semibold">{row.booking_date.split(' : ')[0]}</span>
            </p>
            <p className="text-muted mb-0 mt-0">
              <span className="d-inline">Time:</span>
              <span className="fs-14 text-dark d-inline">{row.booking_date.split(' : ')[1]}</span>
            </p>
          </>

          {row.confirmation_date && <>
            <p className="text-muted mb-0 mt-2">
              <span className="table-label">Confirmation Date & Time</span><br/>
              <span className="fw-medium text-dark fw-semibold">{row.confirmation_date.split(' : ')[0]}</span>
            </p>
            <p className="text-muted mb-0 mt-0">
              <span className="d-inline">Time:</span>
              <span className="fs-14 text-dark d-inline">{row.confirmation_date.split(' : ')[1]}</span>
            </p>
          </>}
        </div>
      ),
      sortable: false,
      // sortFunction: getSorting('date & time')
    },

    {
      name: <span className='font-weight-bold fs-13 text-nowrap'>Car Info</span>,
      selector: 'car-info',
      cell: (row) => (
        <div>

          {row.car_info && <h5 className="fs-14 mb-1 text-nowrap">{row.car_info.car_brand}-{row.car_info.car_model}</h5>}
          {
            row.confirmation_status === 2 && row.driver_name && (
              <div>
                <p className="text-muted mb-0 mt-3">
                  Assign Driver:
                  <span className="fw-medium d-block text-dark text-uppercase">{row.driver_name}</span>
                </p>
                <p className="text-secondary fw-medium  mb-0">+65 {row.confirmation_status === 2 && getDriverDetails(row.driver_id)}</p>
              </div>
            )
          }
          {
            row.confirmation_status === 2 && row.driver_name && (
              <div>
                <p className="text-muted mb-0 mt-3">
                  Car Plate No:
                </p>
                <p className="fw-medium d-block text-dark text-uppercase">{row.confirmation_status === 2 && getDriverDetails1(row.driver_id)}</p>
              </div>
            )
          }

        </div>
      ),
      sortable: false,
    },
    {
      name: <span className='font-weight-bold fs-13 text-nowrap'>Status</span>,
      selector: row => row.confirmation_status,
      cell: (row => <div>
        <p className="text-muted mb-0 mt-2">
          <span className="table-label">Booking Status</span><br/>
          <span className={`badge text-uppercase bg-${row.confirmationNameColor}`}>{row.confirmation_status1}</span>
        </p>

        <p className="text-muted mb-0 mt-2">
          <span className="table-label">Ride Status</span><br/>
          <span className={`badge text-uppercase bg-${row.rideNameColor} `}>{row.ride_status1}</span>
        </p>
      </div>),
      sortable: false,
    },
    {
      name: <span className='font-weight-bold fs-13 text-nowrap'>Payment Info</span>,
      selector: row => row.paymentInfo,
      cell: (row =>
        <div>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1"><span className="text-muted fs-12 fw-medium">Total:</span> <span className="text-dark">${row.payment_info.total}</span></h5>
              <div className="mt-2">
                {row.payment_info.pickup_type === "1" && row.payment_info.arrival_charge ?  <div>
                  <span className="table-label text-muted">Airport Arrival : </span>
                  <span className="fw-medium text-dark">${row.payment_info.arrival_charge}</span>
                </div> : ''}

                {row.payment_info.pickup_type === "2" && row.payment_info.departure_charge ?  <div>
                  <span className="table-label text-muted">Airport Departure : </span>
                  <span className="fw-medium text-dark">${row.payment_info.departure_charge}</span>
                </div> : ''}

                {row.payment_info.pickup_type === "3" && row.payment_info.point_point_charge ?  <div>
                  <span className="table-label text-muted">Point to Point : </span>
                  <span className="fw-medium text-dark">${row.payment_info.point_point_charge}</span>
                </div> : ''}

                {(row.payment_info.pickup_type === "4" || row.payment_info.pickup_type === "5") && row.payment_info.disposal_charge ?  <div>
                  <span className="table-label text-muted">{getType(row.payment_info.pickup_type)} : </span>
                  <span className="fw-medium text-dark">${row.payment_info.disposal_charge}</span>
                </div> : ''}
              </div>
              <div className="mt-2">
                {(row.payment_info.pickup_type === "4" || row.payment_info.pickup_type === "5") && row.payment_info.extra_disposal_charge ?  <div>
                  <span className="table-label text-muted">Extra Hours For Disposal ({row.extra_disposal_hours} Hrs): </span>
                  <span className="fw-medium text-dark">${row.payment_info.extra_disposal_charge}</span>
                </div> : ''}

                {row.payment_info.midnight_charge ?  <div>
                  <span className="table-label text-muted">Midnight Surcharge ({row.midnight_hours} Hrs): </span>
                  <span className="fw-medium text-dark">${row.payment_info.midnight_charge}</span>
                </div> : ''}

                {row.payment_info.extra_stop_charge ?  <div>
                  <span className="table-label text-muted">Extra Stop Surcharge ({row.extra_stop + row.additional_extra_stop} Stop): </span>
                  <span className="fw-medium text-dark">${row.payment_info.extra_stop_charge}</span>
                </div> : ''}

                {row.payment_info.waiting_minutes_charge || row.waiting_minutes ?  <div>
                  <span className="table-label text-muted">Waiting Time Surcharge ({row.waiting_minutes * 15} Mins): </span>
                  <span className="fw-medium text-dark">${row.payment_info.waiting_minutes_charge}</span>
                </div> : ''}

                {row.surcharge_percentage ?  <div>
                  <span className="table-label text-muted">Surcharge Percentage: </span>
                  <span className="fw-medium text-dark">{row.surcharge_percentage * 25}%</span>
                </div> : ''}
              </div>

              {/* <p className="mb-0 mt-3"><span className="text-muted">Hotel Payment Status : </span> <span className={`fw-medium text-${row.paymentNameColor}`}>{row.hotel_payment1}</span></p> */}
              {/* {userType !== "2" && userType !== "3" && (<p className="mb-0"><span className="text-muted">Driver Payment Status : </span> <span className={`fw-medium text-${row.driverpaymentNameColor}`}>{row.driver_payment1}</span></p>)} */}
            </div>
          </div>
        </div>
      ),
      sortable: false,
    },

    {
      name: <span className='font-weight-bold fs-13 text-nowrap'>Action</span>,
      id: "action",
      style: { position: 'sticky', right: 0, backgroundColor: 'white', zIndex: 9 },
      selector: row => row.bookingID,
      cell: (row =>
        <div>
          <Button className="btn btn-soft-info btn-icon fs-14 me-1" onClick={() => handleViewBooking(row._id, row.booking_id)}>
            <i className="ri-eye-line"></i>
          </Button>
          {row.ride_status !== "2" && row.confirmation_status !== 3 && userType !== "1" && (
            <Button className="btn btn-soft-info btn-icon fs-14 me-1" onClick={() => handleEditBooking(row._id)} >
              <i className="ri-edit-box-line"></i>
            </Button>
          )}
          {userType !== "2" && userType !== "3" && (<Button className="btn btn-soft-info btn-icon fs-14" onClick={() => handleBookingConfirmation(row._id)}>
            <i className="ri-settings-4-line"></i>
          </Button>)}

          {Number(row.confirmation_status) !== 3 && Number(row.ride_status) !== 2 && <Button className="btn btn-danger btn-icon fs-14" onClick={() => { setSelectedbooking(row); setIsCancelModalOpen(true)}}>
            <i className="ri-close-line"></i>
          </Button>}
        </div>
      ),
      sortable: false,
    },
  ];
  // Conditional rendering of columns based on userType
  const columnsToDisplay = userType === "1" ? columnsProducts : columnsProducts1;

  /********Data table Search****** */
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
    false
  );
  const bookingListArray = Object.values(bookingdetails);
  const filteredItems = bookingdetails.filter(
    item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
  );
  statusIdentification(filteredItems);
  RidestatusIdentification(filteredItems);
  PaymentstatusIdentification(filteredItems);
  DriverpaymentstatusIdentification(filteredItems);
  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const [showSideBar, setShowSideBar] = useState(false)
  const [showShareBooking, setShowShareBooking] = useState(false)
  const [showViewBooking, setShowViewBooking] = useState(false)
  const [showBookingdID, setIsBookingID] = useState(false)
  const [showpaymentConfirmation, setPaymentConfirmation] = useState(false)
  const [showBookingConfirmation, setBookingConfirmation] = useState(false)
  const [sideBarTile, setSideBarTile] = useState('')
  // const handleBookingId = () => {
  //     setShowSideBar(true)
  //     setIsBookingID(true)
  //     setShowShareBooking(false)
  //     setShowViewBooking(false)
  //     setPaymentConfirmation(false)
  //     setBookingConfirmation(false)
  //     setSideBarTile('Booking Confirmation')
  // }

  const [selectedRows, setSelectedRows] = useState([]);
  const handleRowClicked = () => {
    console.log("checkbox343")
  }
  const [showAlert, setShowAlert] = useState(false);
  const [showstatus, setShowstatus] = useState(false);
  const handleMarkPayment = (bookingIds) => {
    if (bookingIds.length === 0) {
      setShowAlert(true);
      setShowstatus(true);
      setTimeout(() => {
        setShowAlert(false);
        setShowstatus(false);
      }, 3000);
    } else {
      setShowAlert(false); // Reset the alert state if there was a previous alert
      setShowstatus(false);
      setShowSideBar(true);
      setIsBookingID(false);
      setShowShareBooking(false);
      setShowViewBooking(false);
      setPaymentConfirmation(true);
      setBookingConfirmation(false);
      setSideBarTile('Payment Confirmation');
      //  console.log('Selected Booking IDs:', bookingIds);
      setSelectedRowId(bookingIds);
    }
  };

  const [ID, setID] = useState()
  const [viewId, setViewId] = useState("")
  const [extrahrs, setExtrahrs] = useState("")
  const [midHours, setmidhrs] = useState("")
  const handleViewBooking = (rowId, bookid) => {
    setID(rowId)
    setViewId(rowId)
    setShowSideBar(true)
    setIsBookingID(false)
    setShowShareBooking(false)
    setShowViewBooking(true)
    setPaymentConfirmation(false)
    setBookingConfirmation(false)
    setBId(bookid)
    //console.log("RowID for view booking:",rowId)

    fetch(config.baseUrl + 'api/bookingid/' + rowId)
      .then((response) => response.json())
      .then((data) => {
        // console.log(data.data,"11111111---------");
        setData(data.data);
        setpassengername(data.data.passenger_name)
        setpassengernumber(data.data.passenger_contact_number)
        setcarmodel(data.data.car_info.car_model)
        setpassengerSeat(data.data.number_of_passengers.adult);
        setbabyseat(data.data.number_of_passengers.child)
        settotal(data.data.payment_info.total)
        setluggage(data.data.number_of_luggage)
        setmidhrs(data.data.midnight_hours)
        if (data.data.confirmation_status === 1)
          setbookstatus1("Requested")
        else if (data.data.confirmation_status === 2)
          setbookstatus1("Accepted")
        else if (data.data.confirmation_status === 3)
          setbookstatus1("Cancelled")
        setdestination(data.data.destination)
        setfnumber(data.data.flight_number)
        //settraveltype(data.data.)
        setdspslhrs(data.data.disposal_hours)
        if (data.data.pickup_type === "1") {
          setbooktype("Airport Arrival")
        } else if (data.data.pickup_type === "2") {
          setbooktype("Airport Departure")
        } else if (data.data.pickup_type === "3") {
          setbooktype("Point To Point(Transfer)")
        } else if (data.data.pickup_type === "4") {
          setbooktype("Singapore Disposal")
        } else {
          setbooktype("Malaysia Disposal")
        }
        setconfirmed_time(data.data.confirmation_date)
        //  console.log("Confirmed time",confirmed_time)
        setextrastop(data.data.extra_stop)
        setExtraStops([data.data?.extra_stop_1 || '', data.data?.extra_stop_2 || '' ]);
        setpickup(data.data.pickup_location)
        setdrop(data.data.dropoff_location)
        settraveldate(getDate(data.data.date))
        settraveltime(data.data.time)
        sethotel(data.data.hotel_name)
        //setnumber(data.data.)
        if (data.data.ride_status === "1")
          setridestatus1("Not Yet")
        else {
          setridestatus1("Completed")
        }
        setcarbrand(data.data.car_info.car_brand)
        setdrivername(data.data.driver_name)
        // setdriverId(data.data.driver_id)
        // console.log(driverId,"---------DRIVER ID----------",drivername)
        // //const number = getDriverDetails(driverId);
        // console.log(drivername, "DRIVER NUMBER")
        setbook_info(data.data.booking_date)
        setairportarrival(data.data.payment_info.arrival_charge)
        setairportdeparture(data.data.payment_info.departure_charge)
        // setptp(data.data.)
        setsingaporedspsl(data.data.payment_info.disposal_charge)
        setmalaysiadspsl(data.data.payment_info.disposal_charge)
        setextra(data.data.payment_info.extra_stop_charge)
        setExtrahrs(data.data.extra_disposal_hours)
        setExtraDisposalHours(data.data.payment_info.extra_disposal_charge)
        setmidnight(data.data.payment_info.midnight_charge)
        setwaiting(data.data.payment_info.waiting_minutes_charge)
        if (data.data.hotel_payment === 0)
          sethpayment("Unpaid")
        else {
          sethpayment("Paid")
        }
        setremarks(data.data.hotel_payment_remark)
        if (data.data.driver_payment === 0) {
          setdpayment("Unpaid")
        }
        else {
          setdpayment("Paid")
        }
        setdremarks(data.data.driver_payment_remark)
      });
  }

  const [selectdId, setSelectedId] = useState("");

  const findDetails = (selectdId) => {
    //  console.log("selected id",selectdId);
    const bookingDetails = filteredItems.find((bookingDetails) => bookingDetails._id === selectdId);
    //  console.log("Booking details:", bookingDetails);
    if (bookingDetails) {
      return bookingDetails;
    }
    return null;
  }



  const [shareid, setshareid] = useState()
  const shareArray = findDetails(shareid);
  //console.log(shareArray,"THE DETAILS OF SHARE BOOKING")
  // const handleShareBooking = (id) => {
  //     setshareid(id)
  //     console.log("Share details:",shareArray)
  //     console.log("Share id:",shareid)
  //     setIsBookingID(false)
  //     setShowViewBooking(false)
  //     setPaymentConfirmation(false)
  //     setBookingConfirmation(false)
  //     setSideBarTile('Share Booking')
  //     setShowSideBar(true)
  //     setShowShareBooking(true)
  // }
  const [BookingId, setBookingId] = useState()
  const handleShareBooking = (rowId, bookid) => {
    setshareid(rowId)
    setViewId(rowId)
    setShowSideBar(true)
    setIsBookingID(false)
    setShowShareBooking(true)
    setShowViewBooking(false)
    setPaymentConfirmation(false)
    setBookingConfirmation(false)

    fetch(config.baseUrl + 'api/bookingid/' + rowId)
      .then((response) => response.json())
      .then((data) => {
        //  console.log(data.data,"11111111---------");
        setData(data.data);
        setpassengername(data.data.passenger_name)
        setpassengernumber(data.data.passenger_contact_number)
        setcarmodel(data.data.car_info.car_model)
        setpassengerSeat(data.data.number_of_passengers.adult);
        setbabyseat(data.data.number_of_passengers.child)
        setBookingId(data.data.booking_id)
        setluggage(data.data.number_of_luggage)
        if (data.data.confirmation_status === 1)
          setbookstatus1("Requested")
        else if (data.data.confirmation_status === 2)
          setbookstatus1("Accepted")
        else if (data.data.confirmation_status === 3)
          setbookstatus1("Cancelled")
        setdestination(data.data.destination)
        setfnumber(data.data.flight_number)
        //settraveltype(data.data.)
        if (data.data.pickup_type === "1") {
          setbooktype("Airport Arrival")
        } else if (data.data.pickup_type === "2") {
          setbooktype("Airport Departure")
        } else if (data.data.pickup_type === "3") {
          setbooktype("Point To Point(Transfer)")
        } else if (data.data.pickup_type === "4") {
          setbooktype("Singapore Disposal")
        } else {
          setbooktype("Malaysia Disposal")
        }
        setdspslhrs(data.data.disposal_hours)
        setconfirmed_time(data.data.confirmation_date)
        //  console.log("Confirmed time",confirmed_time)
        setextrastop(data.data.extra_stop)
        setExtraStops([data.data?.extra_stop_1 || '', data.data?.extra_stop_2 || '' ]);
        setpickup(data.data.pickup_location)
        setdrop(data.data.dropoff_location)
        settraveldate(getDate(data.data.date))
        settraveltime(data.data.time)
        sethotel(data.data.hotel_name)
        //setnumber(data.data.)
        if (data.data.ride_status === "1")
          setridestatus1("Not Yet")
        else {
          setridestatus1("Completed")
        }
        setcarbrand(data.data.car_info.car_brand)
        setdrivername(data.data.driver_name)
        // setdriverId(data.data.driver_id)
        // console.log(driverId,"---------DRIVER ID----------",drivername)
        // //const number = getDriverDetails(driverId);
        //  console.log(drivername, "DRIVER NUMBER")
        setbook_info(data.data.booking_date)
        setairportarrival(data.data.payment_info.arrival_charge)
        setairportdeparture(data.data.payment_info.departure_charge)
        // setptp(data.data.)
        setsingaporedspsl(data.data.payment_info.disposal_charge)
        setmalaysiadspsl(data.data.payment_info.disposal_charge)
        setextra(data.data.payment_info.extra_stop_charge)
        setExtraDisposalHours(data.data.payment_info.extra_disposal_charge)
        setmidnight(data.data.payment_info.midnight_charge)
        setwaiting(data.data.payment_info.waiting_minutes_charge)
        if (data.data.hotel_payment === 0)
          sethpayment("Unpaid")
        else {
          sethpayment("Paid")
        }
        setremarks(data.data.hotel_payment_remark)
        if (data.data.driver_payment === 0) {
          setdpayment("Unpaid")
        }
        else {
          setdpayment("Paid")
        }
        setdremarks(data.data.driver_payment_remark)
      });
  }

  // console.log("------SELECTED FROM CHECKBOX",selectedRowId)
  const array = findDetails(selectdId);
  const viewArray = findDetails(viewId);

  const handleBookingConfirmation = (id) => {
    //  console.log("selectedid34",id)
    setSelectedId(id);
    setShowSideBar(true)
    setIsBookingID(false)
    setShowShareBooking(false)
    setShowViewBooking(false)
    setPaymentConfirmation(false)
    setBookingConfirmation(true)
    setSideBarTile('Booking Confirmation')
  }
  const handleCloseSidebar = () => {
    setShowSideBar(false)
  }
  document.title = "DB LIMO - All Booking";
  const isCheckboxSelected = selectedRowId.length > 0;
  const markPay = findDetails(selectedRowId[0])
  // console.log(markPay,"------DETAILS FOR PAYMENT")

  const [bookingCount2, setBookingCount2] = useState("");
  const setBookingCount1 = (setBookingCount1) => {
    setBookingCount2(setBookingCount1)
  }
  const hanleSelectWidget = (id) => {
    setPageNumber(1);
    /*if(id === 1) {
      setPickupType(0);
    }*/
    setWidgetId(id);
  }

  const handleSelectPickUpType = (e) => {
    setPageNumber(1);
    setPickupType(e.target.value)
  }

  const handleCancelBooking = async () => {
    try {
      let res = await fetch(config.baseUrl + "api/cancel_booking/"+ selectedBooking._id , {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(selectedBooking)
      }).then(res => res.ok ? res.json() : Promise.reject(res));
      console.log(res);
      setIsCancelModalOpen(false);
      fetchBooking();
    } catch (error) {
      console.log(error);
      setIsCancelModalOpen(false);
    }
  }

  const handleRideFilter = (e) => {
    setRideFilter(Number(e.target.value));
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {userType !== "1" ? (
            <Row>
              <Col xxl={12}>
                <Section setBookingCount1={setBookingCount1} title="All Booking" pageTitle="Home" />
                <div>
                </div>
              </Col>
            </Row>
          ) : ""}

          {userType === "1" ? <BreadCrumb title="All Booking" pageTitle="Home"></BreadCrumb> : ""}
          <BookingWidgets bookingDetails={bookingcount} totalcount={totalcount} setSelectedWidget={hanleSelectWidget} widgetId={widgetId} />
          <Card>
            <CardBody className="p-0">
              <Row>
                <Col>
                  {showAlert && showstatus && (
                    <UncontrolledAlert color="danger" onClose={() => setShowstatus(false)}>
                      Please select atleast one Booking before marking payment
                    </UncontrolledAlert>
                  )}
                  <DataTable
                    title={<h5 className="mb-0">All Booking</h5>}
                    className="custom-datatable "
                    columns={columnsToDisplay}
                    data={filteredItems}
                    sortIcon={<span className="custom-sort-img"><i className='bx bxs-sort-alt custom-sort-icon'></i></span>}
                    pagination
                    paginationServer={true}
                    paginationTotalRows={totalcount}
                    onChangeRowsPerPage={handlePerRowsChange}
                    paginationDefaultPage={pageNumber}
                    onChangePage={setPageNumber}
                    paginationComponentOptions={paginationComponentOptions}
                    paginationRowsPerPageOptions={["10", "20", "40"]}
                    // selectableRows={
                    //     filteredItems.every(item => item.hotel_payment === '1' && item.driver_payment === '1')
                    //       ? false
                    //       : true
                    // }
                    // selectableRows={true}
                    onRowClicked={handleRowClicked}
                    //onRowSelected={(data)=>console.log("hello checkbox")}

                    selectableRowsComponent={TableCheckBox}
                    noDataComponent={<NoResultData />}
                    // selectableRowsHighlight={true}
                    selectableRowsVisibleOnly={false}
                    subHeaderAlign="left"
                    contextMenu={true}
                    persistTableHead

                    actions={
                      <Row className="g-4 align-items-start align-items-lg-center flex-column flex-row-lg">

                        <Col md={12} lg="auto" className="ms-lg-auto no-paading-mobile">
                          <div className="hstack gap-2 flex-lg-row flex-wrap">
                            <select name="pickup_type" className="form-select table-dropdown" value={rideFilter} onChange={handleRideFilter}>
                              <option value="0">All Rides</option>
                              <option value="1">Upcoming Rides</option>
                              <option value="2">Existing Rides</option>
                            </select>
                            {subHeaderComponent}
                            <select name="pickup_type" className="form-select table-dropdown" value={pickupType} onChange={handleSelectPickUpType}>
                              <option defaultValue="all" value="0">All Service Type</option>
                              <option value="1">Airport Arrival</option>
                              <option value="2">Airport Departure</option>
                              <option value="3">Point to Point(Transfer)</option>
                              <option value="4">Singapore Disposal</option>
                              <option value="5">Malaysia Disposal</option>
                            </select>
                            {/* <select className="form-select table-dropdown">
                                                        <option defaultValue="all">Select Rides</option>
                    <option value="Buy">Completed </option>
                    <option value="Sell">Notyet</option>
                                                        </select> */}


                            {userType !== "1" && (
                              <Link to="/add-booking" type="button" className="btn btn-success w-100-mobile">
                                <i className="ri-add-line align-bottom me-1"></i> Add New Booking
                              </Link>
                            )}
                            {/* {userType !== "2" && userType !== "3" && (<button
                              type="button"
                              className="btn btn-primary w-100-mobile"
                              //disabled={!isCheckboxSelected}
                              onClick={() => handleMarkPayment(selectedRowId)}
                            >
                              Mark Payment
                            </button>)} */}
                          </div>
                        </Col>
                      </Row>
                    }
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
      <Offcanvas
        direction="end"
        isOpen={showSideBar}
        id="offcanvasExample"
        toggle={handleCloseSidebar}
        className="offcanvas-custom-style">
        <OffcanvasHeader className="bg-light" toggle={handleCloseSidebar}>
          {sideBarTile} {showBookingdID && <span> {array?.booking_id}</span>} {showBookingConfirmation && <span> {array?.booking_id}</span>}
        </OffcanvasHeader>
        <React.Fragment>
          {showBookingdID && <BookingInfo />}
          {showShareBooking && <ShareBooking
            setShowSideBar={setShowSideBar}
            data={data}
            passengerSeat={passengerSeat}
            BookingId={BookingId}
            babyseat={babyseat}
            luggage={luggage}
            bookstatus={bookstatus1}
            destination={destination}
            fnumber={fnumber}
            pickup={pickup}
            drop={drop}
            traveldate={traveldate}
            booktype={booktype}
            traveltime={traveltime}
            ridestatus={ridestatus1}
            hotel={hotel}
            extrastop={extrastop}
            extraStops={extraStops}
            carbrand={carbrand}
            drivername={drivername}
            drivernumber={drivernumber}
            book_info={book_info}
            airportarrival={airportarrival}
            airportdeparture={airportdeparture}
            singaporedspsl={singaporedspsl}
            malaysiadspsl={malaysiadspsl}
            shareArray={shareArray}
            ptp={ptp}
            extra={extra}
            midnight={midnight}
            waiting={waiting}
            hpayment={hpayment}
            remarks={remarks}
            dpayment={dpayment}
            dremarks={dremarks}
            viewArray={viewArray}
            passengername={passengername}
            passengernumber={passengernumber}
            drivers={drivers}
            carNumber={carNumber}
            carmodel={carmodel}
            dspslhrs={dspslhrs}
            extraDisposalHours={extraDisposalHours}
            confirmed_time={confirmed_time}
            setSideBarTile={setSideBarTile}
          />}
          {showpaymentConfirmation && <PaymentConfirmation fetchBooking={fetchBooking} array={array} markpay={markPay} setSelectedRowId={setSelectedRowId} setChecked={setChecked} setChecked1={setChecked1} selectedRowId={selectedRowId} setShowSideBar={setShowSideBar} setPaymentConfirmation={setPaymentConfirmation} />}
          {showViewBooking && <ShareViewBooking
            total={total}
            passengerSeat={passengerSeat}
            data={data}
            ID={ID}
            babyseat={babyseat}
            luggage={luggage}
            bookstatus={bookstatus1}
            destination={destination}
            fnumber={fnumber}
            pickup={pickup}
            drop={drop}
            traveldate={traveldate}
            booktype={booktype}
            traveltime={traveltime}
            midHours={midHours}
            ridestatus={ridestatus1}
            hotel={hotel}
            extrahrs={extrahrs}
            extrastop={extrastop}
            extraStops={extraStops}
            carbrand={carbrand}
            drivername={drivername}
            drivernumber={drivernumber}
            drivers={drivers}
            book_info={book_info}
            airportarrival={airportarrival}
            airportdeparture={airportdeparture}
            singaporedspsl={singaporedspsl}
            malaysiadspsl={malaysiadspsl}
            shareArray={shareArray}
            ptp={ptp}
            extra={extra}
            midnight={midnight}
            waiting={waiting}
            hpayment={hpayment}
            remarks={remarks}
            dpayment={dpayment}
            dremarks={dremarks}
            setShowSideBar={setShowSideBar}
            viewArray={viewArray}
            passengername={passengername}
            passengernumber={passengernumber}
            carmodel={carmodel}
            dspslhrs={dspslhrs}
            extraDisposalHours={extraDisposalHours}
            confirmed_time={confirmed_time}
            setSideBarTile={setSideBarTile}
            bId={bId}
          />}
          {showBookingConfirmation && <BookingConfirmation array={array}
            fetchBooking={fetchBooking}
            setShowSideBar={setShowSideBar} />}
        </React.Fragment>
      </Offcanvas>
      <CancelModal
        show={isCancelModalOpen}
        onCancelClick={handleCancelBooking}
        onCloseClick={() => setIsCancelModalOpen(false)}
      />
    </React.Fragment>
  )
}

export default AllBooking