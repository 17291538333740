import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { upCommingRide } from '../../common/data';
import config from "../../common/config";
import { useState,useEffect } from "react"

const UpcomingStatus = () => {

    const [selectedOption, setSelectedOption] = useState('All Ride');
    const [rideData,setRideData]=useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const itemsPerPage = 5;
    const [totalItems,setTotalItems]=useState("");
    const [totalPages,setTotalPages]=useState("") 
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(0);


    const handleOptionChange = (option) => {
        setSelectedOption(option);
      };

      useEffect(() => {
        fetchRideStatus();

    }, [selectedOption]);


    const fetchRideStatus = async () =>{
        let res=  await fetch(config.baseUrl + "api/dashboard/ride_status", {
            method: "POST",
    
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                page: pageNumber,
                limit: "all",
                days:getDays(),
            }),
        });
        let resJson = await res.json();
                if (res.status === 200) {
                  // console.log(resJson.data,"Retrieved Data");
                  //const sortedData = resJson.data.sort((a, b) => a.booking_id.localeCompare(b.booking_id));

                   setRideData(resJson.data)
                   if(resJson.data.length > 0){
                    setTotalItems(resJson.totalCount)
                   }
                  else{
                    setTotalItems(0)
                  }
                   setTotalPages(Math.ceil(resJson.totalCount / itemsPerPage));

                } else {
                   
                    console.log("Data Not retrieved")
                }
    }


    const getDays =()=>{
        if(selectedOption === "1 Day Ride"){
            return 1
        }else if(selectedOption === "3 Days Ride"){
            return 3
        }else if(selectedOption === "5 Days Ride"){
            return 5
        }else if(selectedOption === "7 Days Ride"){
            return 7
        }else{
            return "all"
        }
    }


    function addLeadingZero(number) {
        return number < 10 ? `0${number}` : number.toString();
      }


    const getDate= (date) => {
       // console.log("date in api:",date)
        const [year, month, day] = date.split('-');
        const dateObject = new Date(year, month - 1, day);
        const monthName = dateObject.toLocaleString('default', { month: 'short' });
        const date1= parseInt(day, 10);
        const date2=addLeadingZero(date1); 
        const formattedDate = `${date2} ${monthName} ${year}`;
      //  console.log("formatted Date:",formattedDate)
        return formattedDate;
       
        }


        statusIdentification(rideData);
        function statusIdentification(array) {
          for (var j = 0; j < array.length; j++) {
                   
             array[j].statusClass='success';
                   
         }
     
      }
      

      const rideData1 = Object.values(rideData);
      const filteredData = rideData1.filter((obj) => obj.confirmation_status===2);


      const today = new Date();
      const upcomingData = filteredData.filter((item) => {
        const targetDate = new Date(item.date);
        return targetDate > today;
      });
    

    const getDifference = (date)=>{

        const today = new Date();
       const targetDate = new Date(date);
        const differenceInMs = targetDate - today;
      
        // Step 4: Convert the difference from milliseconds to days
        const oneDayInMs = 24 * 60 * 60 * 1000; // Number of milliseconds in one day
        const differenceInDays = Math.floor(differenceInMs / oneDayInMs);

        return differenceInDays;
    }

    useEffect(() => {
        const startIdx = (pageNumber - 1) * itemsPerPage;
       
        const endIdx = Math.min(startIdx + itemsPerPage, totalItems);
        setStartIndex(startIdx);
        setEndIndex(endIdx);

    }, [rideData,pageNumber,totalItems]);


    const recordsToShow = rideData.slice(startIndex, endIndex);
    const handlePageChange = async (newPage) => {
        setPageNumber(newPage);
        fetchRideStatus();
      };


    return (
        <React.Fragment>
            <Col xl={5}>
                <Card>
                    <CardHeader className="card-header align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1 py-1">Upcoming Ride Status</h4>
                        <div className="flex-shrink-0">
                            <UncontrolledDropdown className="card-header-dropdown">
                                <DropdownToggle className="text-reset dropdown-btn" tag="a" role="button">
                                    <span className="text-muted">{selectedOption} <i className="mdi mdi-chevron-down ms-1"></i></span>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                    <DropdownItem onClick={() => handleOptionChange('All Ride')}>All Ride</DropdownItem>
                                    <DropdownItem onClick={() => handleOptionChange('1 Day Ride')}>1 Day Ride </DropdownItem>
                                    <DropdownItem onClick={() => handleOptionChange('3 Days Ride')}>3 Days Ride</DropdownItem>
                                    <DropdownItem onClick={() => handleOptionChange('5 Days Ride')}>5 Days Ride</DropdownItem>
                                    <DropdownItem onClick={() => handleOptionChange('7 Days Ride')}>7 Days Ride</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <div className="table-responsive table-card">
                            <table className="table table-nowrap table-centered align-middle">
                                <thead className="table-light text-muted">
                                    <tr>
                                        <th scope="col">Assign Driver Name</th>
                                        <th scope="col">Boooking ID</th>
                                        <th scope="col">Ride Date</th>
                                        <th scope="col">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(recordsToShow || []).map((item, key) => (<tr key={key}>
                                       
                                        <td>
                                            <div className="d-inline-block">
                                        {/* <img src={item.img} alt="" className="avatar-xxs rounded-circle me-1" /> */}
                                            {item.driver_name}
                                            </div>
                                            </td>
                                        <td>
                                            <p className='text-secondary'>
                                            {item.booking_id}
                                            </p>
                                            </td>
                                        <td className='text-muted'>
                                            
                                                {getDate(item.date)}
                                        </td>
                                        <td><span className={"badge badge-soft-" + item.statusClass}>{getDifference(item.date)} Days to Go</span></td>
                                    </tr>))}
                                </tbody>
                            </table>
                        </div>
                        <div className="align-items-center mt-xl-3 mt-4 justify-content-between d-flex">
                                    <div className="flex-shrink-0">
                                    <div className="text-muted">
                                        Showing <span className="fw-semibold">{endIndex - startIndex}</span> of{' '}
                                        <span className="fw-semibold">{totalItems}</span> Results
                               
                                    </div>
                                    </div>
                                    <ul className="pagination pagination-separated pagination-sm mb-0">
                                        <li className={`page-item ${pageNumber === 1 ? 'disabled' : ''}`}>
                                            <button
                                            className="page-link"
                                            onClick={() => setPageNumber(pageNumber - 1)}
                                            disabled={pageNumber === 1}
                                            >
                                            ←
                                            </button>
                                        </li>
                                        {Array.from({ length: Math.ceil(totalPages) }, (_, index)  => (
                                                <li key={index} className={`page-item ${pageNumber === index + 1 ? 'active' : ''}`}>
                                                    <button className="page-link" onClick={() => handlePageChange(index + 1)}>
                                                        {index + 1}
                                                    </button>
                                                </li>
                                            ))}
                                        <li className={`page-item ${pageNumber === Math.ceil(rideData.length / itemsPerPage) ? 'disabled' : ''}`}>
                                            <button
                                            className="page-link"
                                            onClick={() => setPageNumber(pageNumber + 1)}
                                            disabled={pageNumber === Math.ceil(rideData.length / itemsPerPage)}
                                            >
                                            →
                                            </button>
                                        </li>
                                        </ul>
                                </div>
                                
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
};

export default UpcomingStatus;