import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { BookingStatusData } from '../../common/data';
import config from "../../common/config";
import { useState,useEffect } from "react"

const BookingStatus = () => {

    const [selectedOption, setSelectedOption] = useState('All Status');
    const [bookingData,setBookingData]=useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const itemsPerPage = 5;
    const [totalItems,setTotalItems]=useState("");
    const [totalPages,setTotalPages]=useState("") 
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(0);
    // const startIndex = (pageNumber - 1) * itemsPerPage;
    // const endIndex = Math.min(startIndex + itemsPerPage, totalItems);

    const handleOptionChange = (option) => {
        setSelectedOption(option);
      };


    useEffect(() => {
        fetchBookingStatus();

    }, [selectedOption]);

    const fetchBookingStatus = async () =>{
        let res=  await fetch(config.baseUrl + "api/dashboard/booking_status", {
            method: "POST",
    
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                page: pageNumber,
                limit: "all",
                confirmation_status:getStatus(),
            }),
        });
        let resJson = await res.json();
                if (res.status === 200) {
                   //console.log(resJson,"DATA----------");
                   const sortedData = resJson.data.sort((a, b) => a.booking_id.localeCompare(b.booking_id));

                   setBookingData(sortedData)
                   if(resJson.data.length > 0){
                    setTotalItems(resJson.totalCount)
                   }
                  else{
                    setTotalItems(0)
                  }
                   setTotalPages(Math.ceil(resJson.totalCount / itemsPerPage));

                } else {
                   
                    console.log("Data Not retrieved")
                }
    }



    const getStatus =()=>{
        if(selectedOption === "Requested"){
            return 1
        }else if(selectedOption === "Accepted"){
            return 2
        }else if(selectedOption === "Cancelled"){
            return 3
        }else{
            return "all"
        }
    }

    const getType=(type)=>{
        if(type==="1"|| type==="Arrival"){
            return "Airport Arrival";
        }else if(type==="2"){
            return "Airport Departure";
        }else if(type==="3"){
            return "Point To Point(Transfer)";
        }else if(type==="4"){
            return "Singapore Disposal";
        }else if(type==="5"){
            return "Malaysia Disposal";
        }
      }

      statusIdentification(bookingData);
      function statusIdentification(array) {
        for (var j = 0; j < array.length; j++) {
                  if(array[j].confirmation_status===1){
                      array[j].confirmation_status1 = 'Requested';
            array[j].badgeClass='warning';
                  }
                 else if(array[j].confirmation_status===2){
                    array[j].confirmation_status1 = 'Accepted';
          array[j].badgeClass='success';
                }
                  
       else{  array[j].confirmation_status1 = 'Cancelled';
             array[j].badgeClass= 'danger';
         

        }
       }
   //console.log(bookingData,"After Including Status");
    }

    useEffect(() => {
        const startIdx = (pageNumber - 1) * itemsPerPage;
        const endIdx = Math.min(startIdx + itemsPerPage, totalItems);
        setStartIndex(startIdx);
        setEndIndex(endIdx);

    }, [bookingData,pageNumber]);
                      


    const recordsToShow = bookingData.slice(startIndex, endIndex);
    const handlePageChange = async (newPage) => {
        setPageNumber(newPage);
        fetchBookingStatus();
      };


      
    return (
        <React.Fragment>
            <Col xl={7}>
                <Card>
                    <CardHeader className="d-flex align-items-center">
                        <h4 className="card-title flex-grow-1 mb-0">Booking Status</h4>
                        <div className="flex-shrink-0">
                            <UncontrolledDropdown className="card-header-dropdown">
                                <DropdownToggle className="text-reset dropdown-btn" tag="a" role="button">
                                    <span className="text-muted">{selectedOption} <i className="mdi mdi-chevron-down ms-1"></i></span>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                    <DropdownItem onClick={() => handleOptionChange('All Status')}>All Status</DropdownItem>
                                    <DropdownItem onClick={() => handleOptionChange('Requested')}>Requested </DropdownItem>
                                    <DropdownItem onClick={() => handleOptionChange('Accepted')}>Accepted</DropdownItem>
                                    <DropdownItem onClick={() => handleOptionChange('Cancelled')}>Cancelled</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <div className="table-responsive table-card">
                            <table className="table table-nowrap table-centered align-middle">
                                <thead className="bg-light text-muted">
                                    <tr>
                                        <th scope="col">Booking ID</th>
                                        <th scope="col">Hotel Name</th>
                                        <th scope="col">Travel Info</th>
                                        <th scope="col">Car Type</th>
                                        <th scope="col">Booking Date & Time</th>
                                        <th scope="col">Status</th>

                                    </tr>
                                </thead>

                                <tbody>
                                    {(recordsToShow  || []).map((item, key) => (<tr key={key}>
                                        <td className="fw-medium">
                                            <p className='text-secondary' >{item.booking_id}</p>
                                        </td>
                                        <td>
                                            {/* <img src={item.hotelImg} className="avatar-xxs rounded-circle me-1" alt="" /> */}
                                            <div className="text-reset">{item.hotel_name}</div>
                                        </td>
                                        <td >
                                            <div className='text-muted'>
                                                {getType(item.pickup_type)}
                                            </div>
                                        </td>
                                        <td>
                                        <div className='text-muted'>
                                            {item.car_info.car_brand}-{item.car_info.car_model}
                                            </div>
                                        </td>
                                        <td>
                                        <div className='text-muted'>
                                            {item.booking_date}
                                            </div>
                                            </td>
                                        <td><span className={"badge badge-soft-" + item.badgeClass}>{item.confirmation_status1}</span></td>

                                    </tr>))}
                                </tbody>
                            </table>
                        </div>
                        {/* <div className="align-items-center mt-xl-3 mt-4 justify-content-between d-flex">
                            <div className="flex-shrink-0">
                                <div className="text-muted">Showing <span className="fw-semibold">5</span> of <span className="fw-semibold">25</span> Results
                                </div>
                            </div>
                            <ul className="pagination pagination-separated pagination-sm mb-0">
                                <li className="page-item disabled">
                                    <Link to="#" className="page-link">←</Link>
                                </li>
                                <li className="page-item">
                                    <Link to="#" className="page-link">1</Link>
                                </li>
                                <li className="page-item active">
                                    <Link to="#" className="page-link">2</Link>
                                </li>
                                <li className="page-item">
                                    <Link to="#" className="page-link">3</Link>
                                </li>
                                <li className="page-item">
                                    <Link to="#" className="page-link">→</Link>
                                </li>
                            </ul>
                        </div> */}
                              <div className="align-items-center mt-xl-3 mt-4 justify-content-between d-flex">
                                    <div className="flex-shrink-0">
                                    <div className="text-muted">
                                        Showing <span className="fw-semibold">{endIndex - startIndex}</span> of{' '}
                                        <span className="fw-semibold">{totalItems}</span> Results
                                    </div>
                                    </div>
                                    <ul className="pagination pagination-separated pagination-sm mb-0">
                                        <li className={`page-item ${pageNumber === 1 ? 'disabled' : ''}`}>
                                            <button
                                            className="page-link"
                                            onClick={() => setPageNumber(pageNumber - 1)}
                                            disabled={pageNumber === 1}
                                            >
                                            ←
                                            </button>
                                        </li>
                                        {Array.from({ length: Math.ceil(totalPages) }, (_, index)  => (
                                                <li key={index} className={`page-item ${pageNumber === index + 1 ? 'active' : ''}`}>
                                                    <button className="page-link" onClick={() => handlePageChange(index + 1)}>
                                                        {index + 1}
                                                    </button>
                                                </li>
                                            ))}
                                        <li className={`page-item ${pageNumber === Math.ceil(bookingData.length / itemsPerPage) ? 'disabled' : ''}`}>
                                            <button
                                            className="page-link"
                                            onClick={() => setPageNumber(pageNumber + 1)}
                                            disabled={pageNumber === Math.ceil(bookingData.length / itemsPerPage)}
                                            >
                                            →
                                            </button>
                                        </li>
                                        </ul>
                                </div>
                                
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
};

export default BookingStatus;