import React from "react"
import { Card, CardBody, CardHeader, Col, NavLink, Container, Form, Input, Label, Nav, NavItem, Row, TabContent, TabPane, UncontrolledAlert } from "reactstrap"
import BreadCrumb from "../../../Components/Common/BreadCrumb"
import Select from "react-select";
import { TravelType, TravelType2, countryCodes, executiveNamesList } from "./data";
import Flatpickr from "react-flatpickr";
import { Link } from "react-router-dom";
import BookingInfoSideBar from "./booking-info";
import { useState, useEffect } from "react"
import CarsInfoList from "./cars-info";
import classnames from "classnames";
import { dispose } from "echarts";
import { useLocation } from "react-router-dom";
import LocationAutoComplete from "../../../Components/LocationAutoComplete";
import moment from "moment";
const CreateBooking = () => {
    const [extraHours, setExtraHours] = React.useState(0);
    const [waitingCharge, setWaitingCharge] = React.useState(0)
    const [extraStop, setExtraStop] = React.useState(0)
    const [babySeats, setBabySeats] = React.useState(0)
    const [airDepExtraStop, setAirDepExtraStop] = React.useState(0)
    const [pointtotPointExtraStop, setPointtotPointExtraStop] = React.useState(0)
    const [fromDisposel, setFromDisposal] = React.useState(3)
    const [toDisposel, settoDisposal] = React.useState(4)
    const [passengerName, setPassengerName] = useState("")
    const [number, setNumber] = useState("")
    const [countryCode, setCountryCode] = useState("+65")
    const [flightNo, setFlightNo] = useState("")
    const [airportName1, setAirportName1] = useState("")
    const [selectedDateTime, setSelectedDateTime] = useState(null)
    const [pickupLocation, setPickupLocation] = useState("")
    const [dropLocation, setDropLocation] = useState("")
    const [editBookingId, setEditBookingId] = useState("")
    const [dateFormat, setDateFormat] = useState("")
    const [hotelPaid, isHotelPaid] = useState(0)
    const [remarks, setRemarks] = useState("")
    const [hotelRemarks, setHotelremarks] = useState("")
    const [driverPaid, setDriverPaid] = useState()
    const [driverRemarks, setDriverRemarks] = useState("")
    const [isEdit, setIsEdit] = useState(false)
    const [carId, setCarId] = useState("")

    const [firstCall, setFirstCall] = useState("")
    const [secondCall, setSecondCall] = useState("")

    const [bookingHistory, setBookingHistory] = useState([])
    const [rideStatus, setRideStatus] = useState()
    const [confirmStatus, setConfirmStatus] = useState()
    const [editDate, setEditDate] = useState()
    const [editTime, setEditTime] = useState()
    const [highlightedDate, setHighlightedDate] = useState(null);

    const [dropDestination, setDropDestination] = useState("Airport Singapore")
    const [selectedCar, setSelectedCar] = useState("");
    const [date, setDate] = useState("")
    const [Time, setTime] = useState("")
    const [brand, setBrand] = useState("")
    const [error, setError] = useState({
        status: false,
        msg: "",
        type: ""
    })
    const location = useLocation();
    const editDetails = location.state?.editDetails || null;
    const hotelname = localStorage.getItem("hotelname")
    const [destination, setDestination] = useState(hotelname || "");
    const [destination1, setDestination1] = useState(hotelname || "");
    const onchange = (e) => {
        const { value } = e.target;
        setDestination(value);
    };
    const onchange1 = (e) => {
        const { value } = e.target;
        setDestination1(value);
    };


    const airportName = [
        { label: "Changi International Airport", value: "Changi International Airport" },
        { label: "Seletar Airport", value: "Seletar Airport" },
    ];



    const carBrandAndModel = (car) => {
        setSelectedCar(car);
    };

    const handleDateTimeChange = (selectedDates) => {

        if (selectedDates.length > 0) {
            setSelectedDateTime(selectedDates[0]);
        } else {
            setSelectedDateTime(null);
        }
        // console.log(selectedDateTime,"AFTER SELECTING DATE")
        const date = getDate();
        setDate(date)
        const Time = getTime();
        setTime(Time)

    };
    function addLeadingZero(number) {
        return number < 10 ? `0${number}` : number.toString();
    }

    const getDate = () => {
        if (selectedDateTime) {
            // const singaporeTime = new Date(selectedDateTime.toLocaleString('en-US', { timeZone: 'Asia/Singapore' }));
            // console.log(singaporeTime,selectedDateTime,"DATE AFTER CHANGED")
            const day = selectedDateTime.getDate();
            const monthIndex = selectedDateTime.getMonth();
            const year = selectedDateTime.getFullYear();
            const months = [
                "Jan", "Feb", "Mar", "Apr", "May", "Jun",
                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
            ];

            const month = addLeadingZero(monthIndex + 1);
            const date = addLeadingZero(day);
            const formattedDate = `${date} ${months[monthIndex]}, ${year}`;
            const formattedDate1 = `${year}-${month}-${date}`;
            setDateFormat(formattedDate1)
            return formattedDate;
        } else if (editDetails) {
            //  console.log("INSIDE GET DATE")
            let date = editDetails.date;
            //  console.log(date,"INSIDE EDIT DETAILS DATE FUNCTION")
            const inputDate = new Date(date);
            //  console.log(inputDate,"INSIDE EDIT DETAILS DATE FUNCTION INPUT DATE")
            let day = inputDate.getDate();
            const monthIndex = inputDate.getMonth();
            const year = inputDate.getFullYear();
            const months = [
                "Jan", "Feb", "Mar", "Apr", "May", "Jun",
                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
            ];

            const month = addLeadingZero(monthIndex + 1);
            day = addLeadingZero(day);
            // console.log(day,monthIndex,year,month,"AFTER SEPARATING THE DATE FROM INPUT")
            const formattedDate = `${day} ${months[monthIndex]}, ${year}`;
            //  console.log(formattedDate,"THE DATE WHICH IS RETURNED")
            const formattedDate1 = `${year}-${month}-${day}`;
            //  console.log(formattedDate1,"THE DATE WHICH IS RETURNED1")
            setDateFormat(formattedDate1)
            return formattedDate;
        }
        return "";
    };
    const handlePassengerNameChange = (event) => {
        const inputValue = event.target.value;
        const updatedValue = inputValue.replace(/[^a-zA-Z\s]/g, ''); // Remove non-alphabetic and non-space characters

        setPassengerName(updatedValue);
    };
    const handleNumberChange = (e) => {
        const inputNumber = e.target.value;
        // setNumber(e.target.value)
        // const regex = /^[0-9]{0,13}$/;
        const regex = /^[0-9]{0,13}$/;
        if (regex.test(inputNumber)) {
            setNumber(inputNumber);
        } else {
            setError({ status: true, message: "Please Enter Valid WhatsApp Number", type: 'error' });
            setTimeout(() => {
                setError({ status: false, message: '', type: '' });
            }, 3000);
        }
    }

    const handleCallChange = (cb) => (e) => {
        const value = e.target.value;
        const regex = /^[0-9]{0,13}$/;
        if (regex.test(value)) {
            cb(value);
        } else {
            setError({ status: true, message: "Please Enter Valid WhatsApp Number", type: 'error' });
            setTimeout(() => {
                setError({ status: false, message: '', type: '' });
            }, 3000);
        }
    }

    const getTime = () => {
        if (selectedDateTime) {
            const hours = selectedDateTime.getHours();
            const minutes = selectedDateTime.getMinutes();
            const time = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
            //  console.log(time, "THE SELECTED TIME IS")
            return time;
        } else if (editDetails) {
            let time1 = editDetails.time.includes('.') ? editDetails.time.replace('.', ':') : editDetails.time;
            //  console.log(time1, "THE SELECTED TIME IS")
            return time1;
        }
        return "";
    };
    const [stop1, setStop1] = useState('');
    const [stop2, setStop2] = useState('');
    const [stop3, setStop3] = useState('');
    const [stop4, setStop4] = useState('');
    // function countUP(id, prev_data_attr) {
    //     id(prev_data_attr + 1);
    // }

    function countUP(id, prev_data_attr) {
        if (prev_data_attr < 2) { // Limit to a maximum of 2 extra stops
            id(prev_data_attr + 1);
        }
    }

    function countUP1(id, prev_data_attr) {
        id(prev_data_attr + 1);
    }
    function countDown1(id, prev_data_attr) {
        if (prev_data_attr !== 3) {
            id(prev_data_attr - 1);
        }

    }
    function countUP2(id, prev_data_attr) {
        id(prev_data_attr + 1);
    }
    function countDown2(id, prev_data_attr) {
        if (prev_data_attr !== 4) {
            id(prev_data_attr - 1);
        }

    }

    function countDown(id, prev_data_attr) {
        if (prev_data_attr !== 0) {
            id(prev_data_attr - 1);
        }

    }

    function countUPBaby(setBabySeats, babySeats) {
        setBabySeats(babySeats + 1);

    }
    function countDownBaby(setBabySeats, babySeats) {
        if (babySeats !== 0) {
            setBabySeats(babySeats - 1);
        }

    }



    function countUPPass(setExtraHours, extraHours) {

        setExtraHours(extraHours + 1);

    }


    function countDownPass(setExtraHours, extraHours) {
        if (extraHours !== 0) {
            setExtraHours(extraHours - 1);
        }

    }





    const disableToday = (date) => {
        const today = new Date();
        return date.getFullYear() === today.getFullYear() &&
            date.getMonth() === today.getMonth() &&
            date.getDate() === today.getDate();
    };

    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();
    const hours = String(today.getHours()).padStart(2, '0');
    const minutes = String(today.getMinutes()).padStart(2, '0');
    const placeholder1 = `${day}/${month}/${year}-${hours}:${minutes}`;
    //  console.log(placeholder1,"FOR THE DATE PICKER")

    const [allExecutiveName, setAllExecutiveName] = React.useState(null)
    const [isActiveCarChecked, setIsActiveCarChecked] = React.useState(true)
    const [isBookingSuccess, setIsBookingSuccess] = useState(false)
    const [bookingId, setBookingId] = useState("")
    const [isUpdateSuccess, setIsUpdateSuccess] = useState(false)
    const handleExecutiveName = (allExecutiveName) => {
        setAllExecutiveName(allExecutiveName)
    }

    const [Id, setId] = useState(null);

    const success = (success) => {
        if (success) {
            if (isEdit) {
                setIsUpdateSuccess(true)
                return;
            }
            setIsBookingSuccess(true)

        } else {
            setIsBookingSuccess(false)
            if (isEdit) {
                setIsUpdateSuccess(false)
            }
        }

    }
    const [error1, setError1] = useState("")
    const sendError = (sendError) => {
        if (sendError) {
            setError1(sendError)
        } else {
            setError1("")
        }
    }
    const [activeTab, setactiveTab] = React.useState("1")
    const tabNames = ["Airport Arrival", "Airport Departure", "Point to Point (Transfer)", "Singapore Disposal", "Malaysia Disposal"];

    const isFormValid = () => {
        return passengerName && number && extraHours && selectedDateTime;
    };
    const [error2, setError2] = useState("")
    const toggle = (tab) => {
        if (activeTab !== tab) {
            setAirportName1('');
            setDestination(hotelname);
            setFlightNo('');
            setExtraStop(0);
            setStop1('');
            setStop2('');
            setRemarks('');

            setDestination1(hotelname);
            setAirDepExtraStop(0);
            setPickupLocation('')
            setDropLocation('');
            setPointtotPointExtraStop(0);
            setFromDisposal(3);
            settoDisposal(4)

            setactiveTab(tab);
        }



    };


    const getTabName = () => {
        return tabNames[Number(activeTab) - 1];
    };

    const tabName = getTabName();
    document.title = "DB LIMO - Add Booking";
    const bookingid23 = localStorage.getItem("bookingID67")

    useEffect(() => {

        const bookingid23 = localStorage.getItem("bookingID67")
        //console.log(localStorage.getItem("bookingID67"),"mju",)
        if (editDetails) {

            setBookingId(editDetails.booking_id)
            setPassengerName(editDetails.passenger_name)
            setCountryCode(editDetails.country_code);
            setNumber(editDetails.passenger_contact_number)
            setFirstCall(editDetails.first_call)
            setSecondCall(editDetails.second_call)
            setactiveTab(editDetails.pickup_type)
            toggle(activeTab)
            setAirportName1(editDetails.airport_name);
            setPickupLocation(editDetails.pickup_location)
            setFlightNo(editDetails.flight_number)
            setDestination(editDetails.destination)
            setRemarks(editDetails.remark)

            setExtraStop(editDetails.extra_stop)
            setAirDepExtraStop(editDetails.extra_stop);
            setPointtotPointExtraStop(editDetails.extra_stop);
            setStop1(editDetails.extra_stop_1)
            setStop2(editDetails.extra_stop_2)

            setExtraHours(editDetails.number_of_passengers.adult)
            setBabySeats(editDetails.number_of_passengers.child)
            setWaitingCharge(editDetails.number_of_luggage)
            setPickupLocation(editDetails.pickup_location)
            setDropLocation(editDetails.dropoff_location)
            setPointtotPointExtraStop(editDetails.extra_stop)
            setFromDisposal(editDetails.disposal_hours)
            settoDisposal(editDetails.disposal_hours)
            setEditBookingId(editDetails._id)
            isHotelPaid(editDetails.hotel_payment)
            setHotelremarks(editDetails.hotel_payment_remark)
            setDriverPaid(editDetails.driver_payment)
            setDriverRemarks(editDetails.driver_payment_remark)
            if (editDetails.booking_history.length > 0) {
                setBookingHistory(editDetails.booking_history)
            } else {
                setBookingHistory([])
            }

            setIsEdit(true)
            setCarId(editDetails.car_info._id)
            //setDate(editDetails.date)
            //setTime(editDetails.time)
            setRideStatus(editDetails.ride_status)
            setConfirmStatus(editDetails.confirmation_status)
            const formattedDate = formatDate(editDetails.date, editDetails.time);
            // console.log(formattedDate,"------THE DATE FROM EDIT PAGE-----")
            setHighlightedDate(formattedDate);
            setSelectedCar(editDetails.car_info)
            const date1 = getDate();
            setDate(date1)
            const time1 = getTime();
            setTime(time1)
        }

        if (isBookingSuccess) {

            setPassengerName('')
            setNumber("")
            setFlightNo("")
            setExtraHours(0)
            setWaitingCharge(0)
            setExtraStop(0)
            setBabySeats(0)
            setAirDepExtraStop(0)
            setPointtotPointExtraStop(0)
            setFromDisposal(3)
            settoDisposal(4)
            setSelectedDateTime(null)
            setPickupLocation("")
            setDropLocation("")
            setSelectedCar("")
            setCarId("")
            setDateFormat("")
            setDate("")
            setTimeout(() => {
                window.location.reload();
            }, 5000);

        }

    }, [editDetails, isBookingSuccess]);




    const formatDate = (dateString, timeString) => {

        const dateParts = dateString.split("-");
        const month = dateParts[1];
        const day = parseInt(dateParts[2]);
        const year = parseInt(dateParts[0]);

        let [hour, minute] = timeString.includes(':') ? timeString.split(":") : timeString.split(".");
        // console.log(hour,minute,"------333333333333----------")
        hour = parseInt(hour);
        minute = parseInt(minute);

        //console.log(hour,minute,"------2222222----------")
        const formattedDate = new Date(year, month - 1, day, hour, minute);
        //console.log(month,day,year,formattedDate,"111111111111------------")
        return formattedDate;

    };


    const convertMonthToNumber = (month) => {
        const monthNames = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        return monthNames.findIndex((name) => name === month);
    };

    const hasNullValues = bookingHistory.every(item => item !== null);

    const handleAirportName = (airport) => {

        setAirportName1(airport)


    }

    //console.log(airportName1,"SELECTED AIRPORT")
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={isEdit ? 'Edit Booking' : 'Create New Booking'} pageTitle="Home"></BreadCrumb>

                    <Row>
                        <Col md={8} lg={8} xl={9}>
                            <Card>
                                <CardBody>
                                    <div>
                                        <h5 className="mb-0">{isEdit ? 'Edit Booking' : 'Create New Booking'}</h5>
                                        <p className="text-muted mt-0">Fill all information below</p>
                                    </div>
                                    {isBookingSuccess && (
                                        <UncontrolledAlert color="success" onClose={() => setIsBookingSuccess(false)}>Your Booking has been Registered successfully - {Id}</UncontrolledAlert>)}
                                    {!isBookingSuccess && !isEdit && error1.status && (
                                        <UncontrolledAlert color="danger" onClose={() => setIsBookingSuccess(false)}>   {error1.status && (
                                            <div
                                                id="error"
                                                style={{

                                                    textAlign: 'start'
                                                }}
                                            >
                                                <i className="bi bi-exclamation-circle" >
                                                </i>&nbsp;&nbsp;&nbsp;&nbsp;{error1.message}

                                            </div>
                                        )}</UncontrolledAlert>)}
                                    {!isEdit && error2.status && (
                                        <UncontrolledAlert color="danger">
                                            <div
                                                id="error"
                                                style={{

                                                    textAlign: 'start'
                                                }}
                                            >
                                                <i className="bi bi-exclamation-circle" >
                                                </i>&nbsp;&nbsp;&nbsp;&nbsp;{error2.message}

                                            </div>
                                        </UncontrolledAlert>)}
                                    {isUpdateSuccess && (
                                        <UncontrolledAlert color="success" onClose={() => setIsUpdateSuccess(false)}>Your Booking has been Updated successfully - {bookingId}</UncontrolledAlert>)}
                                    {!isUpdateSuccess && isEdit && error1.status && (
                                        <UncontrolledAlert color="danger" onClose={() => setIsUpdateSuccess(false)}>   {error1.status && (
                                            <div
                                                id="error"
                                                style={{

                                                    textAlign: 'start'
                                                }}
                                            >
                                                <i className="bi bi-exclamation-circle" >
                                                </i>&nbsp;&nbsp;&nbsp;&nbsp;{error1.message}

                                            </div>
                                        )}</UncontrolledAlert>)}

                                    <Row className="g-3">
                                        <Col md={6} lg={6} xl={3}>
                                            <Label className="form-label">Passenger Name (<span className="text-danger">*</span>)</Label>
                                            <input type="text" className="form-control" id="passenger_name" placeholder="Enter Passenger Name"
                                                value={passengerName} onChange={handlePassengerNameChange} required />
                                            <div className="text-muted fs-10"> Passenger Full Name </div>
                                        </Col>
                                        <Col md={6} lg={6} xl={3}>
                                            <Label className="form-label" for="passengerContact">Contact Number (<span className="text-danger">*</span>)</Label>
                                            <div className="input-group input-group-flag" data-input-flag="">
                                                <Select
                                                    className="mb-0"
                                                    value={ countryCode ? countryCodes.find((option) => option.value === countryCode.toString()) : ""}
                                                    placeholder="code"
                                                    onChange={opt => {setCountryCode(opt.value)}}
                                                    options={countryCodes}
                                                    getOptionLabel={(option) => option.value}
                                                    inputId="countryCode"
                                                    classNamePrefix="form-control-select"
                                                    required

                                                ></Select>
                                                <input type="number" id="passengerContact" className="form-control rounded-end flag-input" placeholder="Contact Number"
                                                    value={number}
                                                    onChange={handleNumberChange}
                                                />
                                            </div>
                                            <div className="text-muted fs-10">WhatsApp Number</div>
                                        </Col>
                                        <Col md={6} lg={6} xl={3}>
                                            <Label className="form-label" for="firstCall">First Call</Label>
                                            <div className="input-group input-group-flag" data-input-flag="">
                                                <Select
                                                    className="mb-0"
                                                    value={ countryCodes.find((option) => option.value === "+65")}
                                                    placeholder="code"
                                                    onChange={opt => {setCountryCode(opt.value)}}
                                                    options={countryCodes}
                                                    getOptionLabel={(option) => option.value}
                                                    inputId="countryCode"
                                                    classNamePrefix="form-control-select"
                                                    isDisabled
                                                ></Select>
                                                <input type="number" id="firstCall" className="form-control rounded-end flag-input" placeholder="First Call"
                                                    value={firstCall} onChange={handleCallChange(setFirstCall)}/>
                                            </div>
                                        </Col>
                                        <Col md={6} lg={6} xl={3}>
                                            <Label className="form-label" for="secondCall">Second Call</Label>
                                            <div className="input-group input-group-flag" data-input-flag="">
                                                <Select
                                                    className="mb-0"
                                                    value={ countryCodes.find((option) => option.value === "+65")}
                                                    placeholder="code"
                                                    onChange={opt => {setCountryCode(opt.value)}}
                                                    options={countryCodes}
                                                    getOptionLabel={(option) => option.value}
                                                    inputId="countryCode"
                                                    classNamePrefix="form-control-select"
                                                    isDisabled
                                                ></Select>
                                                <input type="number" id="secondCall" className="form-control rounded-end flag-input" placeholder="Second Call"
                                                    value={secondCall} onChange={handleCallChange(setSecondCall)}/>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className="g-3 mt-0">
                                        <Col md={6} lg={3}>
                                            <Label>Date & Time  (<span className="text-danger">*</span>)</Label>
                                            <div className="input-group">
                                                <Flatpickr
                                                    className="form-control"
                                                    placeholder={placeholder1}
                                                    options={{
                                                        enableTime: true,
                                                        time_24hr: true,
                                                        dateFormat: "d/m/y H:i",
                                                        onValueUpdate: handleDateTimeChange,
                                                        defaultDate: new Date(),
                                                        minDate: moment().add(60,'minutes').toDate(),
                                                    }}

                                                    value={highlightedDate ? highlightedDate : ''}
                                                />
                                                <span className="input-group-text bg-transparent" ><i className="ri-calendar-2-line"></i></span>
                                            </div>
                                            <div className="text-muted fs-10"> Format: dd/mm/yyyy - 24Hrs</div>
                                        </Col>
                                        <Col md={6} lg={3}>
                                            <Label>No. of Seats (<span className="text-danger">*</span>)</Label>
                                            <div className="input-group flex-shrink-0">
                                                <Col xs={6} sm={6}>

                                                    <div className="input-step flex-shrink-0 size-99p">
                                                        <button
                                                            type="button"
                                                            className="minus"
                                                            onClick={() => {
                                                                countDownPass(setExtraHours, extraHours);
                                                            }}
                                                        >
                                                            –
                                                        </button>
                                                        <Input
                                                            type="number"

                                                            value={extraHours}
                                                            min="0"
                                                            max="100"
                                                            readOnly
                                                            required
                                                        />
                                                        <button
                                                            type="button"
                                                            className="plus"
                                                            onClick={() => {
                                                                countUPPass(setExtraHours, extraHours);
                                                            }}
                                                        >
                                                            +
                                                        </button>
                                                    </div>
                                                    <div className="text-muted fs-10"> Passenger Seats</div>
                                                </Col>
                                                <Col xs={6} sm={6}>
                                                    <div className="input-step flex-shrink-0 ms-1 size-99p">
                                                        <button
                                                            type="button"
                                                            className="minus"
                                                            onClick={() => {
                                                                countDownBaby(setBabySeats, babySeats);
                                                            }}
                                                        >
                                                            –
                                                        </button>
                                                        <Input
                                                            type="number"

                                                            value={babySeats}
                                                            min="0"
                                                            max="100"
                                                            readOnly

                                                        />
                                                        <button
                                                            type="button"
                                                            className="plus"
                                                            onClick={() => {
                                                                countUPBaby(setBabySeats, babySeats);
                                                            }}
                                                        >
                                                            +
                                                        </button>
                                                    </div>
                                                    <div className="text-muted fs-10"> Baby Seats</div>
                                                </Col>
                                            </div>

                                        </Col>
                                        <Col md={3}>
                                            <Label className="d-block">No.Luggage </Label>
                                            <div className="input-step w-50">
                                                <button
                                                    type="button"
                                                    className="minus"
                                                    onClick={() => {
                                                        setWaitingCharge(waitingCharge - 1);
                                                    }}
                                                >
                                                    –
                                                </button>
                                                <Input
                                                    type="number"

                                                    value={waitingCharge}
                                                    min="0"
                                                    max="100"
                                                    readOnly
                                                    required
                                                />
                                                <button
                                                    type="button"
                                                    className="plus"
                                                    onClick={() => {
                                                        setWaitingCharge(waitingCharge + 1);
                                                    }}
                                                >
                                                    +
                                                </button>
                                            </div>
                                            <div className="text-muted fs-10"> Luggage Box</div>
                                        </Col>

                                    </Row>


                                    <Row className="g-3 row mt-0">
                                        <Col>
                                            <Nav tabs className="nav-tabs mb-0 activetab">
                                                <NavItem>
                                                    <NavLink style={{ cursor: "pointer" }} className={`nav-link ${classnames({ active: activeTab === "1", })}`} onClick={() => { toggle("1"); }} >
                                                        Airport Arrival
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink style={{ cursor: "pointer" }} className={`nav-link ${classnames({ active: activeTab === "2", })}`} onClick={() => { toggle("2"); }} >
                                                        Airport Departure
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink style={{ cursor: "pointer" }} className={`nav-link ${classnames({ active: activeTab === "3", })}`} onClick={() => { toggle("3"); }} >
                                                        Point to Point (Transfer)
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink style={{ cursor: "pointer" }} className={`nav-link ${classnames({ active: activeTab === "4", })}`} onClick={() => { toggle("4"); }} >
                                                        Singapore Disposal
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink style={{ cursor: "pointer" }} className={`nav-link ${classnames({ active: activeTab === "5", })}`} onClick={() => { toggle("5"); }} >

                                                        Malaysia Disposal

                                                    </NavLink>
                                                </NavItem>
                                            </Nav>

                                            <TabContent activeTab={activeTab} className="text-muted bg-light ps-3 pe-3 pb-2 ">
                                                <TabPane tabId="1" id="airpotArival">
                                                    <Row className="g-3 mt-0">
                                                        <Col md={6} xl={3}>

                                                            <Label
                                                                htmlFor="airportName"
                                                                className="form-label"
                                                            >
                                                                Pickup Airport Name (<span className="text-danger">*</span>)
                                                            </Label>
                                                            <Select
                                                                className="mb-0"
                                                                classNamePrefix="form-control-select"
                                                                value={airportName1 ? airportName.find((option) => option.value === airportName1) : ""}
                                                                placeholder="Select Airport Name"
                                                                onChange={(selectedOption) => handleAirportName(selectedOption.value)}
                                                                options={airportName}
                                                                inputId="airportName"
                                                                required

                                                            ></Select>

                                                        </Col>
                                                        <Col md={6} xl={3}>

                                                            <Label for="zip" className="form-label">Drop Location (<span className="text-danger">*</span>)</Label>
                                                            <Input type="text" id="destination" name="destination"
                                                                // defaultValue={ destination ? destination :"Radisson Blu"}
                                                                value={destination}
                                                                onChange={onchange}
                                                                required />
                                                        </Col>
                                                        <Col md={6} xl={3}>
                                                            <Label for="zip" className="form-label">Flight Number (<span className="text-danger">*</span>)</Label>
                                                            <Input type="text" id="flightnumber" placeholder="Enter Flight Number" required
                                                                value={flightNo} onChange={(e) => setFlightNo(e.target.value)} />
                                                        </Col>


                                                    </Row>
                                                    <Row className="g-3 mt-0">
                                                        <Col md={6} xl={3}>
                                                            <Label for="zip" className="form-label"> Extra Stop</Label>
                                                            <div className="input-group" data-input-flag="">
                                                                <div className="col-12">
                                                                    <div className="input-step flex-shrink-0 size-99p w-100">
                                                                        <button
                                                                            type="button"
                                                                            className="minus"
                                                                            onClick={() => {
                                                                                countDown(setExtraStop, extraStop);
                                                                                if(extraStop === 2) {
                                                                                    setStop2('')
                                                                                }
                                                                                if(extraStop === 1) {
                                                                                    setStop1('')
                                                                                }
                                                                            }}
                                                                        >
                                                                            –
                                                                        </button>
                                                                        <Input
                                                                            type="number"
                                                                            className="form-control-extra-stop"
                                                                            value={extraStop}
                                                                            min="0"
                                                                            max="2"
                                                                            readOnly
                                                                            required
                                                                        />
                                                                        <button
                                                                            type="button"
                                                                            className="plus"
                                                                            onClick={() => {
                                                                                countUP(setExtraStop, extraStop);
                                                                            }}
                                                                        >
                                                                            +
                                                                        </button>
                                                                    </div>
                                                                    <div className="text-muted fs-10">One Extra Stop $15</div>
                                                                </div>

                                                            </div>

                                                        </Col>

                                                        {extraStop >= 1 && (
                                                            <Col md="3">
                                                                <Label for="stop1" className="form-label">Stop 1 (<span className="text-danger">*</span>)</Label>
                                                                <Input
                                                                    type="text"
                                                                    id="stop1"
                                                                    placeholder="Enter Stop1"
                                                                    value={stop1}
                                                                    onChange={(e) => setStop1(e.target.value)}
                                                                    required
                                                                />
                                                            </Col>
                                                        )}

                                                        {extraStop >= 2 && (
                                                            <Col md="3">
                                                                <Label for="stop2" className="form-label">Stop 2 (<span className="text-danger">*</span>)</Label>
                                                                <Input
                                                                    type="text"
                                                                    id="stop2"
                                                                    placeholder="Enter Stop2"
                                                                    value={stop2}
                                                                    onChange={(e) => setStop2(e.target.value)}
                                                                    required
                                                                />
                                                            </Col>
                                                        )}

                                                    </Row>
                                                    <Row className="g-3 mt-0 mb-3 pb-0">
                                                        <Col md={12} xl={9}>

                                                            <Label for="zip" className="form-label">Remarks</Label>
                                                            <textarea
                                                                id="flightnumber"
                                                                placeholder="Enter Remarks"
                                                                className="form-control"
                                                                value={remarks}
                                                                onChange={(e) => setRemarks(e.target.value)}
                                                                required

                                                                style={{ height: '90px' }} // Set the height as desired
                                                            />

                                                        </Col>
                                                    </Row>
                                                </TabPane>

                                                <TabPane tabId="2" id="airportDeparture">
                                                    <Row className="g-3 mt-0">
                                                        <Col md="3">
                                                            <Label for="zip" className="form-label">Pickup Location (<span className="text-danger">*</span>) </Label>
                                                            <Input type="text" id="destination" required
                                                                //defaultValue={ destination ? destination :"Airport Singapore"}
                                                                value={destination1}
                                                                onChange={onchange1} />
                                                        </Col>
                                                        <Col md="3">

                                                            <Label
                                                                htmlFor="airportName"
                                                                className="form-label"
                                                            >
                                                                Drop Airport Name (<span className="text-danger">*</span>)
                                                            </Label>
                                                            <Select
                                                                className="mb-0"
                                                                classNamePrefix="form-control-select"
                                                                value={airportName1 ? airportName.find((option) => option.value === airportName1) : ""}
                                                                placeholder="Select Airport Name"
                                                                onChange={(selectedOption) => handleAirportName(selectedOption.value)}
                                                                options={airportName}
                                                                inputId="airportName"
                                                                required

                                                            ></Select>

                                                        </Col>
                                                        <Col md="3">
                                                            <Label for="zip" className="form-label">Flight Number (<span className="text-danger">*</span>)</Label>
                                                            <Input type="text" id="flightnumber" placeholder="Enter Flight Number"
                                                                value={flightNo} onChange={(e) => setFlightNo(e.target.value)} required />
                                                        </Col>
                                                    </Row>

                                                    <Row className="g-3 mt-0">
                                                        <Col md={6} xl={3}>

                                                            <Label for="zip" className="form-label"> Extra Stop</Label>
                                                            <div className="input-group" data-input-flag="">
                                                                <div className="col-12">
                                                                    <div className="input-step flex-shrink-0 size-99p">
                                                                        <button
                                                                            type="button"
                                                                            className="minus"
                                                                            onClick={() => {
                                                                                countDown(setAirDepExtraStop, airDepExtraStop);
                                                                                if(airDepExtraStop === 2) {
                                                                                    setStop2('')
                                                                                }
                                                                                if(airDepExtraStop === 1) {
                                                                                    setStop1('')
                                                                                }
                                                                            }}
                                                                        >
                                                                            –
                                                                        </button>
                                                                        <Input
                                                                            type="number"
                                                                            className="form-control-extra-stop"
                                                                            value={airDepExtraStop}
                                                                            min="0"
                                                                            max="100"
                                                                            readOnly
                                                                            required
                                                                        />
                                                                        <button
                                                                            type="button"
                                                                            className="plus"
                                                                            onClick={() => {
                                                                                countUP(setAirDepExtraStop, airDepExtraStop);
                                                                            }}
                                                                        >
                                                                            +
                                                                        </button>
                                                                    </div>
                                                                    <div className="text-muted fs-10">One Extra Stop $15</div>
                                                                </div>
                                                            </div>
                                                        </Col>

                                                        {airDepExtraStop >= 1 && (
                                                            <Col md="3">
                                                                <Label for="stop1" className="form-label">Stop 1 (<span className="text-danger">*</span>)</Label>
                                                                <Input
                                                                    type="text"
                                                                    id="stop1"
                                                                    placeholder="Enter Stop1"
                                                                    value={stop1}
                                                                    onChange={(e) => setStop1(e.target.value)}
                                                                    required
                                                                />
                                                            </Col>
                                                                 )}
                                                                  {airDepExtraStop >= 2 && (
                                                            <Col md="3">
                                                                <Label for="stop2" className="form-label">Stop 2 (<span className="text-danger">*</span>)</Label>
                                                                <Input
                                                                    type="text"
                                                                    id="stop2"
                                                                    placeholder="Enter Stop2"
                                                                    value={stop2}
                                                                    onChange={(e) => setStop2(e.target.value)}
                                                                    required
                                                                />
                                                            </Col>

                                                                  )}

                                                    </Row>
                                                    <Row className="g-3 mt-0 mb-3 pb-0">
                                                        <Col md={12} xl={9}>
                                                            <Label for="zip" className="form-label">Remarks</Label>
                                                            <textarea
                                                                    id="flightnumber"
                                                                    placeholder="Enter Remarks"
                                                                    value={remarks}
                                                                    onChange={(e) => setRemarks(e.target.value)}
                                                                    required
                                                                    className="form-control"
                                                                    style={{ height: '90px' }} // Set the height as desired
                                                                />
                                                        </Col>
                                                    </Row>
                                                </TabPane>

                                                <TabPane tabId="3" id="pointtopoint">
                                                    <Row className="g-3 mt-0">
                                                        <Col md="3">
                                                            <Label for="zip" className="form-label">Pickup Location (<span className="text-danger">*</span>)</Label>
                                                            <LocationAutoComplete placeholder="Pickup location" value={pickupLocation} onChange={setPickupLocation}/>
                                                        </Col>
                                                        <Col md="3">
                                                            <Label for="zip" className="form-label">Drop Location (<span className="text-danger">*</span>)</Label>
                                                            <LocationAutoComplete placeholder="Drop location" value={dropLocation} onChange={setDropLocation}/>
                                                        </Col>
                                                    </Row>

                                                    <Row className="g-3 mt-0">
                                                        <Col md="3">
                                                            <Label for="zip" className="form-label"> Extra Stop</Label>
                                                            <div className="input-group" data-input-flag="">
                                                                <div className="col-12">
                                                                    <div className="input-step flex-shrink-0 size-99p w-100">
                                                                        <button
                                                                            type="button"
                                                                            className="minus"
                                                                            onClick={() => {
                                                                                countDown(setPointtotPointExtraStop, pointtotPointExtraStop);
                                                                                if(pointtotPointExtraStop === 2) {
                                                                                    setStop2('')
                                                                                }
                                                                                if(pointtotPointExtraStop === 1) {
                                                                                    setStop1('')
                                                                                }
                                                                            }}
                                                                        >
                                                                            –
                                                                        </button>
                                                                        <Input
                                                                            type="number"
                                                                            className="form-control-extra-stop"
                                                                            value={pointtotPointExtraStop}
                                                                            min="0"
                                                                            max="100"
                                                                            readOnly
                                                                            required
                                                                        />
                                                                        <button
                                                                            type="button"
                                                                            className="plus"
                                                                            onClick={() => {
                                                                                countUP(setPointtotPointExtraStop, pointtotPointExtraStop);
                                                                            }}
                                                                        >
                                                                            +
                                                                        </button>
                                                                    </div>
                                                                    <div className="text-muted fs-10">One Extra Stop $15</div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        {pointtotPointExtraStop >= 1 && (
                                                        <Col md="3">
                                                                <Label for="stop1" className="form-label">Stop 1 (<span className="text-danger">*</span>)</Label>
                                                                <Input
                                                                    type="text"
                                                                    id="stop1"
                                                                    placeholder="Enter Stop1"
                                                                    value={stop1}
                                                                    onChange={(e) => setStop1(e.target.value)}
                                                                    required
                                                                />
                                                            </Col>
                                                        )}
                                                         {pointtotPointExtraStop >= 2 && (
                                                            <Col md="3">
                                                                <Label for="stop2" className="form-label">Stop 2 (<span className="text-danger">*</span>)</Label>
                                                                <Input
                                                                    type="text"
                                                                    id="stop2"
                                                                    placeholder="Enter Stop2"
                                                                    value={stop2}
                                                                    onChange={(e) => setStop2(e.target.value)}
                                                                    required
                                                                />
                                                            </Col>
                                                         )}
                                                    </Row>
                                                    <Row className="g-3 mt-0 pb-3">
                                                        <Col md={12} xl={6}>
                                                            <div>
                                                                <Label for="zip" className="form-label">Remarks</Label>
                                                                <textarea
                                                                    id="flightnumber"
                                                                    placeholder="Enter Remarks"
                                                                    value={remarks}
                                                                    onChange={(e) => setRemarks(e.target.value)}
                                                                    required
                                                                    className="form-control"
                                                                    style={{ height: '90px' }} // Set the height as desired
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </TabPane>

                                                <TabPane tabId="4" id="singaporeDisposal">
                                                    <Row className="g-3 mt-0">
                                                        <Col md="3">
                                                            <Label for="zip" className="form-label">Pickup Location (<span className="text-danger">*</span>)</Label>
                                                            <LocationAutoComplete placeholder="Pickup location" value={pickupLocation} onChange={setPickupLocation}/>
                                                        </Col>
                                                        <Col md="3">
                                                            <Label for="zip" className="form-label">Drop Location(<span className="text-danger">*</span>) </Label>
                                                            <LocationAutoComplete placeholder="Drop location" value={dropLocation} onChange={setDropLocation}/>
                                                        </Col>

                                                        <Col md="3">
                                                            <Label for="zip" className="form-label"> Disposal Hours</Label>
                                                            <div className="input-group" data-input-flag="">
                                                                <div className="col-12">
                                                                    <div className="input-step flex-shrink-0 size-99p w-100">
                                                                        <button
                                                                            type="button"
                                                                            className="minus"
                                                                            onClick={() => {
                                                                                countDown1(setFromDisposal, fromDisposel);
                                                                            }}
                                                                        >
                                                                            –
                                                                        </button>
                                                                        <Input
                                                                            type="number"
                                                                            className="form-control-extra-stop"
                                                                            value={fromDisposel}
                                                                            min="0"
                                                                            max="100"
                                                                            readOnly
                                                                            required
                                                                        />
                                                                        <button
                                                                            type="button"
                                                                            className="plus"
                                                                            onClick={() => {
                                                                                countUP1(setFromDisposal, fromDisposel);
                                                                            }}
                                                                        >
                                                                            +
                                                                        </button>
                                                                    </div>
                                                                    {/* <div className="text-muted fs-10">One Extra Stop $15</div> */}
                                                                </div>
                                                            </div>
                                                        </Col>

                                                    </Row>
                                                    <Row className="g-3 mt-0 mb-3">
                                                        <Col md={12} xl={9}>
                                                            <Label for="zip" className="form-label">Remarks</Label>
                                                            <textarea
                                                                    id="flightnumber"
                                                                    placeholder="Enter Remarks"
                                                                    value={remarks}
                                                                    onChange={(e) => setRemarks(e.target.value)}
                                                                    required
                                                                    className="form-control"
                                                                    style={{ height: '90px' }} // Set the height as desired
                                                                />
                                                        </Col>
                                                        </Row>
                                                </TabPane>
                                                <TabPane tabId="5" id="malasiyaDisposal">
                                                    <Row className="g-3 mt-0">
                                                        <Col md="3">
                                                            <Label for="zip" className="form-label">Pickup Location (<span className="text-danger">*</span>)</Label>
                                                            <LocationAutoComplete placeholder="Pickup location" value={pickupLocation} onChange={setPickupLocation}/>
                                                        </Col>
                                                        <Col md="3">
                                                            <Label for="zip" className="form-label">Drop Location(<span className="text-danger">*</span>) </Label>
                                                            <LocationAutoComplete placeholder="Drop location" value={dropLocation} onChange={setDropLocation}/>
                                                        </Col>


                                                        <Col md="3">
                                                            <Label for="zip" className="form-label"> Disposal Hours</Label>
                                                            <div className="input-group" data-input-flag="">
                                                                <div className="col-12">
                                                                    <div className="input-step flex-shrink-0 size-99p w-100">
                                                                        <button
                                                                            type="button"
                                                                            className="minus"
                                                                            onClick={() => {
                                                                                countDown2(settoDisposal, toDisposel);
                                                                            }}
                                                                        >
                                                                            –
                                                                        </button>
                                                                        <Input
                                                                            type="number"
                                                                            className="form-control-extra-stop"
                                                                            value={toDisposel}
                                                                            min="0"
                                                                            max="100"
                                                                            readOnly
                                                                            required
                                                                        />
                                                                        <button
                                                                            type="button"
                                                                            className="plus"
                                                                            onClick={() => {
                                                                                countUP2(settoDisposal, toDisposel);
                                                                            }}
                                                                        >
                                                                            +
                                                                        </button>
                                                                    </div>
                                                                    {/* <div className="text-muted fs-10">One Extra Stop $15</div> */}
                                                                </div>
                                                            </div>
                                                        </Col>

                                                    </Row>
                                                    <Row className="g-3 mt-0 mb-3">
                                                        <Col md={12} xl={9}>
                                                            <Label for="zip" className="form-label">Remarks</Label>
                                                            <textarea
                                                                    id="flightnumber"
                                                                    placeholder="Enter Remarks"
                                                                    value={remarks}
                                                                    onChange={(e) => setRemarks(e.target.value)}
                                                                    required
                                                                    className="form-control"
                                                                    style={{ height: '90px' }} // Set the height as desired
                                                                />
                                                        </Col>
                                                        </Row>
                                                </TabPane>
                                            </TabContent>




                                        </Col>
                                    </Row>




                                    <hr className="my-4 text-muted" />
                                    <CarsInfoList
                                        selectedCar={carBrandAndModel} extraHours={extraHours} carId={carId} setCarId={setCarId} activeTab={activeTab} babySeats={babySeats} isEdit={isEdit}
                                        isBookingSuccess={isBookingSuccess}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={4} lg={4} xl={3}>
                            <BookingInfoSideBar
                                passengerName={passengerName}
                                setId={setId}
                                countryCode={countryCode}
                                number={number}
                                firstCall={firstCall}
                                secondCall={secondCall}
                                flightNo={flightNo}
                                airportName1={airportName1}
                                remarks={remarks}
                                stop1={stop1}
                                stop2={stop2}
                                destination={destination}
                                destination1={destination1}
                                date={date}
                                Time={Time}
                                extraHours={extraHours}
                                babySeats={babySeats}
                                waitingCharge={waitingCharge}
                                tabName={tabName}
                                pickupLocation={pickupLocation}
                                dropLocation={dropLocation}
                                pointtotPointExtraStop={pointtotPointExtraStop}
                                airDepExtraStop={airDepExtraStop}
                                extraStop={extraStop}
                                fromDisposel={fromDisposel}
                                toDisposel={toDisposel}
                                activeTab={activeTab}
                                selectedCar={selectedCar}
                                successStatus={success}
                                sendError={sendError}
                                bookingId={bookingId}
                                editBookingId={editBookingId}
                                isHotelPaid={isHotelPaid}
                                hotelRemarks={hotelRemarks}
                                driverPaid={driverPaid}
                                driverRemarks={driverRemarks}
                                isEdit={isEdit}
                                rideStatus={rideStatus}
                                confirmStatus={confirmStatus}
                                editDate={editDate}
                                editTime={editTime}
                                editDetails={editDetails}
                                dateFormat={dateFormat}
                            />

                        </Col>
                    </Row>
                    {/* {hasNullValues ? ( <div
                                            className="accordion accordion-flush"
                                            id="accordionFlushExample" >
                                                 {bookingHistory.map((item, i) => (

                                        <div className="accordion-item border-0" key={i}>
                                            <div className="accordion-header" id="headingOne">
                                                <Link to="#" className="accordion-button p-2 shadow-none">
                                                    <div className="d-flex align-items-center" style={{marginTop:10}}>
                                                        <div className="flex-shrink-0 avatar-xs" style={{marginTop:0}}>
                                                            <div className="avatar-title bg-success rounded-circle" style={{marginTop:-80}}>
                                                                <i className='ri-account-circle-fill'  ></i>
                                                            </div>
                                                        </div>
                                                        <div className="flex-grow-1 ms-3">

                                                            {Object.keys(item).map((key) => {
                                                                const bookingData = item[key];

                                                                return (
                                                                <div key={key} className="text-dark"  >
                                                                     <h6 className="fs-14 mb-0">
                                                                  <p className="text-dark">
                                                                    {key}</p>
                                                                    </h6>
                                                                    {Object.entries(bookingData).map(([subKey, value]) => (

                                                                        <div  className="text-dark" key={subKey}>

                                                                            <p className="mb-1">{subKey} <p className="text-muted">{value}</p>
                                                                            </p>
                                                                        </div>
                                                                    ))}

                                                                </div>
                                                                );
                                                            })}

                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div

                                            >

                                            </div>
                                        </div>
                                         ))


                                        }
                                    </div>):""} */}
                </Container>
            </div></React.Fragment>
    )
}

export default CreateBooking