import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Navdata = () => {
  const history = useNavigate();

  // State data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isApps, setIsApps] = useState(false);
  const [isReport, setIsReport] = useState(false);
  const [isBooking, setIsBooking] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [isCarOnBoard, setIsCarOnBoard] = useState(false);

  // Authentication
  const [isSignIn, setIsSignIn] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const [isPasswordCreate, setIsPasswordCreate] = useState(false);
  const [isLockScreen, setIsLockScreen] = useState(false);
  const [isLogout, setIsLogout] = useState(false);
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isVerification, setIsVerification] = useState(false);
  const [isError, setIsError] = useState(false);

  const [iscurrentState, setIscurrentState] = useState("Dashboard");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    sidemenuvalidation()
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Dashboard") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "Apps") {
      setIsApps(false);
    }
    if (iscurrentState !== "Auth") {
      setIsAuth(false);
    }
    if (iscurrentState !== "Booking") {
      setIsBooking(false);
    }
    if (iscurrentState !== "Reports") {
      setIsReport(false);
    }
  }, [history, iscurrentState, isDashboard, isApps, isAuth, isReport, isBooking]);

  const flag = localStorage.getItem("userType");
  console.log("flag",flag)
 // const menuItems=[];
  const[menuItems,setMenuItems]=useState([])

  const sidemenuvalidation =()=>{
    if(flag==1){
      console.log("flag4",flag)
   setMenuItems(
    [
        {
          label: "Menu",
          isHeader: true,
        },
        {
          id: "dashboard",
          label: "Dashboards",
          icon: "bx bxs-dashboard",
          link: "/dashboard",
          isVisible: true, // Always visible
        },
        {
          label: "pages",
          isHeader: true,
        },
        {
          id: "booking",
          label: "Booking",
          icon: "bx bx-car",
          link: "/#",
          click: function (e) {
            e.preventDefault();
            setIsBooking(!isBooking);
            setIscurrentState("Booking");
            updateIconSidebar(e);
          },
          stateVariables: isBooking,
          subItems: [
            {
              id: "allBooking",
              label: "All Booking",
              link: "/booking",
            },
         
            
            
          ],
          //isVisible: flag !== "1", // Hide if flag value is 1
        },
        {
          id: "hotelOnBoarding",
          label: "Hotel Onboarding",
          icon: "bx bx-buildings",
          link: "/hotel-onboarding",
          //isVisible: true, // Always visible
          isVisible: flag !== "2" && flag !== "3",
        },
        {
          id: "PrimaryUserOnboard",
          label: "Primary User Onboard",
          icon: "bx bx-user-circle",
          link: "/Primary-user-onboard",
          //isVisible: true, // Always visible
          isVisible: flag !== "3",
        },
        {
          id: "carBoard",
          label: "Car Onboard",
          icon: "bx bx-bus",
          link: "/#",
          //isVisible: true, // Always visible
          isVisible: flag !== "2" && flag !== "3",
          click: function (e) {
            e.preventDefault();
            setIsCarOnBoard(!isCarOnBoard);
            setIscurrentState("carBoard");
            updateIconSidebar(e);
          },
          stateVariables: isCarOnBoard,
          subItems: [
            
            {
              id: "carModel",
              label: "Car Model",
              link: "/car-model",
            },
            {
              id: "carBrand",
              label: "Car Brand",
              link: "/car-brand",
            },
            {
              id: "caronBoard",
              label: "Car Configuration",
              link: "/car-onboard",
            },
          ],
        },
        {
          id: "driverOnBoard",
          label: "Driver Onboard",
          icon: "bx bxs-user",
          link: "/driver-onboard",
          //isVisible: true, // Always visible
          isVisible: flag !== "2" && flag !== "3",
        },
        {
          label: "Reports",
          isHeader: true,
        },
        {
          id: "reports",
          label: "Reports",
          icon: "bx bx-file",
          link: "/#",
          click: function (e) {
            e.preventDefault();
            setIsReport(!isReport);
            setIscurrentState("Reports");
            updateIconSidebar(e);
          },
          stateVariables: isReport,
          subItems: [
            {
              id: "bookingReport",
              label: "Booking Report",
              link: "/booking-report",
            },
            {
              id: "paymentReport",
              label: "Payment Report",
              link: "/payment-Report",
            },
        
          ],
           // Hide if flag value is 2
        },
      ])
    }else if (flag==2){
      console.log("flag1",flag)
      setMenuItems( 
        [
      
    
        {
          label: "pages",
          isHeader: true,
        },
        {
          id: "booking",
          label: "Booking",
          icon: "bx bx-car",
          link: "/#",
          click: function (e) {
            e.preventDefault();
            setIsBooking(!isBooking);
            setIscurrentState("Booking");
            updateIconSidebar(e);
          },
          stateVariables: isBooking,
          subItems: [
            {
              id: "allBooking",
              label: "All Booking",
              link: "/booking",
            },
            {
              id: "createBooking",
              label: "Create Booking",
              subLabel: "New",
              subLabelBg: "success",
              link: "/add-booking",
              
            },
            
            
          ],
          //isVisible: flag !== "1", // Hide if flag value is 1
        },
        {
          id: "hotelOnBoarding",
          label: "Hotel Onboarding",
          icon: "bx bx-buildings",
          link: "/hotel-onboarding",
          //isVisible: true, // Always visible
          isVisible: flag !== "2" && flag !== "3",
        },
        {
          id: "PrimaryUserOnboard",
          label: "User Onboard",
          icon: "bx bx-user-circle",
          link: "/Primary-user-onboard",
          //isVisible: true, // Always visible
          isVisible: flag !== "3",
        },
        {
          id: "carOnBoard",
          label: "Car Onboard",
          icon: "bx bx-bus",
          link: "/#",
          //isVisible: true, // Always visible
          isVisible: flag !== "2" && flag !== "3",
          click: function (e) {
            e.preventDefault();
            setIsCarOnBoard(!isCarOnBoard);
            setIscurrentState("carOnBoard");
            updateIconSidebar(e);
          },
          stateVariables: isCarOnBoard,
          subItems: [
            {
              id: "carModel",
              label: "Create Car Model",
              link: "/car-model",
            },
            {
              id: "carBrand",
              label: "Create Car Brand",
              link: "/car-brand",
            },
            {
              id: "carOnBoard",
              label: "Create Car",
              link: "/car-onboard",
            },
           
          ],
        },
        {
          id: "driverOnBoard",
          label: "Driver Onboard",
          icon: "bx bxs-user",
          link: "/driver-onboard",
          //isVisible: true, // Always visible
          isVisible: flag !== "2" && flag !== "3",
        },
        {
          label: "Reports",
          isHeader: true,
        },
        {
          id: "reports",
          label: "Reports",
          icon: "bx bx-file",
          link: "/#",
          click: function (e) {
            e.preventDefault();
            setIsReport(!isReport);
            setIscurrentState("Reports");
            updateIconSidebar(e);
          },
          stateVariables: isReport,
          subItems: [
            {
              id: "bookingReport",
              label: "Booking Report",
              link: "/booking-report",
            },
            // {
            //   id: "paymentReport",
            //   label: "Payment Report",
            //   link: "/payment-Report",
            // },
        
          ],
           // Hide if flag value is 2
        },
      ])
      
    }else{
      console.log("flag2",flag)
      setMenuItems(
         [
       
        {
          label: "pages",
          isHeader: true,
        },
        {
          id: "booking",
          label: "Booking",
          icon: "bx bx-car",
          link: "/#",
          click: function (e) {
            e.preventDefault();
            setIsBooking(!isBooking);
            setIscurrentState("Booking");
            updateIconSidebar(e);
          },
          stateVariables: isBooking,
          subItems: [
            {
              id: "allBooking",
              label: "All Booking",
              link: "/booking",
            },
            {
              id: "createBooking",
              label: "Create Booking",
              subLabel: "New",
              subLabelBg: "success",
              link: "/add-booking",
              
            },
            
            
          ],
          //isVisible: flag !== "1", // Hide if flag value is 1
        },
     
        {
          label: "Reports",
          isHeader: true,
        },
        {
          id: "reports",
          label: "Reports",
          icon: "bx bx-file",
          link: "/#",
          click: function (e) {
            e.preventDefault();
            setIsReport(!isReport);
            setIscurrentState("Reports");
            updateIconSidebar(e);
          },
          stateVariables: isReport,
          subItems: [
            {
              id: "bookingReport",
              label: "Booking Report",
              link: "/booking-report",
            },
            // {
            //   id: "paymentReport",
            //   label: "Payment Report",
            //   link: "/payment-Report",
            // },
           
          ],
           // Hide if flag value is 2
        },
      ])
    }
  }

 

  // Filter visible menu items based on the isVisible property
  const visibleMenuItems = menuItems.filter((item) => item.isVisible !== false);

  return <React.Fragment>{visibleMenuItems}</React.Fragment>;
};

export default Navdata;
