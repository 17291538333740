import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LogoutModal from "./LogoutModel"
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

//import images
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import user11 from "../../assets/images/users/user.png"
const ProfileDropdown = () => {

    const { user } = useSelector(state => ({
        user: state.Profile.user,
    }));

    const [userName, setUserName] = useState("Admin");
    const navigate = useNavigate()
    useEffect(() => {
        if (localStorage.getItem("authUser")) {
            const obj = JSON.parse(localStorage.getItem("authUser"));
            setUserName(process.env.REACT_APP_DEFAULTAUTH === "fake" ? obj.username === undefined ? user.first_name ? user.first_name : obj.data.first_name : "Admin" || "Admin" :
                process.env.REACT_APP_DEFAULTAUTH === "firebase" ? obj.providerData[0].email : "Admin"
            );
        }
    }, [userName, user]);

    //Dropdown Toggle
    const [isProfileDropdown, setIsProfileDropdown] = useState(false);
    const [logoutModal, setlogoutModal] = useState(false);
    const toggleProfileDropdown = () => {
        setIsProfileDropdown(!isProfileDropdown);
    };
    const handleLogout = ()=> {
        console.log("inside logout")
        setlogoutModal(!logoutModal)
    }
    const handleLogout1 = ()=> {
        navigate("/logout")
        setlogoutModal(!logoutModal)
    }
    const userType=localStorage.getItem("userType");
    const Jobrole= localStorage.getItem("jobrole")
   //const USERNAME= localStorage.getItem("username");first_name
   let username1;

   if (userType === "1") {
     username1 =  localStorage.getItem("username");
   } else {
     username1 = localStorage.getItem("firstname");
   }
  
   let text;
   if (userType === "1") {
    text =  "Super Admin";
  } else {
    text = localStorage.getItem("jobrole");
  }
  // let text;

// if (userType === "1") {
//   text = "Super Admin";
// } else if (userType === "2") {
//   text = " Primary User";
// } else if (userType === "3") {
//   text = "Secondary User";
// } else {
//   text = "Welcome!";
// }
const handleHelp = (event) => {
   navigate('/change-password')
};

    return (
        <React.Fragment>
            <Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown} className="ms-sm-3 header-item topbar-user bg-white">
                <DropdownToggle tag="button" type="button" className="btn">
                    <span className="d-flex align-items-center">
                        <img className="rounded-circle header-profile-user" src={user11}
                            alt="Header Avatar" />
                        <span className="text-start ms-xl-2">
                            <span className="d-none d-xl-inline-block ms-1 fw-semibold user-name-text">{username1}</span>
                            <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">{text}</span>
                        </span>
                    </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">

                    <h6 className="dropdown-header">Welcome {username1}!</h6>
                    <DropdownItem onClick={()=>handleHelp()}><i
                        className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i> <span
                            className="align-middle">Change Password</span></DropdownItem>
                   
                    {/* <DropdownItem onClick={()=>handleHelp()}><i
                        className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i> <span
                            className="align-middle">Help</span></DropdownItem> */}
                    <div className="dropdown-divider"></div>
                   
                    <DropdownItem  onClick={()=>handleLogout()}><i
                        className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i> <span
                            className="align-middle" data-key="t-logout">Logout</span></DropdownItem>
                </DropdownMenu>
            </Dropdown>
           
            <LogoutModal
        show={logoutModal}
        onDeleteClick={handleLogout1}
        onCloseClick={() => setlogoutModal(false)}
        
      />
     
        </React.Fragment>
    );
};

export default ProfileDropdown;