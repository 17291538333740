import React from "react"
import { Button, Card, CardBody, CardHeader, Col, Container, Form, Input, Label, Row } from "reactstrap"
//import { Link } from "react-router-dom";
import { Link, animateScroll as scroll } from 'react-scroll';
import { useState,useEffect } from "react"
import { useNavigate } from 'react-router-dom';
import CancelModal from "../../../Components/Common/CancelModel"
import config from "../../../common/config"
const BookingInfoSideBar = ({ setId,passengerName,countryCode, number, firstCall, secondCall, flightNo,date,Time,extraHours,babySeats,waitingCharge,tabName,pickupLocation,dropLocation,pointtotPointExtraStop,
    airDepExtraStop,highlightedDate,extraStop,fromDisposel,toDisposel,destination,destination1,activeTab,selectedCar,successStatus,sendError,dropDestination,confirmStatus,
    isEdit,rideStatus,editBookingId,editDetails,dateFormat,bookingId,airportName1,remarks,stop1,stop2 }) => {
        const [error, setError] = useState({
            status: false,
            msg: "",
            type: ""
        })
        const [success,setSucess]=useState(false)
        const [button,setbutton]=useState(false)
        const [isCancel,setIsCancel]=useState(false)
        const [deleteModal, setDeleteModal] = useState(false);
        const Name =localStorage.getItem("firstname");
        const HotelName=localStorage.getItem("hotelname");
        const navigate = useNavigate()


        const getExtraStop = () =>{
            if(activeTab === "2"){
                return airDepExtraStop;
            }else if (activeTab === "1"){
                return extraStop;
            }
            else{
                return pointtotPointExtraStop;
            }
        }
        const getDisposal = () =>{
            if(activeTab === "4"){
                return fromDisposel;
            }else{
                return toDisposel;
            }
        }
        const getDestination = () =>{
            if(activeTab===1 ){
                return destination;
            }else{
                return dropLocation;
            }
            
        }
       
        const createBooking =async ()=>{
            console.log("Inside Booking After creating build")
            const car_id = selectedCar && selectedCar._id;
            const hotelid=localStorage.getItem("hotelid");
            const userid=localStorage.getItem("userid");
            if(!passengerName && !number && !date && !waitingCharge && !extraHours && !car_id){
                setError({ status: true, message: "Please Enter Required Fields", type: 'error' });
            setTimeout(() => {
                setError({ status: false, message: '', type: '' });
              }, 5000);
            return;
            }

            if(!passengerName){
                setError({ status: true, message: "Please Enter Passenger Name", type: 'error' });
            setTimeout(() => {
                setError({ status: false, message: '', type: '' });
              }, 5000);
            return;
            }

            if(!number){
                setError({ status: true, message: "Please Enter WhatsApp Number", type: 'error' });
            setTimeout(() => {
                setError({ status: false, message: '', type: '' });
              }, 5000);
            return;
            }

            if(extraHours === 0){
                setError({ status: true, message: "Please Enter Number Of Seats", type: 'error' });
            setTimeout(() => {
                setError({ status: false, message: '', type: '' });
              }, 5000);
            return;
            }

            if(!dateFormat){
                setError({ status: true, message: "Please Select Date and Time of Travel", type: 'error' });
            setTimeout(() => {
                setError({ status: false, message: '', type: '' });
              }, 5000);
            return;
            }

            if((activeTab === "1" || activeTab === "2") && !flightNo){
                setError({ status: true, message: "Please Enter Flight Number", type: 'error' });
                setTimeout(() => {
                    setError({ status: false, message: '', type: '' });
                  }, 5000);
                return;
            }

            if(activeTab === "1"  && !destination){
                setError({ status: true, message: "Please Enter Destination", type: 'error' });
                setTimeout(() => {
                    setError({ status: false, message: '', type: '' });
                  }, 5000);
                return;
            }

            if(activeTab === "2"  && !destination1){
                setError({ status: true, message: "Please Enter Destination", type: 'error' });
                setTimeout(() => {
                    setError({ status: false, message: '', type: '' });
                  }, 5000);
                return;
            }


            if((activeTab === "3" || activeTab === "4" || activeTab === "5") && !pickupLocation){
                setError({ status: true, message: "Please Enter Pick Up Location", type: 'error' });
                setTimeout(() => {
                    setError({ status: false, message: '', type: '' });
                  }, 5000);
                return;
            }

            if((activeTab === "3" || activeTab === "4" || activeTab === "5")  && !dropLocation){
                setError({ status: true, message: "Please Enter Drop Location", type: 'error' });
                setTimeout(() => {
                    setError({ status: false, message: '', type: '' });
                  }, 5000);
                return;
            }
            if (activeTab === "1") {
                if (extraStop === 1 && !stop1) {
                  setError({ status: true, message: "Please Enter Stop 1", type: 'error' });
                } 
              
                setTimeout(() => {
                  setError({ status: false, message: '', type: '' });
                }, 5000);
              }
              
            
              if (activeTab === "1" && extraStop === 2  && (!stop1 || !stop2)) {
                setError({ status: true, message: "Stop 1 and Stop 2 is mandatory", type: 'error' });
                setTimeout(() => {
                  setError({ status: false, message: '', type: '' });
                }, 5000);
                return;
              }
              if (activeTab === "2") {
                if (extraStop === 1 && !stop1) {
                  setError({ status: true, message: "Please Enter Stop 1", type: 'error' });
                } 
              
                setTimeout(() => {
                  setError({ status: false, message: '', type: '' });
                }, 5000);
              }
              
            
              if (activeTab === "2" && extraStop === 2  && (!stop1 || !stop2)) {
                setError({ status: true, message: "Stop 1 and Stop 2 is mandatory", type: 'error' });
                setTimeout(() => {
                  setError({ status: false, message: '', type: '' });
                }, 5000);
                return;
              }
              if (activeTab === "3") {
                if (extraStop === 1 && !stop1) {
                  setError({ status: true, message: "Please Enter Stop 1", type: 'error' });
                } 
              
                setTimeout(() => {
                  setError({ status: false, message: '', type: '' });
                }, 5000);
              }
              
            
              if (activeTab === "3" && extraStop === 2  && (!stop1 || !stop2)) {
                setError({ status: true, message: "Stop 1 and Stop 2 is mandatory", type: 'error' });
                setTimeout(() => {
                  setError({ status: false, message: '', type: '' });
                }, 5000);
                return;
              }
            if(!car_id){
                setError({ status: true, message: "Please Select a Car", type: 'error' });
            setTimeout(() => {
                setError({ status: false, message: '', type: '' });
              }, 5000);
            return;
            }
            // console.log(JSON.stringify({
            //     hotel_id: hotelid,
            //     user_id: userid,
            //     passenger_name: passengerName,
            //     country_code: "+65",
            //     passenger_contact_number: number,
            //     pickup_type: activeTab,
            //     flight_number: flightNo,
            //     destination: activeTab === "2" ? destination1 : destination ,
            //     extra_stop: getExtraStop(),
            //     pickup_location: pickupLocation,
            //     dropoff_location: dropLocation,
            //     disposal_hours: getDisposal(),
            //     date: dateFormat,
            //     time:Time,
            //     dateFormat:dateFormat ? dateFormat :'', 
            //     number_of_passengers_adult:extraHours,
            //     number_of_passengers_child: babySeats ? babySeats: 0,
            //     number_of_luggage: waitingCharge ? waitingCharge :0,
            //     car_id: car_id,
            //     confirmation_status: 1,
            //     ride_status: 1
            // }),"---------IN CREATE BOOKING----------")


          let res=  await fetch(config.baseUrl + "api/booking", {
                method: "POST",
    
                headers: {
                    "Content-Type": "application/json",
              },
                body: JSON.stringify({
                    hotel_id: hotelid,
                    user_id: userid,
                    passenger_name: passengerName,
                    country_code: countryCode,
                    passenger_contact_number: number,
                    first_call: firstCall,
                    second_call: secondCall,
                    pickup_type: activeTab,
                    airport_name:airportName1,
                    remark:remarks,
                    flight_number: flightNo,
                    destination: activeTab === "2" ? destination1 : destination ,
                    extra_stop: getExtraStop(),
                    extra_stop_1:stop1 || undefined,
                    extra_stop_2:stop2 || undefined,
                    pickup_location: pickupLocation,
                    dropoff_location: dropLocation,
                    disposal_hours: getDisposal(),
                    date: dateFormat,
                    time:Time,
                    dateFormat:dateFormat ? dateFormat :'', 
                    number_of_passengers_adult:extraHours,
                    number_of_passengers_child: babySeats ? babySeats: 0,
                    number_of_luggage: waitingCharge ? waitingCharge :0,
                    car_id: car_id,
                    confirmation_status: 1,
                    ride_status: 1
                }),
            });
            let resJson = await res.json();
                if (res.status === 200) {
                   
                   // console.log("Booking Success");
                    setId(resJson.data.booking_id)
                    
                    localStorage.setItem("bookingID67",resJson.data.booking_id)
                    console.log("result of bookingId",resJson.data.booking_id)
                    setSucess(true);
                    setbutton(true)
                    navigate('/booking')
                    setTimeout(()=>{
                        setbutton(false)
                    }, 3000)
                    // setTimeout(() => {
                    //    // setUpdateAlert(false)
                    //    setSucess(false);
                    //    //navigate('/booking');
                    // }, 4000);

                } else {
                    //setUpdateAlert(true)
                    //setupdatestatus(false)
                    setSucess(false)
                    setError({
                        status: true,
                        message: resJson.message,
                        type: 'error'
                    });
                    setTimeout(() => {
                        setError({ status: false, message: '', type: '' });
                    }, 3000);
                    return;
                }
        }
        successStatus(success);
        sendError(error);


        const cancelBooking =()=>{
            console.log("Booking Cancelled");
            navigate("/booking")
        }


        const [car,setCar]=useState("")


        const updateBooking =async ()=>{
            const car_id = selectedCar && selectedCar._id;

            if(!passengerName && !number && !date && !waitingCharge && !extraHours){
                setError({ status: true, message: "Please Enter Required Fields", type: 'error' });
            setTimeout(() => {
                setError({ status: false, message: '', type: '' });
              }, 5000);
            return;
            }

            if(!passengerName){
                setError({ status: true, message: "Please Enter Passenger Name", type: 'error' });
            setTimeout(() => {
                setError({ status: false, message: '', type: '' });
              }, 5000);
            return;
            }

            if(!number){
                setError({ status: true, message: "Please Enter WhatsApp Number", type: 'error' });
            setTimeout(() => {
                setError({ status: false, message: '', type: '' });
              }, 5000);
            return;
            }

            if(extraHours === 0){
                setError({ status: true, message: "Please Enter Number Of Seats", type: 'error' });
            setTimeout(() => {
                setError({ status: false, message: '', type: '' });
              }, 5000);
            return;
            }

            if(!dateFormat){
                setError({ status: true, message: "Please Select Date and Time of Travel", type: 'error' });
            setTimeout(() => {
                setError({ status: false, message: '', type: '' });
              }, 5000);
            return;
            }

            if((activeTab === "1" || activeTab === "2") && !flightNo){
                setError({ status: true, message: "Please Enter Flight Number", type: 'error' });
                setTimeout(() => {
                    setError({ status: false, message: '', type: '' });
                  }, 5000);
                return;
            }

            if(activeTab === "1"  && !destination){
                setError({ status: true, message: "Please Enter Destination", type: 'error' });
                setTimeout(() => {
                    setError({ status: false, message: '', type: '' });
                  }, 5000);
                return;
            }

            if(activeTab === "2"  && !destination1){
                setError({ status: true, message: "Please Enter Destination", type: 'error' });
                setTimeout(() => {
                    setError({ status: false, message: '', type: '' });
                  }, 5000);
                return;
            }


            if((activeTab === "3" || activeTab === "4" || activeTab === "5") && !pickupLocation){
                setError({ status: true, message: "Please Enter Pick Up Location", type: 'error' });
                setTimeout(() => {
                    setError({ status: false, message: '', type: '' });
                  }, 5000);
                return;
            }

            if((activeTab === "3" || activeTab === "4" || activeTab === "5")  && !dropLocation){
                setError({ status: true, message: "Please Enter Drop Location", type: 'error' });
                setTimeout(() => {
                    setError({ status: false, message: '', type: '' });
                  }, 5000);
                return;
            }

            if(!car_id){
              setError({ status: true, message: "Please Select a Car", type: 'error' });
              setTimeout(() => {
                  setError({ status: false, message: '', type: '' });
                }, 5000);
              return;
            }

            let res=  await fetch(config.baseUrl + "api/updatebooking/" + editBookingId, {
                method: "PUT",

                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    passenger_name:passengerName,
                    country_code: "+65",
                    passenger_contact_number:number,
                    first_call: firstCall,
                    second_call: secondCall,
                    pickup_type: activeTab,
                    airport_name:airportName1,
                    remark:remarks,
                    flight_number: flightNo,
                    destination: destination,
                    extra_stop: getExtraStop(),
                    extra_stop_1:stop1 || undefined,
                    extra_stop_2:stop2 || undefined,
                    pickup_location: pickupLocation,
                    dropoff_location: dropLocation,
                    disposal_hours: getDisposal(),
                    date: dateFormat,
                    time: Time,
                    number_of_passengers_adult: extraHours,
                    number_of_passengers_child: babySeats ? babySeats: 0,
                    number_of_luggage: waitingCharge ? waitingCharge :0,
                    car_id: car_id,
                    car_brand:selectedCar ? selectedCar.car_brand : car.car_brand,
                    car_model: selectedCar ? selectedCar.car_model : car.car_model,
                    seat_capacity: selectedCar ? selectedCar.seat_capacity : car.seat_capacity,
                    singapore_disposal_per_hours: selectedCar ? selectedCar.singapore_disposal_per_hours : car.singapore_disposal_per_hours,
                    malaysia_disposal_per_hours: selectedCar ? selectedCar.malaysia_disposal_per_hours : car.malaysia_disposal_per_hours,
                    extra_stop_surcharge: selectedCar ? selectedCar.extra_stop_surcharge : car.extra_stop_surcharge,
                    midnight_surcharge_per_hours: selectedCar ? selectedCar.midnight_surcharge_per_hours : car.midnight_surcharge_per_hours,
                    waiting_time_per_minutes: selectedCar ? selectedCar.waiting_time_per_minutes : car.waiting_time_per_minutes,
                    confirmation_status: confirmStatus,
                    ride_status: rideStatus
                }),
            });
            let resJson = await res.json();
            if (res.status === 200) {

                console.log("Booking Success",resJson);

            setSucess(true);
            setTimeout(() => {
               //setUpdateAlert(false)
               setSucess(false);
               setbutton(false);
               navigate('/booking');             
            }, 3000);

        } else {
            console.log(resJson.message);
            setSucess(false);
            setError({
                status: true,
                message: resJson.message,
                type: 'error'
            });
            setTimeout(() => {
                setError({ status: false, message: '', type: '' });
            }, 3000);
            return;
        }
    }
    useEffect(() => {   
        if(selectedCar === null){
            if(editDetails){
                setCar(editDetails.car_info)
            }
           
        }

      }, [selectedCar,dateFormat,success,isCancel]);

      const handleChange = () => {
        setIsCancel(!isCancel)
       // if(isCancel){
            setDeleteModal(!deleteModal);
       // }
       

      };

      const handleDeleteBooking = async ()=>{
        console.log("Inside Delete",editBookingId)
        let res = await fetch(config.baseUrl + "api/cancel_booking/"+editBookingId , {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                passenger_name:passengerName,
                country_code: "+65",
                passenger_contact_number:number,
                pickup_type: activeTab,
                flight_number: flightNo,
                destination: destination,
                extra_stop: getExtraStop(),
                pickup_location: pickupLocation,
                dropoff_location: dropLocation,
                disposal_hours: getDisposal(),
                date: dateFormat,
                time: Time,
                number_of_passengers_adult: extraHours,
                number_of_passengers_child: babySeats ? babySeats: 0,
                number_of_luggage: waitingCharge ? waitingCharge :0,
                car_id: selectedCar ? selectedCar._id : car._id,
                car_brand:selectedCar ? selectedCar.car_brand : car.car_brand,
                car_model: selectedCar ? selectedCar.car_model : car.car_model,
                seat_capacity: selectedCar ? selectedCar.seat_capacity : car.seat_capacity,
                singapore_disposal_per_hours: selectedCar ? selectedCar.singapore_disposal_per_hours : car.singapore_disposal_per_hours,
                malaysia_disposal_per_hours: selectedCar ? selectedCar.malaysia_disposal_per_hours : car.malaysia_disposal_per_hours,
                extra_stop_surcharge: selectedCar ? selectedCar.extra_stop_surcharge : car.extra_stop_surcharge,
                midnight_surcharge_per_hours: selectedCar ? selectedCar.midnight_surcharge_per_hours : car.midnight_surcharge_per_hours,
                waiting_time_per_minutes: selectedCar ? selectedCar.waiting_time_per_minutes : car.waiting_time_per_minutes,
                confirmation_status: 3,
                ride_status: rideStatus
            }),
        }); 
          let resJson = await res.json();
          if (res.status === 200) {
            navigate('/booking');
            setIsCancel(false);
          }else {
            console.log(resJson.message);
            setSucess(false);
            setError({
                status: true,
                message: resJson.message,
                type: 'error'
            });
            setTimeout(() => {
                setError({ status: false, message: '', type: '' });
            }, 3000);
            return;
        }
        setDeleteModal(!deleteModal);
      }

     
    
    return(
        <Card className="position-sticky sidebar-sticky">
        <CardHeader className="border-bottom-dashed">
        <div id="top"></div><h4 className="card-title mb-0">Booking Info</h4>
        <p className="text-muted mb-0 mt-3">Hotel Name <span className="fw-medium text-dark d-block">{HotelName}</span></p>
        <p className="text-muted mb-0 mt-1">Manager Name <span className="fw-medium text-dark d-block">{Name}</span></p>
        </CardHeader>  
        <CardBody className="form-steps">
            <Form className="vertical-navs-step">
                <div className="col-lg-12">
                    <div className="offcanvas-body border-radius-10" >
                        <div className="mb-3">
                        {passengerName ? <div>
                            <Label className="form-label text-muted mb-0 fs-14">Passenger Info</Label>
                            <hr className="m-2 ms-0 me-0 border-lght-2"/>
                            </div> :''}
                            
                            <div className="flex-grow-1">
                              {passengerName ? <h5 className="fs-14 mb-1"> <Link to="#" className="text-dark">{passengerName}</Link ></h5>:''}  
                               {number && passengerName ?  <p className="text-muted mb-0"><span className="fw-medium text-secondary">{countryCode} {number}</span></p>:''}
                               {extraHours && passengerName?  <p className="text-muted mb-0 mt-3">Passenger Seat : <span className="fw-medium text-dark">{extraHours}</span></p>:''}
                                {babySeats && passengerName ? <p className="text-muted mb-0">Baby Seat : <span className="fw-medium text-dark">{babySeats}</span></p>:''}
                                {waitingCharge && passengerName ? <p className="text-muted mb-0">Luggage : <span className="fw-medium text-dark">{waitingCharge}</span></p>:''}
                            </div>
                        </div>
                        <div className="mb-3">
                           
                            {number && passengerName ? <div> 
                                <hr className="m-2 ms-0 me-0 border-lght-2"/>
                                <Label for="datepicker-range" className="form-label text-muted mb-0 fs-14">Travel Info</Label>
                                <hr className="m-2 ms-0 me-0 border-lght-2" />
                                </div> :''}
                            
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1">
                                    
                                   {number && passengerName ? <div>
                                    <p className="text-muted mb-0 mt-2">Service Type </p> 
                                    <h5 className="fs-14 mb-1"> <Link to="#" className="text-dark">{tabName}</Link ></h5>
                                   </div>:'' } 
                                   {activeTab === "1"  && passengerName && (
  <div>
    <p className="text-muted mb-0 mt-2">Destination</p>
    <h5 className="fs-14 mb-1">
      <Link to="#" className="text-dark">
        {destination}
      </Link>
    </h5>
  </div>
)}

{activeTab === "2"  && passengerName && (
  <div>
    <p className="text-muted mb-0 mt-2">Destination</p>
    <h5 className="fs-14 mb-1">
      <Link to="#" className="text-dark">
        {destination1}
      </Link>
    </h5>
  </div>
)}
                                    {activeTab !== "3" && activeTab !== "4" && activeTab !== "5" && passengerName &&  airportName1 && ( <p className="text-muted mb-0 mt-2">Airport Name : <span className="fw-medium text-dark">{airportName1}</span></p>)}
                                    {activeTab !== "3" && activeTab !== "4" && activeTab !== "5" && passengerName && flightNo && ( <p className="text-muted mb-0 mt-2">Flight Number : <span className="fw-medium text-dark">{flightNo}</span></p>)}
                                    {(extraStop || airDepExtraStop ||pointtotPointExtraStop) && passengerName ? <p className="text-muted mb-0 mt-2">Extra Stop :  <span className="fw-medium text-dark">{getExtraStop()}</span></p>:''}
                                    { stop1  ? <div> <p className="text-muted mb-0 mt-3">Stop1:  <Link to="#" target="_blank" 
                                    className="text-dark" style={{width:"280px"}}>{stop1}</Link ></p></div>:''}
                                { stop2  ? <div> <p className="text-muted mb-0 mt-3">Stop2:  <Link to="#" target="_blank" 
                                    className="text-dark" style={{width:"280px"}}>{stop2}</Link ></p></div>:''}
                                    {pickupLocation && passengerName ? <p className="text-muted mb-0 mt-2">Pickup Location :<span className="fw-medium text-dark">{pickupLocation}</span></p>:''}
                                    {dropLocation && passengerName ?  <p className="text-muted mb-0 mt-0">Drop Location :<span className="fw-medium text-dark">{dropLocation}</span></p>:''}
                                   {activeTab === "4" && passengerName  && <p className="text-muted mb-0 mt-2">Disposal Hours : <span className="fw-medium text-dark">{fromDisposel}</span></p>}
                                   {activeTab === "5" && passengerName && <p className="text-muted mb-0 mt-2">Disposal Hours : <span className="fw-medium text-dark">{toDisposel}</span></p>}
                                   { passengerName && date ? <div> <p className="text-muted mb-0 mt-3">Booking Date</p>
                                    <h5 className="fs-14 mb-1"> <Link to="#" target="_blank" className="text-dark">{date} : {Time}</Link ></h5></div>:''}
                                    { remarks  ? <div> <p className="text-muted mb-0 mt-3">Remarks</p>
                                    <h5 className="fs-14 mb-1" style={{width:"280px"}}> <Link to="#" target="_blank" 
                                    className="text-dark">{remarks} </Link ></h5></div>:''}
                                </div>
                            </div>
                        </div>
                        <div className="mb-0">
                            
                           {selectedCar ?<div>
                            <hr className="m-2 ms-0 me-0 border-lght-2" />
                            <Label for="datepicker-range" className="form-label text-muted mb-0 fs-14">Car &amp; Payment Info</Label>
                            <hr className="m-2 ms-0 me-0 border-lght-2" />
                           </div>:( car ?<div>
                            <hr className="m-2 ms-0 me-0 border-lght-2" />
                            <Label for="datepicker-range" className="form-label text-muted mb-0 fs-14">Car &amp; Payment Info</Label>
                            <hr className="m-2 ms-0 me-0 border-lght-2" />
                           </div>:'')}
                          
                            
                            <div className="row g-2">
                                <div className="col-lg-6">
                                    <div className="form-check p-0">
                                    {selectedCar ? <div>
                                            <h5 className="fs-14 mb-1"> <Link to="#" className="text-dark">{selectedCar.car_brand}</Link ></h5>
                                        <p className="text-muted mb-0 mt-0">{selectedCar.car_model}</p>
                                        </div> : <div>
                                            <h5 className="fs-14 mb-1"> <Link to="#" className="text-dark">{car.car_brand}</Link ></h5>
                                        <p className="text-muted mb-0 mt-0">{car.car_model}</p>
                                        </div> }
                                                                            
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                {selectedCar ? <div className="form-check p-0">
                                        <h5 className="fs-14 mb-1"> 
                                        {activeTab !== "4" && activeTab !== "5" && (<Link to="#" className="text-dark">${selectedCar.airport_arrival}</Link >)}
                                        {activeTab === "4" && (<Link to="#" className="text-dark">${selectedCar.singapore_disposal_per_hours}</Link >)}
                                        {activeTab === "5"  && (<Link to="#" className="text-dark">${selectedCar.malaysia_disposal_per_hours}</Link >)}
                                        </h5>
                                        <p className="text-muted mb-0 mt-0">Singapore Dollar</p>
                                    </div>:( car ? <div className="form-check p-0">
                                        <h5 className="fs-14 mb-1"> 
                                        {activeTab !== "4" && activeTab !== "5" && (<Link to="#" className="text-dark">${car.airport_arrival}</Link >)}
                                        {activeTab === "4" && (<Link to="#" className="text-dark">${car.singapore_disposal_per_hours}</Link >)}
                                        {activeTab === "5"  && (<Link to="#" className="text-dark">${car.malaysia_disposal_per_hours}</Link >)}
                                        </h5>
                                        <p className="text-muted mb-0 mt-0">Singapore Dollar</p>
                                    </div>:'')}
                                </div>
                                    <div className="mb-0 mt-4">
                                 {/* { isEdit ?    <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked={isCancel} onChange={() => handleChange()}/>
                                    <label className="form-check-label" >Do you want cancel the booking?</label>
                                </div> :''} */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Form>
        </CardBody>   
        <CardHeader>

            <div className="d-flex align-items-start gap-3 mt-0">
            {isEdit ? (<Link to="top" smooth={true} duration={100} offset={-window.innerHeight}>
            <Button onClick={updateBooking} className="btn btn-success add-btn"><i className="ri-check-fill align-bottom me-1"></i> Update Booking</Button> </Link>) : 
            !button && (<Link to="top" smooth={true} duration={100} offset={-window.innerHeight}>
            <Button onClick={createBooking} className="btn btn-success add-btn"><i className="ri-check-fill align-bottom me-1"></i> Book Now </Button>
            </Link>)
            }
            <Button onClick={cancelBooking} className="btn btn-light border_gry"> Cancel </Button>
            </div>
        </CardHeader>    
        <CancelModal
        show={deleteModal}
        onDeleteClick={handleDeleteBooking}
        onCloseClick={() =>{
          setIsCancel(false);
          setDeleteModal(false)}}
        entityName="Booking"
        //entityId={selectedId}
        entityId={bookingId}
      />           
    </Card>
    
    )
}

export default BookingInfoSideBar