import React from 'react';
import { projectsWidgets } from '../../common/data';
import CountUp from "react-countup";
import { useState,useEffect } from "react"
//Import Icons
import FeatherIcon from "feather-icons-react";
import { Card, CardBody, Col, Row } from 'reactstrap';

const Widgets = ({ bookingCount1 }) => {

    const [updatedProjectsWidgets, setUpdatedProjectsWidgets] = useState([]);
   // console.log(bookingCount1,"INSIDE WIDGETS------------")
    const projectsWidgets1 = [
        {
            id: 1,
            feaIcon: "briefcase",
            feaIconClass: "primary",
            label: "Total booking",
            badgeClass: "danger",
            icon: "ri-arrow-down-s-line",
          //  percentage: "5.02 %",
          //  caption: "Projects this month",
            subCounter: [{ id: 1, counter: "825", suffix: "" }]
        },
        {
            id: 2,
            feaIcon: "award",
            feaIconClass: "primary",
            label: "Confirm booking",
            badgeClass: "success",
            icon: "ri-arrow-up-s-line",
           // percentage: "3.58 %",
          //  caption: "Leads this month",
            subCounter: [{ id: 1, counter: "620", suffix: "", separator: "," }]
        },
        {
            id: 3,
            feaIcon: "clock",
            feaIconClass: "primary",
            label: "Cancelled booking",
            badgeClass: "success",
            icon: "ri-arrow-down-s-line",
         //   percentage: "3.58 %",
          //  caption: "Work this month",
            subCounter: [{ id: 1, counter: "205", suffix: "" }]
        }
    ];

        
    useEffect(() => {
        const updatedWidgets = projectsWidgets1.map(widget => {
            const matchingKey = widget.label.toLowerCase().replace(' ', '_');
            const count = bookingCount1[matchingKey] || 0;
      
            const updatedSubCounter = widget.subCounter.map(subCounter => ({
              ...subCounter,
              counter: count.toString()
            }));


      
            return {
              ...widget,
              subCounter: updatedSubCounter
            };
          });
          
          const confirmBookingCount = bookingCount1['accepted_booking'] || 0;

            updatedWidgets.forEach(widget => {
            if (widget.label === 'Confirm booking') {
                widget.subCounter[0].counter = confirmBookingCount.toString();
            }
            });
        setUpdatedProjectsWidgets(updatedWidgets);
      }, [bookingCount1]);

      //console.log(updatedProjectsWidgets,"AFTER UPDATING")

    return (
        <React.Fragment>
            <Row>
                {(updatedProjectsWidgets || []).map((item, key) => (
                    <Col sm={6} md={4} lg={4} xl={4} key={key}>
                        <Card className="card-animate">
                            <CardBody>
                                <div className="d-flex align-items-center">
                                    <div className="avatar-sm flex-shrink-0">
                                        <span className={`avatar-title bg-soft-${item.feaIconClass} text-${item.feaIconClass} rounded-2 fs-2`}>
                                            <FeatherIcon icon={item.feaIcon} className={`text-${item.feaIconClass}`} />
                                        </span>
                                    </div>
                                    <div className="flex-grow-1 overflow-hidden ms-3">
                                        <p className="text-uppercase fw-medium text-muted text-truncate mb-3">{item.label}</p>
                                        <div className="d-flex align-items-center mb-3">
                                            <h4 className="fs-4 flex-grow-1 mb-0">
                                                {item.subCounter.map((item,key)=>(<span className="counter-value me-1" data-target="825" key={key}>
                                                    <CountUp
                                                        start={0}
                                                        suffix={item.suffix}
                                                        separator={item.separator}
                                                        end={item.counter}
                                                        duration={4}
                                                    />
                                                </span>))}
                                            </h4>
                                            {/* <span className={"fs-12 badge badge-soft-" + item.badgeClass}><i className={"fs-13 align-middle me-1 " + item.icon}></i>{item.percentage}</span> */}
                                        </div>
                                      
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                ))}
            </Row>
        </React.Fragment>
    );
};

export default Widgets;