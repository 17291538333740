import React from "react";
import { Col, Input, Row } from "reactstrap";

import { Link } from 'react-router-dom';
import { useState,useEffect } from "react"
const productname = [
  {
    options: [
      { label: "Naveen Rajanderan", value: 0 },
      { label: "Vetrivel Rajesh", value: 1 },
      { label: "Ajith Kumar Mohan", value: 2 },
      { label: "Jeyasri Selvaraj", value: 3 },
    ],
  },
];
const Section = ({props,title, pageTitle}) => {
  const userType=localStorage.getItem("userType");
  //const USERNAME= localStorage.getItem("username");first_name
  let username1;
  //const [bookingCount1,setBookingCount1]=useState("")

  if (userType === "1") {
    username1 =  localStorage.getItem("username");
  } else {
    username1 = localStorage.getItem("hotelname");
  }



 




  return (
    <React.Fragment>
      <Row className="mb-3 pb-1">
        <Col xs={12}>
          <div className="d-flex align-items-lg-center flex-lg-row flex-column">
            <div className="flex-grow-1">
              {/* <h4 className="fs-16 mb-1">Good Morning, {username1}!</h4>
              <p className="text-muted mb-0">
              Here's what's happening with your store today.&nbsp;
               </p> */}
               <div className=" d-sm-flex align-items-center justify-content-between">
                <h4 className="fs-16 mb-1">Good Morning, {username1}!
                <h6 className="text-muted mb-0">
                    Here's what's happening with your hotel today&nbsp;
               </h6>
                </h4>
                {/* <p className="text-muted mb-0">
                    Here's what's happening with your store today.
                </p> */}
                        <div >
                            <ol className="breadcrumb m-0">
                                {userType==="1" ? 
                                <li className="breadcrumb-item">
                                    <Link to="/dashboard">{pageTitle}</Link></li>:

                                    <li className="breadcrumb-item">
                                    <Link to="/booking">{pageTitle}</Link></li>
                                }   
                                <li className="breadcrumb-item active">{title}</li>
                            </ol>
                        </div>

                    </div>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};
export default Section;
