/* eslint-disable no-debugger */
import React from 'react';
import { Link } from "react-router-dom"
import { Label, OffcanvasBody } from "reactstrap"
import { BookingHistory } from './data';
import { useEffect ,useState} from 'react';
import config from "../../../common/config";
const ShareViewBooking = ({data, setSideBarTile,bId,passengerSeat,passengername,passengernumber,carmodel,confirmed_time,viewArray,ID,babyseat,luggage,bookstatus,destination,fnumber,pickup,drop,traveldate,extrastop, extraStops, traveltime,hotel,booktype,extrahrs,midHours,
                         setShowSideBar,ridestatus,dspslhrs,carbrand,drivername,drivernumber,drivers,book_info,ptp,airportdeparture,airportarrival,singaporedspsl,malaysiadspsl,extra, midnight,waiting, hpayment,remarks,extraDisposalHours,total, dpayment,dremarks}) => {
console.log("hotelname:",hotel)
const hasNullValues = viewArray.booking_history.every(item => item !== null);
  
console.log(data);
useEffect(()=>{
    setSideBarTile('View Booking ' +bId)
})

const fetchinvoice=()=>
{
console.log(ID)
fetch(config.baseUrl +'api/invoice/' +ID)
.then((response) => {
    if(response.ok) {
        return response.blob();
    } else {
        return Promise.reject(response);
    }
})
.then((blob) => {
const url = URL.createObjectURL(blob);
const downloadLink = document.createElement('a');
downloadLink.href = url;
downloadLink.download = 'Invoice.pdf';
downloadLink.click();
URL.revokeObjectURL(url);
})
.catch((error) => {
    error.json().then((jsonError) => {
        console.error('Error downloading PDF:', jsonError);
    });
});
}
 
const getDriverDetails =(id)=>{
    // console.log(id,"DRIVER ID")
     if(id !== undefined || id !== null){
       if(drivers){
         const driverArrray = Object.values(drivers);
         const driver = driverArrray.find((obj) => obj._id === id);
         if(driver){
           const phoneNumber = driver && driver.phone_number;
           return driver.country_code + ' ' + phoneNumber;
         }else{
           return ""
         }
         
         }else{
           return ""
         }
     }else{
       return "";
     }
     
     
   }

   const getDriverDetails1 =(id)=>{
    // console.log(id,"DRIVER ID")
     if(id !== undefined || id !== null){
       if(drivers){
         const driverArrray = Object.values(drivers);
         const driver = driverArrray.find((obj) => obj._id === id);
         if(driver){
           const carNumber = driver && driver.car_number;
          
           return carNumber;
         }else{
           return ""
         }
         
         }else{
           return ""
         }
     }else{
       return "";
     }
     
     
   }

const firstname23 = localStorage.getItem("firstname")

console.log(extraStops, 'extraStops')
const userType = localStorage.getItem("userType");

    return (
        
        <React.Fragment>
            <div className='d-flex flex-column justify-content-end h-100 canvas-right-sidebar'>
                <OffcanvasBody>
                    <div className="mb-4">
                        <label className="form-label text-muted mb-0 fs-14">Passenger Info</label>
                        <hr className="m-2 ms-0 me-0" />
                        <div className="flex-grow-1"> 
                        <h5 className="fs-14 mb-1">{passengername} <Link to="#" className="text-dark"></Link></h5>
                            <p className="text-secondary fw-medium  mb-0">{data?.country_code} {passengernumber}<span className="fw-medium text-secondary"></span></p>
                            <p className="text-muted mb-0 mt-3">Passenger Seat : <span className="fw-medium text-dark">{passengerSeat}</span></p>
                            {babyseat!=0 && <p className="text-muted mb-0">Baby Seat : <span className="fw-medium text-dark">{babyseat}</span></p>}
                            <p className="text-muted mb-0">Luggage : <span className="fw-medium text-dark">{luggage}</span></p>
                            <p className="text-muted mb-0 mt-3">Booking Status: 
                                {bookstatus ==="Requested" ? ( <span className="fw-medium p-1 text-warning">{bookstatus}</span>) : ''}
                                {bookstatus ==="Accepted" ? ( <span className="fw-medium p-1 text-success">{bookstatus}</span>) : ''}
                                {bookstatus ==="Cancelled" ? ( <span className="fw-medium p-1 text-danger">{bookstatus}</span>) : ''}
                            </p>
                        </div>
                    </div>
                    <div className="mb-4">
                        <hr className="m-2 ms-0 me-0" />
                        <label className="form-label text-muted mb-0 fs-14">Travel Info</label>
                        <hr className="m-2 ms-0 me-0" />
                        <div className="d-flex align-items-center">
                            <div className="flex-grow-1">
                                <h5 className="fs-14 mb-1"> <Link to="#" className="text-dark">{booktype}</Link></h5>
                                {data && viewArray.pickup_type !== "3" && viewArray.pickup_type !== "4" && viewArray.pickup_type !== "5" && (<>
                                    <p className="text-muted mb-0 mt-3">Pickup Location: <span className="fw-medium text-dark d-block">{data?.pickup_type === '1' ?  data.airport_name : data.destination}</span></p>
                                    <p className="text-muted mb-0">Drop Location: <span className="fw-medium text-dark d-block">{data.pickup_type === '1' ?  data.destination : data.airport_name}</span></p>
                                </>
                                )}
                                {fnumber?<p className="text-muted mb-0 mt-3">Flight Number : <span className="fw-medium text-dark">{fnumber}</span></p>:""}
                                {/* <p className="text-muted mb-0 mt-3">Travel Type : <span className="fw-medium text-dark"></span></p> */}
                                {extrastop?<p className="text-muted mb-0 mt-0">Extra Stop : <span className="fw-medium text-dark">{extrastop}</span></p>:""}
                                {extraStops?.[0] ? <p className="text-muted mb-0 mt-0">Stop 1: <span className="fw-medium text-dark">{extraStops[0]}</span></p>:""}
                                {extraStops?.[1] ? <p className="text-muted mb-0 mt-0">Stop 2: <span className="fw-medium text-dark">{extraStops[1]}</span></p>:""}
                                {pickup?<p className="text-muted mb-0 mt-3">Pickup Location: <span className="fw-medium text-dark d-block">{pickup}</span></p>:""}
                                {drop?<p className="text-muted mb-0 mt-0">Drop Location: <span className="fw-medium text-dark d-block">{drop}</span></p>:""}
                                {viewArray.pickup_type=== "4" && <p className="text-muted mb-0 mt-0">
                                <span className="table-label">Disposal Hours:</span>
                                <span className="fw-medium text-dark">{dspslhrs}</span></p>}
                                {viewArray.pickup_type=== "5"  && <p className="text-muted mb-0 mt-0">
                                <span className="table-label">Disposal Hours:</span>
                                <span className="fw-medium text-dark">{dspslhrs} </span></p>}
                                <p className="text-muted mb-0 mt-3">Travel Date &amp; Time : <span className="fw-medium text-dark d-block">{traveldate} : {traveltime}</span></p>
                                {data?.remark ? <p className="text-muted mb-0 mt-3">Remarks: <span className="fw-medium text-dark d-block">{data?.remark}</span></p> : ''}
                                <p className="text-muted mb-0 mt-3">Hotel Name: <span className="fw-medium text-dark d-block">{hotel}</span></p>
                                <p className="text-muted mb-0"></p>
                                <p className="text-muted mb-0 mt-3">Ride Status : {ridestatus==="Not Yet"?( <span className="fw-medium text-danger">{ridestatus}</span>):( <span className="fw-medium text-success">{ridestatus}</span>)}</p>
                           
                            </div>
                        </div>
                    </div>
                    <div className="mb-4">
                        <hr className="m-2 ms-0 me-0" />
                        <Label className="form-label text-muted mb-0 fs-14">Car Info</Label>
                        <hr className="m-2 ms-0 me-0" />
                        <div className="d-flex align-items-center">
                            <div className="flex-grow-1">
                            <h5 className="fs-14 mb-1"> <Link to="#" className="text-dark">{carbrand}-{carmodel}</Link></h5>

                            {bookstatus==="Accepted" && <div>
                                <p className="text-muted mb-0 mt-3">Assign Driver: <span className="fw-medium d-block text-dark text-uppercase">{drivername}</span></p>
                                <p className="text-secondary fw-medium  mb-0">{getDriverDetails(viewArray.driver_id)}</p></div>}
                            {bookstatus==="Accepted" && <div>
                                <p className="text-muted mb-0 mt-3">Car Plate No: </p>
                                <p className="fw-medium d-block text-dark text-uppercase">{getDriverDetails1(viewArray.driver_id)}</p></div>}

                            </div>
                        </div>
                    </div>
                  

<div className="mb-4">
                        <hr className="m-2 ms-0 me-0" />
                        <label className="form-label text-muted mb-0 fs-14">Dates</label>
                        <hr className="m-2 ms-0 me-0" />
                        <div className="row g-2">
                            <div className="col-lg-6">
                                <div className="p-0">
                                    <p className="text-muted mb-0 mt-0">Booking Date</p>
                                    <h5 className="fs-14 mb-1"> <Link to="#" className="text-dark">{book_info}</Link></h5>
                                    <p className="text-muted mb-0 mt-0"><span className="fw-medium text-dark d-block"></span></p>
                                </div>
                            </div>
                            {bookstatus==="Accepted" && (
                             <div className="col-lg-6">
                                <div className="p-0">
                                <p className="text-muted mb-0 mt-0">Confirmation Date</p>
                                    <h5 className="fs-14 mb-1"> <Link href="#" className="text-dark">{confirmed_time}</Link></h5>
                                    <p className="text-muted mb-0 mt-0"><span className="fw-medium text-dark d-block"></span></p>
                                </div>
                            </div> )}
                        </div>



                </div>
                    <div className='mb-3'>
                        <hr className="m-2 ms-0 me-0" />
                        <label className="form-label text-muted mb-0 fs-14">Payment Info</label>
                        <hr className="m-2 ms-0 me-0" />
                        <div className='d-flex align-items-center'>
                            <div className="flex-grow-1">
                                <h5 className="fs-14 mb-1 mt-2"><span className="sidebar-lable-text">Booking Payment:</span> <Link to="#" className="text-dark sidebar-value">${total}</Link></h5>
                                {viewArray.pickup_type === "1" && airportarrival > 0 && (  <p className="text-muted mb-0 mt-3"><span className="sidebar-lable-text">Airport Arrival :</span> <span className="fw-medium text-dark">${airportarrival}</span></p>)}
                                {viewArray.pickup_type === "2" && airportdeparture > 0 &&  (<p className="text-muted mb-0"><span className="sidebar-lable-text">Airport Departure :</span> <span className="fw-medium text-dark">${airportdeparture}</span></p>)}
                                {viewArray.pickup_type === "3" && ptp > 0 && (<p className="text-muted mb-0"><span className="sidebar-lable-text">Point to Point :</span> <span className="fw-medium text-dark">${ptp}</span></p>)}
                                {viewArray.pickup_type === "4" && singaporedspsl > 0 &&( <p className="text-muted mb-0"><span className="sidebar-lable-text">Singapore Disposal :</span> <span className="fw-medium text-dark">${singaporedspsl}</span></p>)}
                                {viewArray.pickup_type === "5" && malaysiadspsl > 0 && (<p className="text-muted mb-0"><span className="sidebar-lable-text">Malaysia Disposal :</span> <span className="fw-medium text-dark">${malaysiadspsl}</span></p>)}
                                {extraDisposalHours > 0 && (<p className="text-muted mb-0"><span className="sidebar-lable-text">Extra Hours For Disposal ({extrahrs}Hrs) :</span> <span className="fw-medium text-dark">${extraDisposalHours}</span></p>)}
                                {midnight > 0 && (<p className="text-muted mb-0 mt-3"><span className="sidebar-lable-text">Midnight Surcharge ({midHours} Hrs) :</span> <span className="fw-medium text-dark">${midnight}</span></p>)}
                                {viewArray.pickup_type !== "4" && viewArray.pickup_type !=="5" && extra > 0 && (<p className="text-muted mb-0"><span className="sidebar-lable-text">Extra Stop Surcharge ({data.additional_extra_stop + data.extra_stop} Stop):</span> <span className="fw-medium text-dark">${extra}</span></p>)}
                                {viewArray.pickup_type !=="4" && viewArray.pickup_type !=="5" && waiting > 0 &&(<p className="text-muted mb-0"><span className="sidebar-lable-text">Waiting Time Surcharge ({data.waiting_minutes * 15} Mins) :</span> <span className="fw-medium text-dark">${waiting}</span></p>)}
                                {/* <p className="text-muted mb-0 mt-3"><span className="sidebar-lable-text">Hotel Payment :</span> {hpayment==="Paid"?(<span className="fw-medium text-success">{hpayment}</span>):(<span className="fw-medium text-danger">{hpayment}</span>)}</p> */}
                                <p className="text-muted mb-0 p-3 bg-light mt-3"><span className="sidebar-lable-text">Additional Remarks :</span> <span className="fw-medium text-dark d-block">{remarks}</span></p>
                                {Number(userType) === 1 ? <>
                                    <p className="text-muted mb-0 mt-3"><span className="sidebar-lable-text">Driver Payment :</span>{dpayment==="Paid"?( <span className="fw-medium text-success">{dpayment}</span>):(<span className="fw-medium text-danger">{dpayment}</span>)}</p>
                                    <p className="text-muted mb-0 p-3 bg-light"><span className="sidebar-lable-text">Remarks :</span> <span className="fw-medium text-dark d-block">{dremarks}</span></p>
                                </> : null}
                            </div>
                        </div>
                    </div>
                    <div>
                        {/* <hr className="m-2 ms-0 me-0" />
                        <label className="form-label text-muted mb-0 fs-14">Booking History</label>
                        <hr className="m-2 ms-0 me-0" /> */}
                        <div className="profile-timeline">
                           
                                 
                        <div
                                            className="accordion accordion-flush"
                                            id="accordionFlushExample" >
                                               {/* { hasNullValues ? (viewArray.booking_history.map((item, i) => (

                                            <div className="accordion-item border-0" key={i}>
                                                <div className="accordion-header" id="headingOne">
                                                    <Link to="#" className="accordion-button p-2 shadow-none">
                                                        <div className="d-flex align-items-center" style={{marginTop:10}}>
                                                            <div className="flex-shrink-0 avatar-xs" style={{marginTop:0}}>
                                                                <div className="avatar-title bg-success rounded-circle" style={{marginTop:-80}}>
                                                                    <i className='ri-account-circle-fill'  ></i>
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                               
                                                                {Object.keys(item).map((key) => {
                                                                    const bookingData = item[key];

                                                                    return (
                                                                    <div key={key} className="text-dark"  >
                                                                         <h6 className="fs-14 mb-0">
                                                                      <p className="text-dark">
                                                                        {key}</p>
                                                                        </h6>
                                                                        {Object.entries(bookingData).map(([subKey, value]) => (

                                                                            <div  className="text-dark" key={subKey}>
                                                                                
                                                                                <p className="mb-1">{subKey} <p className="text-muted">{value}</p>
                                                                                </p>
                                                                            </div>
                                                                        ))}

                                                                    </div>
                                                                    );
                                                                })}

                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div

                                                >
                                                    
                                                </div>
                                            </div>
                                             ))

                                               ) : <p></p>} */}
                                        </div>
                                  
                               

                        </div>
                    </div>
                </OffcanvasBody>
                <div className="offcanvas-footer border-top p-3 text-center hstack gap-2 bg-light position-sticky bottom-0">
                    <button type="submit"
                        className="btn btn-success w-100" onClick={()=>fetchinvoice(ID)}
                    >
                        Download Invoice
                    </button>
                    <button
                        type="submit"
                        className="btn  btn-outline-light w-100 border-color-b9b9b9" 
                        onClick={()=>{setShowSideBar(false)}}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </React.Fragment>
    )
    
}

export default ShareViewBooking