import { Button, Card, CardBody, Col, Container, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row,UncontrolledAlert } from "reactstrap"
import BreadCrumb from "../../Components/Common/BreadCrumb"

import DataTable from "react-data-table-component"
import React from "react"
import { carBrandList, cityList, countryList, } from "./data"
import { useMemo, useEffect, } from "react"
import FilterComponent from '../../Components/datatable/filter'
import { Link } from "react-router-dom"
import { useState } from "react"
import DeleteModal from "../../Components/Common/DeleteModal"
//import LoadingSpinner from "./loader"
import Select from "react-select";
import NoResultData from "../../Components/datatable/no-result"
import CustomPagination from "../../Components/custom-pagination/pagination";
import config from "../../common/config"
import "../PrimaryUserOnboard/car.css";
import { number } from "echarts"
// eslint-disable-next-line react/display-name
const TableCheckBox = React.forwardRef(({ onClick, ...rest }, ref) => (
    <div className="form-check form-check-size-16" >
        <input
            htmlFor="booty-check"
            type="checkbox"
            className="form-check-input"
            ref={ref}
            onClick={onClick}
            {...rest}
        />
        <label className="form-check-label" id="booty-check" />
    </div>
))
const DriverOnBoarding = () => {
    const [isAdd, setIsAdd] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false);
    const [isActiveChecked, setIsActiveChecked] = useState(true);
    const [selectedId, setSelectedId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [DId,setDId]= useState();
    const [validMail,setValidMail]=useState("")
    // Delete APi
    const handleDeleteData = (driver_id,_id) => {
        console.log("Selected driver Id is", driver_id);
        setSelectedId(_id);
        setDId(driver_id)
        setDeleteModal(!deleteModal);
    }
    const handleDeleteData1 = () => {
        if (selectedId) {
            console.log(config.baseUrl + 'api/admin/deletedriver/' + selectedId);
            fetch(config.baseUrl + 'api/admin/deletedriver/' + selectedId, {
                method: 'DELETE'
            }).then((response) => response.json())
                .then((data) => {
                    console.log(data);
                    driverlistAPI();
                });
        }
        setDeleteModal(!deleteModal);
    }
    const [selectCarBrand, setSelectCarBrand] = React.useState(null);
    const handleCarBrand = (selectCarBrand) => {
        setSelectCarBrand(selectCarBrand)
    }
    const [pageNumber, setPageNumber] = useState(1)
    const [selectCountry, setSelectCountry] = React.useState(null)
    const handleSelectCountry = (selectCountry) => {
        setSelectCountry(selectCountry)
    }
    const [selectCity, setSelectCity] = React.useState(null);
    const handleSelectCity = (selectCity) => {
        setSelectCity(selectCity)
    }
    const handleCloseDialog = () => {
        setIsAdd(false)
        setUpdateAlert(false)
        setupdatestatus(false)
        setIsEdit(false)
         setAddress1("")
         setAddress2("")
         setEmail("")
         setNumber("")
         setFirstname("")
        setLastname("")
        setSelectedcar("")
        setCarNumber("")
        setValidMail("")
        setShowError(false)
        setShowcontactError(false)
    }
    const handleAddDialog = () => {
        setIsAdd(true)
        setIsEdit(false)
    }

    useEffect(() => {
        driverlistAPI();
        fetchCategory()
    }, [pageNumber]);

    const [totalpages, setTotalpages] = useState('')
    const [driverlist, setDriverlist] = useState([])
    const driverlistAPI = async ()=> {
        console.log("API CALL");
        setIsLoading(true)
        await fetch(config.baseUrl + "api/admin/driverlist", {
            method: "POST",

            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                page: pageNumber,
                limit: 10,
            }),
        })
        .then((response) => response.json())
        .then((data) => {
                console.log(data);
                setDriverlist(data.data);
                setTotalpages(data.totalPages)
                setTotalcount(data.totalCount)

            });
            setIsLoading(false)
        }

            const handlePageChange = async(pageNumber) => {
                //callAPI(pageNumber)
                console.log("pagenumber",pageNumber)
                console.log(JSON.stringify({
                 page: pageNumber,
                 limit: perpage,
             }));
             await fetch(config.baseUrl + "api/admin/driverlist", {
                 method: "POST",

                 headers: {
                     "Content-Type": "application/json",
                 },
                 body: JSON.stringify({
                     page: pageNumber,
                     limit: perpage,
                 }),
             })
             .then((response) => response.json())
             .then((data) => {
                     console.log(data);
                     setDriverlist(data.data);
                   //  setTotalpages(data.totalPages)
                     setTotalcount(data.totalCount)

                 });


                console.log("pagenumber",pageNumber)

          };
         const[perpage,setPerPage]=useState(10)
         const[totalcount,setTotalcount]=useState()
         const handlePerRowsChange = async (newPerPage, page) => {
            // if(newPerPage === totalcount){
            //     console.log("Need to show all data in list");
            //     return
            // }else{
            //     console.log("Selected data in list");
            //     return
            // }
            await fetch(config.baseUrl + "api/admin/driverlist", {
                method: "POST",

                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    page: page,
                    limit: newPerPage,
                }),
            })
            .then((response) => response.json())
            .then((data) => {
                    console.log(data);
                    setDriverlist(data.data);
                  //  setTotalpages(data.totalPages)
                    setTotalcount(data.totalCount)

                });

            setPerPage(newPerPage);
            //setLoading(false);
        };

        const paginationComponentOptions = {
            selectAllRowsItem: true,
            selectAllRowsItemText: 'ALL',
          };

    //post api
    const [firstname, setFirstname] = useState("")
    const [lastname, setLastname] = useState("")
    const [email, setEmail] = useState("")
    const [address1, setAddress1] = useState("")
    const [address2, setAddress2] = useState("")
    const [number, setNumber] = useState("")
    const [carnumber, setCarNumber] = useState("")
    const handleActiveStatusChange = () => {
        setIsActiveChecked((state) => !state);
    };
    const getStatusFlag = () => {
        return isActiveChecked ? 1 : 0;
    };
    const [message, setMessage] = useState("");

    const [error, setError] = useState({
        status: false,
        msg: "",
        type: ""
    })
    const [cate, setCate] = useState([])

    const fetchCategory = async () => {
        setIsLoading(true)
        console.log("API",JSON.stringify({
                page: pageNumber,
                limit: 10,
            }))
        await fetch(config.baseUrl + "api/admin/carlist", {
            method: "POST",

            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                page: pageNumber,
                limit: 10,
            }),
        })
        .then((response)=>response.json())
            .then((data)=>{
                console.log(data.data)
               setCate(data.data);
               setTotalpages(data.totalPages)
               setTotalcount(data.totalCount)
               setIsLoading(false)
            });
      };
      const activeCars = cate.filter((car) => car.status === 1);
      console.log("Active cars", activeCars)
    const carOptions = cate && activeCars.map((car) => ({

        value: car._id,
        label: `${car.car_brand} - ${car.car_model}`
    }));

    const [selectedcar, setSelectedcar] = useState(null);
    const handlecarChange = (selectedOption) => {
        setSelectedcar(selectedOption);

    };
    const [showcontacterror, setShowcontactError] = useState(false)
  const [isBlurred, setIsBlurred] = useState(false);

  const validatePhoneNumber = (input) => {
    return /^\d{8}$/.test(input);
  };

  const handlenumberBlur = () => {
    setIsBlurred(true);
    setShowcontactError(!validatePhoneNumber(number));
  };
  const handleFocus = () => {
    setShowError(false); // Hide the error when input is focused again
  };
  const handlenumberfocus=()=>{
    setShowcontactError(false)
  }
    const handleFirstNameChange = (event) => {
        const inputValue = event.target.value;
        const updatedValue = inputValue.replace(/[^a-zA-Z\s]/g, ''); // Remove non-alphabetic and non-space characters
        setFirstname(updatedValue);
      };
      const handleLastNameChange = (event) => {
          const inputValue = event.target.value;
          const updatedValue = inputValue.replace(/[^a-zA-Z\s]/g, ''); // Remove non-alphabetic and non-space characters
          setLastname(updatedValue);
        };
    const [id,setDriver]=useState("")
    let handleCreatedriver = async (e) => {
        e.preventDefault();
        if(!selectedcar && !firstname && !number && !email && !address1){
            setError({ status: true, message: "Please Enter Required Fields", type: 'error' });
            setTimeout(() => {
                setError({ status: false, message: '', type: '' });
              }, 3000);
            return;
        }
        if(!firstname){
            setError({ status: true, message: "Please Enter Your Name", type: 'error' });
            setTimeout(() => {
                setError({ status: false, message: '', type: '' });
              }, 3000);
            return;
        }
        if(!selectedcar){
            setError({ status: true, message: "Please Select Car", type: 'error' });
            setTimeout(() => {
                setError({ status: false, message: '', type: '' });
              }, 3000);
            return;
        }
        if(!email){
            setError({ status: true, message: "Please Enter Email", type: 'error' });
            setTimeout(() => {
                setError({ status: false, message: '', type: '' });
              }, 3000);
            return;
        }
        if(!number){
            setError({ status: true, message: "Please Enter Contact Number", type: 'error' });
            setTimeout(() => {
              setError({ status: false, message: '', type: '' });
            }, 3000);
            return;
          }



        if(!address1){
            setError({ status: true, message: "Please Enter Address", type: 'error' });
            setTimeout(() => {
                setError({ status: false, message: '', type: '' });
              }, 3000);
            return;
        }
        if(number.length<8){
                setError({
                    status: true,
                    message: 'Contact Number Should be 8-digits.',
                    type: 'error'
                  });
                  setAddAlert(true)
                  setAddStatus(false)
                  setTimeout(() => {
                    setError({ status: false, message: '', type: '' });
                  }, 3000);
                  return;
         }
        //const isValid = email.includes("@")&& email.includes(".com");
        const isValid=/\S+@\S+\.\S+/.test(email);
        if(!isValid){
           setError({
               status: true,
               message: 'Please Enter a valid Email',
               type: 'error'
             });
             setAddAlert(true)
             setAddStatus(false)
             setTimeout(() => {
               setError({ status: false, message: '', type: '' });
             }, 3000);
             return;
            }
        try {
            setIsLoading(true)

            let res = await fetch(config.baseUrl + "api/admin/createdriver", {
                method: "POST",

                headers: {
                    "Content-Type": "application/json",


                },
                body: JSON.stringify({
                    car_id: selectedcar ? selectedcar.value : null,

                    car_name: selectedcar ? selectedcar.label : null,
                    first_name: firstname,
                    last_name: lastname,

                    car_number: carnumber,
                    country_code: "+65",
                    phone_number: number,
                    email_address: validMail,
                    address_one: address1,
                    address_two: address2,
                    city: "Singapore",
                    country: "Singapore",

                    status: getStatusFlag()


                }),
            });
            let resJson = await res.json();
            if (res.status === 200) {
                setDriver(resJson.data.driver_id);
            setAddStatus(true)
            setAddAlert(true)
                //setMessage("User created successfully");

                setCarNumber("")
                setSelectedcar("")
                setFirstname("")
                setLastname("")
                setAddress1("")
                setAddress2("")
                setNumber("")
                setEmail("")
                driverlistAPI()
                setIsLoading(false)
                setTimeout(() => {
                    //setIsAdd(false)
                    setAddAlert(false)
                    handleCloseDialog();
                   //setError({ status: false, message: '', type: '' });
                }, 2000);

            } else {
                setMessage("Some error occured");
                setError({ status: true, message: resJson.message, type: 'error' });
                setTimeout(() => {
                    setError(prevError => ({ ...prevError, status: false }));
                    setAddAlert(false)
                }, 3000);

                setIsLoading(false)
                setAddStatus(false)
                setAddAlert(true)
            }
        } catch (err) {
            console.log(err);
            setIsLoading(false)

        }
        setIsLoading(false)
    };
    const [numberError, setNumberError] = useState("");

const handleNumberChange = (e) => {
  const inputNumber = e.target.value;
  const regex = /^[0-9]{0,8}$/;
  if (regex.test(inputNumber)) {
    setNumber(inputNumber);
   //setNumberError("");
  } else {
    //  setNumberError("Please enter a valid 8-digit number.");
    //  setTimeout(() => {
    //     setNumberError("");
    //   }, 3000);
  }
};
    const [editId,setEditId]=useState("")
    const [hotelDriver,setDriverStatus]=useState();
    const handleEditDialog = (rowId) => {
        setIsLoading(true)
        console.log(rowId,"fddf");
        setEditId(rowId);
        const newStatus = isActiveChecked ? 1 : 0;
        setDriverStatus(newStatus);
        fetch(config.baseUrl + 'api/admin/getdriver/' + rowId).then((response)=>response.json())
        .then((data)=>{
          setSelectedcar({ value: data.data.car_id, label: data.data.car_name });
          setDId(data.data.driver_id)
          setFirstname(data.data.first_name)
          setLastname(data.data.last_name)
          setEmail(data.data.email_address)
          setValidMail(data.data.email_address)
          setNumber(data.data.phone_number)
          setAddress1(data.data.address_one)
          setAddress2(data.data.address_two)
          setCarNumber(data.data.car_number)
//           const carid = data.data.car_id;
// const carname = data.data.car_name;

// const selectedcar1 = `${carid} - ${carname}`;
// setSelectedcar(selectedcar1);




            if(data.data.status===1){
                setIsActiveChecked(true);
            }else{
                setIsActiveChecked(false);
            }
            setIsLoading(false)
        });
        setIsAdd(true)
        setIsEdit(true)
        setIsLoading(false)
    }
    const handleUpdateDriver = async (e) =>{
        e.preventDefault();
        console.log("email id:",validMail)
        const formattedNumber = String(number).padStart(10, "0");
        if(!selectedcar && !firstname && !number && !email && !address1){
            setError({ status: true, message: "Please Enter Required Fields", type: 'error' });
            setTimeout(() => {
                setError({ status: false, message: '', type: '' });
              }, 3000);
            return;
        }
        if(!firstname){
            setError({ status: true, message: "Please Enter Your Name", type: 'error' });
            setTimeout(() => {
                setError({ status: false, message: '', type: '' });
              }, 3000);
            return;
        }
        if(!selectedcar){
            setError({ status: true, message: "Please Select Car", type: 'error' });
            setTimeout(() => {
                setError({ status: false, message: '', type: '' });
              }, 3000);
            return;
        }
        if(!email){
            setError({ status: true, message: "Please Enter Email", type: 'error' });
            setTimeout(() => {
                setError({ status: false, message: '', type: '' });
              }, 3000);
            return;
        }
        if(!number){
            setError({ status: true, message: "Please Enter Contact Number", type: 'error' });
            setTimeout(() => {
                setError({ status: false, message: '', type: '' });
              }, 3000);
            return;
        }
        if(!address1){
            setError({ status: true, message: "Please Enter Address", type: 'error' });
            setTimeout(() => {
                setError({ status: false, message: '', type: '' });
              }, 3000);
            return;
        }
        if(number.length<8){
                setError({
                    status: true,
                    message: 'Contact Number Should be 8-digits.',
                    type: 'error'
                  });
                  setTimeout(() => {
                    setError({ status: false, message: '', type: '' });
                  }, 3000);
                  return;
         }
       // const isValid = email.includes("@")&& email.includes(".com");
       console.log("email id:",validMail)
       const isValid=/^[A-Za-z][A-Za-z0-9_.-]*@[A-Za-z][A-Za-z0-9_.-]*\.[A-Za-z]{2,}$/.test(email);
        if(!isValid){
           setError({
               status: true,
               message: 'Please Enter a valid Email',
               type: 'error'
             });
             setTimeout(() => {
               setError({ status: false, message: '', type: '' });
             }, 3000);
             return;
            }
        try {
            setIsLoading(true)
            console.log("email id:",validMail)
            let res = await fetch(config.baseUrl + "api/admin/updatedriver/"+editId, {
              method: "PUT",

              headers: {
                "Content-Type": "application/json",

              },
              body: JSON.stringify({
                car_id: selectedcar ? selectedcar.value : null,

                car_name: selectedcar ? selectedcar.label : null,
                first_name: firstname,
                last_name: lastname,

                car_number: carnumber,
                country_code: "+65",
                phone_number: number,
                email_address: validMail,
                address_one: address1,
                address_two: address2,
                city: "Singapore",
                country: "Singapore",

                status: getStatusFlag()
              }),
            });
            let resJson = await res.json();

            if (res.status === 200) {
                console.log("SUCCESS");
                console.log(selectedcar)
                driverlistAPI();
                setUpdateAlert(true)
                setupdatestatus(true);
            //     setAddress1("")
            //     setAddress2("")
            //     setEmail("")
            //     setNumber("")
            //     setFirstname("")
            //    setLastname("")
            //    setSelectedcar("")
            //    setCarNumber("")
               setTimeout(() => {
                setUpdateAlert(false)
                setupdatestatus(false)
                handleCloseDialog();
                //setIsAdd(false)
              }, 2000);
                setIsLoading(false)
            } else {
              //setMessage("Some error occured");
             // setError({ status: true, message: resJson.message, type: 'error' });
             console.log("Error",resJson.message);
             setError(resJson.message);
             setUpdateAlert(true)
             setupdatestatus(false)
             setIsLoading(false)
            }
          } catch (err) {
            console.log(err);
            setUpdateAlert(true)
                setupdatestatus(false)
            setIsLoading(false)
          }
          setError("")
          setIsLoading(false)
    }
    function statusIdentification(array) {
        for (var i = 0; i < array.length; i++) {
            if (array[i].status === 1) {
                array[i].status1 = 'Active';
                array[i].statusColor = 'success';
            } else if (array[i].status === 0) {
                array[i].status1 = 'In-Active';
                array[i].statusColor = 'danger';
            }
        }
    }
    const [inputValue, setInputValue] = useState('');
    const [showError, setShowError] = useState(false);

    const handleEmailChange = (e) => {
        const newEmail = e.target.value;
        setEmail(newEmail);

        validateEmail(newEmail);
        if(validateEmail(newEmail)){
         setShowError(false);
          setValidMail(newEmail);
        }else{
          setError({
              status: true,
              message: 'Please Enter a valid Email',
              type: 'error'
          });
          setTimeout(() => {
              setError({ status: false, message: '', type: '' });
          }, 3000);
        }
      }
    const validateEmail = (input) => {
        const regex = /^[A-Za-z][A-Za-z0-9_.-]*@[A-Za-z][A-Za-z0-9_.-]*\.[A-Za-z]{2,}$/;
       //const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        return regex.test(input);
      };
      const handleBlur = () => {
        setShowError(!validateEmail(email));

      };
    const handleCarInputChange = (event) => {
        const value = event.target.value;
        const pattern = /^[a-zA-Z0-9]{1,10}$/;

    if (pattern.test(value)) {
      setCarNumber(value);
    }
    else if (value === '') {
        setCarNumber('')
    }}
    const [updatestatus,setupdatestatus]=useState(false);
    const [addStatus,setAddStatus]=useState(false);
    const [addAlert,setAddAlert]=useState(false)
    const [updateAlert,setUpdateAlert]=useState(false)
    const columnsProducts = [
        {
            name: <span className='font-weight-bold fs-13 text-nowrap'>Driver ID</span>,
            selector: row => row.driver_id,
            cell: (row => <p className="fw-medium link-primary">{row.driver_id}</p>),
            sortable: true,
        },
        {
            name: <span className='font-weight-bold fs-13 text-nowrap'>Driver Name</span>,
            selector: row => row.first_name,
            cell: (row) => (
                <div >
                    <h5 className="fs-14 mb-1">{row.first_name} {row.last_name}</h5>
                    <p className="fw-medium link-primary">{row.country_code} {row.phone_number}</p>
                </div>
            ),

            sortable: true,
        },
        {
            name: <span className='font-weight-bold fs-13 text-nowrap'>Email Address</span>,
            selector: row => row.email_address,
            cell: (row => row.email_address),
            sortable: true,
        },
        {
            name: <span className='font-weight-bold fs-13 text-nowrap'>Car Info</span>,

            selector: row => row.car_name,
            cell: (row) => (
                <div>
                    <h5 className="fs-14 mb-1 text-dark">{row.car_name}</h5>
                    <p className="fw-medium text-dark mb-0">{row.model}</p>
                    <p className="text-muted mt-2 mb-0">Car Number</p>
                    <h5 className="fs-14 m-0">{row.car_number}</h5>
                </div>
            ),

            sortable: true,
        },
        {
            name: <span className='font-weight-bold fs-13 text-nowrap'>Address</span>,
            selector: row => row.address_one,
            cell: (row) => {
                return <p className="text-muted mb-0 multiline-cell" >{row.address_one}{row.address_two}</p>
            }
            ,
            sortable: true,
        },


        {
            name: <span className='font-weight-bold fs-13 text-nowrap'>Status</span>,
            selector: row => row.status,
            cell: (row => <div className={`badge text-uppercase pt-1 bg-${row.statusColor}`}>{row.status1}</div>),
            sortable: true,
        },
        {
            name: <span className='font-weight-bold fs-13 text-nowrap'>Action</span>,
            id: "action",
            style: { position: 'sticky', right: 0, backgroundColor: 'white', zIndex: 9 },
            selector: row => row.userAccount,
            cell: (row =>
                <div>
                    <Button className="btn btn-soft-info btn-icon fs-14 me-1" onClick={setIsEdit ? () =>handleEditDialog(row._id) : null}>
                        <i className="ri-edit-box-line"></i>
                    </Button>
                    <Button className="btn btn-soft-info btn-icon fs-14" onClick={() => handleDeleteData(row.driver_id,row._id)}>
                        <i className="ri-delete-bin-line"></i>
                    </Button>
                </div>
            ),
            sortable: true,
        },

    ];

    /********Data table Search****** */
    const [filterText, setFilterText] = React.useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
        false
    );
    const filteredItems = driverlist.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );
    const hotelListArray = Object.values(driverlist);
    statusIdentification(filteredItems);
    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <FilterComponent
                onFilter={e => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [filterText, resetPaginationToggle]);
    document.title = "DB LIMO - Driver Onboarding";


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Driver Onboard" pageTitle="Home"></BreadCrumb>
                    {//isLoading ? <LoadingSpinner /> :
                    <Card>
                        <CardBody className="p-0">
                            <Row>
                                <Col>
                                    <DataTable
                                        title={<h5 className="mb-0">Driver Details</h5>}
                                        className="custom-datatable"
                                        columns={columnsProducts}
                                        data={filteredItems}
                                        sortIcon={<span className="custom-sort-img"><i className='bx bxs-sort-alt custom-sort-icon'></i></span>}
                                        pagination
                                        paginationServer={true}
                                        paginationTotalRows={totalcount}
                                        onChangeRowsPerPage={handlePerRowsChange}
                                        onChangePage={handlePageChange}
                                        paginationComponentOptions={paginationComponentOptions}
                                        paginationRowsPerPageOptions={["10","20","40"]}
                                        // pagination
                                        // paginationComponent={CustomPagination}
                                        // paginationComponentOptions={0}
                                        // paginationRowsPerPageOptions={[2, 4, 8, 12, 15]}
                                        // selectableRows={false}
                                        // selectableRowsComponent={TableCheckBox}
                                         noDataComponent={<NoResultData />}
                                        // selectableRowsHighlight={true}
                                        // selectableRowsVisibleOnly={false}
                                         subHeaderAlign="left"
                                         contextMenu={true}
                                         persistTableHead
                                        actions={
                                            <Row className="g-4 align-items-start align-items-lg-center flex-column flex-row-lg row">

                                                <Col md={12} lg="auto" className="ms-lg-auto no-padding-mobile">
                                                    <div className="hstack gap-2 flex-lg-row flex-wrap">
                                                        {subHeaderComponent}
                                                        <button type="button" className="btn btn-success w-100-mobile" onClick={handleAddDialog} >
                                                            <i className="ri-add-line align-bottom me-1"></i>{" "}
                                                            Add New Driver
                                                        </button>


                                                    </div>
                                                </Col>
                                            </Row>

                                        }
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>}
                </Container>
            </div>
            {//isLoading ? <LoadingSpinner /> :
            <Modal
                isOpen={isAdd}
                toggle={handleCloseDialog}
                centered
                size="lg"
                className="border-0"
                modalClassName='modal fade zoomIn'
            >
                <ModalHeader className="p-3 bg-light" toggle={handleCloseDialog}>
                    {!!isEdit ? 'Driver Onboarding' : 'Driver Onboarding'}
                </ModalHeader>
                <Form onSubmit={isEdit ? handleUpdateDriver:handleCreatedriver }>
                    <ModalBody>
                    {isEdit && updateAlert && updatestatus &&(
                <UncontrolledAlert color="success" onClose={() => setupdatestatus(false)}>Driver has been updated successfully -{DId} </UncontrolledAlert>)}
                    {isEdit && updateAlert && !updatestatus && error.status && (
                <UncontrolledAlert color="danger" onClose={() => setupdatestatus(false)}><div >  <div id='error'
                style={{  textAlign: "start" }} ><i className="bi bi-exclamation-circle"
                    ></i>&nbsp;&nbsp;&nbsp;&nbsp;{error.message}</div>
            </div></UncontrolledAlert>)}

            {isAdd && addAlert && addStatus &&(
            <UncontrolledAlert color="success" onClose={() => setAddStatus(false)}>Driver has been added successfully - {id}</UncontrolledAlert>)}
            {isAdd && addAlert && !addStatus && error.status &&(
            <UncontrolledAlert color="danger" onClose={() => setAddStatus(false)}><div >  <div id='error'
            style={{  textAlign: "start" }} ><i className="bi bi-exclamation-circle"
                ></i>&nbsp;&nbsp;&nbsp;&nbsp;{error.message}</div>
        </div></UncontrolledAlert>)}
                        <Row className="g-3">
                            <Col sm={12} xl={6} xxl={6} md={12}>
                                <div className="mb-0">
                                    <Label
                                        htmlFor="firstName"
                                        className="form-label"
                                    >
                                        First Name (<span className="text-danger">*</span>)
                                    </Label>
                                    <Input
                                        id="firstName"
                                        name="firstName"
                                        type="text"
                                        placeholder="Enter Your First Name"
                                        value={firstname} onChange={handleFirstNameChange}  required
                                    />
                                </div>
                            </Col>
                            <Col xxl={6} sm={12} lg={6} md={12}>
                                <div className="mb-0">
                                    <Label
                                        htmlFor="lastName"
                                        className="form-label"
                                    >
                                        Last Name
                                    </Label>
                                    <Input
                                        id="lastName"
                                        name="lastName"
                                        type="text"
                                        placeholder="Enter Your Last Name"
                                        value={lastname} onChange={handleLastNameChange}
                                    />


                                </div>
                            </Col>
                            <Col xxl={6} sm={12} lg={6} md={12}>
                                <div className="mb-0">
                                    <Label
                                        htmlFor="carName"
                                        className="form-label"
                                    >
                                        Car Name (<span className="text-danger">*</span>)
                                    </Label>
                                    <Select
                                        className="mb-0"
                                        classNamePrefix="form-control-select"

                                        placeholder="Select Car Brand Name"
                                        value={selectedcar}
                                        onChange={handlecarChange}
                                        options={carOptions}
                                        inputId="carName"
                                        required

                                    ></Select>
                                </div>
                            </Col>
                            <Col xxl={6} sm={12} lg={6} md={12}>
                                <div className="mb-0">
                                    <Label
                                        htmlFor="carNumber"
                                        className="form-label"
                                    >
                                        Car Number
                                    </Label>

                                    <Input
                                        id="carNumber"
                                        name="carNumber"
                                        type="text"
                                        placeholder="Enter Your Car Number"
                                        //value={carnumber} onChange={(e) => setCarNumber(e.target.value)}
                                        value={carnumber} onChange={handleCarInputChange}
                                    />

                                </div>
                            </Col>
                            <Col xxl={6} sm={12} lg={6} md={12}>
                                <div className="mb-0">
                                    <Label
                                        htmlFor="contactNumber"
                                        className="form-label"
                                    >
                                        Contact Number (<span className="text-danger">*</span>)
                                    </Label>
                                    <div className="input-group">
                                        <div className="input-group-text">
                                            +65
                                        </div>
                                        <Input
  id="contactNumber"
  name="contactNumber"
  type="number"
  placeholder="Enter Contact Number"
  value={number}
  //onChange={handleNumberChange}
  onChange={(e)=>setNumber(e.target.value)}
  onBlur={handlenumberBlur}
  onFocus={handlenumberfocus}
                        className={showcontacterror && isBlurred ? 'invalid' : ''}
                        onKeyPress={(e) => {
                          const allowedKeys = /[0-9]/;
                          const key = e.key;
                          if (!allowedKeys.test(key)) {
                            e.preventDefault();
                          } else if (number.length >= 8) {
                            e.preventDefault();
                          }
                        }}
                        required
                      />
                      {showcontacterror && number && <span style={{ color: 'red' }}>Please enter a valid 8-digit phone number</span>}


                                    </div>
                                    {numberError && <p className="error-message" style={{color:"red"}}>{numberError}</p>}
                                </div>
                            </Col>
                            <Col xxl={6} sm={12} lg={6} md={12}>
                                <div className="mb-0">
                                    <Label
                                        htmlFor="emailAddress"
                                        className="form-label"
                                    >
                                        Email Address (<span className="text-danger">*</span>)
                                    </Label>
                                    <Input
                                        id="emailAddress"
                                        name="emailAddress"
                                        type="text"
                                        placeholder="Enter Email Address"
                                        value={email} onChange={handleEmailChange} required
                                        onBlur={handleBlur}
                                        onFocus={handleFocus}
                                        className={showError ? 'invalid' : ''}
                        />
                        {showError && email && <span style={{ color: 'red' }}>Please enter a valid email address</span>}


                                </div>
                            </Col>
                            <Col xxl={6} sm={12} lg={6} md={12}>
                                <div className="mb-0">
                                    <Label
                                        htmlFor="username"
                                        className="form-label"
                                    >
                                        Address 1 (<span className="text-danger">*</span>)
                                    </Label>
                                    <Input
                                        id="username"
                                        name="username"
                                        type="text"
                                        placeholder="Enter Hotel Address"
                                        value={address1} onChange={(e) => setAddress1(e.target.value)} required
                                    />
                                </div>
                            </Col>
                            <Col xxl={6} sm={12} lg={6} md={12}>
                                <div className="mb-0">
                                    <Label
                                        htmlFor="address2"
                                        className="form-label"
                                    >
                                        Address 2
                                    </Label>

                                    <Input
                                        id="address2"
                                        name="username"
                                        type="text"
                                        placeholder="Enter Hotel Address"
                                        value={address2} onChange={(e) => setAddress2(e.target.value)}
                                    />

                                </div>
                            </Col>
                            <Col xxl={6} sm={12} lg={6} md={12}>
                                <div className="mb-0">
                                    <Label
                                        htmlFor="carName"
                                        className="form-label"
                                    >
                                        City
                                    </Label>
                                    <Input
                                        className="mb-0"
                                        classNamePrefix="form-control-select"
                                        //value={selectCity}
                                        value="Singapore"
                                        placeholder="Select City"
                                        style={{userSelect:"none",cursor:"not-allowed",background:" rgba(var(--vz-light-rgb), .75)"}}
                                        // onChange={() => {
                                        //     handleSelectCity();
                                        // }}
                                        // options={cityList}
                                        inputId="carName"

                                    />
                                </div>
                            </Col>
                            <Col xxl={6} sm={12} lg={6} md={12}>
                                <div className="mb-0">
                                    <Label
                                        htmlFor="carName"
                                        className="form-label"
                                    >
                                        Country
                                    </Label>
                                    <Input
                                        className="mb-0"
                                        classNamePrefix="form-control-select"
                                        defaultValue={{ label: 'Singapore', value: 'Singapore' }}
                                        isDisabled
                                        placeholder="Select Country"
                                        style={{userSelect:"none",cursor:"not-allowed",background:" rgba(var(--vz-light-rgb), .75)"}}
                                        value="Singapore"
                                        // onChange={() => {
                                        //     handleSelectCountry();
                                        // }}
                                        // options={countryList}
                                        inputId="carName"

                                    />
                                </div>
                            </Col>
                            <Col xxl={12} sm={12} lg={6} md={12}>
                                <div className="form-check form-switch form-check-primary mt-0">
                                    <Input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        id="ActiveStatus"
                                        checked={isActiveChecked}
                                        onChange={handleActiveStatusChange}
                                    />
                                    <Label className="form-check-label" for="ActiveStatus">Driver Status Active?</Label>
                                </div>
                            </Col>
                          {/*  <div >   {error.status ? <div id='error'
                                style={{ color: "red", fontWeight: "bold", textAlign: "center" }} ><i className="bi bi-exclamation-circle"
                                    style={{ fontWeight: "bold" }}></i>&nbsp;&nbsp;&nbsp;&nbsp;{error.message}</div> : ''}
                            </div>*/}
                        </Row>

                    </ModalBody>
                    <ModalFooter className="justify-content-start">
                        <div className="hstack gap-2">

                            { isEdit ? (<button type="submit" className="btn btn-success" id="add-btn1" >Update Driver</button>):
                            (<button type="submit" className="btn btn-success" id="add-btn" >Add Driver</button>)}
                            <button type="button" className="btn btn-light border_gry" data-bs-dismiss="modal" onClick={handleCloseDialog}>Cancel</button>
                        </div>
                    </ModalFooter>
                </Form>
            </Modal>}
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteData1}
                onCloseClick={() => {
                    setSelectedId(null);
                    setDeleteModal(false);
                }}
                entityName="Driver"
                entityId={DId}
            />
        </React.Fragment>
    )
}

export default DriverOnBoarding


