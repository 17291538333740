import  {useEffect} from 'react';
import React from 'react';
import { Link } from "react-router-dom"
import { Label, OffcanvasBody } from "reactstrap"
import { getWhatsAppMessage } from './utils';

const ShareBooking = ({data, BookingId,setSideBarTile,passengerSeat,passengername,passengernumber,carmodel,viewArray,babyseat,luggage,bookstatus,destination,fnumber,pickup,drop,traveldate,extrastop, extraStops, traveltime,hotel,booktype,
  setShowSideBar,carbrand,drivername,drivernumber,dspslhrs,drivers,carNumber,shareArray}) => {

    useEffect(()=>{
      setSideBarTile('Share Booking '+ BookingId)
  })



const getDriverDetails =(id)=>{
  // console.log(id,"DRIVER ID")
   if(id !== undefined || id !== null){
     if(drivers){
       const driverArrray = Object.values(drivers);
       const driver = driverArrray.find((obj) => obj._id === id);
       if(driver){
         const phoneNumber = driver && driver.phone_number;

         return phoneNumber;
       }else{
         return ""
       }

       }else{
         return ""
       }
   }else{
     return "";
   }


 }

 const getDriverDetails1 =(id)=>{
   // console.log(id,"DRIVER ID")
    if(id !== undefined || id !== null){
      if(drivers){
        const driverArrray = Object.values(drivers);
        const driver = driverArrray.find((obj) => obj._id === id);
        if(driver){
          const carNumber = driver && driver.car_number;

          return carNumber;
        }else{
          return ""
        }

        }else{
          return ""
        }
    }else{
      return "";
    }


  }

const driveNumber = getDriverDetails(shareArray.driver_id);
const carNo = getDriverDetails1(shareArray.driver_id);


function handleShare()
{
/*
var number = passengernumber;
var message = ""
var title = "*DB LIMO Booking Details:* "
var booking_info =
`*Passenger Info:*
--------------------------------
*Passenger Name:* ${passengername}
*Passenger Number:* ${passengernumber}
*Passenger Seat:* ${passengerSeat}
*Baby Seat:* ${babyseat}
*Luggage:* ${luggage}
*Booking Status:* ${bookstatus}

*Travel Info:*
--------------------------------
*${booktype}*
${(booktype === 'Airport Arrival' || booktype === 'Airport Departure' || booktype === 'Point To Point(Transfer)') && destination ?  "*Destination:* " + destination  : ''}
${fnumber && "*Flight number:* " + fnumber}
${pickup && "*Pickup location:* " + pickup}
${drop && "*Drop location:* " + drop}
${extrastop > 0 ? "*Extra stop:* " + extrastop:""}
${extrastop >= 1 ? "*Stop 1:* " + extraStops[0]:""}
${extrastop >= 2 ? "*Stop 2:* " + extraStops[1]:""}
${(booktype === 'Singapore Disposal' || booktype === 'Malaysia Disposal') && dspslhrs && "*Disposal Hrs:* " + dspslhrs}

*Travel Date:* ${traveldate}
*Time:* ${traveltime}
*Hotel:* ${hotel}

*Car Info:*
--------------------------------
*${carbrand}-${carmodel}*
${bookstatus === "Accepted" ? "*Driver Name:* " + drivername:" "}
${bookstatus === "Accepted" ? "*Driver Number:* +65" + driveNumber:" "}
${bookstatus === "Accepted" ? "*Car Plate No:* " +carNo:" "}
`
message = title + "\n \n" + booking_info
*/
const message = getWhatsAppMessage(data, { booktype, driveNumber, carNo, carbrand, carmodel });
console.log(message);
var share_text = "https://wa.me/"+"?text="+encodeURIComponent(message)
window.open(share_text,'_blank');
}

    return(
        <React.Fragment>
            <div className='d-flex flex-column justify-content-end h-100 canvas-right-sidebar'>
              <OffcanvasBody>
            <div className="mb-4">
                    <label className="form-label text-muted mb-0 fs-14">Passenger Info</label>
                    <hr className="m-2 ms-0 me-0" />
                    <div className="flex-grow-1">
                        <h5 className="fs-14 mb-1"> {passengername}<Link to="#" className="text-dark"></Link></h5>
                        <p className="text-secondary fw-medium  mb-0">{data?.country_code} {passengernumber}<span className="fw-medium text-secondary"></span></p>
                        <p className="text-muted mb-0 mt-3">Passenger Seat : <span className="fw-medium text-dark">{passengerSeat}</span></p>
                        {babyseat!=0 && <p className="text-muted mb-0">Baby Seat : <span className="fw-medium text-dark">{babyseat}</span></p>}
                        <p className="text-muted mb-0">Luggage : <span className="fw-medium text-dark">{luggage}</span></p>
                        <p className="text-muted mb-0 mt-3">Booking Status:
                          {bookstatus ==="Requested" ? ( <span className="fw-medium p-1 text-warning">{bookstatus}</span>) : ''}
                          {bookstatus ==="Accepted" ? ( <span className="fw-medium p-1 text-success">{bookstatus}</span>) : ''}
                          {bookstatus ==="Cancelled" ? ( <span className="fw-medium p-1 text-danger">{bookstatus}</span>) : ''}
                        </p>


                    </div>
                </div>
                <div className="mb-4">
                    <hr className="m-2 ms-0 me-0" />
                    <label className="form-label text-muted mb-0 fs-14">Travel Info</label>
                        <hr className="m-2 ms-0 me-0" />
                        <div className="d-flex align-items-center">
                            <div className="flex-grow-1">
                                <h5 className="fs-14 mb-1 text-dark">{booktype}</h5>
                               {data && viewArray.pickup_type !== "3" && viewArray.pickup_type !== "4" && viewArray.pickup_type !== "5" && (<>
                                    <p className="text-muted mb-0 mt-3">Pickup Location: <span className="fw-medium text-dark d-block">{data?.pickup_type === '1' ?  data.airport_name : data.destination}</span></p>
                                    <p className="text-muted mb-0">Drop Location: <span className="fw-medium text-dark d-block">{data.pickup_type === '1' ?  data.destination : data.airport_name}</span></p>
                                </>
                                )}
                                {fnumber?<p className="text-muted mb-0 mt-3">Flight Number : <span className="fw-medium text-dark">{fnumber}</span></p>:""}
                                {/* <p className="text-muted mb-0 mt-3">Travel Type : <span className="fw-medium text-dark"></span></p> */}
                                {pickup?<p className="text-muted mb-0 mt-3">Pickup Location: <span className="fw-medium text-dark d-block">{pickup}</span></p>:""}
                                {drop?<p className="text-muted mb-0 mt-0">Drop Location: <span className="fw-medium text-dark d-block">{drop}</span></p>:""}
                                {extrastop?<p className="text-muted mb-0 mt-0">Extra Stop : <span className="fw-medium text-dark">{extrastop}</span></p>:""}
                                {extraStops?.[0] ? <p className="text-muted mb-0 mt-0">Stop 1: <span className="fw-medium text-dark">{extraStops[0]}</span></p>:""}
                                {extraStops?.[1] ? <p className="text-muted mb-0 mt-0">Stop 2: <span className="fw-medium text-dark">{extraStops[1]}</span></p>:""}
                                {viewArray.pickup_type=== "4" || viewArray.pickup_type=== "5"  && <p className="text-muted mb-0 mt-0">
                                <span className="table-label">Disposal Hours:</span>
                                <span className="fw-medium text-dark">{dspslhrs}</span></p>}
                                <p className="text-muted mb-0 mt-3">Travel Date &amp; Time : <span className="fw-medium text-dark d-block">{traveldate} : {traveltime}</span></p>
                                {data?.remark ? <p className="text-muted mb-0 mt-3">Remarks: <span className="fw-medium text-dark d-block">{data?.remark}</span></p> : ''}
                                <p className="text-muted mb-0 mt-3">Hotel Name: <span className="fw-medium text-dark d-block">{hotel}</span></p>
                                <p className="text-muted mb-0"></p>

                            </div>
                    </div>
                </div>
                <div className="mb-4">
                <hr className="m-2 ms-0 me-0" />
                        <Label className="form-label text-muted mb-0 fs-14">Car Info</Label>
                        <hr className="m-2 ms-0 me-0" />
                        <div className="d-flex align-items-center">
                            <div className="flex-grow-1">
                            <h5 className="fs-14 mb-1"> <Link to="#" className="text-dark">{carbrand}-{carmodel}</Link></h5>
                            {bookstatus==="Accepted" && <div>
                                <p className="text-muted mb-0 mt-3">Assign Driver: <span className="fw-medium d-block text-dark text-uppercase">{drivername}</span></p>
                                <p className="text-secondary fw-medium  mb-0">+65 {driveNumber}</p></div>}
                                {bookstatus==="Accepted" && <div>
                                <p className="text-muted mb-0 mt-3">Car Plate No:</p>
                                <p className="fw-medium d-block text-dark text-uppercase">{carNo}</p></div>}
                        </div>
                    </div>
                </div>
                </OffcanvasBody>
                <div className="offcanvas-footer border-top p-3 text-center hstack gap-2 bg-light position-sticky bottom-0">
          <button
            className="btn btn-success w-100"  onClick={()=>{handleShare()}}
          >
        Share Booking
          </button>
          <button
            type="submit"
            className="btn  btn-outline-light w-100 border-color-b9b9b9"
            onClick={()=>{setShowSideBar(false)}}
            >
            Cancel
          </button>
        </div>
        </div>
        </React.Fragment>
    )
}

export default ShareBooking