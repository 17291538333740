import React, { useState,useEffect } from "react"
import config from "../../../common/config"
import { Form, Input, Label, OffcanvasBody, UncontrolledAlert } from "reactstrap"

import { useNavigate } from "react-router-dom"
const PaymentConfirmation = ({array,markpay, selectedRowId, setPaymentConfirmation, setShowSideBar, fetchBooking,setSelectedRowId,setChecked ,setChecked1}) => {
  const [paid, setPaid] = useState(false); // Default value is false (Not Paid)
  const [paid1, setPaid1] = useState(false);
  const navigate = useNavigate()
  const [hpaymentremark, setHpaymentremark] = useState("")
  const [dpaymentremark, setDpaymentremark] = useState("")
  const [error, setError] = useState({
    status: false,
    msg: "",
    type: ""
  })
  const [addStatus, setAddStatus] = useState(false);
  const [addAlert, setAddAlert] = useState(false)
  const [message, setMessage] = useState("");
  const [isHotelPaid, setIsHotelPaid] = useState(false);
  const [isDriverPaid, setIsDriverPaid] = useState(false);
  useEffect(() => {
   
    if (markpay) {

      const hremarks = markpay.hotel_payment_remark;
      setHpaymentremark(hremarks);
      const dremarks = markpay.driver_payment_remark;
      setDpaymentremark(dremarks)
      //console.log(array.hotel_payment,"array.data.hotel_payment")
      //const hotelPaymentStatus = array.data.hotel_payment === "0" ? false : true;
      //const driverPaymentStatus = array.data.driver_payment === "0" ? false : true;
      if(markpay.driver_payment===0){
        setIsDriverPaid(false)
      }else{
        setIsDriverPaid(true)
      }
      if(markpay.hotel_payment===0){
        setIsHotelPaid(false)
      }else{
        setIsHotelPaid(true)
      }
      //setIsHotelPaid(hotelPaymentStatus);
      //setIsDriverPaid(driverPaymentStatus);
    }
  }, [markpay]);
  // Handle checkbox change event
  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    const checkboxId = e.target.id;
  
    if (isChecked) {
      if (checkboxId === "inlineCheckbox5") {        
        setIsHotelPaid(true); // Select "Paid" option
      } else if (checkboxId === "inlineCheckbox6") {
       
        setIsHotelPaid(false); // Select "Not Paid" option
      }
    }
  };
  
  const getHotelPayment =()=>{
    return isHotelPaid ? "1":"0";
  }
  const handleDriverPayment =(e)=>{
     
    const isChecked = e.target.checked;
    const checkboxId = e.target.id;
    if (isChecked) {
      if (checkboxId === "inlineCheckbox7") {        
        setIsDriverPaid(true); // Select "Paid" option
      } else if (checkboxId === "inlineCheckbox8") {
       
        setIsDriverPaid(false); // Select "Not Paid" option
      }
    }
  }
 
  const getDriverPayment=()=>{
    return isDriverPaid ? "1":"0";
  }

  // const handleCheckboxChange1 = (e) => {
  //     const isChecked = e.target.checked;
  //     setPaid1(isChecked);
  //   };
  const handleCheckboxChange1 = (e) => {
    const isChecked = e.target.checked;
    const checkboxId = e.target.id;

    if (isChecked) {
      if (checkboxId === "inlineCheckbox7") {
        setPaid1(true); // Select "Paid" option
      } else if (checkboxId === "inlineCheckbox8") {
        setPaid1(false); // Select "Not Paid" option
      }
    }
  };
  const handleHotelCheckboxChange = (e) => {
    setIsHotelPaid(e.target.checked);
  };

  const handleDriverCheckboxChange = (e) => {
    setIsDriverPaid(e.target.checked);
  };
  const [cate, setCate] = useState(false)
  let handleCreatedriver = async (e) => {
    e.preventDefault();
    console.log("arraydata6666666666", selectedRowId)
    try {
      //setIsLoading(true)

      let res = await fetch(config.baseUrl + "api/make_payment", {
        method: "POST",

        headers: {
          "Content-Type": "application/json",


        },
        body: JSON.stringify({

          hotel_payment: getHotelPayment(),
          hotel_payment_remark: hpaymentremark,
          driver_payment: getDriverPayment(),
          driver_payment_remark: dpaymentremark,
          booking_id: selectedRowId

        }),
      });
      let resJson = await res.json();
      if (res.status === 200) {
        console.log("success")
        navigate("/booking")
        //setPaymentConfirmation(false)
        //setShowSideBar(false)
        setCate(true)
        setAddStatus(true)
        setAddAlert(true)
  // Uncheck the selected checkboxes
  setSelectedRowId([]);
  setChecked(false);
  setChecked1(false)
        setTimeout(() => {
          // setIsAdd(false)
          setShowSideBar(false)
          setAddAlert(false)

          //setError({ status: false, message: '', type: '' });
        }, 7000);
        fetchBooking()

      } else {
        setMessage("Some error occured");
        setError({ status: true, message: resJson.message, type: 'error' });

        setTimeout(() => {
          setError({ status: false, message: '', type: '' });
          setAddAlert(false)
        }, 3000);
        setAddStatus(false)
        setAddAlert(true)

      }
    } catch (err) {
      console.log(err);
      //setIsLoading(false)

    }
    //setIsLoading(false)
  };
  const handleCancel = () =>{
    setSelectedRowId([]);
    setChecked(false);
    setShowSideBar(false)
    setChecked1(false)
  }
  return (
    <React.Fragment>
      <Form onSubmit={handleCreatedriver}>

        <div className='d-flex flex-column justify-content-end h-100 canvas-right-sidebar'>
          <div>
            {addAlert && addStatus && (
              <UncontrolledAlert color="success" onClose={() => setAddStatus(false)}>Your Payment added successfully</UncontrolledAlert>)}
            {addAlert && !addStatus && error.status && (
              <UncontrolledAlert color="danger" onClose={() => setAddStatus(false)}>  <div >
                <div
                  id="error"
                  style={{
                    //  color: 'red',
                    //  fontWeight: 'bold',
                    textAlign: 'start'
                  }}
                >
                  <i className="bi bi-exclamation-circle" ></i>&nbsp;&nbsp;&nbsp;&nbsp;{error.message}
                </div>

              </div></UncontrolledAlert>)}
          </div>
          <OffcanvasBody >

            <div className="mb-4">
              <Label for="datepicker-range" className="text-muted mb-1 fs-14">Hotel Payment</Label>
              <div className="row g-2">
              <div className="col-lg-6">
            <div className="form-check">
              <Input
                className="form-check-input"
                type="checkbox"
                name="hotelPayment" id="inlineCheckbox5"
                checked={isHotelPaid}
                onChange={handleCheckboxChange} />
              <Label className="form-check-Label mb-0" for="inlineCheckbox5">
                Paid
              </Label>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-check">
              <Input
                className="form-check-input"
                type="checkbox"
                name="hotelPayment" id="inlineCheckbox6" 
                checked={!isHotelPaid}
                onChange={handleCheckboxChange}
                defaultChecked
              />
              <Label className="form-check-Label mb-0" for="inlineCheckbox6">
                Not Paid
              </Label>
            </div>
          </div>
                <div className="col-lg-12">
                  <Label for="formtextInput" className="">Payment Remarks</Label>
                  <textarea className="form-control" id="exampleFormControlTextarea5" rows="3" value={hpaymentremark}
                    onChange={(e) => setHpaymentremark(e.target.value)}></textarea>
                  <div id="passwordHelpBlock" className="form-text">
                    Must be 8-20 characters long.
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="mb-4">
              <Label for="datepicker-range" className=" text-muted mb-1 fs-14">Driver Payment</Label>
              <div className="row g-2">
              <div className="col-lg-6">
            <div className="form-check">
              <Input
                className="form-check-input"
                type="checkbox"
                name="driverPayment" id="inlineCheckbox7" 
                checked={isDriverPaid}
                onChange={handleDriverPayment}
              />
              <Label className="form-check-Label mb-0" for="inlineCheckbox7">
                Paid
              </Label>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-check">
              <Input
                className="form-check-input"
                type="checkbox"
                name="driverPayment" id="inlineCheckbox8" 
                checked={!isDriverPaid}
                onChange={handleDriverPayment}
                defaultChecked
              />
              <Label className="form-check-Label mb-0" for="inlineCheckbox8">
                Not Paid
              </Label>
            </div>
          </div>
                <div className="col-lg-12">
                  <Label for="formtextInput" className="">Payment Remarks</Label>
                  <textarea className="form-control" id="exampleFormControlTextarea5" rows="3" value={dpaymentremark}
                    onChange={(e) => setDpaymentremark(e.target.value)}></textarea>
                  <div id="passwordHelpBlock" className="form-text">
                    Must be 8-20 characters long.
                  </div>
                </div>
              </div>
            </div>
          </OffcanvasBody>

        </div>
        <div className="offcanvas-footer border-top p-3 text-center hstack gap-2 bg-light position-sticky bottom-0">
          <button type="submit"
            className="btn btn-success w-100"
          >
            Submit
          </button>
          <button
            type="submit" onClick={handleCancel}
            className="btn  btn-outline-light w-100 border-color-b9b9b9"
          >
            Cancel
          </button>
        </div>
      </Form>
    </React.Fragment>
  )
}

export default PaymentConfirmation