export const AllBookingWidget = [
    {
        id: 1,
        icon: "ri-police-car-fill",
        feaIconClass: "primary",
        cardBg: 'primary',
        label: "Total Booking",
        badgeClass: "danger",       
        subCounter: [{ id: 1, counter: "547", suffix: " K" }]
    },
    {
        id: 2,
        icon: "ri-bus-wifi-fill",
        feaIconClass: "warning",
        cardBg: 'warning',
        label: "Requested Booking",
        badgeClass: "danger",       
        subCounter: [{ id: 1, counter: "124", suffix: " K" }]
    },
    {
        id: 3,
        icon: "ri-taxi-wifi-fill",
        feaIconClass: "success",
        cardBg: 'success',
        label: "Accepted Booking",
        badgeClass: "danger",       
        subCounter: [{ id: 1, counter: "107", suffix: " K" }]
    },
    {
        id: 4,
        icon: "ri-taxi-wifi-fill",
        feaIconClass: "danger",
        cardBg: 'danger',
        label: "Cancelled Booking",
        badgeClass: "danger",       
        subCounter: [{ id: 1, counter: "74", suffix: " K" }]
    },
    {
        id: 5,
        icon: "ri-taxi-wifi-fill",
        feaIconClass: "info",
        cardBg: 'secondary',
        label: "Completed Rides",
        badgeClass: "danger",       
        subCounter: [{ id: 1, counter: "54", suffix: " K" }]
    },
]

export const data = [
{
    id: '1',
    bookingID: '#LG0001',
    bookingInfo: {
        details: [
        {
            name: 'John Selvaraj',
            mobileNumber: '+65 987 654 321',
            passengerSeat: 4,
            babySeat: 2,
            luggage : 4,
            hotelName: 'Radisson Blu, Singapore',
            bookingPerson: 'John David'
        }
       ]
    },
    travelInfo: {
        details: [
            {
                airport: 'Airport Departure',
                destination: 'Airport Singapore',
                flightNumber : 'JADR024GH78',
                travelType: 'One Way Trip',
                extraStop: 'Yes',
                oneStop: 'Yes',
                pickupLocation: 'Singapore Museum',
                dropLocation: 'Singapore Museum'
 
            }
        ]
    },
    travelDateAndTime: '25 Jan, 2022',
    time: '10.30 AM',
    carInfo: 'Mercedes Benz S400',
    bookingStatus: 'Requested',
    bookingStatusColor: 'warning',    
    rideStatus: 'Not Yet',
    rideStatusColor: 'danger',
    paymentInfo: {
        details: [
            {
                total: '$578',
                airportArrival: '$180',
                airportDepature: '$180',
                pointtopoint: '$180',
                fromDisposal: '$180',
                fromDisposalLocation: 'Singapore Disposal',
                toDisposalLocation: 'Malaysia Disposal',
                toDisposal: '$180',
                extraHoursDisposal: '$60',
                extraStopSurCharge: '$15',
                minNightSurcharge: '$30',
                waintingTimePrice: '$30',
                hotelPaymentStatus: 'Unpaid',
                hotelPaymentStatusColor: 'danger',
                driverPayment: 'UnPaid',
                driverPaymentStatusColor: 'danger',

            }
        ]
    }
},
{
    id: '2',
    bookingID: '#LG0002',
    bookingInfo: {
        details: [
        {
            name: 'John Selvaraj',
            mobileNumber: '+65 987 654 321',
            passengerSeat: 4,
            babySeat: 2,
            luggage : 4,
            hotelName: 'Radisson Blu, Singapore',
            bookingPerson: 'John David'
        }
       ]
    },
    travelInfo: {
        details: [
            {
                airport: 'Airport Departure',
                destination: 'Airport Singapore',
                flightNumber : 'JADR024GH78',
                travelType: 'One Way Trip',
                extraStop: 'Yes',
                oneStop: 'Yes',
                pickupLocation: 'Singapore Museum',
                dropLocation: 'Singapore Museum'
 
            }
        ]
    },
    travelDateAndTime: '25 Jan, 2022',
    time: '10.30 AM',
    carInfo: 'Mercedes Benz S400',
    bookingStatus: 'Accepted',
    bookingStatusColor: 'success',    
    rideStatus: 'Completed',
    rideStatusColor: 'success',
    paymentInfo: {
        details: [
            {
                total: '$578',
                airportArrival: '$180',
                airportDepature: '$180',
                pointtopoint: '$180',
                fromDisposal: '$180',
                fromDisposalLocation: 'Singapore Disposal',
                toDisposalLocation: 'Malaysia Disposal',
                toDisposal: '$180',
                extraHoursDisposal: '$60',
                extraStopSurCharge: '$15',
                minNightSurcharge: '$30',
                waintingTimePrice: '$30',
                hotelPaymentStatus: 'Paid',
                hotelPaymentStatusColor: 'success',
                driverPayment: 'Paid',
                driverPaymentStatusColor: 'success',

            }
        ]
    }
},
{
    id: '3',
    bookingID: '#LG0003',
    bookingInfo: {
        details: [
        {
            name: 'John Selvaraj',
            mobileNumber: '+65 987 654 321',
            passengerSeat: 4,
            babySeat: 2,
            luggage : 4,
            hotelName: 'Radisson Blu, Singapore',
            bookingPerson: 'John David'
        }
       ]
    },
    travelInfo: {
        details: [
            {
                airport: 'Airport Departure',
                destination: 'Airport Singapore',
                flightNumber : 'JADR024GH78',
                travelType: 'One Way Trip',
                extraStop: 'Yes',
                oneStop: 'Yes',
                pickupLocation: 'Singapore Museum',
                dropLocation: 'Singapore Museum'
 
            }
        ]
    },
    travelDateAndTime: '25 Jan, 2022',
    time: '10.30 AM',
    carInfo: 'Mercedes Benz S400',
    bookingStatus: 'Accepted',
    bookingStatusColor: 'success',    
    rideStatus: 'Not Yet',
    rideStatusColor: 'danger',
    paymentInfo: {
        details: [
            {
                total: '$578',
                airportArrival: '$180',
                airportDepature: '$180',
                pointtopoint: '$180',
                fromDisposal: '$180',
                fromDisposalLocation: 'Singapore Disposal',
                toDisposalLocation: 'Malaysia Disposal',
                toDisposal: '$180',
                extraHoursDisposal: '$60',
                extraStopSurCharge: '$15',
                minNightSurcharge: '$30',
                waintingTimePrice: '$30',
                hotelPaymentStatus: 'Paid',
                hotelPaymentStatusColor: 'success',
                driverPayment: 'Paid',
                driverPaymentStatusColor: 'success',

            }
        ]
    }
},
{
    id: '4',
    bookingID: '#LG0004',
    bookingInfo: {
        details: [
        {
            name: 'John Selvaraj',
            mobileNumber: '+65 987 654 321',
            passengerSeat: 4,
            babySeat: 2,
            luggage : 4,
            hotelName: 'Radisson Blu, Singapore',
            bookingPerson: 'John David'
        }
       ]
    },
    travelInfo: {
        details: [
            {
                airport: 'Airport Departure',
                destination: 'Airport Singapore',
                flightNumber : 'JADR024GH78',
                travelType: 'One Way Trip',
                extraStop: 'Yes',
                oneStop: 'Yes',
                pickupLocation: 'Singapore Museum',
                dropLocation: 'Singapore Museum'
 
            }
        ]
    },
    travelDateAndTime: '25 Jan, 2022',
    time: '10.30 AM',
    carInfo: 'Mercedes Benz S400',
    bookingStatus: 'Accepted',
    bookingStatusColor: 'success',    
    rideStatus: 'Not yet',
    rideStatusColor: 'danger',
    paymentInfo: {
        details: [
            {
                total: '$578',
                airportArrival: '$180',
                airportDepature: '$180',
                pointtopoint: '$180',
                fromDisposal: '$180',
                fromDisposalLocation: 'Singapore Disposal',
                toDisposalLocation: 'Malaysia Disposal',
                toDisposal: '$180',
                extraHoursDisposal: '$60',
                extraStopSurCharge: '$15',
                minNightSurcharge: '$30',
                waintingTimePrice: '$30',
                hotelPaymentStatus: 'Unpaid',
                hotelPaymentStatusColor: 'danger',
                driverPayment: 'Paid',
                driverPaymentStatusColor: 'success',

            }
        ]
    }
},
{
    id: '5',
    bookingID: '#LG0005',
    bookingInfo: {
        details: [
        {
            name: 'John Selvaraj',
            mobileNumber: '+65 987 654 321',
            passengerSeat: 4,
            babySeat: 2,
            luggage : 4,
            hotelName: 'Radisson Blu, Singapore',
            bookingPerson: 'John David'
        }
       ]
    },
    travelInfo: {
        details: [
            {
                airport: 'Airport Departure',
                destination: 'Airport Singapore',
                flightNumber : 'JADR024GH78',
                travelType: 'One Way Trip',
                extraStop: 'Yes',
                oneStop: 'Yes',
                pickupLocation: 'Singapore Museum',
                dropLocation: 'Singapore Museum'
 
            }
        ]
    },
    travelDateAndTime: '25 Jan, 2022',
    time: '10.30 AM',
    carInfo: 'Mercedes Benz S400',
    bookingStatus: 'Accepted',
    bookingStatusColor: 'success',    
    rideStatus: 'Completed',
    rideStatusColor: 'success',
    paymentInfo: {
        details: [
            {
                total: '$578',
                airportArrival: '$180',
                airportDepature: '$180',
                pointtopoint: '$180',
                fromDisposal: '$180',
                fromDisposalLocation: 'Singapore Disposal',
                toDisposalLocation: 'Malaysia Disposal',
                toDisposal: '$180',
                extraHoursDisposal: '$60',
                extraStopSurCharge: '$15',
                minNightSurcharge: '$30',
                waintingTimePrice: '$30',
                hotelPaymentStatus: 'Unpaid',
                hotelPaymentStatusColor: 'danger',
                driverPayment: 'Paid',
                driverPaymentStatusColor: 'success',

            }
        ]
    }
}
]

export const BookingHistory = [
    {
        avatarIcon: 'ri-account-circle-line',
        statusHeading: 'Booking Requested',
        statusDate: 'Thu, 16 Dec 2021 - 15:30',
        hotelName: "",
        bookingPersonName: "",
        airportArrival: 'Airport Arrival',
        flightNumberLabel: 'Flight Number',
        flightNumber: "",
        statusLabel: 'Booking Person:'

    },
    // {
    //     avatarIcon: 'ri-account-circle-fill',
    //     statusHeading: 'Booking Confirmed',
    //     statusDate: "",
    //     hotelName:"",
    //     bookingPersonName: "",
    //     statusLabel: 'Confirmed Person'

    // },
    // {
    //     avatarIcon: 'ri-account-circle-line',
    //     statusHeading: 'Edit Booking',
    //     statusDate: "",
    //     hotelName: "",
    //     bookingPersonName:"", 
    //     airportArrival: 'Airport Arrival',
    //     flightNumberLabel: 'Flight Number',
    //     flightNumber: "",
    //     statusLabel: "",
    // },
    // {
    //     avatarIcon: 'ri-account-circle-fill',
    //     statusHeading: 'Booking Confirmed',
    //     statusDate: "",
    //     hotelName: "",
    //     bookingPersonName: "",
    //     statusLabel: 'Confirmed Person'

    // },
    // {
    //     avatarIcon: 'ri-account-circle-line',
    //     statusHeading: 'Ride Completed',
    //     statusDate: "",
    //     hotelName: "",
    //     bookingPersonName: "",
    //     statusLabel: 'Confirmed Person'

    // },
]