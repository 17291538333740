import { Button, Card, CardBody, Col, Container, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row,UncontrolledAlert } from "reactstrap"
import BreadCrumb from "../../Components/Common/BreadCrumb"

import DataTable from "react-data-table-component"
import React from "react"
import {  data } from "./data"
import { useMemo } from "react"
import axios from "axios"
import FilterComponent from '../../Components/datatable/filter'
import { Link } from "react-router-dom"
import { useState,useEffect } from "react"
import DeleteModal from '../../Components/Common/DeleteModal';
import Select from "react-select";
import NoResultData from "../../Components/datatable/no-result"
import CustomPagination from "../../Components/custom-pagination/pagination"
import config from "../../common/config";
//import './car.css'
//import LoadingSpinner from "./loader"
import { className } from "gridjs"
// eslint-disable-next-line react/display-name
const TableCheckBox = React.forwardRef(({ onClick, ...rest }, ref) => (
    <div className="form-check form-check-size-16" >
        <input
            htmlFor="booty-check"
            type="checkbox"
            className="form-check-input"
            ref={ref}
            onClick={onClick}
            {...rest}
        />
        <label className="form-check-label" id="booty-check" />
    </div>
))
const CarOnBoarding = () => {
    const [isAdd, setIsAdd] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false);
    const [isActiveChecked, setIsActiveChecked] = useState(true);
    const [isActiveCarChecked, setIsActiveCarChecked] = useState(true)
    const [airportPrice,setAirportPrice]=useState("");
    const [sDisposal,setSDisposal]=useState("");
    const [mDisposal,setMDisposal]=useState("");
    const [extraStop,setExtraStop]=useState("");
    const [extrahours,setExtrahours]=useState("");
    const [midnightCharge,setMidnightCharge]=useState("");
    const [waitingCharge,setWaitingCharge]=useState("");
    const [carDetails,setCarDetails]=useState("");
    const [babySeatCapacity, setBabySeatCapacity]=useState("")
    const [carStatus,setCarStatus]=useState("")
    const [image,setImage]=useState([])
    const [file,setSelectedFile]=useState("")
    const [editId,setEditId]=useState("")
    const [length,setLength]=useState("")
    const [isLoading, setIsLoading] = useState(false);
    const [CId,setCId]= useState();
    const [carBrands, setCarBrands] = useState([]);
    const [carModels, setCarModels] = useState([]);
    const [error, setError] = useState({
        status: false,
        msg: "",
        type: ""
    })
    const [message, setMessage] = useState("");

    useEffect(() => {
      axios.post(`${config.baseUrl}api/admin/getallcarbrand`, { page:1,limit:"all" }).then(res => {
        const data = res.data.map(item => ({ label: item.car_brand, value: item.car_brand, ...item }));
        setCarBrands(data);
      })

      axios.post(`${config.baseUrl}api/admin/getallcarmodel`, { page:1,limit:"all" }).then(res => {
        const data = res.data.map(item => ({ label: item.car_model, value: item.car_model, ...item }));
        setCarModels(data);
      })
    }, []);
    const seatCapacityList = [
                { label: "Select Seat Capacity", value: "Select Seat Capacity", isDisabled: true },
                { label: "1", value: "1" },
                { label: "2", value: "2" },
                { label: "3", value: "3" },
                { label: "4", value: "4" },
                { label: "5", value: "5" },
                { label: "6", value: "6" },
                { label: "7", value: "7" },
                { label: "8", value: "8" },
                { label: "9", value: "9" },
                { label: "10", value: "10" },
                { label: "11", value: "11" },
                { label: "12", value: "12" },
                { label: "13", value: "13" },
                { label: "14", value: "14" },
                { label: "15", value: "15" },
                { label: "16", value: "16" },

    ];
    const[pageNumber,setPageNumber]=useState(1)
    const[perpage,setPerPage]=useState(10)
    const [totalpages, setTotalpages] = useState('')
     const[totalcount,setTotalcount]=useState()
     const[cate,setCate]=useState([])
    const fetchCategory = async () => {
        setIsLoading(true)
        console.log("API",JSON.stringify({
                page: pageNumber,
                limit: 10,
            }))
        await fetch(config.baseUrl + "api/admin/carlist", {
            method: "POST",

            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                page: pageNumber,
                limit: 10,
            }),
        })
        .then((response)=>response.json())
            .then((data)=>{
                console.log(data.data)
               setCate(data.data);
               setTotalpages(data.totalPages)
               setTotalcount(data.totalCount)
               setIsLoading(false)
            });
      };
      useEffect(() => {
        //fetchProduct()

        fetchCategory()


      }, [pageNumber]);
      const handlePageChange = async (selectedPage) => {
        setPageNumber(selectedPage);
        await fetch(config.baseUrl + "api/admin/carlist", {
            method: "POST",

            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                page: selectedPage,
                limit:perpage,
            }),
        })
        .then((response)=>response.json())
            .then((data)=>{
                console.log(data.data)
               setCate(data.data);
            //   setTotalpages(data.totalPages)
               setTotalcount(data.totalCount)
               setIsLoading(false)
            });

        console.log("pagenumber888",pageNumber)

  };

     const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
        if(newPerPage === totalcount){
            await fetch(config.baseUrl + "api/admin/carlist", {
                method: "POST",

                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    page: page,
                    limit: newPerPage,
                }),
            })
            .then((response)=>response.json())
                .then((data)=>{
                    console.log(data.data)
                   setCate(data.data);
                   setTotalpages(data.totalPages)
                   setTotalcount(data.totalCount)
                   setIsLoading(false)
                });
            console.log("Need to show all data in list");
            return
        }else{
            await fetch(config.baseUrl + "api/admin/carlist", {
                method: "POST",

                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    page: page,
                    limit: newPerPage,
                }),
            })
            .then((response)=>response.json())
                .then((data)=>{
                    console.log(data.data)
                   setCate(data.data);
                   setTotalpages(data.totalPages)
                   setTotalcount(data.totalCount)
                   setIsLoading(false)
                });
            console.log("Selected data in list");
            return
        }
    };
    const paginationComponentOptions = {
        selectAllRowsItem: true,
        selectAllRowsItemText: 'ALL',
      };
      function statusIdentification(array) {
        for (var i = 0; i < array.length; i++) {
          if (array[i].status === 1) {
            array[i].status1 = 'Active';
            array[i].statusColor = 'success';
          } else if (array[i].status === 0) {
            array[i].status1 = 'In-Active';
            array[i].statusColor = 'danger';
          }
        }
    }
    const [Id,setId]=useState(null);
    const handleDeleteData = (car_id) => {
        fetch(config.baseUrl + 'api/admin/getcar/' +car_id).then((response)=>response.json())
        .then((data)=>{
                setCId(data.data.car_id)})
                setId(car_id);
                setDeleteModal(!deleteModal);
    }
     const handleDeleteData1=()=>{
         if(Id){
         fetch(config.baseUrl+'api/admin/deletecar/'+Id, {
     method: 'DELETE'
   })
     .then((response )=>response.json())
     .then((data)=>{
         fetchCategory();
     });
 }
 setDeleteModal(!deleteModal);
     }


    const [selectCarBrand, setSelectCarBrand] = React.useState("");
    const handleCarBrand = (selectCarBrand) => {
        console.log(selectCarBrand);
        console.log(selectCarModel);
        if(!selectCarModel){
            setSelectCarBrand(selectCarBrand)
        }else{
            console.log(selectCarModel);
            const carBrandAndModel = filteredItems.map((item) => ({
                brand: item.car_brand,
                model: item.car_model,
              }));
              const isModelSelected = carBrandAndModel.some(
                (item) => item.brand === selectCarBrand && item.model === selectCarModel
              );
              if(isModelSelected){
                setError({ status: true, message: "Brand and Model is present already, Kindly Select another Brand or Model", type: 'error' });
            setTimeout(() => {
                setError({ status: false, message: '', type: '' });
              }, 3000);
              setAddAlert(true)
              setAddStatus(false)
              }else{
                setSelectCarBrand(selectCarBrand)
                //setAddAlert(false)

              }

        }
    }
    const [selectCarModel, setSelectCarModel] = React.useState(null)
    const handleCarModel = (selectCarModel) => {
      const model = selectCarModel.value;
      console.log(selectCarBrand);
      const carBrandAndModel = filteredItems.map((item) => ({
        brand: item.car_brand,
        model: item.car_model,
      }));

          console.log(carBrandAndModel);
          const isModelSelected = carBrandAndModel.some(
            (item) => item.brand === selectCarBrand && item.model === model
          );
          if(isModelSelected){
            console.log("Brand and Model is present already");
            setError({ status: true, message: "Brand and Model is present already, Kindly Select another Brand or Model", type: 'error' });
            setTimeout(() => {
                setError({ status: false, message: '', type: '' });
              }, 3000);
               setAddAlert(true)
               setAddStatus(false)

          }else{
            setSelectCarModel(selectCarModel.value)

          }

    }
    const [selectSeatCapacity, setSeatCapacity] = React.useState(null)
    const handleSeatCapacity = (selectSeatCapacity) => {
        console.log(selectSeatCapacity);
        setSeatCapacity(selectSeatCapacity)
    }
    const handleBabySeatcapacity = (isActiveChecked) => {
        const newBabySeatCapacity = isActiveChecked ? 1 : 0;
        setBabySeatCapacity(newBabySeatCapacity);
        console.log(newBabySeatCapacity);
    }
    const handleCarStatus = (isActiveCarChecked) => {
        const newStatus = isActiveCarChecked ? 1 : 0;
        setCarStatus(newStatus);
        console.log(newStatus);
    }
    // function handleFileSelect(event) {
    //     const selectedFiles = event.target.files;
    //     setImage(selectedFiles);
    //     // Perform further operations with the selected file
    // }
    const [images, setImages] = useState([]);


    // function handleFileSelect(event) {
    //   const selectedFiles = event.target.files;

    //   // Check if the total number of images is less than 3 before adding more
    //   if (images.length + selectedFiles.length > 3) {
    //     alert("You can select a maximum of three images.");
    //   } else {
    //     // Convert selectedFiles to an array and add to the existing images array
    //     const newImages = Array.from(selectedFiles);
    //     setImages((prevImages) => [...prevImages, ...newImages]);
    //   }
    // }
    function handleFileSelect(event) {
      const selectedFiles = event.target.files;
      const newImages = Array.from(selectedFiles);

      if (images.length + newImages.length > 3) {
        setImageAlert(true);
        setImageStatus(true)
      setTimeout(() => {
        setImageAlert(false);
        setImageStatus(false)
      }, 7000);
        //alert("You can select a maximum of three images.");
      } else {
        const updatedImages = [...images, ...newImages];

        // If the total number of images exceeds 3, keep only the first 3
        if (updatedImages.length > 3) {
          updatedImages.splice(3);
        }

        setImages(updatedImages);
      }
    }

    const handleImageDelete = (index) => (e) => {
      e.preventDefault();
      const updatedImages = [...images];
      const deletePhoto = updatedImages[index].replace(config.baseUrl, "");

      updatedImages.splice(index, 1);
      setImages(updatedImages);

      axios.delete(`${config.baseUrl}api/admin/deletecarphoto/${editId}`, {
        data: { car_photo: deletePhoto }
      }).then(res => {
        console.log('>>>>>>', res);
      }).catch(err => {
        const list = [...updatedImages, `${config.baseUrl}${deletePhoto}`];
        setImages(list);
      })
    }


    const getStatusFlag = () => {
        return isActiveCarChecked ? 1 : 0;
      };
      const getBabySeatStatus = () => {
        return isActiveChecked ? 1 : 0;
      };
      const [id,setCarId] = useState("")
    const handleAddCar = async (e) => {
        e.preventDefault();
        if(!selectCarBrand &&  !selectSeatCapacity && !airportPrice && !sDisposal && !mDisposal && !extraStop && !midnightCharge && !waitingCharge && !carDetails){
            setError({ status: true, message: "Please Enter Required Fields", type: 'error' });
            setTimeout(() => {
                setError({ status: false, message: '', type: '' });
              }, 3000);
            return;
        }
        if(!selectCarBrand){
            setError({ status: true, message: "Please Select Car Brand", type: 'error' });
            setTimeout(() => {
                setError({ status: false, message: '', type: '' });
              }, 3000);
            return;
        }
        if(!selectSeatCapacity){
            setError({ status: true, message: "Please Select Seat Capacity", type: 'error' });
            setTimeout(() => {
                setError({ status: false, message: '', type: '' });
              }, 3000);
            return;
        }
        if(!airportPrice){
            setError({ status: true, message: "Please Enter Airport Arrival/Departure Price", type: 'error' });
            setTimeout(() => {
                setError({ status: false, message: '', type: '' });
              }, 3000);
            return;
        }
        if(!sDisposal){
            setError({ status: true, message: "Please Enter Singapaore Disposal Charge", type: 'error' });
            setTimeout(() => {
                setError({ status: false, message: '', type: '' });
              }, 3000);
            return;
        }
        if(!mDisposal){
            setError({ status: true, message: "Please Enter Malaysia Disposal Charge", type: 'error' });
            setTimeout(() => {
                setError({ status: false, message: '', type: '' });
              }, 3000);
            return;
        }
        if(!extraStop){
            setError({ status: true, message: "Please Enter Price for Extra stop", type: 'error' });
            setTimeout(() => {
                setError({ status: false, message: '', type: '' });
              }, 3000);
            return;
        }
        if(!midnightCharge){
            setError({ status: true, message: "Please Enter Price for Midnight Travel", type: 'error' });
            setTimeout(() => {
                setError({ status: false, message: '', type: '' });
              }, 3000);
            return;
        }
        if(!waitingCharge){
            setError({ status: true, message: "Please Enter Waiting Charge", type: 'error' });
            setTimeout(() => {
                setError({ status: false, message: '', type: '' });
              }, 3000);
            return;
        }
        if(!extrahours){
            setError({ status: true, message: "Please Enter Extra hours", type: 'error' });
            setTimeout(() => {
                setError({ status: false, message: '', type: '' });
              }, 3000);
            return;
        }
        if(!carDetails){
            setError({ status: true, message: "Please Enter Details", type: 'error' });
            setTimeout(() => {
                setError({ status: false, message: '', type: '' });
              }, 3000);
            return;
        }


        setIsLoading(true)
        const formData = new FormData();
        formData.append('car_brand',selectCarBrand);
        formData.append('car_model',selectCarModel);
        formData.append('seat_capacity',selectSeatCapacity);
        formData.append('baby_seat_available',getBabySeatStatus());
        formData.append('airport_arrival',airportPrice);
        formData.append('singapore_disposal_per_hours',sDisposal);
        formData.append('malaysia_disposal_per_hours',mDisposal);
        formData.append('extra_stop_surcharge',extraStop);
        formData.append('midnight_surcharge_per_hours',midnightCharge);
        formData.append('waiting_time_per_minutes',waitingCharge);
        formData.append('extra_hours',extrahours);
        formData.append('car_details',carDetails);
          // Append each image to the FormData
  for (let i = 0; i < images.length; i++) {
    formData.append(`car_photo_${i + 1}`, images[i]);
  }

        // formData.append('car_photo_1', image[0]);
        // formData.append('car_photo_2', image[1]);
        // formData.append('car_photo_3', image[2]);
        // formData.append('car_photo_4', image[3]);
        formData.append('status',getStatusFlag());


       let res = await fetch(config.baseUrl + "api/admin/createcar", {
            method: 'POST',
            body: formData
        });
        let resJson = await res.json();
        if (res.status === 200) {

            fetchCategory()
           // console.log(data)
            setCarId(resJson.data.car_id);
            setAddStatus(true)
            setAddAlert(true)
            setSelectCarBrand("")
            setSelectCarModel("")
            setSeatCapacity("")
            setAirportPrice("")
            setSDisposal("")
            setMDisposal("")
            setImages([]);
            setExtraStop("")
            setMidnightCharge("")
            setWaitingCharge("")
            setExtrahours("")
            setCarDetails("")
            setTimeout(() => {
               // setIsAdd(false)
                setAddAlert(false);
                handleCloseDialog();
            }, 2000);
            setIsLoading(false)
        }else{
            setError({ status: true, message: resJson.message, type: 'error' });
            setTimeout(() => {
                        setError({ status: false, message: '', type: '' });
                        setAddAlert(false)
                    }, 3000);
                    setAddStatus(false)
                    setAddAlert(true)

        }

    }
    const [updatestatus,setupdatestatus]=useState(false);
    const [addStatus,setAddStatus]=useState(false);
    const [addAlert,setAddAlert]=useState(false)
    const [updateAlert,setUpdateAlert]=useState(false)
    const [imageStatus,setImageStatus]=useState(false);
    const [imageAlert,setImageAlert]=useState(false)
    const handleCloseDialog = () => {
        setSelectCarBrand("")
        setSelectCarModel("")
        setSeatCapacity("")
        setAirportPrice("")
        setSDisposal("")
        setImages([]);
        setMDisposal("")
        setExtraStop("")
        setMidnightCharge("")
        setWaitingCharge("")
        setExtrahours("")
        setCarDetails("")
        setLength("")
        setImage([])
        setIsAdd(false)
    }
    const handleAddDialog = () => {
        setIsAdd(true)
        setIsEdit(false)
    }

    const handleEditDialog = (rowId) => {
        console.log(rowId)
        setEditId(rowId);
        setIsLoading(true)
        console.log(config.baseUrl + 'api/admin/getcar/' + rowId)
        fetch(config.baseUrl + 'api/admin/getcar/' +rowId).then((response)=>response.json())
        .then((data)=>{

                setCId(data.data.car_id)
                setLength(data.data.car_photos.length)
                setSelectCarBrand(data.data.car_brand);
                setSelectCarModel(data.data.car_model.toString())
                setSeatCapacity(data.data.seat_capacity.toString())
                setAirportPrice(data.data.airport_arrival)
                setSDisposal(data.data.singapore_disposal_per_hours)
                setMDisposal(data.data.malaysia_disposal_per_hours)
                setExtraStop(data.data.extra_stop_surcharge)
                setMidnightCharge(data.data.midnight_surcharge_per_hours)
                setWaitingCharge(data.data.waiting_time_per_minutes)
                setCarDetails(data.data.car_details)
                setExtrahours(data.data.extra_hours)
                 // Fetch car images
      if (data.data.car_photos && Array.isArray(data.data.car_photos)) {
        // Assuming you have an "images" state to store the fetched images
        setImages(data.data.car_photos.map((photoUrl) => config.baseUrl + photoUrl));
        console.log(images,"777777777777777777",data.data.car_photos.map((photoUrl) => config.baseUrl + photoUrl))
      }
            if(data.data.status===1){
                setIsActiveCarChecked(true);
            }else{
                setIsActiveCarChecked(false);
            }
            if(data.data.baby_seat_available===1){
                setIsActiveChecked(true);
            }else{
                setIsActiveChecked(false);
            }
            setIsLoading(false)
        });
        setIsAdd(true)
        setIsEdit(true)
    }

    console.log(images,"ffffffffffffffffff")
    const handleUpdateCar = async (e) =>{

        e.preventDefault();
        // if(!selectCarBrand &&  !selectSeatCapacity && !airportPrice && !sDisposal && !mDisposal && !extraStop && !midnightCharge && !waitingCharge && !carDetails){
        //     setError({ status: true, message: "Please Enter Required Fields", type: 'error' });
        //     setTimeout(() => {
        //         setError({ status: false, message: '', type: '' });
        //       }, 3000);
        //     return;
        // }
        // if(!selectCarBrand){
        //     setError({ status: true, message: "Please Select Car Brand", type: 'error' });
        //     setTimeout(() => {
        //         setError({ status: false, message: '', type: '' });
        //       }, 3000);
        //     return;
        // }
        // if(!selectSeatCapacity){
        //     setError({ status: true, message: "Please Select Seat Capacity", type: 'error' });
        //     setTimeout(() => {
        //         setError({ status: false, message: '', type: '' });
        //       }, 3000);
        //     return;
        // }
        // if(!airportPrice){
        //     setError({ status: true, message: "Please Enter Airport Arrival/Departure Price", type: 'error' });
        //     setTimeout(() => {
        //         setError({ status: false, message: '', type: '' });
        //       }, 3000);
        //     return;
        // }
        // if(!sDisposal){
        //     setError({ status: true, message: "Please Enter Singapaore Disposal Charge", type: 'error' });
        //     setTimeout(() => {
        //         setError({ status: false, message: '', type: '' });
        //       }, 3000);
        //     return;
        // }
        // if(!mDisposal){
        //     setError({ status: true, message: "Please Enter Malaysia Disposal Charge", type: 'error' });
        //     setTimeout(() => {
        //         setError({ status: false, message: '', type: '' });
        //       }, 3000);
        //     return;
        // }
        // if(!extraStop){
        //     setError({ status: true, message: "Please Enter Price for Extra stop", type: 'error' });
        //     setTimeout(() => {
        //         setError({ status: false, message: '', type: '' });
        //       }, 3000);
        //     return;
        // }
        // if(!midnightCharge){
        //     setError({ status: true, message: "Please Enter Price for Midnight Travel", type: 'error' });
        //     setTimeout(() => {
        //         setError({ status: false, message: '', type: '' });
        //       }, 3000);
        //     return;
        // }
        // if(!waitingCharge){
        //     setError({ status: true, message: "Please Enter Waiting Charge", type: 'error' });
        //     setTimeout(() => {
        //         setError({ status: false, message: '', type: '' });
        //       }, 3000);
        //     return;
        // }
        // if(!carDetails){
        //     setError({ status: true, message: "Please Enter Details", type: 'error' });
        //     setTimeout(() => {
        //         setError({ status: false, message: '', type: '' });
        //       }, 3000);
        //     return;
        // }

        console.log("inside update")
        const formData = new FormData();
        formData.append('car_brand',selectCarBrand);
        formData.append('car_model',selectCarModel);
        formData.append('seat_capacity',selectSeatCapacity);
        formData.append('baby_seat_available',getBabySeatStatus());
        formData.append('airport_arrival',airportPrice);
        formData.append('singapore_disposal_per_hours',sDisposal);
        formData.append('malaysia_disposal_per_hours',mDisposal);
        formData.append('extra_stop_surcharge',extraStop);
        formData.append('midnight_surcharge_per_hours',midnightCharge);
        formData.append('waiting_time_per_minutes',waitingCharge);
        formData.append('extra_hours',extrahours);
        formData.append('car_details',carDetails);
        // if(image !== undefined || image !==null){
        //     formData.append('car_photo_1', image[0]);
        //     formData.append('car_photo_2', image[1]);
        //     formData.append('car_photo_3', image[2]);
        //     formData.append('car_photo_4', image[3]);
        // }
        for (let i = 0; i < images.length; i++) {
          formData.append(`car_photo_${i + 1}`, images[i]);
        }

        formData.append('status',getStatusFlag());
        console.log("Inside update")

        let res = await fetch(config.baseUrl + "api/admin/updatecar_v2/"+ editId, {
              method: "PUT",
              body: formData
            });
            let resJson = await res.json();
            if (res.status === 200) {
                console.log("SUCCESS");
                fetchCategory()
                setUpdateAlert(true)
                setupdatestatus(true);
                setTimeout(() => {
                    setUpdateAlert(false)
                    setupdatestatus(false);
                    handleCloseDialog();
                   //setIsAdd(false)
                  }, 2000);
                setIsLoading(false)
             }else{

                setError({ status: true, message: resJson.message, type: 'error' });
                setTimeout(() => {
                            setError({ status: false, message: '', type: '' });
                            setUpdateAlert(false)
                        }, 3000);
                        setupdatestatus(false)
                        setUpdateAlert(true)

            }
            // )
            // .catch(error => {
            //     console.error(error);
            //     console.log("Error",data.message);
            //     setError(data.message);
            //     setUpdateAlert(true)
            //     setupdatestatus("failed")
            // });


    }

    const columnsProducts = [
        {
            name: <span className='font-weight-bold fs-13 text-nowrap'>Car ID</span>,
            selector: row => row.car_id,
            cell: (row => <p className="fw-medium link-primary">{row.car_id}</p>),
            sortable: true,
        },
        {
            name: <span className='font-weight-bold fs-13 text-nowrap'>Car Brand & Model</span>,
            selector: row => row.car_brand,
            cell: (row) => (
                <div>
                  <h5 className="fs-14 mb-1">{row.car_brand}</h5>
                  <p className="fw-medium text-secondary">{row.car_model}</p>
                </div>
              ),

            sortable: true,
        },
        {
            name: <span className='font-weight-bold fs-13 text-nowrap'>Seat Capacity</span>,
            selector: row => row.seat_capacity,
            cell: (row) => (
                <div>
                  <h5 className="fs-14 mb-1">{row.seat_capacity}</h5>
                  <p className="text-muted mb-0">
                    <span className="text-muted mb-0 mt-0">Baby Seat: </span>
                    <span className="fw-medium text-dark">{row.baby_seat_available}</span>
                  </p>
                </div>
              ),
            sortable: true,
        },
        {
            name: <span className='font-weight-bold fs-13 text-nowrap'>Service Price</span>,
            selector: 'service_price',
            cell: (row) => (
              <div className="fw-medium">
                <p className="text-muted mb-0">Airport Arrival / Departure</p>
                <p className="text-muted mb-0">Point to Point:</p>
                {row.airport_arrival && <p className="mb-0 mt-0 text-dark">${row.airport_arrival.toFixed(2)}</p>}
                {row.singapore_disposal_per_hours && (
                  <div>
                    <p className="text-muted mb-0 mt-3">
                      <span>Singapore Disposal: </span>
                      <span className="fw-medium text-dark">${row.singapore_disposal_per_hours.toFixed(2)}</span>
                    </p>
                    <p className="text-muted mb-0 mt-0">
                      <span>Malaysia Disposal: </span>
                      <span className="fw-medium text-dark">${row.malaysia_disposal_per_hours.toFixed(2)}</span>
                    </p>
                  </div>
                )}
              </div>
            ),
            sortable: true,
        },
        {
            name: <span className='font-weight-bold fs-13 text-nowrap'>Extra Price</span>,
            selector: 'extra_price',
            cell: (row) => (
              <div className="d-flex align-items-center">
                <div className="flex-grow-1">
                  <p className="text-muted mb-0">
                    Extra Stop Surcharge: <span className="fw-medium text-dark">${row.extra_stop_surcharge.toFixed(2)}</span>
                  </p>
                  <p className="text-muted mb-0">
                    Midnight Surcharge: <span className="fw-medium text-dark">${row.midnight_surcharge_per_hours.toFixed(2)}</span>
                  </p>
                  <p className="text-muted mb-0 mt-3">
                    Waiting Time (Per 15mins):
                    <span className="fw-medium text-dark d-block">${row.waiting_time_per_minutes.toFixed(2)}</span>
                  </p>
                </div>
              </div>
            ),
            sortable: true,
        },


        {
            name: <span className='font-weight-bold fs-13 text-nowrap'>Status</span>,
            selector: row => row.status,
            cell: (row => <div className={`badge text-uppercase pt-1 bg-${row.statusColor}`}>{row.status1}</div>),
            sortable: true,
        },
        {
            name: <span className='font-weight-bold fs-13 text-nowrap'>Action</span>,
            id: "action",
            style: { position: 'sticky', right: 0, backgroundColor: 'white', zIndex: 9 },
            selector: row => row.userAccount,
            cell: (row =>
                <div>
                    <Button className="btn btn-soft-info btn-icon fs-14 me-1" onClick={setIsEdit?() => handleEditDialog(row._id): null}>
                        <i className="ri-edit-box-line"></i>
                    </Button>
                     <Button className="btn btn-soft-info btn-icon fs-14" onClick={()=>handleDeleteData(row._id)}>
                        <i className="ri-delete-bin-line"></i>
                    </Button>
                </div>
            ),
            sortable: true,
        },

    ];
    const handleKeyPress = (e) => {

            const allowedKeys = /[0-9]/;
            const key = e.key;
            if (!allowedKeys.test(key)) {
              e.preventDefault();
            }

      };

    /********Data table Search****** */
    const [filterText, setFilterText] = React.useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
        false
    );
    const carListArray = Object.values(cate);
    const filteredItems = carListArray.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );
    statusIdentification(filteredItems);
    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };
    let formChanged = false;
    let isOpen = false;

    // Check if the form has been modified
    function checkFormChanges() {
      formChanged = true;
    }

    // Display confirmation dialog when canceling the form
    function cancelForm() {
      if (formChanged) {
        openDialog();
      } else {
        resetForm();
      }
    }

    // Open the confirmation dialog
    function openDialog() {
      isOpen = true;
      document.getElementById("confirmationDialog").style.display = "block";
    }

    // Close the confirmation dialog
    function closeDialog() {
      isOpen = false;
      document.getElementById("confirmationDialog").style.display = "none";
    }

    // Confirm the cancellation and reset the form
    function confirmCancellation() {
      closeDialog();
      resetForm();
    }

    // Reset the form
    function resetForm() {
      document.getElementById("myForm").reset();
      formChanged = false;
    }

    const formInputs = document.querySelectorAll("#myForm input");
    formInputs.forEach(input => {
      input.addEventListener("input", checkFormChanges);
    });

        return (
            <FilterComponent
                onFilter={e => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [filterText, resetPaginationToggle]);

    document.title="DB LIMO - Car Onboarding";
    return (
        <React.Fragment>

        <div className="page-content">
            <Container fluid>
                <BreadCrumb title="Car Onboard" pageTitle="Home" ></BreadCrumb>
            {// isLoading ? <LoadingSpinner /> :
                <Card>
                    <CardBody className="p-0">
                        <Row>
                            <Col>
                                <DataTable
                                    title={<h5 className="mb-0">Car Details</h5>}
                                    className="custom-datatable"
                                    columns={columnsProducts}
                                    data={filteredItems}
                                    sortIcon={<span className="custom-sort-img"><i className='bx bxs-sort-alt custom-sort-icon'></i></span>}
                                    pagination
                                    paginationServer={true}
                                    paginationTotalRows={totalcount}
                                    onChangeRowsPerPage={handlePerRowsChange}
                                    onChangePage={handlePageChange}
                                    paginationComponentOptions={paginationComponentOptions}
                                    paginationRowsPerPageOptions={["10","20","40"]}
                                    // pagination
                                    // paginationComponent={CustomPagination}
                                    // paginationComponentOptions={0}
                                    // paginationRowsPerPageOptions={[2, 4, 8, 12, 15]}
                                    // selectableRows={false}
                                    // selectableRowsComponent={TableCheckBox}
                                     noDataComponent={<NoResultData />}
                                    // selectableRowsHighlight={true}
                                    // selectableRowsVisibleOnly={false}
                                     subHeaderAlign="left"
                                     contextMenu={true}
                                     persistTableHead
                                    actions={
                                        <Row className="g-4 align-items-start align-items-lg-center flex-column flex-row-lg row">

                                            <Col md={12} lg="auto" className="ms-lg-auto no-paading-mobile">
                                                <div className="hstack gap-2 flex-lg-row flex-wrap">
                                                    {subHeaderComponent}
                                                    <button type="button" className="btn btn-success w-100-mobile" onClick={handleAddDialog} >
                                                        <i className="ri-add-line align-bottom me-1"></i>{" "}
                                                        Add New Car
                                                    </button>


                                                </div>
                                            </Col>
                                        </Row>

                                    }
                                />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>}
            </Container>
        </div>
        { //isLoading ? <LoadingSpinner /> :
        <Modal
                isOpen={isAdd}
                toggle={handleCloseDialog}
                centered
                size="lg"
                className="border-0"
                modalClassName='modal fade zoomIn'
            >
                <ModalHeader className="p-3 bg-light" toggle={handleCloseDialog}>
                    {!!isEdit ? 'Car Onboarding' : 'Car Onboarding'}
                </ModalHeader>
                <Form id="Update" onSubmit={isEdit ? handleUpdateCar : handleAddCar}>
                    <ModalBody>
                    {isEdit && updateAlert && updatestatus &&(
                <UncontrolledAlert color="success" onClose={() => setupdatestatus(false)}>Car has been updated successfully- {CId}</UncontrolledAlert>)}
                    {isEdit && updateAlert && !updatestatus && error.status &&(
                <UncontrolledAlert color="danger" onClose={() => setupdatestatus(false)}>  <div >
                <div
                  id="error"
                  style={{
                   // color: 'red',
                  //  fontWeight: 'bold',
                    textAlign: 'start'
                  }}
                >
                  <i className="bi bi-exclamation-circle" ></i>&nbsp;&nbsp;&nbsp;&nbsp;{error.message}
                </div>

                          </div></UncontrolledAlert>)}

            {isAdd && addAlert && addStatus &&  (
            <UncontrolledAlert color="success" onClose={() => setAddStatus(false)}>Car has been added successfully- {id}</UncontrolledAlert>)}
            {isAdd && addAlert && !addStatus && error.status &&(
            <UncontrolledAlert color="danger" onClose={() => setAddStatus(false)}>  <div >
                <div
                  id="error"
                  style={{
                  //  color: 'red',
                  //  fontWeight: 'bold',
                    textAlign: 'start'
                  }}
                >
                  <i className="bi bi-exclamation-circle" ></i>&nbsp;&nbsp;&nbsp;&nbsp;{error.message}
                </div>

                          </div></UncontrolledAlert>)}
                        <Row className="g-3">
                            <Col sm={12} xl={6} xxl={4}  lg={4} md={6}>

                                    <Label
                                        htmlFor="carBrandName"
                                        className="form-label"
                                    >
                                        Car Brand Name (<span className="text-danger">*</span>)
                                    </Label>
                                    <Select
                                        className="mb-0"
                                        classNamePrefix="form-control-select"
                                        value={selectCarBrand ? carBrands.find((option) => option.value === selectCarBrand.toString()) : ""}
                                        placeholder="Select Car Brand Name"
                                        onChange={(selectedOption) => handleCarBrand(selectedOption.value)}
                                        options={carBrands}
                                        inputId="carBrandName"
                                        required

                                    ></Select>

                            </Col>
                            <Col sm={12} xl={6} xxl={4}  lg={4} md={6}>

                                    <Label
                                        htmlFor="lastName"
                                        className="form-label"
                                    >
                                        Car Model(<span className="text-danger">*</span>)
                                    </Label>
                                    <Select
                                        className="mb-0"
                                        classNamePrefix="form-control-select"
                                        value={selectCarModel ? carModels.find((option) => option.value === selectCarModel.toString()) : ""}
                                       // value={selectCarModel.value}
                                        placeholder="Select Car Model Name"
                                        onChange={ handleCarModel}
                                        options={carModels}
                                        inputId="hotelName" required

                                    ></Select>
                            </Col>
                            <Col sm={12} xl={6} xxl={4}  lg={4} md={6}>

                                    <Label
                                        htmlFor="jobRoleName"
                                        className="form-label"
                                    >
                                        Seat Capacity (<span className="text-danger">*</span>)
                                    </Label>
                                    <Select
                                        className="mb-0"
                                        classNamePrefix="form-control-select"
                                        value={selectSeatCapacity ? seatCapacityList.find((option) => option.value === selectSeatCapacity.toString()) : ""}
                                        placeholder="Select Seat Capacity"
                                        onChange={(selectedOption) => handleSeatCapacity(selectedOption.value)}
                                        options={seatCapacityList}
                                        inputId="hotelName" required

                                    ></Select>

                            </Col>

                            <Col xxl={12} sm={12} lg={6} md={12} className="mb-3">
                                <div className="form-check form-switch form-check-primary mt-0">
                                    <Input className="form-check-input" type="checkbox" role="switch" id="ActiveStatus" checked={isActiveChecked} onChange={() => {
                                        setIsActiveChecked((state) => !state);
                                        handleBabySeatcapacity(!isActiveChecked);
                                    }} />
                                    <Label className="form-check-label" for="ActiveStatus">Baby Seat Available?</Label>
                                </div>
                            </Col>

                        <Col>
                                <div>
                                    <h5 className="mb-0 text-dark">Car Price</h5>
                                    <p className="text-muted mt-0">Fill all information below</p>
                                </div>
                                <table className="table table-bordered align-middle table-nowrap mb-0">
                                    <tbody><tr className="bg-light">
                                        <td> Airport Arrival / Departure/ Point to Point (<span className="text-danger">*</span>)</td>
                                        <td> Singapore Disposal - Per Hours (Min. 3Hrs) (<span className="text-danger">*</span>)</td>
                                    </tr>
                                    <tr>
                                        <td><Input type="number" id="assignedtoName-field1"  placeholder="Price"  onKeyPress={handleKeyPress}
                                            value={airportPrice} onChange={(e)=>setAirportPrice(e.target.value)}  required

                                         /></td>
                                        <td><Input type="number" id="assignedtoName-field2"  placeholder="Price"  onKeyPress={handleKeyPress}
                                            value={sDisposal} onChange={(e)=>setSDisposal(e.target.value)} required
                                        /></td>
                                    </tr>
                                    <tr className="bg-light">
                                        <td> Malaysia Disposal - Per Hours (Min. 4Hrs) (<span className="text-danger">*</span>)</td>
                                        <td> Extra Stop Surcharge - Per Stop (<span className="text-danger">*</span>)</td>
                                    </tr>
                                    <tr>
                                        <td><Input type="number" id="assignedtoName-field1"  placeholder="Price"  onKeyPress={handleKeyPress}
                                            value={mDisposal} onChange={(e)=>setMDisposal(e.target.value)} required
                                        /></td>
                                        <td><Input type="number" id="assignedtoName-field2"  placeholder="Price"  onKeyPress={handleKeyPress}
                                            value={extraStop} onChange={(e)=>setExtraStop(e.target.value)} required
                                        /></td>
                                    </tr>
                                    <tr className="bg-light">
                                        <td> Midnight Surcharge (23:00 to 06:59) - Per Hrs (<span className="text-danger">*</span>)</td>
                                        <td> Waiting Time - Per 15mins (<span className="text-danger">*</span>)</td>
                                    </tr>
                                    <tr>
                                        <td><Input type="number" id="assignedtoName-field1"  placeholder="Price"  onKeyPress={handleKeyPress}
                                            value={midnightCharge} onChange={(e)=>setMidnightCharge(e.target.value)} required
                                        /></td>
                                        <td><Input type="number" id="assignedtoName-field2"  placeholder="Price"  onKeyPress={handleKeyPress}
                                            value={waitingCharge} onChange={(e)=>setWaitingCharge(e.target.value)} required
                                        /></td>
                                    </tr>
                                    <tr className="bg-light">
                                        <td> Extra Hours (<span className="text-danger">*</span>)</td>

                                    </tr>
                                    <tr>
                                        <td><Input type="text" id="assignedtoName-field1"  placeholder="Enter Extra Hours"
                                            value={extrahours} onChange={(e)=>setExtrahours(e.target.value)} required
                                        /></td>

                                    </tr>
                                </tbody></table>
                            </Col>
                            <Col sm={12} xl={12} xxl={12}  lg={12} md={12}>
                                    <Label
                                        htmlFor="carDetails"
                                        className="form-label"
                                    >
                                        Car Details (<span className="text-danger">*</span>)
                                    </Label>
                                    <Input
                                     type="textarea"
                                     rows={3}
                                        className="mb-0"
                                        classNamePrefix="form-control-select"
                                        placeholder="Enter Description"
                                        value={carDetails} onChange={(e)=>setCarDetails(e.target.value)}
                                        id="carDetails" required

                                    />

                            </Col>
                            <Col sm={12} xl={12} xxl={12}  lg={12} md={12}>
                            <Label
                                        htmlFor="carPhotos"
                                        className="form-label me-2"
                                    >
                                        Car Photos
                                    </Label>
                                    <input
          type="file"
          id="carPhotos"
          onChange={handleFileSelect}
          multiple
        />
      </Col>
      <div style={{ display: "flex", flexDirection: "row" }}>
        {images.map((image, index) => (
          <div key={index} style={{ marginRight: "10px", position: "relative" }}>
            <img
             src={typeof image === 'string' ? image : URL.createObjectURL(image)}
              //src={image}
              alt={`Car Photo ${index + 1}`}
              width="100" height="60"
            />
            <button
              onClick={handleImageDelete(index)}
              style={{
                position: "absolute",
                top: "-1px",
                right: "0px",
                background: "transparent",
                border: "none",
                color: "white",
                cursor: "pointer",backgroundColor:"green",
                borderRadius:"10px",height:"20px",width:"20px"
              }}
            >
              X
            </button>
          </div>
        ))}
        </div>
                                    {/* {isEdit && (
                                        <p className="form-label me-2">{length} File(s) Uploaded</p>
                                      )} */}
                                       {imageAlert && (
        <div className="your-custom-alert-class"  style={{color:"red",fontSize:"18px"}} >
          You can select a maximum of three images.
        </div>
      )}
        {/* {imageAlert  && !imageStatus && (
                <UncontrolledAlert color="danger" onClose={() => setImageStatus(false)}>  You can select a maximum of three images.</UncontrolledAlert>)} */}

                            <Col xxl={12} sm={12} lg={6} md={12} className="mb-0">
                                <div className="form-check form-switch form-check-primary mt-0">
                                    <Input className="form-check-input" type="checkbox" role="switch" id="ActiveSCartatus" checked={isActiveCarChecked} onChange={() => {
                                        setIsActiveCarChecked((state) => !state);
                                        handleCarStatus(!isActiveCarChecked);
                                    }} />
                                    <Label className="form-check-label" for="ActiveSCartatus">Car Status Active?</Label>
                                </div>
                            </Col>
                        </Row>
                  {/*       <div >   {error.status && (
        <div
          id="error"
          style={{
            color: 'red',
            fontWeight: 'bold',
            textAlign: 'start'
          }}
        >
          <i className="bi bi-exclamation-circle" style={{ fontWeight: 'bold' }}></i>&nbsp;&nbsp;&nbsp;&nbsp;{error.message}
        </div>
      )}
                  </div>*/}

                    </ModalBody>
                    <ModalFooter className="justify-content-start">
                        <div className="hstack gap-2">
                            { isEdit ? ( <button type="submit" className="btn btn-success" id="add-btn" >Update Car</button>):
                            ( <button type="submit" className="btn btn-success" id="add-btn">Add Car</button>)}

                            <button type="button" className="btn btn-light border_gry" data-bs-dismiss="modal" onClick={handleCloseDialog}>Cancel</button>
                        </div>
                    </ModalFooter>
                </Form>
            </Modal>}
            <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteData1}
        onCloseClick={() => setDeleteModal(false)}
        entityName="Car"
        entityId={CId}
      />
        </React.Fragment>
    )
}

export default CarOnBoarding