import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import BookingOverView from './BookingOverView';
import UpcomingSchedules from './UpcomingSchedules';
import Widgets from './Widgets';
import BookingStatus from './BookingStatus';
import UpcomingStatus from './UpcomingStatus';
import Section from './Section'
import { useState,useEffect } from "react"
const DashboardProject = () => {
    const [bookingCount2, setBookingCount2] = useState("");
    const setBookingCount1 =(setBookingCount1)=>{
        setBookingCount2(setBookingCount1)
       // console.log(setBookingCount1,"INSIDE INDEX")
    }
  document.title="DB LIMO - Booking Dahboard";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                   <Row>
                    <Col xxl={12}>
                        <Section setBookingCount1={setBookingCount1}/>
                    </Col>
                   </Row>
                    <Row className="project-wrapper">
                        <Col xxl={8}>
                            <Widgets bookingCount1={bookingCount2}/>
                            <BookingOverView />
                        </Col>
                        <UpcomingSchedules />
                    </Row>
                    <Row>
                        <BookingStatus />
                        <UpcomingStatus />
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
};

export default DashboardProject;