import moment from "moment";

export const getWhatsAppMessage = (data, {booktype, driveNumber, carNo, carbrand, carmodel}) => {
  console.log(data);
  let message = '';

  switch (booktype) {
    case "Airport Arrival":
      message =
`
Account:
${data.hotel_name}

Booking No: ${data.booking_id}

Guest: ${data.passenger_name}
Guest Number: ${data.country_code} ${data.passenger_contact_number}
No. of Guest: ${data.number_of_passengers.adult} adult ${data.number_of_passengers.child} child
No. of Luggages: ${data.number_of_luggage}

Airport Arrival

Date: ${moment(data.date).format('DD-MM-YYYY')}
Time: ${data.time}

Pickup Location:
${data.airport_name}

Dropoff Location:
${data.hotel_name}

Flight Number: ${data.flight_number}

${data?.extra_stop === 0 ? 'Extra Stop: ' + data.extra_stop : ''}${data?.extra_stop === 1 ? `Extra Stop: 1 (${data?.extra_stop_1})` : ''}${data?.extra_stop === 2 ? `Extra Stop: 2 (${data?.extra_stop_1},${data?.extra_stop_2})` : ''}

Driver Name: ${data.driver_name || ''}
Contact: ${driveNumber ? '+65' : ''} ${driveNumber || ''}
Car Number: ${carNo}
Car Model: ${carbrand}-${carmodel}

Remarks:
${data.remark || ''}
${getFirstCall(data)} ${getSecondCall(data)}`;
      break;

      case "Airport Departure":
        message =
`
Account:
${data.hotel_name}

Booking No: ${data.booking_id}

Guest: ${data.passenger_name}
Guest Number: ${data.country_code} ${data.passenger_contact_number}
No. of Guest: ${data.number_of_passengers.adult} adult ${data.number_of_passengers.child} child
No. of Luggages: ${data.number_of_luggage}

Airport Departure

Date: ${moment(data.date).format('DD-MM-YYYY')}
Time: ${data.time}

Pickup Location:
${data.hotel_name}

Dropoff Location:
${data.airport_name}

Flight Number: ${data.flight_number}

${data?.extra_stop === 0 ? 'Extra Stop: ' + data.extra_stop : ''}${data?.extra_stop === 1 ? `Extra Stop: 1 (${data?.extra_stop_1})` : ''}${data?.extra_stop === 2 ? `Extra Stop: 2 (${data?.extra_stop_1},${data?.extra_stop_2})` : ''}

Driver Name: ${data.driver_name || ''}
Contact: ${driveNumber ? '+65' : ''} ${driveNumber || ''}
Car Number: ${carNo || ''}
Car Model: ${carbrand || ''}-${carmodel || ''}

Remarks:
${data.remark || ''}
${getFirstCall(data)} ${getSecondCall(data)}`;
      break;


      case "Point To Point(Transfer)":
        message =
`
Account:
${data.hotel_name}

Booking No: ${data.booking_id}

Guest: ${data.passenger_name}
Guest Number: ${data.country_code} ${data.passenger_contact_number}
No. of Guest: ${data.number_of_passengers.adult} adult ${data.number_of_passengers.child} child
No. of Luggages: ${data.number_of_luggage}

Point To Point(Transfer)

Date: ${moment(data.date).format('DD-MM-YYYY')}
Time: ${data.time}

Pickup Location:
${data.pickup_location}

Dropoff Location:
${data.dropoff_location}

${data?.extra_stop === 0 ? 'Extra Stop: ' + data.extra_stop : ''}${data?.extra_stop === 1 ? `Extra Stop: 1 (${data?.extra_stop_1})` : ''}${data?.extra_stop === 2 ? `Extra Stop: 2 (${data?.extra_stop_1},${data?.extra_stop_2})` : ''}

Driver Name: ${data.driver_name || ''}
Contact: ${driveNumber ? '+65' : ''} ${driveNumber || ''}
Car Number: ${carNo || ''}
Car Model: ${carbrand || ''}-${carmodel || ''}

Remarks:
${data.remark || ''}
${getFirstCall(data)} ${getSecondCall(data)}`;
      break;

      case "Singapore Disposal":
        message =
`
Account:
${data.hotel_name}

Booking No: ${data.booking_id}

Guest: ${data.passenger_name}
Guest Number: ${data.country_code} ${data.passenger_contact_number}
No. of Guest: ${data.number_of_passengers.adult} adult ${data.number_of_passengers.child} child
No. of Luggages: ${data.number_of_luggage}

Singapore Disposal

Date: ${moment(data.date).format('DD-MM-YYYY')}
Time: ${data.time}

Pickup Location:
${data.pickup_location}

Dropoff Location:
${data.dropoff_location}

${data?.extra_stop === 0 ? 'Extra Stop: ' + data.extra_stop : ''}${data?.extra_stop === 1 ? `Extra Stop: 1 (${data?.extra_stop_1})` : ''}${data?.extra_stop === 2 ? `Extra Stop: 2 (${data?.extra_stop_1},${data?.extra_stop_2})` : ''}

Driver Name: ${data.driver_name || ''}
Contact: ${driveNumber ? '+65' : ''} ${driveNumber || ''}
Car Number: ${carNo || ''}
Car Model: ${carbrand || ''}-${carmodel || ''}

Remarks:
${data.remark || ''}
${getFirstCall(data)} ${getSecondCall(data)}`;
      break;

      case "Malaysia Disposal":
        message =
`
Account:
${data.hotel_name}

Booking No: ${data.booking_id}

Guest: ${data.passenger_name}
Guest Number: ${data.country_code} ${data.passenger_contact_number}
No. of Guest: ${data.number_of_passengers.adult} adult ${data.number_of_passengers.child} child
No. of Luggages: ${data.number_of_luggage}

Malaysia Disposal

Date: ${moment(data.date).format('DD-MM-YYYY')}
Time: ${data.time}

Pickup Location:
${data.pickup_location}

Dropoff Location:
${data.dropoff_location}

${data?.extra_stop === 0 ? 'Extra Stop: ' + data.extra_stop : ''}${data?.extra_stop === 1 ? `Extra Stop: 1 (${data?.extra_stop_1})` : ''}${data?.extra_stop === 2 ? `Extra Stop: 2 (${data?.extra_stop_1},${data?.extra_stop_2})` : ''}

Driver Name: ${data.driver_name || ''}
Contact: ${driveNumber ? '+65' : ''} ${driveNumber || ''}
Car Number: ${carNo || ''}
Car Model: ${carbrand || ''}-${carmodel || ''}

Remarks:
${data.remark || ''}
${getFirstCall(data)} ${getSecondCall(data)}`;
      break;

    default:
      break;
  }
  return message;
}

const getFirstCall = (data) => {
  if(data.first_call) {
    return `\nFirst Call: +65 ${data.first_call}`
  }  else {
    return ''
  }
}

const getSecondCall = (data) => {
  if(data.second_call) {
    return `\nSecond Call: +65 ${data.second_call}`
  } else {
    return ''
  }
}