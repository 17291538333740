import {
    Button, Card, CardBody, Col, Container, Form, Input, Label, Modal,
    ModalBody, ModalFooter, ModalHeader, Pagination, PaginationItem, PaginationLink, Row, Alert, UncontrolledAlert
} from "reactstrap"
import BreadCrumb from "../../Components/Common/BreadCrumb"
import DataTable from "react-data-table-component"
import React from "react"
import { cityList } from "./data"
import { useMemo } from "react"
import FilterComponent from '../../Components/datatable/filter'
import { Link } from "react-router-dom"
import { useState, useEffect } from "react"
import Select from "react-select";
import axios from "axios";
import LoadingSpinner from "./loader"
import DeleteModal from '../../Components/Common/DeleteModal';
import NoResultData from '../../Components/datatable/no-result'
import CustomPagination from "../../Components/custom-pagination/pagination"
import config from "../../common/config";
import Section from '../../Components/Common/Section'

import "./hotel.css"
//import { CountryDropdown } from 'react-country-codes';

// eslint-disable-next-line react/display-name
const TableCheckBox = React.forwardRef(({ onClick, ...rest }, ref) => (
    <div className="form-check form-check-size-16" >
        <input
            htmlFor="booty-check"
            type="checkbox"
            className="form-check-input"
            ref={ref}
            onClick={onClick}
            {...rest}
        />
        <label className="form-check-label" id="booty-check" />
    </div>
))
const HotelOnBoarding = () => {
    const [isAdd, setIsAdd] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false);
    const [isActiveChecked, setIsActiveChecked] = useState(true);
    const [phone, setPhone] = useState('');
    const [hotelname, setHotelname] = useState("")
    const [address1, setAddress1] = useState("")
    const [address2, setAddress2] = useState("")
    const [city, setCity] = useState("")
    const [pageNumber, setPageNumber] = useState(1)
    const [number, setNumber] = useState("")
    const [email, setEmail] = useState("")
    const [country, setCountry] = useState("")
    const [countrycode, setCountrycode] = useState("")
    const [status, setStatus] = useState("")
    const [selectedCountryCode, setSelectedCountryCode] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [countryCode, setCountryCode] = useState('');
    const [selectedId, setSelectedId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [id, setId] = useState("")
    const [HId,setHId]=useState("");
    const [value, setValue] = useState("");
    const [validMail,setValidMail]=useState("")
    //  const options = countryList().getData();
    const changeHandler1 = (selectedOption) => {
        setCountry(selectedOption.label);
        // You can send the selectedOption.label in your API request
        // instead of sending the entire selectedOption object
    };
    const changeHandler = (selectedOption) => {
        const selectedCountry = selectedOption.label;
        setCountry(selectedCountry);

        if (selectedCountry === "Singapore") {
            // Filter city options for Singapore cities
            const singaporeCityOptions = cityOptions.filter(
                (cityOption) => cityOption.country === "Singapore"
            );

            // Set the city options to the Singapore cities
            setCityOptions(singaporeCityOptions);

            // Set the default city to the first Singapore city
            setCity(singaporeCityOptions[0]);
        } else {
            // Reset the city options and selected city
            setCityOptions([]);
            setCity(null);
        }
    };

    //phonecode
    const handlePhoneChange = (value, country) => {
        const selectedCountryData = country || phoneInputRef?.getSelectedCountryData();
        setCountryCode(`+${selectedCountryData?.dialCode}`);
    };
    // Ref to the PhoneInput component
    let phoneInputRef;
    const handleCityChange = (value, country) => {
        setSelectCity(value);
    };
    const [cityOptions, setCityOptions] = useState([]);

    const dropdownClass = 'custom-dropdown';
    const handleChangeCountryCode = (selectedOption) => {
        setCountryCode(selectedOption.value);
    };
    const changeHandler2 = (selectedOption) => {
        const selectedCountry = selectedOption.label;
        setCountry(selectedCountry);

        if (selectedCountry === "Singapore") {
            // Filter city options for Singapore cities
            const singaporeCityOptions = cityOptions.filter(
                (cityOption) => cityOption.country === "sg"
            );

            // Set the city options to the Singapore cities
            setCityOptions(singaporeCityOptions);

            // Set the default city to the first Singapore city
            setCity(singaporeCityOptions[0]);
        } else {
            // Reset the city options and selected city
            setCityOptions([]);
            setCity(null);
        }
    };

    /* const countryCodeOptions = options.map((option) => ({
         value: option.value,
         label: `+${option.value}`,
     }));*/

    const handleCountryCodeChange = (selectedOption) => {
        setSelectedCountryCode(selectedOption);
    };
    const [showError, setShowError] = useState(false);
    const [showcontacterror, setShowcontactError] = useState(false)
    const [isBlurred, setIsBlurred] = useState(false);

    const validatePhoneNumber = (input) => {
      return /^\d{8}$/.test(input);
    };
    const handleCountryChange = (selectedOption) => {
        setSelectedCountry(selectedOption);
    };
    const handleActiveStatusChange = () => {
        setIsActiveChecked((state) => !state);
    };

      const handleFocus = () => {
        setShowError(false); // Hide the error when input is focused again
      };
      const handlenumberfocus=()=>{
        setShowcontactError(false)
      }
    const getStatusFlag = () => {
        return isActiveChecked ? 1 : 0;
    };
    const [message, setMessage] = useState("");

    const [error, setError] = useState({
        status: false,
        message: "",
        type: ""
    })
    let handleCreatehotel = async (e) => {
        e.preventDefault();

        if (!hotelname && !number && !email && !address1) {
            setError({ status: true, message: "Please Enter Required Fields", type: 'error' });
            setTimeout(() => {
                setError({ status: false, message: '', type: '' });
            }, 3000);
            return;
        }
        if (!hotelname) {
            setError({ status: true, message: "Please Enter Hotel Name", type: 'error' });
            setTimeout(() => {
                setError({ status: false, message: '', type: '' });
            }, 3000);
            return;
        }
        if (!number) {
            setError({ status: true, message: "Please Enter Contact Number", type: 'error' });
            setTimeout(() => {
                setError({ status: false, message: '', type: '' });
            }, 3000);
            return;
        }
        if (!email) {
            setError({ status: true, message: "Please Enter Email", type: 'error' });
            setTimeout(() => {
                setError({ status: false, message: '', type: '' });
            }, 3000);
            return;
        }
        if (!address1) {
            setError({ status: true, message: "Please Enter Address", type: 'error' });
            setTimeout(() => {
                setError({ status: false, message: '', type: '' });
            }, 3000);
            return;
        }
        if (number.length < 8) {
            setError({
                status: true,
                message: 'Contact Number Should be 8-digits.',
                type: 'error'
            });
            setAddStatus(false)
            setAddAlert(true)
            setTimeout(() => {
                setError({ status: false, message: '', type: '' });
            }, 3000);
            return;
        }
        // const isValid = email.includes("@")&& email.includes(".com");
        const isValid = /\S+@\S+\.\S+/.test(email);
        if (!isValid) {
            setError({
                status: true,
                message: 'Please Enter a valid Email',
                type: 'error'
            });
           setAddStatus(false)
           setAddAlert(true)
            setTimeout(() => {
                setError({ status: false, message: '', type: '' });
            }, 3000);
            return;
        } else {

            try {

                setIsLoading(true)
                let res = await fetch(config.baseUrl + "api/admin/createhotel", {
                    method: "POST",

                    headers: {
                        "Content-Type": "application/json",


                    },
                    body: JSON.stringify({
                        hotel_name: hotelname,
                        address_one: address1,
                        address_two: address2,
                        city: "Singapore",
                        country: "Singapore",
                        country_code: "+65",
                        contact_number: number,
                        email_id: validMail,
                        status: getStatusFlag()


                    }),
                });
                let resJson = await res.json();
                if (res.status === 200) {
                    console.log(resJson)
                    setId(resJson.data.hotel_id);
                    setAddStatus(true)
                    setAddAlert(true)
                    setIsLoading(false)
                    //setMessage("User created successfully");
                    // setHotelname("")

                    // setAddress1("")
                    // setAddress2("")
                    // setSelectCity("")
                    // setNumber("")
                    // setEmail("")
                    callAPI()
                    setTimeout(() => {
                        //setIsAdd(false)
                        setAddAlert(false)
                        handleCloseDialog();
                        //setError({ status: false, message: '', type: '' });
                    }, 2000);


                } else {
                    setIsLoading(false)
                    setMessage("Some error occured");
                    setError({ status: true, message: resJson.message, type: 'error' });
                    setTimeout(() => {
                        setError(prevError => ({ ...prevError, status: false }));
                        setAddAlert(false)
                        //setError({ status: false, message: '', type: '' });
                    }, 3000);
                    setAddStatus(false)
                    setAddAlert(true)
                }
            } catch (err) {
                console.log(err);
                setIsLoading(false)
                setAddStatus(false)
            }
        }
    };

    const inputStyle = {
        width: '110px',
        height: '40px',
    };
    {/*} const [selectCity, setSelectCity] = React.useState(null);
    const handleSelectCity = (selectCity) => {
        setSelectCity(selectCity)
    }*/}
    const [selectCity, setSelectCity] = useState('');

    const handleCityChange2 = (selectedOption) => {
        setSelectCity(selectedOption.value);
    };

    const citiesInSingapore = [
        'Central Area',
        'Jurong East',
        'Jurong West',
        'Woodlands',
        'Bedok',
        'Bukit Batok',
        'Bukit Merah',
        'Bukit Panjang',
        'Choa Chu Kang',
        'Geylang',
        'Hougang',
        'Kallang',
    ];

    const cityOptions2 = citiesInSingapore.map((city) => ({
        value: city,
        label: city,
    }));
    const [selectCountry, setSelectCountry] = React.useState(null)
    const handleSelectCountry = (selectCountry) => {
        setSelectCountry(selectCountry)
    }
    const handleCloseDialog = () => {
        setAddStatus(false)
        setIsAdd(false)
        setIsEdit(false)
        setHotelname("")
        setAddress1("")
        setAddress2("")
        setSelectCity("")
        setNumber("")
        setEmail("")
        setValidMail("")
        setShowError(false)
        setShowcontactError(false)
    }
    const handleAddDialog = () => {
        setIsAdd(true)
        setIsEdit(false)
    }
const [usercount,setusercount] = useState();
    const handleEditDialog = (rowId) => {
        setIsLoading(true)
        console.log(rowId);
        setEditId(rowId);
        const newStatus = isActiveChecked ? 1 : 0;
        setHotelStatus(newStatus);
        fetch(config.baseUrl + 'api/admin/gethotel/' + rowId).then((response) => response.json())
            .then((data) => {
                console.log("primary user count:",data.primaryUserCount)
                setusercount(data.primaryUserCount)
                setHId(data.data.hotel_id);
                setSelectedHotel(data.data);
                setHotelname(data.data.hotel_name);
                setNumber(data.data.contact_number);
                setAddress1(data.data.address_one);
                setAddress2(data.data.address_two);
                setEmail(data.data.email_id);
                setValidMail(data.data.email_id)
                setSelectCity(data.data.city);
                setIsActiveChecked(data.data.status)
                // if(data.data.status===1){
                //     setIsActiveChecked(true);
                // }else if(data.data.status===0){
                //     setIsActiveChecked(false);
                // }
                setIsLoading(false)
            });
        setIsAdd(true)
        setIsEdit(true)
        setIsLoading(false)
    }
    const handleUpdateHotel = async (e) => {
        e.preventDefault();
        if (!hotelname && !number && !email && !address1) {
            setError({ status: true, message: "Please Enter Required Fields", type: 'error' });
            setTimeout(() => {
                setError({ status: false, message: '', type: '' });
            }, 3000);
            return;
        }
        if (!hotelname) {
            setError({ status: true, message: "Please Enter Hotel Name", type: 'error' });
            setTimeout(() => {
                setError({ status: false, message: '', type: '' });
            }, 3000);
            return;
        }
        if (!number) {
            setError({ status: true, message: "Please Enter Contact Number", type: 'error' });
            setTimeout(() => {
                setError({ status: false, message: '', type: '' });
            }, 3000);
            return;
        }

        if (!address1) {
            setError({ status: true, message: "Please Enter Address", type: 'error' });
            setTimeout(() => {
                setError({ status: false, message: '', type: '' });
            }, 3000);
            return;
        }
        if (number.length < 8) {
            setError({
                status: true,
                message: 'Contact Number Should be 8-digits.',
                type: 'error'
            });
            setTimeout(() => {
                setError({ status: false, message: '', type: '' });
            }, 3000);
            return;
        }
        if (!email) {
            setError({ status: true, message: "Please Enter Email", type: 'error' });
            setTimeout(() => {
                setError({ status: false, message: '', type: '' });
            }, 3000);
            return;
        }
        //const isValid = email.includes("@")&& email.includes(".com");
        const isValid=/^[A-Za-z][A-Za-z0-9_.-]*@[A-Za-z][A-Za-z0-9_.-]*\.[A-Za-z]{2,}$/.test(email);
        if (!isValid) {
            setError({
                status: true,
                message: 'Please Enter a valid Email',
                type: 'error'
            });
            setTimeout(() => {
                setError({ status: false, message: '', type: '' });
            }, 3000);
            return;
        }
            const formattedNumber = String(number).padStart(10, "0");
            try {
                setIsLoading(true)
                let res = await fetch(config.baseUrl + "api/admin/updatehotel/" + editId, {
                    method: "PUT",

                    headers: {
                        "Content-Type": "application/json",

                    },
                    body: JSON.stringify({
                        hotel_name: hotelname,
                        address_one: address1,
                        address_two: address2,
                        city: "Singapore",
                        country: "Singapore",
                        country_code: "+65",
                        contact_number: number,
                        email_id: validMail,
                        status: getStatusFlag()
                    }),
                });
                let resJson = await res.json();
                if (res.status === 200) {

                    console.log("SUCCESS");
                                      setUpdateAlert(true)
                    setupdatestatus(true);
                    setIsLoading(false)
                    callAPI();
                    // setHotelname("")
                    // setAddress1("")
                    // setAddress2("")
                    // setSelectCity("")
                    // setNumber("")
                    // setEmail("")
                    setTimeout(() => {
                        setUpdateAlert(false)
                        handleCloseDialog();
                      //  setIsAdd(false)
                    }, 2000);
                    //setIsAdd(false)
                } else {
                    //setMessage("Some error occured");
                    // setError({ status: true, message: resJson.message, type: 'error' });
                    setIsLoading(false)
                    setUpdateAlert(true)
                    setupdatestatus(false)
                    setError({
                        status: true,
                        message: resJson.message,
                        type: 'error'
                    });
                    setTimeout(() => {
                        setError({ status: false, message: '', type: '' });
                        setUpdateAlert(false)
                    }, 3000);
                    return;


                }
            } catch (err) {
                console.log(err);
                setIsLoading(false)
                setUpdateAlert(true)
                setupdatestatus("failed")
            }

        setError("")
        setIsLoading(false)
    }
    const [editId, setEditId] = useState("")
    const [hotelStatus, setHotelStatus] = useState();
    const [selectedHotel, setSelectedHotel] = useState("")
    const [hotelList, setHotelList] = useState("")

    useEffect(() => {
        callAPI();
    }, [pageNumber]);

    const [totalpages, setTotalpages] = useState('')
    const callAPI = async () => {
        //setIsLoading(true)
        await fetch(config.baseUrl + "api/admin/hotellist", {
            method: "POST",

            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                page: pageNumber,
                limit: "10",
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                setHotelList(data.data);
                setTotalpages(data.totalPages)
                setTotalcount(data.totalCount)
                setIsLoading(false)
            });
        setIsLoading(false)
    }
    const handlePageChange = async (pageNumber) => {
        //callAPI(pageNumber)


        console.log("pagenumber", pageNumber)
        console.log("Inside Page Change", JSON.stringify({
            page: pageNumber,
            limit: perpage,
        }));
        await fetch(config.baseUrl + "api/admin/hotellist", {
            method: "POST",

            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                page: pageNumber,
                limit: perpage,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                // console.log(data);
                setHotelList(data.data);
                //  setTotalpages(data.totalPages)
                setTotalcount(data.totalCount)
                setIsLoading(false)
            });


    };
    const [perpage, setPerPage] = useState(10)
    const [totalcount, setTotalcount] = useState()
    const handlePerRowsChange = async (newPerPage, page) => {
        //setLoading(true);
        await fetch(config.baseUrl + "api/admin/hotellist", {
            method: "POST",

            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                page: page,
                limit: newPerPage,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                setHotelList(data.data);
                //  setTotalpages(data.totalPages)
                setTotalcount(data.totalCount)
                setIsLoading(false)
            });

        setPerPage(newPerPage);
        //setLoading(false);
    };
    const handleHotelStatus = (isActiveChecked) => {
        const newStatus = isActiveChecked ? 1 : 0;
        setHotelStatus(newStatus);
    }
    const handleKeyPress = (e) => {
        if (e.key === '+') {
            e.preventDefault();
        }
    };

    const handleEmailChange = (e) => {
      const newEmail = e.target.value;
      setEmail(newEmail);
      setShowError(false);
      validateEmail(newEmail);
      if(validateEmail(newEmail)){
        setValidMail(newEmail);
      }else{
        setError({
            status: true,
            message: 'Please Enter a valid Email',
            type: 'error'
        });
        setTimeout(() => {
            setError({ status: false, message: '', type: '' });
        }, 3000);
      }
    }
    const validateEmail = (input) => {
      // const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      const regex = /^[A-Za-z][A-Za-z0-9_.-]*@[A-Za-z][A-Za-z0-9_.-]*\.[A-Za-z]{2,}$/;
      return regex.test(input);
      };
      const handleBlur = () => {
        setShowError(!validateEmail(email));
      };
    const handleNumberChange = (e) => {
        const inputNumber = e.target.value;
        if (/^[0-9]{0,8}$/.test(inputNumber)) {
            setNumber(inputNumber);
        }
        else {
            setError({ status: false, message: '', type: '' });
        }
    };
    // Delete APi
    const handleDeleteData = (hotel_id,_id) => {
        console.log("Selected Hotel Id is", hotel_id);
        setSelectedId(_id);
        setHId(hotel_id)
        setDeleteModal(!deleteModal);
    }
    const handleDeleteData1 = () => {
        if (selectedId) {
           // console.log(config.baseUrl + 'api/admin/deletehotel/' + selectedId);
            fetch(config.baseUrl + 'api/admin/deletehotel/' + selectedId, {
                method: 'DELETE'
            }).then((response) => response.json())
                .then((data) => {
                    console.log(data);
                    callAPI();
                });
        }
        setDeleteModal(!deleteModal);
    }
    function statusIdentification(array) {
        for (var i = 0; i < array.length; i++) {
            if (array[i].status === 1) {
                array[i].status1 = 'Active';
                array[i].statusColor = 'success';
            } else if (array[i].status === 0) {
                array[i].status1 = 'In-Active';
                array[i].statusColor = 'danger';
            }
        }
    }
    const calculateHeight = (address) => {
        const lines = address.split('\n').length;
        const lineHeight = 20; // Adjust this value based on your desired line height
        const minHeight = 20; // Adjust this value based on your desired minimum height
        const calculatedHeight = lines * lineHeight + minHeight;
        return calculatedHeight;
    };
    const [updatestatus, setupdatestatus] = useState(false);
    const [addStatus, setAddStatus] = useState(false);
    const [addAlert, setAddAlert] = useState(false)
    const [updateAlert, setUpdateAlert] = useState(false)

    const [isOpen, setIsOpen] = useState(false);

    const handleCloseModal = () => {
        setTimeout(() => {
            setIsOpen(false);
        }, 2000); // Adjust the delay as needed (in this example, 2 seconds)
    };
    const paginationComponentOptions = {
        selectAllRowsItem: true,
        selectAllRowsItemText: 'ALL',
    };
    const columnsProducts = [
        {
            name: <span className='font-weight-bold fs-13 text-nowrap'>Hotel ID</span>,
            selector: row => row.hotel_id,
            cell: (row => <p className="fw-medium link-primary">{row.hotel_id}</p>),
            sortable: true,
        },
        {
            name: <span className='font-weight-bold fs-13 text-nowrap'>Hotel Name</span>,
            selector: row => row.hotel_name,
            cell: (row => row.hotel_name),
            sortable: true,
        },
        {
            name: <span className='font-weight-bold fs-13 text-nowrap'>Contact Number</span>,
            selector: row => row.contact_number,
            cell: (row => <div >{row.country_code} {row.contact_number}</div>),
            sortable: true,
        },
        {
            name: <span className='font-weight-bold fs-13 text-nowrap'>Email Address</span>,
            selector: row => row.email_id,
            cell: (row => <div className="fw-medium text-secondary">{row.email_id}</div>),
            sortable: true,
        },
        {
            name: <span className='font-weight-bold fs-13 text-nowrap'>Address </span>,
            selector: row => row.address_one,
            // cell: (row => row.address_one),
            cell: (row) => <div className="multiline-cell ${row.address_one.includes('\n') ? 'custom-height' : ''}"
            // style={row.address_one.includes('\n') ? { height: `${calculateHeight(row.address_one)}px` } : null}
            >{row.address_one}  {row.address_two}</div>,
            sortable: true,
        },

        {
            name: <span className='font-weight-bold fs-13 text-nowrap'>City</span>,
            selector: row => row.city,
            cell: (row => row.city),
            sortable: true,
        },
        {
            name: <span className='font-weight-bold fs-13 text-nowrap'>Country</span>,
            selector: row => row.country,
            cell: (row => row.country),
            sortable: true,
        },

        {
            name: <span className='font-weight-bold fs-13 text-nowrap'>Status</span>,
            selector: row => row.status,
            cell: (row => <div className={`badge text-uppercase pt-1 bg-${row.statusColor}`}>{row.status1}</div>),
            sortable: true,
        },
        {
            name: <span className='font-weight-bold fs-13 text-nowrap'>Action</span>,
            id: "action",
            style: { position: 'sticky', right: 0, backgroundColor: 'white', zIndex: 9 },
            selector: row => row.userAccount,
            cell: (row =>
                <div>
                    <Button className="btn btn-soft-info btn-icon fs-14 me-1" onClick={setIsEdit ? () => handleEditDialog(row._id) : null}>
                        <i className="ri-edit-box-line"></i>
                    </Button>
                    <Button className="btn btn-soft-info btn-icon fs-14" onClick={() => handleDeleteData(row.hotel_id,row._id)}>
                        <i className="ri-delete-bin-line"></i>
                    </Button>
                </div>
            ),
            sortable: true,
        },

    ];

    const handleInputBlur = () => {
        setIsBlurred(true);
        setShowcontactError(!validatePhoneNumber(number));

    };

    /********Data table Search****** */
    const [filterText, setFilterText] = React.useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
        false
    );
    const hotelListArray = Object.values(hotelList);
    const filteredItems = hotelListArray.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );
    statusIdentification(filteredItems);
    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <FilterComponent
                onFilter={e => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [filterText, resetPaginationToggle]);
    document.title = "DB LIMO - Hotel Onboarding";

    return (
        <React.Fragment>

            <div className="page-content">
            {/* <Row>
                    <Col xxl={12}>
                        <Section />
                    </Col>
                   </Row> */}
                <Container fluid>

                    <BreadCrumb title="Hotel Onboard" pageTitle="Home"></BreadCrumb>
                    { //isLoading ? <LoadingSpinner /> :

                        <Card>

                            <CardBody className="p-0">
                                <Row>
                                    <Col>

                                        <DataTable
                                            title={<h5 className="mb-0">Hotel Details</h5>}
                                            className="custom-datatable"
                                            columns={columnsProducts}
                                            data={filteredItems}
                                            sortIcon={<span className="custom-sort-img"><i className='bx bxs-sort-alt custom-sort-icon'></i></span>}
                                            pagination
                                            // paginationComponent={CustomPagination}
                                            // paginationComponentOptions={{
                                            //     pageNumber: pageNumber,
                                            //     setPageNumber: setPageNumber,
                                            //     totalpages: totalpages,
                                            //   }}
                                            // paginationRowsPerPageOptions={[2, 4, 8, 12, 15]}
                                            paginationServer

                                            paginationTotalRows={totalcount}
                                            onChangeRowsPerPage={handlePerRowsChange}
                                            onChangePage={handlePageChange}
                                            selectableRows={false}
                                            selectableRowsComponent={TableCheckBox}
                                            paginationComponentOptions={paginationComponentOptions}
                                            paginationRowsPerPageOptions={["10", "20", "40"]}
                                            noDataComponent={<NoResultData />}
                                            selectableRowsHighlight={true}
                                            selectableRowsVisibleOnly={false}
                                            subHeaderAlign="left"
                                            contextMenu={true}
                                            persistTableHead
                                            actions={
                                                <Row className="g-4 align-items-start align-items-lg-center flex-column flex-row-lg row">

                                                    <Col md={12} lg="auto" className="ms-lg-auto no-padding-mobile">
                                                        <div className="hstack gap-2 flex-lg-row flex-wrap">
                                                            {subHeaderComponent}
                                                            <button type="button" className="btn btn-success w-100-mobile" onClick={handleAddDialog} >
                                                                <i className="ri-add-line align-bottom me-1"></i>{" "}
                                                                Add New Hotel
                                                            </button>


                                                        </div>
                                                    </Col>
                                                </Row>

                                            }
                                        />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>}
                </Container>
            </div>
            { //isLoading ? <LoadingSpinner /> :
                <Modal
                    isOpen={isAdd}
                    toggle={handleCloseDialog}
                    centered
                    size="lg"
                    className="border-0"
                    modalClassName='modal fade zoomIn'
                >
                    <ModalHeader className="p-3 bg-light" toggle={handleCloseDialog}>
                        {!!isEdit ? 'Hotel Onboarding' : 'Hotel Onboarding'}
                    </ModalHeader>
                    <Form onSubmit={isEdit ? handleUpdateHotel : handleCreatehotel}>
                        <ModalBody>
                            {isEdit && updateAlert && updatestatus && (
                                <UncontrolledAlert color="success" onClose={() => setupdatestatus(false)}>Your Hotel has been updated successfully - {HId}</UncontrolledAlert>)}
                            {isEdit && updateAlert && !updatestatus && (
                                <UncontrolledAlert color="danger" onClose={() => setupdatestatus(false)}>   {error.status && (
                                    <div
                                        id="error"
                                        style={{

                                            textAlign: 'start'
                                        }}
                                    >
                                        <i className="bi bi-exclamation-circle" >
                                            </i>&nbsp;&nbsp;&nbsp;&nbsp;{error.message}
                                    </div>
                                )}</UncontrolledAlert>)}

                            {isAdd && addAlert && addStatus && (
                                <UncontrolledAlert color="success" onClose={() => setAddStatus(false)}>Your Hotel has been added successfully - {id}</UncontrolledAlert>)}
                            {isAdd && addAlert && !addStatus && error.status && (
                                <UncontrolledAlert color="danger" onClose={() => setAddStatus(false)}>
                                    <div
                                        id="error"
                                        style={{

                                            textAlign: 'start'
                                        }}
                                    >
                                        <i className="bi bi-exclamation-circle">
                                            </i>&nbsp;&nbsp;&nbsp;&nbsp;{error.message}
                                    </div>
                                </UncontrolledAlert>)}
                            <Row className="g-3">
                                <Col sm={12} xl={12} xxl={12} md={12}>
                                    <div className="mb-0">
                                        <Label
                                            htmlFor="hotelName"
                                            className="form-label"
                                        >
                                            Hotel Name (<span className="text-danger">*</span>)
                                        </Label>
                                        <Input
    id="hotelName"
    name="hotelName"
    type="text"
    placeholder="Enter Your Hotel Name"
    value={hotelname}
    disabled={isEdit && usercount !== 0}
        onChange={(e) => setHotelname(e.target.value)}
        required
/>
                                    </div>
                                </Col>
                                <Col xxl={6} sm={12} lg={6} md={12}>
                                    <div className="mb-0">
                                        <Label
                                            htmlFor="contactNumber"
                                            className="form-label"
                                        >
                                            Contact Number (<span className="text-danger">*</span>)
                                        </Label>
                                        <div className="input-group" >
                                            <div className="input-group-text" >
                                                +65

                                            </div>
                                            <Input
  id="contactNumber"
  name="contactNumber"
  type="number"
  placeholder="Enter Contact Number"
  value={number}
  //onChange={handleNumberChange}
  onChange={(e)=>setNumber(e.target.value)}
  onBlur={handleInputBlur}
  onFocus={handlenumberfocus}
                        className={showcontacterror && isBlurred ? 'invalid' : ''}
                        onKeyPress={(e) => {
                          const allowedKeys = /[0-9]/;
                          const key = e.key;
                          if (!allowedKeys.test(key)) {
                            e.preventDefault();
                          } else if (number.length >= 8) {
                            e.preventDefault();
                          }
                        }}
                        required
                      />
                      {showcontacterror && number && <span style={{ color: 'red' }}>Please enter a valid 8-digit phone number</span>}


                                    </div>

                                    </div>
                                </Col>
                                <Col xxl={6} sm={12} lg={6} md={12}>
                                    <div className="mb-0">
                                        <Label
                                            htmlFor="email"
                                            className="form-label"
                                        >
                                            Email Address (<span className="text-danger">*</span>)
                                        </Label>
                                        <Input
                                            id="email"
                                            name="email"
                                            type="email"
                                            placeholder="Enter Email Address"

                                            value={email} onChange={handleEmailChange} required
                      onBlur={handleBlur}
                      onFocus={handleFocus}
                      className={showError ? 'invalid' : ''}
      />
      {showError && email && <span style={{ color: 'red' }}>Please enter a valid email address</span>}



                                    </div>
                                </Col>
                                <Col xxl={6} sm={12} lg={6} md={12}>
                                    <div className="mb-0">
                                        <Label
                                            htmlFor="address1"
                                            className="form-label"
                                        >
                                            Address 1 (<span className="text-danger">*</span>)
                                        </Label>

                                        <Input
                                            id="address1"
                                            name="address1"
                                            type="text"
                                            placeholder="Enter Hotel Address"
                                            value={address1} onChange={(e) => setAddress1(e.target.value)} required
                                        />

                                    </div>
                                </Col>
                                <Col xxl={6} sm={12} lg={6} md={12}>
                                    <div className="mb-0">
                                        <Label
                                            htmlFor="address2"
                                            className="form-label"
                                        >
                                            Address 2
                                        </Label>
                                        <Input
                                            id="address2"
                                            name="address2"
                                            type="text"
                                            placeholder="Enter Hotel Address"
                                            value={address2} onChange={(e) => setAddress2(e.target.value)}
                                        />
                                    </div>
                                </Col>
                                <Col xxl={6} sm={12} lg={6} md={12}>
                                    <div className="mb-0">
                                        <Label
                                            htmlFor="city"
                                            className="form-label"
                                        >
                                            City
                                        </Label>
                                        <Input
                                            className="mb-0"
                                            classNamePrefix="form-control-select"
                                            // value={cityOptions2.find((option) => option.value === selectCity)}
                                            placeholder="Select City"
                                            value="Singapore"
                                            style={{ userSelect: "none", cursor: "not-allowed", background: " rgba(var(--vz-light-rgb), .75)" }}
                                            //onChange={handleCityChange2}
                                            //options={cityOptions2}
                                            id="category-select"

                                        />

                                    </div>
                                </Col>
                                <Col xxl={6} sm={12} lg={6} md={12}>
                                    <div className="mb-0">
                                        <Label
                                            htmlFor="address2"
                                            className="form-label"
                                        >
                                            Country
                                        </Label>

                                        <Input
                                            placeholder="Select Country"
                                            className="mb-0"
                                            classNamePrefix="form-control-select"
                                            id="country-code-picker"
                                            value="Singapore"
                                            style={{ userSelect: "none", cursor: "not-allowed", background: " rgba(var(--vz-light-rgb), .75)" }}
                                        //value={options.find((option) => option.label === country)}
                                        // onChange={changeHandler}
                                        //options={options}
                                        />
                                    </div>
                                </Col>
                                <Col xxl={12} sm={12} lg={6} md={12}>
                                    <div className="form-check form-switch form-check-primary mt-0">
                                        <Input
                                            className="form-check-input"
                                            type="checkbox"
                                            role="switch"
                                            id="ActiveStatus"
                                            checked={isActiveChecked}
                                            onChange={handleActiveStatusChange}

                                        /> <Label className="form-check-label" for="ActiveStatus">Hotel Status Active?</Label>
                                    </div>
                                </Col>
                            </Row>
                          {/*  <div >
                                  {error.status && (
                                <div
                                    id="error"
                                    style={{
                                        color: 'red',
                                        fontWeight: 'bold',
                                        textAlign: 'start'
                                    }}
                                >
                                    <i className="bi bi-exclamation-circle" style={{ fontWeight: 'bold' }}></i>&nbsp;&nbsp;&nbsp;&nbsp;{error.message}
                                </div>
                            )}
                            </div>*/}
                        </ModalBody>
                        <ModalFooter className="justify-content-start">
                            <div className="hstack gap-2">
                                {isEdit ? (<button type="submit" className="btn btn-success" id="add-btn1" >Update Hotel</button>) :
                                    (<button type="submit" className="btn btn-success" id="add-btn">Add Hotel</button>)}
                                <button type="button" className="btn btn-light border_gry" data-bs-dismiss="modal" onClick={handleCloseDialog}>Cancel</button>
                            </div>
                        </ModalFooter>
                    </Form>
                </Modal>}
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteData1}
                onCloseClick={() => {
                    setSelectedId(null);
                    setDeleteModal(false);
                }}
                entityName="Hotel"
                entityId={HId}
            />
        </React.Fragment>
    )
}

export default HotelOnBoarding